import { Button, makeStyles, TextField } from "@material-ui/core";
import styled, { css } from "styled-components";
interface IBtnColor {
  customcolor: string;
}
export const BtnContained = styled(Button)<IBtnColor>`
  &.MuiButton-root {
    border-radius: 40px;
    min-width: 130px;
    text-transform: none;
    font-weight: 700;
    font-size: 14px;
    box-shadow: none;
  }
  ${({ customcolor }) =>
    customcolor === "purple" &&
    css`
      &:hover {
        background: #bfc0ff;
      }
      background: #a5a6f6;
      color: #ffffff;
    `}
  ${({ customcolor }) =>
    customcolor === "green" &&
    css`
      &:hover {
        background: #64dba9;
      }
      background: #60d0a1;
      color: #ffffff;
    `}
${({ customcolor }) =>
    customcolor === "yellow" &&
    css`
      &:hover {
        background: #ffca6c;
      }
      background: #f9b233;
      color: #ffffff;
    `}
${({ customcolor }) =>
    customcolor === "blue" &&
    css`
      &:hover {
        background: #67bde0;
      }
      background: #51b2da;
      color: #ffffff;
    `}
    ${({ customcolor }) =>
    customcolor === "red" &&
    css`
      &:hover {
        background: #f57373;
      }
      background: #f15f5f;
      color: #ffffff;
    `}
    ${({ customcolor }) =>
    customcolor === "white" &&
    css`
      background: #f5f5f7;
      color: #5a5a5a;
    `}
`;
export const BtnText = styled(Button)<IBtnColor>`
  &.MuiButton-root {
    border-radius: 40px;
    padding: 9px 19px;
    margin: 0px 8px;
    text-transform: none;
    font-weight: 400;
    font-size: 14px;
    width: 130px;
    border: none;
  }
  ${({ customcolor }) =>
    customcolor === "red" &&
    css`
      color: #f15f5f;
    `}
  ${({ customcolor }) =>
    customcolor === "blue" &&
    css`
      color: #51b2da;
    `}
`;
export const BtnOutline = styled(Button)<IBtnColor>`
  &.MuiButton-root {
    border-radius: 40px;
    text-transform: none;
    font-weight: 400;
    font-size: 14px;
    width: 130px;
    padding: 5px 0px;
  }
  ${({ customcolor }) =>
    customcolor === "blue" &&
    css`
      color: #51b2da;
      border: 1px solid #51b2da;
    `}
  ${({ customcolor }) =>
    customcolor === "red" &&
    css`
      color: #f15f5f;
      border: 1px solid #f15f5f;
    `}
`;
export const BtnContainedAction = styled(Button)<IBtnColor>`
  &.MuiButton-root {
    border-radius: 40px;
    padding: 9px 30px;
    // width:130px;
    margin: 0px 8px;
    text-transform: none;
    font-weight: 700;
    box-shadow: none;
  }
  ${({ customcolor }) =>
    customcolor === "purple" &&
    css`
      &:hover {
        background: #bfc0ff;
      }
      background: #a5a6f6;
      color: #ffffff;
    `}
  ${({ customcolor }) =>
    customcolor === "green" &&
    css`
      &:hover {
        background: #64dba9;
      }
      background: #60d0a1;
      color: #ffffff;
    `}
${({ customcolor }) =>
    customcolor === "yellow" &&
    css`
      &:hover {
        background: #ffca6c;
      }
      background: #f9b233;
      color: #ffffff;
    `}
${({ customcolor }) =>
    customcolor === "blue" &&
    css`
      &:hover {
        background: #67bde0;
      }
      background: #51b2da;
      color: #ffffff;
    `}
    ${({ customcolor }) =>
    customcolor === "red" &&
    css`
      &:hover {
        background: #f57373;
      }
      background: #f15f5f;
      color: #ffffff;
    `}
    ${({ customcolor }) =>
    customcolor === "white" &&
    css`
      background: #f5f5f7;
      color: #5a5a5a;
    `}
`;
