import {
  Avatar,
  Button,
  IconButton,
  Tooltip,
  useMediaQuery,
  withStyles,
} from "@material-ui/core";
import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import Bus_hits_Electric_pole from "../../SceneManagement/assetMock/CarCrash-01.png";
import Bus_accident from "../../SceneManagement/assetMock/ChemicalFactory-01.png";
interface Props {
  scenarios: any | undefined;
}

const renderPrevButton = ({ isDisabled, certificates }: any) => {
  return (
    <>
      <IconButton
        style={{
          backgroundColor: "#B9B9B9",
          width: 40,
          height: 40,
          position: "absolute",
          top: "calc(50% - 60px)",
          left: -10,
          opacity: isDisabled ? "0.5" : 1,
        }}
        disabled={isDisabled}
      >
        <ArrowBackIcon style={{ color: "white", width: 25, height: 25 }} />
      </IconButton>
    </>
  );
};

const renderNextButton = ({ isDisabled }: any) => {
  return (
    <>
      <IconButton
        style={{
          backgroundColor: "#B9B9B9",
          width: 40,
          height: 40,
          position: "absolute",
          top: "calc(50% - 60px)",
          right: -10,
          opacity: isDisabled ? "0.5" : 1,
        }}
        disabled={isDisabled}
      >
        <ArrowForwardIcon style={{ color: "white", width: 25, height: 25 }} />
      </IconButton>
    </>
  );
};

const handleItem = (scenarios: any | undefined, history: any) => {
  if (Array.isArray(scenarios)) {
    const InstructorMapped = scenarios.map((el: any) => {
      return (
        <div
          className="item"
          data-value="1"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            style={{
              backgroundColor: "#60D0A1",
              width: 281,
              height: 283,
              borderRadius: 8,
            }}
            onClick={() => {
              history.push({
                pathname: "/list-of-scenario",
                state: { scenarioId: el?._id },
              });
            }}
          >
            <div>
              <div
                style={{
                  width: "246px",
                  height: "178px",
                  margin: "auto",
                }}
              >
                <img
                  src={
                    el.desc === "Bus hits Electric pole"
                      ? Bus_hits_Electric_pole
                      : Bus_accident
                  }
                  alt=""
                  width="100%"
                  height="100%"
                  style={{
                    marginTop: -15,
                    border: "3px solid white",
                    borderRadius: 8,
                  }}
                ></img>
              </div>
              <div
                style={{
                  color: "white",
                  fontSize: 16,
                  fontWeight: 700,
                  textAlign: "left",
                  textTransform: "none",
                }}
              >
                {el.code}
              </div>
              <div
                style={{
                  color: "white",
                  marginTop: 5,
                  fontSize: 12,
                  fontWeight: 400,
                  textAlign: "left",
                }}
              >
                {el.desc}
              </div>
            </div>
          </Button>
        </div>
      );
    });
    return InstructorMapped;
  }
};
export default function OurScenarios({ scenarios }: Props) {
  const matches = useMediaQuery("(min-width:1200px)");

  const responsive = {
    4000: {
      items: 5,
    },
    1500: {
      items: matches ? 2 : 3,
    },
    820: {
      items: matches ? 2 : 2,
    },
    750: {
      items: matches ? 1 : 2,
    },
    0: {
      items: 1,
    },
  };
  const history = useHistory();

  return (
    <div>
      <AliceCarousel
        mouseTracking
        disableDotsControls
        items={handleItem(scenarios, history)}
        responsive={responsive}
        renderPrevButton={renderPrevButton}
        renderNextButton={renderNextButton}
        //   autoWidth
      />
    </div>
  );
}
