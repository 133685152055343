import { Avatar, Box, Button, FormControlLabel, Grid, IconButton, Switch, Typography, useMediaQuery, withStyles } from "@material-ui/core";
import { ReactComponent as DateTime } from "../../../commons/asset/IconDateTime.svg";
import { ReactComponent as ScenarioIcon } from "../../../commons/asset/Scenario.svg";
import { ReactComponent as SituationIcon } from "../../../commons/asset/Situation.svg";
import { ReactComponent as PatientIcon } from "../../../commons/asset/Patient.svg";
import { ReactComponent as Detail } from "../../../commons/asset/detail.svg";
import dayjs from "dayjs";
import Tooltip from '@material-ui/core/Tooltip';
import { convertStringToImg } from "../../../commons/ConvertImg";
import { ButtonContained, ButtonContainedAction } from "../../../commons/Buttons";
import { Unity, useUnityContext } from "react-unity-webgl";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAuth } from "../../../../contexts/AuthContext";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import RecheckDialog from '../../../commons/RecheckDialog';
import { ITextConfirmDetail } from '../../ProfileManagement/Profile';

interface Props {
  classroom: any;
}
interface ITextConfirm {
  DELETE?: ITextConfirmDetail;
}
const TextConfirmPopup: ITextConfirm = {
  DELETE: {
    title: "Exit Simulation?",
    subTitle: "Are you sure you want to stop the simulation?",
    yes: "Stop",
    no: "No",
    color: "red",
  }
};
const BlueOnGreenTooltip = withStyles({
  tooltip: {
    color: "#51B2DA",
    backgroundColor: "white",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px;x 29px 0px",
    borderRadius:16,
    fontWeight: 400,
    padding: "20px 50px 20px 15px",
    fontSize: 12,
  },
})(Tooltip);
export default function VrSpectator() {
  const [classroom, setClassroom] = useState<any>();
  const history = useHistory();
  const [confirmPopup, setConfirmPopup] = useState<ITextConfirmDetail>();
  const [students, setStudents] = useState<any>([]);
  const [selectedInspector, setSelectedInspector] = useState<string>('');
  const { currentUser } = useAuth();
  const [triagedAmount, SetTriagedAmount] = useState<number>();
  const [ownView, setOwnView] = useState(false);
  const location: any = useLocation();
  const [openRecheck, setOpenRecheck] = useState(false);
  const iframeRef = useRef<any>(null);
  const matches = useMediaQuery("(min-width:600px)");
  const {
    unityProvider,
    sendMessage,
    addEventListener,
    removeEventListener,
    loadingProgression,
    unload
  } = useUnityContext({
    loaderUrl: "Build/20102022_RVRT_WebGL_Test.loader.js",
    dataUrl: "Build/20102022_RVRT_WebGL_Test.data",
    frameworkUrl: "Build/20102022_RVRT_WebGL_Test.framework.js",
    codeUrl: "Build/20102022_RVRT_WebGL_Test.wasm",
  });
  const CustomSwitch = withStyles({
    switchBase: {
      color: "#B9B9B9",
      "&$checked": {
        color: "#51B2DA",
      },
      "&$checked + $track": {
        backgroundColor: "#51B2DA",
      },
    },
    checked: {},
    track: {
      opacity: 1,
          backgroundColor: "#E4E4E4",
          "$checked$checked + &": {
            opacity:1,
            backgroundColor: "#D8F4FF"
          }
    },
  })(Switch);
  useEffect(() => {
    setClassroom(location.state);
  }, []);
  const detailsWaiting = [
    {
      content: "Date & Time",
      value: `${dayjs(classroom?.date).format("DD/MM/YYYY HH:mm A")}`,
      icon: <DateTime />,
      fontWeight: 700,
      color: "#5A5A5A",
    },
    {
      content: "Scenario",
      value: `${classroom?.scenario?.code}`,
      icon: <ScenarioIcon />,
      fontWeight: 700,
      color: "#5A5A5A",
    },
    {
      content: "Situation",
      value: `${classroom?.scenario?.desc}`,
      icon: <SituationIcon />,
      fontWeight: 700,
      color: "#5A5A5A",
    },
    {
      content: "Number of Patients",
      value: `${classroom?.scenario?.patientMaps?.length}`,
      icon: <PatientIcon />,
      fontWeight: 700,
      color: "#5A5A5A",
    },
  ];
  function initInspector() {
      sendMessage("MainAppUnityLifeCycle", "SetPlayerName", currentUser?.firstName && currentUser?.firstName + currentUser?.lastName && currentUser?.lastName);
      sendMessage("MainAppUnityLifeCycle", "SetSelectedClassroomName", classroom.classId);
      sendMessage("MainAppUnityLifeCycle", "ConnectToPhoton");
      sendMessage("LobbyPhotonManager","SetSceneDesc", classroom?.scenario?.desc);
      sendMessage("LobbyPhotonManager", "SetClassStatus", classroom?.status);
  }
  function sendPatientMapsJSON() {
    const msg = JSON.stringify({ patientMaps: location?.state?.scenario?.patientMaps})
    sendMessage("SceneSettingManager", "SetPatientMaps", msg);
  }
  function sendHarmFactorJSON() {
    const msg = JSON.stringify({ harmFactors: location?.state?.scenario?.harmFactors })
    sendMessage("SceneSettingManager", "SetHarmFactorMaps", msg);
  }
  function resetCamera() {
    sendMessage("SpectatorViewManager", "ResetToControllerMode");
  }
  function testSpectator(mail:string){
    if(!ownView){
      if(mail === selectedInspector){
        resetCamera();
        setSelectedInspector('');
      }
      else{
        sendMessage("SpectatorViewManager", "SelectedPlayerSpectator", mail);
        setSelectedInspector(mail);
      }
    }
  }
  const getPlayerList = useCallback((playerList) => {
    const player = JSON.parse(playerList);
    if(player?.playerData.length > 0 && students.length === 0) {
     const tmp = player?.playerData.map((ele:any)=>{
      const std = location.state.students.find((item2:any)=>{
        return item2.email == ele.email;
      });
        return std;
      })
      const test = tmp.find((item:any)=>item.email === selectedInspector)
      if(!test){
        setSelectedInspector('');
      }
      setStudents(tmp);
    }
    else {
      setStudents(JSON.parse(playerList));
      setSelectedInspector('');
    }
  }, []);
  const handleSwitch = () =>{
    if(!ownView === true){
      setSelectedInspector('');
      resetCamera();
    }
    setOwnView(!ownView)
  }
  function handleCloseRecheck() {
    setOpenRecheck(false);
  }
  async function handleSubmit() {
    //To stop simulation
    history.push('/classroom-management');
    sendMessage("GameplayPhotonManager", "ToStopSimulation");
    setOpenRecheck(false);
    // await unload();
  }
  function testSendWindDirection() {
    sendMessage("SceneSettingManager", "SetCarParkPosition", location.state.scenario.direction);
}
const GetTriagedAmount = useCallback((amount:number) => {
  SetTriagedAmount(amount);
  console.log(triagedAmount);
}, []);
  useEffect(() => {
      addEventListener("SendPlayerList", getPlayerList);
      return () => {
          removeEventListener("SendPlayerList", getPlayerList);
  };
  }, [addEventListener, removeEventListener, getPlayerList]);
  useEffect(() => {
    addEventListener("InitializedData", initInspector);
    return () => {
        removeEventListener("InitializedData", initInspector);
    };
  }, [addEventListener, removeEventListener, initInspector]);
  useEffect(() => {
    addEventListener("GetPatientMapsData", sendPatientMapsJSON);
    return () => {
        removeEventListener("GetPatientMapsData", sendPatientMapsJSON);
    };
  }, [addEventListener, removeEventListener, sendPatientMapsJSON]);
  useEffect(() => {
    addEventListener("GetHarmFactorMapsData", sendHarmFactorJSON);
    return () => {
        removeEventListener("GetHarmFactorMapsData", sendHarmFactorJSON);
    };
  }, [addEventListener, removeEventListener, sendHarmFactorJSON]);
  useEffect(() => {
    addEventListener("GetWindDirection", testSendWindDirection);
    return () => {
        removeEventListener("GetWindDirection", testSendWindDirection);
    };
}, [addEventListener, removeEventListener, testSendWindDirection]);

useEffect(() => {
  addEventListener("SendTriagedAmount", GetTriagedAmount);
  return () => {
      removeEventListener("SendTriagedAmount", GetTriagedAmount);
  };
}, [addEventListener, removeEventListener, GetTriagedAmount]);
//To handle to stop simulation on React side
  useEffect(() => {
      addEventListener("ToStopSimulation", toHandleStopSimulation);
      return () => {
          removeEventListener("ToStopSimulation", toHandleStopSimulation);
  };
  }, [addEventListener, removeEventListener, toHandleStopSimulation]);
  function toHandleStopSimulation(){
    setOpenRecheck(true);
    setConfirmPopup(TextConfirmPopup['DELETE']);
  //Do sth
  }
  useEffect(()=>{ 
    return ()=>{
      window.location.reload();
    }
  } ,[])
  useEffect(() => {
    console.log('AA',selectedInspector)
  }, [selectedInspector])
  useEffect(() => {
    if(students.length === 0){
      setSelectedInspector('');
      resetCamera();
    }
  }, [students])
  
  
  return (
    <div>
      <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
      <Typography
        style={{
          color: "#5A5A5B",
          fontWeight: 700,
          fontSize: 32,
          marginRight: 10,
        }}
      >
        VR Spectator
      </Typography>
      <Typography
        style={{
          color: "rgba(156, 155, 157, 1)",
          fontWeight: 700,
          fontSize: 14,
        }}
      >
        Current Triage Patient Amount : {triagedAmount}/{classroom?.scenario?.patientMaps.length}
      </Typography>
      </div>
   
      {/* <Button onClick={handleSubmit}>AAA</Button> */}
      <Grid container spacing={2} style={{maxWidth:1041}}>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <div
          id="iframeContainer" ref={iframeRef}
            style={{ position: "relative",maxWidth: 1041 }}
          >
            {Math.round(loadingProgression * 100) !== 100 && <p style={{textAlign:'center',marginTop:30}}>Loading Application... {Math.round(loadingProgression * 100)}%</p>}
            <Unity
              unityProvider={unityProvider}
              style={{ width: "90%", height: "90%" }}
            />
            {/* <Button onClick={toHandleStopSimulation}>BB</Button> */}
             {/* <ButtonContainedAction
                colorText="red"
                // disabled={(rowData.status !== "PENDING" && index === 0 ) || (dayjs(rowData.classDate) >= dayjs(Date.now()) && index === 0)}
                // onClick={() => {
                //   changeState(item.code, rowData);
                // }}
                style={{ 
                fontWeight: 400,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                position:'absolute',
                padding: matches ? "6px 50px" : "0px",
                fontSize: matches ? 14 : 10,
                right: matches ? 120 : 40,
                top: 10 
                }}
                >
                  Exit
              </ButtonContainedAction> */}
          </div>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item lg={5} xl={5} md={5} sm={5} xs={12}>
          <div style={{ color: "#5A5A5A", fontSize: 16, fontWeight: 700 }}>
            Details
          </div>
          <div
            style={{
              padding: 20,
              boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.15)",
              marginTop: 16,
              borderRadius: 8,
            }}
          >
            {detailsWaiting.map((item, index) => {
              return (
                <Grid container key={index} spacing={3}>
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      fontWeight: item.fontWeight,
                      color: item.color,
                    }}
                  >
                    <div style={{ marginRight: 8 }}>{item.icon}</div>
                    <div> {item.content}</div>
                  </Grid>
                  <Grid item xs={6} style={{ fontSize: 14 }}>
                    {item.value}
                  </Grid>
                </Grid>
              );
            })}
          </div>
        </Grid>
        <Grid item lg={5} xl={5} md={5} sm={5} xs={12}>
          <div style={{display:'flex',alignItems:'center',justifyContent:"space-between",padding:0}}>
            <div>
              <span style={{ color: "#5A5A5A", fontSize: 16, fontWeight: 700 }}>
                Student
              </span>
              <span style={{fontSize:11, marginLeft:7, color:'rgba(90, 90, 90, 1)'}}>{students?.length ? students.length : 0 +"/"+classroom?.students?.length}</span>
            </div>
            <div>
            <CustomSwitch
              color="primary"
              size="small"
              checked={ownView}
              onChange={handleSwitch}
            />
            <span style={{color:'rgba(90, 90, 90, 1)',fontSize:12,fontWeight:700}}>Own View</span>
            <BlueOnGreenTooltip placement="top" title="Turn on to enable manual controls." style={{ marginLeft: 3,width:15,height:15 }}>
              <Detail />
            </BlueOnGreenTooltip>
            </div>
           
          </div>
          <Box
            style={{
              marginTop: "15px",
              height: 225,
              boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.15)",
              borderRadius: 8,
              overflowY: "auto",
            }}
          >
            <Box padding="10px">
              {students && students.length > 0 && students.map((ele: any, index: number) => {
                  return (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      marginLeft="8px"
                      marginTop="8px"
                      justifyContent="space-between"
                    >
                      <Box key={index} display="flex" padding="6px 8px">
                        <Avatar src={convertStringToImg(ele)}></Avatar>
                        <Box marginLeft="22px">
                          <Typography
                            style={{
                              color: "#5A5A5A",
                              fontWeight: 700,
                              fontSize: "14px",
                              textAlign: "start",
                            }}
                          >
                            {ele.firstName+" "+ele.lastName}
                          </Typography>
                          <Typography
                            style={{
                              color: "#5A5A5A",
                              fontWeight: 400,
                              fontSize: "10px",
                            }}
                          >
                            {ele.email}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <IconButton onClick={()=>testSpectator(ele.email)}>
                          {selectedInspector === ele.email ? <VisibilityIcon /> : <VisibilityOffIcon/>}
                        </IconButton>
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <RecheckDialog
        open={openRecheck}
        handleClose={handleCloseRecheck}
        confirmNo={handleCloseRecheck}
        confirmYes={handleSubmit}
        confirmPopup={confirmPopup}
      />
    </div>
  );
}
