import axios from "axios";
import { createHashHistory } from "history";

const history = createHashHistory();

const axiosInstance = axios.create({
  baseURL: process.env.DEV_API,
});

axiosInstance.interceptors.response.use(
  function (response) {
    if (history.location.hash !== "") {
      history.push({ hash: "" });
    }

    return response;
  },
  function (error) {
    if (error && error.message === "Network Error") {
      console.log("HAVE PROBLEM ON axios INTERCEPTOR = 503 ");
      if (
        history.location.hash === "" ||
        history.location.hash === "error502"
      ) {
        history.push({ hash: "error503" });
      }

      // throw new Error(
      //   `Potential network CORS preflight error at ${error.config.url}`
      // );
    }
    if (error.response.status === 401) {
      console.log("HAVE PROBLEM ON axios INTERCEPTOR = 401 ");
      if (history.location.hash === "") {
        history.push({ hash: "error401" });
      }
    } else if (error.response.status === 500) {
      console.log("HAVE PROBLEM ON axios INTERCEPTOR = 500 ");
      if (
        history.location.hash === "" ||
        history.location.hash === "error401"
      ) {
        if (
          error.response.data.message === "jwt expired" ||
          error.response.data.name === "JsonWebTokenError"
        ) {
          history.push({ hash: "tokenExpired" });
        }
      }
    } else if (error.response.status === 502) {
      console.log("HAVE PROBLEM ON axios INTERCEPTOR = 502 ");
      if (
        history.location.hash === "" ||
        history.location.hash === "error401"
      ) {
        history.push({ hash: "error502" });
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
