import { Avatar, IconButton, Tooltip, withStyles } from "@material-ui/core";
import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import MockGirl from "../../../commons/asset/girl.png";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { IUser } from '../Dashboard';

const BlueOnGreenTooltip = withStyles({
  tooltip: {
    marginTop: 100,
    color: "rgba(0, 0, 0, 1)",
    backgroundColor: "#F6FAFF",
    fontWeight: 300,
    padding: "20px 50px 20px 15px",
    fontSize: 12,
  },
})(Tooltip);

const responsive = {
  1400: {
    items: 5,
  },
  1000: {
    items: 3,
  },
  0: {
    items: 1,
  },
};
interface Props {
  user: Array<IUser> | undefined;
}
const renderPrevButton = ({ isDisabled }: any) => {
  return (
    <IconButton
      style={{
        backgroundColor: "#B9B9B9",
        width: 25,
        height: 25,
        position: "absolute",
        top: 15,
        left: -10,
        opacity: isDisabled ? "0.5" : 1,
      }}
      disabled={isDisabled}
    >
      <ArrowBackIcon style={{ color: "white", width: 15, height: 15 }} />
    </IconButton>
  );
};

const renderNextButton = ({ isDisabled }: any) => {
  return (
    <IconButton
      style={{
        backgroundColor: "#B9B9B9",
        width: 25,
        height: 25,
        position: "absolute",
        top: 15,
        right: -10,
        opacity: isDisabled ? "0.5" : 1,
      }}
      disabled={isDisabled}
    >
      <ArrowForwardIcon style={{ color: "white", width: 15, height: 15 }} />
    </IconButton>
  );
};

const handleItem = (user: Array<IUser> | undefined) => {
  if (Array.isArray(user)) {
    const InstructorMapped = user.map((el: any) => {
      return (
        <div
          className="item"
          data-value="1"
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "10px 0px",
            width: 120,
          }}
        >
          <BlueOnGreenTooltip
            PopperProps={{
              style: { marginBottom: -80 },
            }}
            title={
              <div>
                <div
                  style={{
                    fontSize: 16,
                    color: "rgba(81, 178, 218, 1)",
                    fontWeight: 700,
                  }}
                >
                  {`${el.firstName} ${el.lastName}`}
                </div>
                <div>Ramathibodi Hospital</div>
                <div style={{ marginTop: 14 }}>{`${el.email}`}</div>
                <div style={{ marginTop: 4 }}>{`${el.tel}`}</div>
              </div>
            }
            placement="top"
            style={{
              fontSize: "2em",
              color: "yellow",
            }}
          >
            <Avatar src={MockGirl}> </Avatar>
          </BlueOnGreenTooltip>
        </div>
      );
    });
    return InstructorMapped;
  }
};
const OurUsers = ({ user }: Props) => (
  <div>
    <AliceCarousel
      mouseTracking
      disableDotsControls
      items={handleItem(user)}
      responsive={responsive}
      renderPrevButton={renderPrevButton}
      renderNextButton={renderNextButton}
      // autoWidth
    />
  </div>
);
export default OurUsers;
