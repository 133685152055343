import { PayloadAction } from '@reduxjs/toolkit'
import { v1 as uuid } from 'uuid'

import { ITests } from './type'

export const testsReducer = {
	create: {
		reducer: (state: ITests[], action: PayloadAction<{ id: string; desc: string; isComplete: boolean }>) => {
			state.push(action.payload)
		},
		prepare: ({ desc }: { desc: string }) => ({
			payload: {
				id: uuid(),
				desc,
				isComplete: false
			}
		})
	}
}
