import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import HttpsIcon from "@material-ui/icons/Https";
import InstructorImg from "../../../../images/pages/loginPage/instructor.png";
import StudentImg from "../../../../images/pages/loginPage/student.png";
import Congratulation from "../../../../images/pages/loginPage/Frame.png";
import EmailIcon from "@material-ui/icons/Email";
import * as Yup from "yup";
import { ReactComponent as ArrowRight } from "../../../../images/pages/loginPage/arrowRight.svg";
import { ButtonSelect, useStyles } from "./style";
import { SelectField, TextFieldCustom } from "../../../commons/Inputs";
import {
  addRegisterInstructorApi,
  addRegisterStudentApi,
  fetchOrganizationsApi,
  IRegister,
} from "../../../../services/Users/users.servies";
import { IOrganization } from "../../UserManagement/User";
import { useSnackbar } from "notistack";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Occupations } from '../../UserManagement/User/Dialog/Preview';
interface Props {
  handleState: (text: string) => void;
}

const CreateForm = ({ handleState }: Props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [organizationAll, setOrganizationAll] = useState<IOrganization[]>([]);
  const [step, setStep] = useState(0);
  const [sameEmail, setSameEmail] = useState(false);
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Must be a valid email")
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Must be a valid password")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Password is required"),
    firstName: Yup.string().required("Firstname is required"),
    lastName: Yup.string().required("Lastname is required"),
    gender: Yup.string().required("Gender is required"),
    organization: Yup.string().required("Organization is required"),
    occupation: Yup.string().required("Occupation is required"),
    birthDate: Yup.string().required("Birthdate is required"),
    tel: Yup.string().required("Telephone is required"),
    oculusEmail: Yup.string()
      .email("Must be a valid oculus email")
      .required("Email Oculus is required"),
  });
  const formik = useFormik({
    initialValues: {
      role: "",
      email: "",
      oculusEmail: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      gender: "",
      organization: "",
      occupation: "",
      occupationTmp: "",
      birthDate: "",
      tel: "",
      showPassword: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const data: IRegister = {
        email: values.email,
        oculusEmail: values.oculusEmail,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName,
        gender: values.gender,
        organization: values.organization,
        occupation: Occupations.includes(formik.values.occupation) ? formik.values.occupation : formik.values.occupationTmp,
        birthDate: values.birthDate,
        tel: values.tel,
        img: "",
      };
      if (values.role === "Instructor") {
        addRegisterInstructor(data);
      } else {
        addRegisterStudent(data);
      }
    },
  });
  function nextStep() {
    if (step < 3) {
      setStep(step + 1);
    }
  }
  function getBtnShow() {
    if (
      !formik.errors.confirmPassword &&
      !formik.errors.email &&
      !formik.errors.password &&
      !formik.errors.confirmPassword &&
      step === 1
    ) {
      return true;
    } else if (formik.values.role && step === 0) {
      return true;
    } else {
      return false;
    }
  }
  function backStep() {
    if (step > 0) {
      setStep(step - 1);
    } else {
      handleState("login");
    }
  }
  function fetchOrganization() {
    fetchOrganizationsApi()
      .then((res) => {
        if (res && res.data && res.data.result) {
          setOrganizationAll(res.data.result);
        }
      })
      .catch((err) => {
      });
  }
  function selectRole(roleName: string) {
    formik.setFieldValue("role", roleName);
  }
  function addRegisterInstructor(data: IRegister) {
    addRegisterInstructorApi(data)
      .then((res) => {
        if (res.data) {
          enqueueSnackbar("Created Success", {
            variant: "success",
          });
          nextStep();
        } else {
          enqueueSnackbar("Created Error", {
            variant: "error",
          });
        }
      })
      .catch(() => {
        enqueueSnackbar("Created Error", {
          variant: "error",
        });
      });
  }
  function addRegisterStudent(data: IRegister) {
    addRegisterStudentApi(data)
      .then((res) => {
        if (res.data) {
          enqueueSnackbar("Created Success", {
            variant: "success",
          });
          nextStep();
        } else {
          enqueueSnackbar("Created Error", {
            variant: "error",
          });
        }
      })
      .catch(() => {
        enqueueSnackbar("Created Error", {
          variant: "error",
        });
      });
  }
  const renderSelectRole = () => {
    return (
      <div style={{ marginLeft: -10 }}>
        <Typography
          style={{
            fontSize: 32,
            color: "#5A5A5A",
            fontWeight: 700,
          }}
        >
          Create New Account
        </Typography>
        <Typography style={{ color: "#9C9B9D", fontSize: 16, marginTop: 10 }}>
          Please select your role
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Button
              className={classes.btnRole}
              fullWidth
              variant="outlined"
              style={{
                border: `${
                  formik.values.role === "Instructor"
                    ? "4px solid #51B2DA"
                    : "1px solid #9C9B9D"
                }`,
                borderRadius: "8px",
                color:
                  formik.values.role === "Instructor" ? "#51B2DA" : "#9C9B9D",
              }}
              onClick={() => selectRole("Instructor")}
              startIcon={
                <Avatar
                  src={InstructorImg}
                  style={{ width: 108, height: 108, margin: "0 auto" }}
                />
              }
            >
              Instructor
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              className={classes.btnRole}
              fullWidth
              style={{
                border: `${
                  formik.values.role === "Student"
                    ? "4px solid #51B2DA"
                    : "1px solid #9C9B9D"
                }`,
                borderRadius: "8px",
                color: formik.values.role === "Student" ? "#51B2DA" : "#9C9B9D",
              }}
              variant="outlined"
              onClick={() => selectRole("Student")}
              startIcon={
                <Avatar
                  src={StudentImg}
                  style={{ width: 108, height: 108, margin: "0 auto" }}
                />
              }
            >
              Student
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  };
  function handleClickShowPassword() {
    formik.setFieldValue("showPassword", !formik.values.showPassword);
  }
  function handleCheckboxSameEmail() {
    if (!sameEmail === true) {
      formik.setFieldValue("oculusEmail", formik.values.email);
    } else {
      formik.setFieldValue("oculusEmail", "");
    }
    setSameEmail(!sameEmail);
  }
  function Occupation(role: string) {
    if (role === "Instructor") {
      return ["Doctor", "Nurse", "First responder", "Other"];
    } else {
      return [
        "Medical Student",
        "Nursing Student",
        "Paramedic Student",
        "Other",
      ];
    }
  }
  const createEmail = () => {
    return (
      <>
        <Typography
          style={{
            fontSize: 32,
            marginTop: 50,
            color: "#5A5A5A",
            fontWeight: 700,
          }}
        >
          Create Account
        </Typography>
        <Typography style={{ color: "#9C9B9D", fontSize: 16 }}>
          {formik.values.role}
        </Typography>
        <Grid container style={{ marginTop: 30 }}>
          <Grid item xs={12}>
            <TextFieldCustom
              name="email"
              InputLabelProps={{
                shrink: true,
              }}
              type="text"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Email"
              margin="dense"
              variant="outlined"
              error={formik.touched.email && formik.errors.email ? true : false}
              textAlert={
                formik.errors.email &&
                formik.touched.email &&
                formik.errors.email
              }
              // helperText={
              //   formik.errors.email &&
              //   formik.touched.email &&
              //   formik.errors.email
              // }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <div style={{ padding: 12 }}>
                      <div className={classes.colorIcon}>
                        <EmailIcon />
                      </div>
                    </div>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldCustom
              // onKeyDown={(event) => {
              //   if (event.key === "Enter") {
              //     formik.submitForm();
              //   }
              // }}
              error={
                formik.errors.password && formik.touched.password ? true : false
              }
              textAlert={
                formik.errors.password &&
                formik.touched.password &&
                formik.errors.password
              }
              id="password"
              name="password"
              value={formik.values.password}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              style={{ width: "100%", maxWidth: 566 }}
              placeholder="Password"
              margin="dense"
              variant="outlined"
              type={formik.values.showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      size="small"
                      edge="end"
                    >
                      <div className={classes.colorIcon}>
                        {formik.values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </div>
                    </IconButton>
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <div style={{ padding: 12 }}>
                      <div className={classes.colorIcon}>
                        <HttpsIcon />
                      </div>
                    </div>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldCustom
              error={
                formik.errors.confirmPassword && formik.touched.confirmPassword
                  ? true
                  : false
              }
              textAlert={
                formik.errors.confirmPassword &&
                formik.touched.confirmPassword &&
                formik.errors.confirmPassword
              }
              // helperText={
              //   formik.errors.confirmPassword &&
              //   formik.touched.confirmPassword &&
              //   formik.errors.confirmPassword
              // }
              id="confirmPassword"
              name="confirmPassword"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{ width: "100%", maxWidth: 566 }}
              placeholder="Confirm Password"
              margin="dense"
              variant="outlined"
              type={formik.values.showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      size="small"
                      edge="end"
                    >
                      <div className={classes.colorIcon}>
                        {formik.values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </div>
                    </IconButton>
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <div style={{ padding: 12 }}>
                      <div className={classes.colorIcon}>
                        <HttpsIcon />
                      </div>
                    </div>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </>
    );
  };
  const createInformation = () => {
    return (
      <>
        <Typography
          style={{
            fontSize: 32,
            marginTop: 50,
            color: "#5A5A5A",
            fontWeight: 700,
          }}
        >
          Create Account
        </Typography>
        <Typography style={{ color: "#9C9B9D", fontSize: 16 }}>
          {formik.values.role}
        </Typography>
        <Grid container spacing={2} style={{ marginTop: 30 }}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextFieldCustom
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  InputProps={{ classes: { input: classes.input } }}
                  placeholder="Firstname"
                  variant="outlined"
                  error={
                    formik.touched.firstName && formik.errors.firstName
                      ? true
                      : false
                  }
                  textAlert={
                    formik.errors.firstName &&
                    formik.touched.firstName &&
                    formik.errors.firstName
                  }
                  // helperText={
                  //   formik.errors.firstName &&
                  //   formik.touched.firstName &&
                  //   formik.errors.firstName
                  // }
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldCustom
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  InputProps={{ classes: { input: classes.input } }}
                  placeholder="Lastname"
                  variant="outlined"
                  error={
                    formik.touched.lastName && formik.errors.lastName
                      ? true
                      : false
                  }
                  textAlert={
                    formik.errors.lastName &&
                    formik.touched.lastName &&
                    formik.errors.lastName
                  }
                  // helperText={
                  //   formik.errors.lastName &&
                  //   formik.touched.lastName &&
                  //   formik.errors.lastName
                  // }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <SelectField
              name="gender"
              displayEmpty
              value={formik.values.gender}
              onChange={formik.handleChange}
              error={
                formik.touched.gender && formik.errors.gender ? true : false
              }
              textAlert={formik.errors.gender}
              renderValue={
                formik.values.gender !== ""
                  ? undefined
                  : () => <div className={classes.hintText}>Gender</div>
              }
            >
              <MenuItem value={"M"}>Male</MenuItem>
              <MenuItem value={"F"}>Female</MenuItem>
            </SelectField>
          </Grid>
          <Grid item xs={12}>
            <SelectField
              name="organization"
              displayEmpty
              value={formik.values.organization}
              onChange={formik.handleChange}
              error={
                formik.touched.organization && formik.errors.organization
                  ? true
                  : false
              }
              textAlert={formik.errors.organization}
              renderValue={
                formik.values.organization !== ""
                  ? undefined
                  : () => (
                      <div className={classes.hintText}>
                        Choose Organization
                      </div>
                    )
              }
            >
              {organizationAll.map((item: any, index: number) => (
                <MenuItem key={index} value={item._id}>
                  {item.orgName}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>
          <Grid item xs={12}>
            <SelectField
              name="occupation"
              displayEmpty
              value={formik.values.occupation}
              onChange={formik.handleChange}
              error={
                formik.touched.occupation && formik.errors.occupation
                  ? true
                  : false
              }
              textAlert={formik.errors.occupation}
              renderValue={
                formik.values.occupation !== ""
                  ? undefined
                  : () => (
                      <div className={classes.hintText}>Choose Occupation</div>
                    )
              }
            >
              {Occupation(formik.values.role).map((option) => {
                return <MenuItem value={option}>{option}</MenuItem>;
              })}
            </SelectField>
          </Grid>
          {!Occupations.includes(formik.values.occupation) && (
                <>
                  <Grid item xs={12}>
                    <TextFieldCustom
                      name="occupationTmp"
                      size="small"
                      variant="outlined"
                      onChange={formik.handleChange}
                      // value={formik.values.occupationTmp}
                    />
                  </Grid>
                </>
            )}
          <Grid item xs={6}>
            <TextFieldCustom
              InputLabelProps={{
                shrink: true,
              }}
              label="BirthDay"
              name="birthDate"
              InputProps={{ classes: { input: classes.input } }}
              value={formik.values.birthDate}
              onChange={formik.handleChange}
              error={
                formik.touched.birthDate && formik.errors.birthDate
                  ? true
                  : false
              }
              // helperText={
              //   formik.errors.birthDate &&
              //   formik.touched.birthDate &&
              //   formik.errors.birthDate
              // }
              textAlert={
                formik.errors.birthDate &&
                formik.touched.birthDate &&
                formik.errors.birthDate
              }
              type="date"
              placeholder="Your Birthday"
              variant="outlined"
            />
          </Grid>       
          <Grid item xs={6}>
            <TextFieldCustom
              InputLabelProps={{
                shrink: true,
              }}
              name="tel"
              fullWidth
              InputProps={{ classes: { input: classes.input } }}
              value={formik.values.tel}
              onChange={formik.handleChange}
              error={formik.touched.tel && formik.errors.tel ? true : false}
              // helperText={
              //   formik.errors.tel && formik.touched.tel && formik.errors.tel
              // }
              textAlert={
                formik.errors.tel && formik.touched.tel && formik.errors.tel
              }
              placeholder="Telephone number"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              inputProps={{
                "aria-label": "uncontrolled-checkbox",
              }}
              style={{ color: "#67BDE0" }}
              // checked={checked[index]?.check || false}
              name="gilad"
              onChange={handleCheckboxSameEmail}
            />
            <div style={{ color: "rgba(156, 155, 157, 1)" }}>
              Use the same email as the registered email.
            </div>
          </Grid>
          <Grid item xs={12}>
            <TextFieldCustom
              InputLabelProps={{
                shrink: true,
              }}
              name="oculusEmail"
              fullWidth
              InputProps={{ classes: { input: classes.input } }}
              disabled={sameEmail}
              value={
                sameEmail ? formik.values.email : formik.values.oculusEmail
              }
              onChange={formik.handleChange}
              error={
                formik.touched.oculusEmail && formik.errors.oculusEmail
                  ? true
                  : false
              }
              // helperText={
              //   formik.errors.tel && formik.touched.tel && formik.errors.tel
              // }
              textAlert={
                formik.errors.oculusEmail &&
                formik.touched.oculusEmail &&
                formik.errors.oculusEmail
              }
              placeholder="Email Oculus"
              variant="outlined"
            />
          </Grid>
        </Grid>
      </>
    );
  };
  const RenderCongrat = () => {
    return (
      <>
        <Typography
          style={{
            fontSize: 48,
            color: "#5A5A5B",
            fontWeight: 700,
          }}
        >
          Congratulation!
        </Typography>
        <Typography
          style={{
            fontSize: 18,
            color: "#5A5A5B",
            fontWeight: 400,
            marginTop: 50,
          }}
        >
          Please comfirm your Email.
        </Typography>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={Congratulation} alt="" />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              fontSize: 12,
              color: "#9C9B9D",
              marginTop: 30,
            }}
          >
            Contact : Mail@admin.com
          </Typography>
          <div style={{ marginTop: 48 }}>
            <a
              style={{ fontSize: 14, color: "#51B2DA", marginTop: 48 }}
              href="#"
              onClick={() => handleState("login")}
            >
              Go back to login
            </a>
            <ArrowRight style={{ marginLeft: 5 }} />
          </div>
        </div>
      </>
    );
  };
  useEffect(() => {
    fetchOrganization();
  }, []);
  return (
    <form onSubmit={formik.handleSubmit}>
      {step === 0 && renderSelectRole()}
      {step === 1 && createEmail()}
      {step === 2 && createInformation()}
      {step == 3 && RenderCongrat()}
      {step < 3 && (
        <Button className={classes.btnBack} onClick={backStep}>
          Back
        </Button>
      )}
      {getBtnShow() && (
        <Button className={classes.btnNext} onClick={nextStep}>
          Next
        </Button>
      )}
      {step === 2 && (
        <Button
          className={classes.btnNext}
          onClick={() => formik.handleSubmit()}
        >
          Create
        </Button>
      )}
    </form>
  );
};
export default CreateForm;
