import React, { useEffect, useState, CSSProperties, FC } from "react";
import { Button, Checkbox, FormControlLabel, Tooltip } from "@material-ui/core";
import { useStyles } from "./style";
import { IPatients, IPosition } from "./CreateScenario";
import { useDrag } from "react-dnd";
import { ItemTypes } from "./itemType";
import { XYCoord, useDragLayer } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";

export function handleCaseColor(color: string) {
  if (color === "RED") {
    return "#F15F5F";
  } else if (color === "YELLOW") {
    return "#F9B233";
  } else if (color === "GREEN") {
    return "#60D0A1";
  } else if (color === "BLACK") {
    return "#787878";
  }
}
export interface BoxProps {
  name: string;
}
interface DropResult {
  name: string;
}

export interface IPatientMap {
  patientName?: string;
  pointMapName?: string;
}
const style: CSSProperties = {
  height: "25px",
  width: "25px",
  backgroundColor: "red",
  borderRadius: 50,
};

interface IProp {
  data: any;
  positionPoint: Array<IPosition>;
  setPositionPoint: React.Dispatch<React.SetStateAction<IPosition[]>>;
  bgColor: string;
  index: number;
  setIsDragTrash: React.Dispatch<React.SetStateAction<boolean>>;
}
export function PointToTrash({
  data,
  positionPoint,
  setPositionPoint,
  bgColor,
  index,
  setIsDragTrash,
}: IProp) {
  const [patientName, setPatientName] = useState<string>("");
  const [showEmptyPlaceholder, setShowEmptyPlaceholder] = useState<boolean>(
    true
  );
  let backgroundColor = bgColor ? "#222" : "#222";
  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: ItemTypes.BOX,
    item: { name: data?.data?.code, color: data?.data?.caseColor },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<DropResult>();
      if (item && dropResult?.name === "TEST") {
        setPatientName(item.name);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  useEffect(() => {
    setIsDragTrash(isDragging);
  }, [isDragging]);

  useEffect(() => {
    if (patientName) {
      const findIdxColor = positionPoint.findIndex((item2: any) => {
        return item2.index === index;
      });
      if (findIdxColor > -1) {
        positionPoint[findIdxColor].caseColor = "";
        positionPoint[findIdxColor].data = "";
        setPositionPoint([...positionPoint]);
        setPatientName("");
      }
      setIsDragTrash(false);
    }
  }, [patientName]);

  useEffect(() => {
    if (showEmptyPlaceholder) {
      preview(getEmptyImage(), { captureDraggingState: true });
    }
  }, [showEmptyPlaceholder, preview]);
  
  return (
    <>
     <Tooltip
      placement="top"
      title={data.patientName}
      style={{ marginLeft: 3, width: 15, height: 15 }}
    >
      <div
        ref={drag}
        //   role={name}
        style={{
          ...style,
          backgroundColor: handleCaseColor(backgroundColor),
        }}
        // onDrop={(e) => console.log(e)}
      >
        {/* {isActive ? "Release to drop" : "Drag a box here"} */}
      </div>
    </Tooltip>
    </>
  );
}
