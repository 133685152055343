import React, { FC, ReactNode, useState } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  Zoom,
} from "@material-ui/core";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { ButtonContained, ButtonOutlined, ButtonText } from "../Buttons";
import { ReactComponent as DownloadIcon } from "../asset/DownloadIcon.svg";
import ChipStatuses from "../Chip";
interface IAlertDialog extends DialogProps {
  handleClick: () => void;
  subTitle?: string | Array<String>;
  contentBtn?: Array<string> | undefined;
  status?: "default" | "PENDING" | "FINISHED" | "IN_PROGRESS";
}
const AlertDialog: FC<IAlertDialog> = ({
  open,
  handleClick,
  maxWidth,
  fullWidth,
  children,
  scroll,
  contentBtn,
  title,
  subTitle,
  status,
  ...rest
}) => {
  return (
    <div>
      <Dialog
        open={open}
        scroll={"paper"}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: 16,
            height: 650,
          },
        }}
        {...rest}
      >
        <DialogTitle>
          {title && (
            <div style={{ fontSize: 32, fontWeight: 700, color: "#5A5A5B" }}>
              {title}
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ fontSize: 14, fontWeight: 400, color: "#787878" }}>
              {subTitle}
            </div>
            {contentBtn && contentBtn.length > 0 && (
              <Button
                style={{
                  width: 160,
                  fontSize: 10,
                  textTransform: "none",
                  border: "1px solid #51b2da",
                  color: "#51b2da",
                  borderRadius: 16,
                }}
                href={`${contentBtn[1]}`}
                target="_blank"
              >
                <DownloadIcon style={{ marginRight: 9 }} />
                {contentBtn[0]}
              </Button>
            )}
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {status && (
              <>
                <ChipStatuses text={status} type={status}></ChipStatuses>
              </>
            )}
          </div>
          <div style={{ position: "absolute", right: 0, top: 0 }}>
            <IconButton onClick={handleClick}>
              <CloseIcon />
            </IconButton>
          </div>
          {contentBtn && <Divider style={{ marginTop: 10 }} />}
        </DialogTitle>
        {children}
      </Dialog>
    </div>
  );
};

export default AlertDialog;
