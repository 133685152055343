import React from 'react'
import ReactDOM from 'react-dom'

import './index.scss'
import App from './App'

import { Provider } from 'react-redux'
import { store } from './stores/index'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'
// import reportWebVitals from './reportWebVitals'

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
	onUpdate: (registration) => {
		const waitingServiceWorker = registration.waiting
		if (waitingServiceWorker) {
			waitingServiceWorker.addEventListener('statechange', (event: any) => {
				if (event.target.state === 'activated') {
					alert('New content is available, please refresh.')
					window.location.reload()
				}
			})
			waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' })
		}
	}
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
