import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from '../CapitalizeFirst';

import { ChipStatusesStyle } from "./style";
import { CricleTest } from "./style";
interface IChipStatusesProps {
  type?: "default" | "PENDING" | "FINISHED" | "IN_PROGRESS";
  text?: string;
  children?: ReactNode;
  $toCapitalize?: boolean;
}
const ChipStatuses = ({
  type = "default",
  text,
  children,
}: IChipStatusesProps) => {
  const { t } = useTranslation();
  return (
    <ChipStatusesStyle type={type}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CricleTest type={type} style={{ marginTop: 4, marginRight: 4 }} />
        {text ? t(`${capitalizeFirstLetter(text)}`) : children}
      </div>
    </ChipStatusesStyle>
  );
};

export default ChipStatuses;
