import React, { useEffect, useState } from "react";
import { Button, Checkbox, FormControlLabel } from "@material-ui/core";
import { useStyles } from "../ListOfScenario/style";
import clsx from "clsx";
import { useAuth } from "../../../../contexts/AuthContext";
import { IScenario } from "../ListOfScenario";
import { handleColor } from "../ListOfScenario/cardPatient";
import { capitalizeFirstLetter } from "../../../commons/CapitalizeFirst";
interface IProp {
  handleSelectScenario?: (scenario: IScenario) => void;
  data: any;
  handleSelectPatient?: any;
  selectPatient?: any;
}
export default function CardScenario({
  handleSelectPatient,
  data,
  selectPatient,
}: IProp) {
  const classes = useStyles();
  const { currentUser } = useAuth();
  const [checked, setChecked] = useState(handleCheckFav());
  const [color, setColor] = useState<string>();

  useEffect(() => {
    setChecked(handleCheckFav());
  }, [data]);
  function handleCheckFav(): boolean {
    if (data.favBy) {
      for (let i = 0; i < data.favBy.length; i++) {
        if (data.favBy[i] === currentUser?.userId) {
          return true;
        }
      }
      return false;
    } else {
      return false;
    }
  }
  function chipColor(name: string) {
    const colorText: Array<string> | undefined = handleColor(name);
    var color, backgroundColor;
    if (colorText) {
      backgroundColor = colorText[0];
      color = colorText[1];
    }
    return (
      <div
        style={{
          backgroundColor: color,
          color: backgroundColor,
          padding: "3px 6px",
          marginLeft: 10,
          borderRadius: 2,
        }}
      >
        <div style={{ fontSize: 8, fontWeight: 500 }}>{name}</div>
      </div>
    );
  }
  function chipColorAccessLevel(text: string) {
    return (
      <div
        style={{
          backgroundColor: text === "PUBLIC" ? "#DFF6EC" : "#FFEFD3",
          color: text === "PUBLIC" ? "#60D0A1" : "#F9B233",
          padding: "1px 6px",
          marginLeft: 10,
          borderRadius: 2,
        }}
      >
        <div style={{ fontSize: 10 }}>
          {capitalizeFirstLetter(text.toString().toLowerCase())}
        </div>
      </div>
    );
  }
  useEffect(() => {
    let colorText = handleColor(data.caseColor);
    if (colorText) {
      setColor(colorText[2]);
    }
  }, [data]);
  return (
    <div style={{ display: "flex", width: "100%", position: "relative" }}>
      <Button
        className={clsx(
          classes.buttonCard,
          data._id === selectPatient?._id && classes.selectedCardPatient
        )}
        style={{ display: "flex", justifyContent: "start" }}
        onClick={() => handleSelectPatient(data)}
      >
        <div
          style={{
            backgroundColor: color,
            height: 65,
            width: 6,
            borderRadius: "8px 0px 0px 8px",
          }}
        ></div>
        <div style={{ padding: "0px 14px" }}>
          <div
            style={{
              fontSize: 14,
              fontWeight: 700,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              // alignItems: "center",
            }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ fontSize: 12, marginLeft: 10 }}>{data.code}</div>
              <div>
                {data.accessLevel === "PUBLIC" &&
                  chipColorAccessLevel(data.accessLevel)}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: 4,
                fontWeight: 500,
                fontSize: 8,
              }}
            >
              {chipColor(data.caseColor)}
              <div
                style={{
                  border: "0.3px solid #E4E4E4",
                  color: "#5A5A5A",
                  padding: "2px 8px",
                  marginLeft: 5,
                }}
              >
                {data.gender === "F"
                  ? "Female"
                  : data.gender === "M"
                  ? "Male"
                  : "Orther"}
              </div>
              <div
                style={{
                  border: "0.3px solid #E4E4E4",
                  color: "#5A5A5A",
                  padding: "2px 8px",
                  marginLeft: 5,
                }}
              >
                {data.age}
              </div>
              <div
                style={{
                  border: "0.3px solid #E4E4E4",
                  color: "#5A5A5A",
                  padding: "2px 8px",
                  marginLeft: 5,
                }}
              >
                {data.pose}
              </div>
            </div>
          </div>
        </div>
      </Button>
    </div>
  );
}
