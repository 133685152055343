import React, { useState } from "react";
import "./Header.scss";
import { useTranslation } from "react-i18next";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  SvgIcon,
  Hidden,
  Button,
  Avatar,
} from "@material-ui/core/";
import MenuIcon from "@material-ui/icons/Menu";
import DrawerData from "./drawerData";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import Logo from "../../commons/asset/Logo 2.png";
interface IProps extends RouteComponentProps {
  render?: any;
  leftDrawer?: any;
  pageAt?: any;
  group?: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButtonHide: {
      color:'#51B2DA',
      marginRight: theme.spacing(2),
      // [theme.breakpoints.down("md")]: {
      //   display: "none",
      // },
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    },
    // toolbar: {
    //   backgroundColor: "#FFFFFF",
    //   color: theme.palette.primary.main,
    // },
    toolbar: {
      minHeight: 100,
      height: "100%",
      alignItems: "flex-start",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    },
    toolbarCustom: {
      backgroundColor: "white",
    },
    colorIconMenu: {
      color: theme.palette.primary.dark,
    },
    divSearch: {
      marginLeft: "0%",
      [theme.breakpoints.down("xl")]: {
        marginLeft: "0%",
      },
      [theme.breakpoints.down("lg")]: {
        marginLeft: "0%",
      },
      [theme.breakpoints.down("md")]: {
        marginLeft: "0%",
      },
    },
    divIconButton: {
      marginLeft: 43,
      color: theme.palette.secondary.main,
    },
    divIconButton2: {
      color: theme.palette.secondary.main,
    },
    menuButton: {
      [theme.breakpoints.up("sm")]: {
        marginLeft: 290,
        flexShrink: 0,
      },
    },
    logoWeb: {
      fontWeight: "bold",
      width: "100%",
      // [theme.breakpoints.down("md")]: {
      //   width: "30%",
      //   color: "#535353",
      // },
    },
  })
);
const Header = (props: IProps) => {
  const history = useHistory();
  const { pageAt } = props;
  const { currentUser, logOut } = useAuth();
  const { t } = useTranslation();
  const classes = useStyles();
  const [showMainDrawer, setShowMainDrawer] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <Toolbar className={`${classes.toolbar} ${classes.toolbarCustom}`}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        className={classes.menuButtonHide}
      >
        <MenuIcon />
      </IconButton>
      <img src={Logo} alt="" style={{ position: "absolute", right: 40 }} />
      {/* <Button
        onClick={() => {
          history.push("/profile-me");
        }}
        style={{
          position: "absolute",
          right: 150,
          top: 15,
          display: "flex",
          justifyContent: "start",
          width: 200,
        }}
      >
        <div>
          <Avatar src={convertStringToImg(currentUser)}></Avatar>
        </div>
        <div style={{ marginLeft: 10 }}>
          <Typography
            style={{
              color: "#5A5A5B",
              fontWeight: 700,
              fontSize: "10px",
              textTransform: "capitalize",
            }}
          >
            {currentUser?.username}
          </Typography>
          <Typography
            style={{
              color: "#9C9B9D",
              fontWeight: 400,
              fontSize: "10px",
              width: 0,
            }}
          >
            {currentUser?.roles[0]?.toLowerCase()
              .split(" ")
              .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
              .join(" ")}
          </Typography>
        </div>
        <Button onClick={logOut}>LogOut</Button>
      </Button> */}
      <DrawerData
        onClose={() => setShowMainDrawer(false)}
        isShow={showMainDrawer}
        pageAt={pageAt}
        history={props.history}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
    </Toolbar>
  );
};

export default withRouter(Header);
