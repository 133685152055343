import React from "react";
import moment from "moment";

interface IProps {
  topic: string;
  subTopic?: string;
  disabledTime?: boolean;
}

export const NotificationDiv = ({ topic, subTopic, disabledTime = false }: IProps) => {
  return (
    <div>
      {`${topic}`}
      {subTopic && <div>{subTopic}</div>}
      {!disabledTime && (
        <div>
          {"Notification"} : {moment().format("DD/MM/YYYY ,HH:mm A")}
        </div>
      )}
    </div>
  );
};
