import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
  useMediaQuery,
  withStyles,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import _, { isArray } from "lodash";
import {
  fetchScenariosApi,
  fetchUserAllApi,
} from "../../../../../services/Classrooms/classroom.service";
import { useStyles } from "../styles";
import {
  TextFieldAutocomplete,
  TextFieldCustom,
} from "../../../../commons/Inputs";
import { convertStringToImg } from "../../../../commons/ConvertImg";
import { fetchGroupApi } from "../../../../../services/Group/group.service";
import { Mark_Required } from "../../../../commons/Inputs/style";
import { IScenario } from "../../../SceneManagement/ListOfScenario";
interface IProps {
  formik: any;
}
const ManagementClassroom = ({ formik }: IProps) => {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStyles();
  const [instructors, setInstructors] = useState<any[]>([]);
  const [students, setStudents] = useState<any[]>([]);
  const [scenarios, setScenarios] = useState<IScenario[]>([]);
  const inputStudent = useRef<HTMLElement>(null);
  const inputInstructor = useRef<HTMLElement>(null);
  const [selectedStudents, setSelectedStudents] = useState<string>("");
  const [selectedInstructors, setSelectedInstructors] = useState<string>("");
  const [loading, setLoading] = useState<Array<boolean>>([true, true]);
  const [open, setOpen] = useState<Array<boolean>>([false, false]);
  const CustomSwitch = withStyles({
    switchBase: {
      color: "#B9B9B9",
      "&$checked": {
        color: "#51B2DA",
      },
      "&$checked + $track": {
        backgroundColor: "#51B2DA",
      },
    },
    checked: {},
    track: {
      opacity: 1,
          backgroundColor: "#E4E4E4",
          "$checked$checked + &": {
            opacity:1,
            backgroundColor: "#D8F4FF"
          }
    },
  })(Switch);
  useEffect(() => {
    if (
      selectedStudents &&
      inputStudent.current &&
      inputStudent.current.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
      )[0]
    ) {
      const el = inputStudent.current.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
      )[0] as HTMLElement;
      el.click();
      setSelectedStudents("");
    }
  }, [selectedStudents]);
  useEffect(() => {
    if (
      selectedInstructors &&
      inputInstructor.current &&
      inputInstructor.current.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
      )[0]
    ) {
      const el = inputInstructor.current.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
      )[0] as HTMLElement;
      setSelectedInstructors("");
      el.click();
    }
  }, [selectedInstructors]);
  useEffect(() => {
    let tempInstructor: any = [];
    let tempStudent: any = [];
    let tempInstructorGroup: any = [];
    let tempStudentGroup: any = [];
    fetchUserAllApi().then((res) => {
      if (res && res.data && res.data.result) {
        tempInstructor = _.chain(res.data.result)
          .filter((x) => _.indexOf(x.userRoles, "INSTRUCTOR") !== -1)
          .value();
        tempStudent = _.chain(res.data.result)
          .filter((x) => _.indexOf(x.userRoles, "STUDENT") !== -1)
          .value();
        fetchGroupApi().then((res) => {
          tempStudentGroup = _.chain(res.data.result)
            .filter((x) => x.groupRoles[0] === "Student")
            .value();
          tempInstructorGroup = _.chain(res.data.result)
            .filter((x) => x.groupRoles[0] === "Instructor")
            .value();
          setStudents([...tempStudent, ...tempStudentGroup]);
          setInstructors([...tempInstructor, ...tempInstructorGroup]);
        });
      }
    });
    fetchScenariosApi().then((res) => {
      if (res && res.data && res.data.result) {
        setScenarios(res.data.result);
      }
    });
  }, []);
  useEffect(() => {
    if (instructors.length > 0 && students.length > 0) {
      setLoading([false, false]);
    }
  }, [instructors, students]);
  function addData(data: any, name: string) {
    let temp: any = [];
    temp.push(...formik.values[name]);
    if (isArray(data)) {
      const listId = temp.map((item: any) => item._id);
      data.forEach((item) => {
        if (!listId.includes(item._id)) temp.push(item);
      });
    } else {
      temp.push(data);
    }
    formik.setFieldValue(name, temp);
  }
  function deleteData(index: number, name: string) {
    let temp: any = [];
    temp.push(...formik.values[name]);
    temp.splice(index, 1);
    formik.setFieldValue(name, temp);
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          Scenario&nbsp;
          <Mark_Required>*</Mark_Required>
        </Grid>
        <Grid item xs={5}>
          <Autocomplete
            // className={classes.autoComplete}
            freeSolo
            // autoSelect
            options={scenarios}
            getOptionLabel={(option) => option.code || ""}
            onChange={(value, newValue) => {
              if (newValue) {
                formik.setFieldValue("scenario", newValue);
              }
            }}
            defaultValue={formik.values?.scenario}
            renderInput={(params) => (
              <TextFieldAutocomplete
                {...params}
                size="small"
                variant="outlined"
                placeholder="Select Scenario"
                error={
                  formik.touched.scenario && formik.errors.scenario
                    ? true
                    : false
                }
                textAlert={formik.errors.scenario?.code && formik.touched.scenario && formik.errors.scenario?.code}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            control={
              <CustomSwitch
                name="certificate"
                color="primary"
                checked={formik.values.certificate}
                onChange={formik.handleChange}
              />
            }
            label="Certificate"
          />
        </Grid>
        <Grid item xs={3}>
          Class ID
        </Grid>
        <Grid item lg={5} xl={5} md={5} sm={5} xs={9}>
          <TextFieldCustom
            name="classId"
            disabled={true}
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.classId}
            error={
              formik.touched.classId && formik.errors.classId ? true : false
            }
            textAlert={
              formik.errors.classId &&
              formik.touched.classId &&
              formik.errors.classId
            }
          />
        </Grid>
       {matches && <Grid item xs={4}> </Grid>} 
        <Grid item xs={3}>
          Date / Time &nbsp;
          <Mark_Required>*</Mark_Required>
        </Grid>
        <Grid item lg={3} xl={3} md={3} sm={3} xs={5}>
          <TextFieldCustom
            name="date"
            type="date"
            variant="outlined"
            value={formik.values.date}
            onChange={formik.handleChange}
            error={formik.touched.date && formik.errors.date ? true : false}
            textAlert={
              formik.errors.date && formik.touched.date && formik.errors.date
            }
          />
        </Grid>
        <Grid item lg={2} xl={2} md={2} sm={2} xs={4}>
          <TextFieldCustom
            name="time"
            type="time"
            variant="outlined"
            value={formik.values.time}
            onChange={formik.handleChange}
            error={formik.touched.time && formik.errors.time ? true : false}
            textAlert={
              formik.errors.time && formik.touched.time && formik.errors.time
            }
          />
        </Grid>
        {matches && <Grid item xs={4}> </Grid>} 
        <Grid item xs={3}>
          Instructors &nbsp;
          <Mark_Required>*</Mark_Required>
        </Grid>
        <Grid item lg={5} xl={5} md={5} sm={5} xs={9}>
          <Autocomplete
            // autoComplete
            freeSolo
            // autoSelect
            ref={inputInstructor}
            options={instructors}
            onOpen={() => {
              setOpen([true, open[1]]);
            }}
            onClose={() => {
              setOpen([false, open[1]]);
            }}
            getOptionDisabled={(option) => {
              if (option.members && isArray(option.members)) {
                let num = 0;
                const listStudentMembers = option.members.map(
                  (item: any) => item._id
                );
                const listStudentFormik = formik.values.instructors.map(
                  (item: any) => item._id
                );

                listStudentFormik.forEach((item: any) => {
                  if (listStudentMembers.includes(item)) {
                    num++;
                  }
                });
                return num === listStudentMembers.length;
              } else {
                return !!formik.values.instructors.find(
                  (useData: any) => useData._id === option._id
                );
              }
            }}
            getOptionSelected={(option, value) => option?._id === value?._id}
            getOptionLabel={(option) =>
              option.firstName
                ? `${option.firstName} ${option.lastName} ${option.email}`
                : option.groupName
            }
            renderOption={(option) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                {option.firstName ? (
                  <>
                    <Avatar src={convertStringToImg(option)}></Avatar>
                    <div style={{ marginLeft: 20 }}></div>
                    <div>
                      <div>
                        {option.firstName} {option.lastName}
                      </div>
                      <div style={{ fontSize: 12 }}>{option.email}</div>
                    </div>
                  </>
                ) : (
                  <>
                    <Avatar src={convertStringToImg(option)}></Avatar>
                    <div style={{ marginLeft: 20 }}></div>
                    {option.groupName}
                  </>
                )}
              </div>
            )}
            // disableCloseOnSelect
            onChange={(value, newValue) => {
              if (
                newValue &&
                typeof newValue === "object" &&
                newValue?.groupName
              ) {
                addData(newValue.members, "instructors");
                setSelectedInstructors("instructors");
              } else if (newValue && typeof newValue === "object") {
                setSelectedInstructors("instructors");
                addData(newValue, "instructors");
              }
            }}
            loading={loading[0]}
            renderInput={(params) => (
              <TextFieldAutocomplete
                {...params}
                size="small"
                variant="outlined"
                placeholder="Instructor's Name"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading[0] && open[0] ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                error={
                  formik.touched.instructors && formik.errors.instructors
                    ? true
                    : false
                }
                textAlert={
                  formik.errors.instructors &&
                  formik.touched.instructors &&
                  formik.errors.instructors
                }
              />
            )}
          />
        </Grid>
        {matches && <Grid item xs={3}> </Grid>} 
        <>
          <Grid item xs={3}></Grid>
          <Grid item lg={5} xl={5} md={5} sm={5} xs={9}>
            <Box
              boxShadow="0px 2px 12px rgba(0, 0, 0, 0.15)"
              borderRadius="8px"
              height="198px"
              overflow="auto"
              marginTop="10px"
              marginBottom="20px"
            >
              {formik.values.instructors.length > 0
                ? formik.values.instructors.map((item: any, index: number) => {
                    return (
                      <div key={index} style={{ padding: "13px 20px" }}>
                        <Box display="flex" justifyContent="space-between">
                          <Box display="flex" alignItems="center">
                            <Avatar src={convertStringToImg(item)}></Avatar>
                            <Box style={{ marginLeft: 20 }}>
                              <Typography
                                style={{
                                  color: "#5A5A5A",
                                  fontWeight: 700,
                                  fontSize: "14px",
                                }}
                              >
                                {item.firstName} {item.lastName}
                              </Typography>
                              <Typography
                                style={{
                                  color: "#9C9B9D",
                                  fontWeight: 400,
                                  fontSize: "12px",
                                }}
                              >
                                {item.email}
                              </Typography>
                            </Box>
                          </Box>
                          <IconButton
                            className={classes.iconBtn}
                            onClick={() => deleteData(index, "instructors")}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      </div>
                    );
                  })
                  : <div style={{textAlign:'center',marginTop:20,fontStyle:'oblique'}}>No Data</div>}
              
            </Box>
          </Grid>
          {matches && <Grid item xs={4}> </Grid>} 
        </>
        <Grid item xs={3}>
          Students &nbsp;
          <Mark_Required>*</Mark_Required>
        </Grid>
        <Grid lg={5} xl={5} md={5} sm={5} xs={9}>
          <Autocomplete
            // autoComplete
            freeSolo
            ref={inputStudent}
            options={students}
            // autoSelect
            onOpen={() => {
              setOpen([open[0], true]);
            }}
            onClose={() => {
              setOpen([open[0], false]);
            }}
            loading={loading[1]}
            getOptionDisabled={(option) => {
              if (option.members && isArray(option.members)) {
                let num = 0;
                const listStudentMembers = option.members.map(
                  (item: any) => item._id
                );
                const listStudentFormik = formik.values.students.map(
                  (item: any) => item._id
                );

                listStudentFormik.forEach((item: any) => {
                  if (listStudentMembers.includes(item)) {
                    num++;
                  }
                });
                return num === listStudentMembers.length;
              } else {
                return !!formik.values.students.find(
                  (useData: any) => useData._id === option._id
                );
              }
            }}
            getOptionSelected={(option, value) => option?._id === value?._id}
            getOptionLabel={(option) =>
              option.firstName
                ? `${option.firstName} ${option.lastName} ${option.email}`
                : option.groupName
            }
            renderOption={(option) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                {option.firstName ? (
                  <>
                    <Avatar src={convertStringToImg(option)}></Avatar>
                    <div style={{ marginLeft: 20 }}></div>
                    <div>
                      <div>
                        {option.firstName} {option.lastName}
                      </div>
                      <div style={{ fontSize: 12 }}>{option.email}</div>
                    </div>
                  </>
                ) : (
                  <>
                    <Avatar src={convertStringToImg(option)}></Avatar>
                    <div style={{ marginLeft: 20 }}></div>
                    {option.groupName}
                  </>
                )}
              </div>
            )}
            // disableCloseOnSelect
            onChange={(value, newValue) => {
              if (
                newValue &&
                typeof newValue === "object" &&
                newValue?.groupName
              ) {
                addData(newValue.members, "students");
                setSelectedStudents("students");
              } else if (newValue && typeof newValue === "object") {
                setSelectedStudents("students");
                addData(newValue, "students");
              }
            }}
            renderInput={(params) => (
              <TextFieldAutocomplete
                {...params}
                size="small"
                variant="outlined"
                placeholder="Student's Name"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading[1] && open[1] ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                error={
                  formik.touched.students && formik.errors.students
                    ? true
                    : false
                }
                textAlert={
                  formik.errors.students &&
                  formik.touched.students &&
                  formik.errors.students
                }
              />
            )}
          />
        </Grid>
        {matches && <Grid item xs={4}></Grid>} 
        <>
          <Grid item xs={3}></Grid>
          <Grid item lg={5} xl={5} md={5} sm={5} xs={9}>
            <Box
              boxShadow="0px 2px 12px rgba(0, 0, 0, 0.15)"
              borderRadius="8px"
              height="330px"
              overflow="auto"
              marginTop="10px"
              marginBottom="20px"
            >
              {formik.values.students.length > 0
                ? formik.values.students.map((item: any, index: number) => {
                    return (
                      <div key={index} style={{ padding: "13px 20px" }}>
                        <Box display="flex" justifyContent="space-between">
                          <Box display="flex" alignItems="center">
                            <Avatar src={convertStringToImg(item)}></Avatar>
                            <Box style={{ marginLeft: 20 }}>
                              <Typography
                                style={{
                                  color: "#5A5A5A",
                                  fontWeight: 700,
                                  fontSize: "14px",
                                }}
                              >
                                {item.firstName} {item.lastName}
                              </Typography>
                              <Typography
                                style={{
                                  color: "#9C9B9D",
                                  fontWeight: 400,
                                  fontSize: "12px",
                                }}
                              >
                                {item.email}
                              </Typography>
                            </Box>
                          </Box>
                          <IconButton
                            className={classes.iconBtn}
                            onClick={() => deleteData(index, "students")}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      </div>
                    );
                  })
                : <div style={{textAlign:'center',marginTop:20,fontStyle:'oblique'}}>No Data</div>}
               
            </Box>
          </Grid>
          <Grid item xs={4}></Grid>
        </>
      </Grid>
    </>
  );
};

export default ManagementClassroom;
