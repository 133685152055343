import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import clsx from "clsx";
import dayjs from "dayjs";
import { SelectField, TextFieldCustom } from "../../../commons/Inputs";
import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { ButtonContained, ButtonOutlined } from "../../../commons/Buttons";
import {
  fetchOrganizationsApi,
  IRegister,
  resetPassword,
  updateImageMeApi,
  updateImageOtherApi,
  updateUserApi,
  updateUserMe,
} from "../../../../services/Users/users.servies";
import { useHistory } from "react-router-dom";
import { useStyles } from "./style";
import { IOrganization } from "../../UserManagement/User";
import { useAuth } from "../../../../contexts/AuthContext";
import { verifyMyTokenApi } from "../../../../services/Management/Users/user.service.ts";
import Layout from "../../../commons/Layout";
import { Skeleton } from "@material-ui/lab";
import ResetPassword from "./Dialog/ResetPassword";
import RecheckDialog from "../../../commons/RecheckDialog";
import { convertStringToImg } from "../../../commons/ConvertImg";
import { ReactComponent as SelectIcon } from "../../UserManagement/User/asset/editphoto.svg";
import { DataURIToBlob } from "../../../commons/ConvertToBlob";
import { capitalizeFirstLetter } from "../../../commons/CapitalizeFirst";
import AlertDialog from '../../../commons/AlertDialog';
import { Occupations } from '../../UserManagement/User/Dialog/Preview';
interface ISubOrg {
  _id: string;
  orgName: string;
}
interface IProfileMe {
  userRoles: string;
  firstName: string;
  lastName: string;
  gender: string;
  organization: ISubOrg;
  occupation: string;
  email: string;
  birthDate: string;
  tel: string;
  imgBackendURL: string;
  _id: string;
}
const Information: Array<string> = [
  "Role",
  "Name",
  "Gender",
  "Organization",
  "Occupation",
  "Email",
  "BirthDay",
  "Telephone",
];
const Information2: Array<string> = [
  "Role",
  "Name",
  "Gender",
  "Organization",
  "Occupation",
  "-",
  "Email",
  "BirthDay",
  "Telephone",
];
export interface ITextConfirmDetail {
  title: string;
  subTitle: string;
  yes: string;
  no: string;
  color: string;
}
interface ITextConfirm {
  UPDATE?: ITextConfirmDetail;
  RESET?: ITextConfirmDetail;
  LOGOUT?: ITextConfirmDetail;
  QUITEDITING?: ITextConfirmDetail;
}
const TextConfirmPopup: ITextConfirm = {
  UPDATE: {
    title: "Are you sure to update profile",
    subTitle: "This action cannot be rolled back.",
    yes: "Update",
    no: "Cancel",
    color: "blue",
  },
  RESET: {
    title: "Change password?",
    subTitle: "Please check your password, you can edit more later.",
    yes: "Yes",
    no: "Cancel",
    color: "blue",
  },
  LOGOUT: {
    title: "Logout?",
    subTitle: "If you want to continue using the dashboard, press No to cancel the logout.",
    yes: "Yes",
    no: "No",
    color: "red",
  },
  QUITEDITING: {
    title: "Quit editing?",
    subTitle: "Changes you made so far will not be saved.",
    yes: "Yes",
    no: "No",
    color: "red",
  },
};
//11.2%
const sizeArray = ["20", "40", "15", "60", "40", "20", "70", "30", "40"];
const ProfileManagement = () => {
  const { currentUser, logOut } = useAuth();
  const matches = useMediaQuery("(min-width:1280px)");
  const history = useHistory();
  const classes = useStyles();
  const [me, setMe] = useState<IProfileMe | undefined>();
  const [organizationAll, setOrganizationAll] = useState<IOrganization[]>([]);
  const [edit, setEdit] = useState<boolean>(false);
  const [state, setState] = useState("");
  const [forceUpdateData, setForceUpdateData] = useState(0);
  const [confirmPopup, setConfirmPopup] = useState<ITextConfirmDetail>();
  const [openRecheck, setOpenRecheck] = useState(false);
  const [stateInformation, setStateInformation] = useState<Array<string>>(Information);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, "Must be a valid password")
      .required("Password is required"),
    newPassword: Yup.string()
      .min(6, "Must be a valid password")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("password is required"),
  });
  const formik = useFormik({
    initialValues: {
      role: me?.userRoles[0] || "",
      firstName: me?.firstName || "",
      lastName: me?.lastName || "",
      gender: me?.gender || "",
      organization: me?.organization?._id || "",
      occupation: me?.occupation && !Occupations.includes(me?.occupation) ? 'Other' :  me?.occupation,
      occupationTmp: me?.occupation && !Occupations.includes(me?.occupation) ? me?.occupation : "",
      email: me?.email || "",
      birthDate: me?.birthDate || "",
      tel: me?.tel || "",
      imgBackendURL: me?.imgBackendURL || "",
      userid: me?._id || "",
      password: "",
      newPassword: "",
      confirmPassword: "",
      showPassword: false,
      imgBackendURLUpload: ""
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      changeState("RESET");
    },
  });
  useEffect(() => {
    fetchOrganizationsApi()
      .then((res) => {
        if (res && res.data && res.data.result) {
          setOrganizationAll(res.data.result);
        }
      })
      .catch(() => {});
  }, []);

  function updateUser() {
    const data: IRegister = {
      firstName: formik.values.firstName,
      lastName: formik.values.lastName,
      gender: formik.values.gender,
      // organization: formik.values.organization,
      occupation: formik.values.occupation === "Other" ? formik.values.occupationTmp : formik.values.occupation,
      birthDate: formik.values.birthDate,
      tel: formik.values.tel,
      // img: formik.values.img.data
      //   ? convertStringToImg(formik.values.img)
      //   : formik.values.img,
    };
    updateUserMe(data).then((res) => {
      if (res?.data?.result) {
        let formData = new FormData();
        if (formik.values.imgBackendURLUpload) {
          formData.append(
            "file",
            DataURIToBlob(formik.values.imgBackendURLUpload),
            "image/jpeg"
          );
          updateImageMeApi(formData).then((res) => {
            // formik.setFieldValue('imgBackendURLUpload','');   
            window.location.reload();
          });
        }
        else {
          window.location.reload();
        }
      } else {
        enqueueSnackbar("Update Error", {
          variant: "error",
        });
      }
    });
  }
  function handleEdit() {
    // formik.setFieldValue("imgBackendURL", "");
    if (!edit === false) {
      if (formik.values.firstName !== me?.firstName || formik.values.lastName !== me?.lastName ||formik.values.gender !== me?.gender 
        ||formik.values.birthDate !== me?.birthDate || formik.values.tel !== me?.tel || formik.values.imgBackendURLUpload) {
        changeState("QUITEDITING");
        handleOpenRecheck();
      } else {
        setEdit(!edit);
      }
    } else {
      setEdit(!edit);
    }
  }
  function handleOpen() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }
  function resetPasswordMe() {
    const data = {
      oldPassword: formik.values.password,
      newPassword: formik.values.newPassword,
    };
    resetPassword(data)
      .then((res) => {
        if (res?.data) {
          handleClose();
          setForceUpdateData((prev: any) => prev + 1);
          enqueueSnackbar("Updated Password Success", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Update Password Error", {
            variant: "error",
          });
        }
      })
      .catch(() => {
        enqueueSnackbar("Updated Password Error", {
          variant: "error",
        });
      });
  }
  function handleCloseRecheck() {
    setOpenRecheck(false);
  }
  function handleOpenRecheck() {
    setOpenRecheck(true);
  }
  function handleSubmit() {
    if (state === "RESET") {
      resetPasswordMe();
    } else if (state === "UPDATE") {
      updateUser();
    } else if (state === "LOGOUT") {
      logOut();
    } else if (state === "QUITEDITING") {
      setEdit(false);
      formik.resetForm();
    }
    handleCloseRecheck();
  }
  function changeState(state: string) {
    if (state === "RESET") {
      setConfirmPopup(TextConfirmPopup[state]);
    } else if (state === "UPDATE") {
      setConfirmPopup(TextConfirmPopup[state]);
    } else if (state === "LOGOUT") {
      setConfirmPopup(TextConfirmPopup[state]);
    } else if (state === "QUITEDITING") {
      setConfirmPopup(TextConfirmPopup[state]);
    }
    handleOpenRecheck();
    setState(state);
  }
  useEffect(() => {
    verifyMyTokenApi(localStorage.getItem("tokenUser")).then((res) => {
      if (res && res.data) {
        let informationList = res.data.result;
        organizationAll.forEach((item) => {
          if (item._id === informationList.organization) {
            informationList.organization = item;
          }
        });
        setMe(informationList);
      }
    });
  }, [organizationAll, forceUpdateData]);
  const handleImage = (name: any) => (event: any) => {
    if (event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        formik.setFieldValue("imgBackendURLUpload", event.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  useEffect(() => {
    if(edit && formik.values.occupation === "Other"){
      setStateInformation(Information2);
    }
    else {
      setStateInformation(Information);
    }
  }, [edit, formik.values.occupation])
  
  //   function addImg(){
  //     if (formik.values.imgURL) {
  //       let formData = new FormData();
  //       formData.append(
  //         "file",
  //         DataURIToBlob(formik.values.imgURL),
  //         "image/jpeg"
  //       );
  //       updateImageOtherApi(formik.values.userid, formData).then((res) => {
  //         setForceUpdateData((prev: any) => prev + 1);
  //       });
  //     }
  //     enqueueSnackbar("Updated Success", {
  //       variant: "success",
  //     });
  //   }
  // }
  
  return (
    <Layout
      pageAt={"ProfileManagement"}
      render={(props: any) => (
        <div className={`${classes.root}`}>
          <div style={{ marginBottom: 48 }}>
            <Typography
              style={{
                color: "#5A5A5B",
                fontWeight: 700,
                fontSize: 32,
                marginRight: 10,
              }}
            >
              Profile
            </Typography>
          </div>
          <Grid container className={classes.content}>
            {matches && <Grid item xs={1}></Grid>}
            <Grid
              item
              sm={12}
              md={12}
              lg={4}
              xs={12}
              style={{ marginBottom: 40 }}
            >
              {edit ? (
                <div style={{ position: "relative" }}>
                  <Avatar
                    style={{ width: 130, height: 130 }}
                    src={formik.values.imgBackendURLUpload ? formik.values.imgBackendURLUpload : convertStringToImg(formik.values)}
                  />
                  <div className={classes.rootInput}>
                    <input
                      accept="image/*"
                      className={classes.input}
                      id="icon-button-file"
                      type="file"
                      onChange={handleImage("file")}
                    />
                    <label htmlFor="icon-button-file">
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        style={{ position: "absolute", bottom: 10, left: 80 }}
                      >
                        <SelectIcon fontSize="small" />
                      </IconButton>
                    </label>
                  </div>
                </div>
              ) : (
                <Avatar
                  src={me && convertStringToImg(formik.values)}
                  style={{ width: 130, height: 130 }}
                ></Avatar>
              )}
            </Grid>
            <Grid item xs={4} lg={2}>
              {stateInformation.map((item: any, index: number) => {
                return (
                  <div>
                    <Typography
                    style={{color: item === "-" ? 'white' : '#5A5A5A'}}
                    className={clsx(
                      { [classes.textTitle20]: !edit && index > 0 },
                      {
                        [classes.textTitle30]: edit && index > 0,
                      }
                    )}
                  >
                    {item}
                  </Typography>
                  </div>
                );
              })}
            </Grid>
            {edit ? (
              <Grid item lg={5} md={8} xs={8} style={{ marginTop: -20 }}>
                <TextFieldCustom
                  variant="outlined"
                  className={classes.btn}
                  value={capitalizeFirstLetter(formik.values.role)}
                  disabled
                ></TextFieldCustom>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={6}>
                    <TextFieldCustom
                      variant="outlined"
                      className={classes.btn}
                      name="firstName"
                      onChange={formik.handleChange}
                      value={formik.values?.firstName}
                    ></TextFieldCustom>
                  </Grid>
                  <Grid item md={6} xs={6}>
                    <TextFieldCustom
                      variant="outlined"
                      className={classes.btn}
                      name="lastName"
                      onChange={formik.handleChange}
                      value={formik.values?.lastName}
                    ></TextFieldCustom>
                  </Grid>
                </Grid>
                <SelectField
                  name="gender"
                  onChange={formik.handleChange}
                  value={formik.values.gender}
                  style={{ marginTop: 16, height: 40 }}
                >
                  <MenuItem value={"M"}>Male</MenuItem>
                  <MenuItem value={"F"}>Female</MenuItem>
                </SelectField>
                <SelectField
                  name="organization"
                  onChange={formik.handleChange}
                  value={formik.values.organization}
                  disabled
                  style={{
                    marginTop: 16,
                    height: 40,
                    color: "rgba(156, 155, 157, 1)",
                  }}
                >
                  {organizationAll.map((item: any, index: number) => (
                    <MenuItem key={index} value={item._id}>
                      {item.orgName}
                    </MenuItem>
                  ))}
                </SelectField>
                {/* <SelectField
                  name="occupation"
                  onChange={formik.handleChange}
                  value={formik.values.occupation}
                  disabled
                  style={{
                    marginTop: 16,
                    height: 40,
                    color: "rgba(156, 155, 157, 1)",
                  }}
                >
                  <MenuItem value={"Medical Student"}>Medical Student</MenuItem>
                  <MenuItem value={"Nursing Student"}>Nursing Student</MenuItem>
                  <MenuItem value={"Paramedic Student"}>
                    Paramedic Student
                  </MenuItem>
                  <MenuItem value={"Doctor"}>Doctor</MenuItem>
                  <MenuItem value={"Nurse"}>Nurse</MenuItem>
                  <MenuItem value={"First responder"}>First responder</MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                </SelectField> */}
              <SelectField
                name="occupation"
                displayEmpty
                onChange={formik.handleChange}
                  style={{
                    marginTop: 16,
                    height: 40,
                }}
                value={formik.values.occupation}
                error={
                  formik.touched.occupation && formik.errors.occupation
                    ? true
                    : false
                }
                textAlert={
                  formik.errors.occupation &&
                  formik.touched.occupation &&
                  formik.errors.occupation
                }
                renderValue={
                  formik.values.occupation !== ""
                    ? undefined
                    : () => <div className={classes.hintText}>Occupation</div>
                }      
              >
                <MenuItem value={"Medical Student"}>Medical Student</MenuItem>
                <MenuItem value={"Nursing Student"}>Nursing Student</MenuItem>
                <MenuItem value={"Paramedic Student"}>Paramedic Student</MenuItem>
                <MenuItem value={"Doctor"}>Doctor</MenuItem>
                <MenuItem value={"Nurse"}>Nurse</MenuItem>
                <MenuItem value={"First responder"}>First responder</MenuItem>
                <MenuItem value={"Other"}>Other</MenuItem>
              </SelectField>
              {formik.values.occupation && !Occupations.includes(formik.values.occupation) && (
                <>
                  <TextFieldCustom
                      name="occupationTmp"
                      size="small"
                      variant="outlined"
                      style={{ marginTop: 16, height: 40 }}
                      onChange={formik.handleChange}
                      value={formik.values.occupationTmp}
                      error={formik.touched.occupationTmp && formik.errors.occupationTmp ? true : false}
                      textAlert={
                        formik.errors.occupationTmp && formik.touched.occupationTmp && formik.errors.occupationTmp
                      }
                    />
                </>
              )}
                <TextFieldCustom
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  disabled
                  style={{ marginTop: 16, height: 40 }}
                  variant="outlined"
                />
                <TextFieldCustom
                  size="small"
                  variant="outlined"
                  type="date"
                  name="birthDate"
                  onChange={formik.handleChange}
                  value={dayjs(formik.values.birthDate).format("YYYY-MM-DD")}
                  style={{ marginTop: 16, height: 40 }}
                />
                <TextFieldCustom
                  name="tel"
                  onChange={formik.handleChange}
                  value={formik.values.tel}
                  style={{ marginTop: 16, height: 40 }}
                  variant="outlined"
                />
              </Grid>
            ) : (
              <Grid item md={5} xs={6}>
                {me?.organization?.orgName ? (
                  <>
                    <Typography className={classes.textContent}>
                      {capitalizeFirstLetter(me?.userRoles[0]) || <Skeleton />}
                    </Typography>
                    <Typography
                      className={classes.textContent}
                      style={{ marginTop: 20 }}
                    >
                      {`${me?.firstName}  ${me?.lastName}`}
                    </Typography>
                    <Typography
                      className={classes.textContent}
                      style={{ marginTop: 20 }}
                    >
                      {me?.gender === "M" ? "Male" : "Female"}
                    </Typography>
                    <Typography
                      className={classes.textContent}
                      style={{ marginTop: 20 }}
                    >
                      {me?.organization?.orgName}
                    </Typography>
                    <Typography
                      className={classes.textContent}
                      style={{ marginTop: 20 }}
                    >
                      {me?.occupation}
                    </Typography>
                    <Typography
                      className={classes.textContent}
                      style={{ marginTop: 20 }}
                    >
                      {me?.email}
                    </Typography>
                    <Typography
                      className={classes.textContent}
                      style={{ marginTop: 20 }}
                    >
                      {dayjs(formik.values.birthDate).format("DD-MM-YYYY")}
                    </Typography>
                    <Typography
                      className={classes.textContent}
                      style={{ marginTop: 20 }}
                    >
                      {me?.tel}
                    </Typography>
                  </>
                ) : (
                  sizeArray.map((item: any, index: number) => {
                    return (
                      <>
                        <Skeleton
                          height="11.2%"
                          width={item + "%"}
                          animation="wave"
                        />
                      </>
                    );
                  })
                )}
              </Grid>
            )}
            {matches && <Grid item md={2}></Grid>}
            {/* <Grid item xs={2}></Grid> */}
            <Grid item lg={12} md={12} xs={12}>
              <div>
                {edit ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: 50,
                    }}
                  >
                    <ButtonOutlined colorText="red" onClick={handleOpen}>
                      Reset Password
                    </ButtonOutlined>
                    <div>
                      <ButtonOutlined colorText="blue" onClick={handleEdit}>
                        Cancel
                      </ButtonOutlined>
                      <ButtonContained
                        colorText="blue"
                        style={{ marginLeft: 20 }}
                        onClick={() => changeState("UPDATE")}
                      >
                        Save
                      </ButtonContained>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 50,
                      }}
                    >
                      <ButtonOutlined colorText="blue" onClick={handleEdit}>
                        Edit Account
                      </ButtonOutlined>
                      <ButtonOutlined
                        colorText="red"
                        style={{ marginTop: "8px" }}
                        onClick={() => changeState("LOGOUT")}
                        // onClick={logOut}
                      >
                        Logout
                      </ButtonOutlined>
                    </div>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
          <AlertDialog
            open={open}
            onClose={handleClose}
            handleClick={handleClose}
            title={"New Password"}
          >
            <DialogContent>
              <ResetPassword formik={formik} />
            </DialogContent>
            <DialogActions style={{ display: "flex", justifyContent: "end" }}>
            <div style={{display:'flex'}}>
                <ButtonOutlined colorText="blue" onClick={handleClose}>
                  Cancel
                </ButtonOutlined>
                <ButtonContained
                  colorText="blue"
                  style={{ marginLeft: 20 }}
                  onClick={() => formik.handleSubmit()}
                >
                  Save
                </ButtonContained>
              </div>
            </DialogActions>
          </AlertDialog>
          {/* Dialog Recheck */}
          <RecheckDialog
            open={openRecheck}
            handleClose={handleCloseRecheck}
            confirmNo={handleCloseRecheck}
            confirmYes={handleSubmit}
            confirmPopup={confirmPopup}
          />
        </div>
      )}
    />
  );
};

export default ProfileManagement;
