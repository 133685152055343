import React from "react";

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Paper,
} from "@material-ui/core";

import { useFormik } from "formik";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../contexts/AuthContext";
import { withRouter, RouteComponentProps } from "react-router-dom";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    test: {
      marginTop: "22px",
    },
    rootForm: {
      flexGrow: 1,
    },
  })
);
interface ILoginForm extends RouteComponentProps {}

const LoginForm = (props: ILoginForm) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { login } = useAuth();
  const classes = useStyles();
  const validationSchema = Yup.object().shape({
    userName: Yup.string().required(t("fieldData.fieldRequire")),
    password: Yup.string()
      .min(6, t("authentication.passwordAtLeast"))
      .required(t("fieldData.fieldRequire")),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords do not match Password")
      .required(t("fieldData.fieldRequire")),
  });
  const formik = useFormik({
    initialValues: {
      showPassword: false,
      userName: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      login(values.userName, values.password);
      enqueueSnackbar(t("authentication.signUpSuccessFully"), {
        variant: "success",
      });
      formik.resetForm();
      props.history.push({
        pathname: "/dashboard",
      });
    },
  });
  function handleClickShowPassword() {
    formik.setFieldValue("showPassword", !formik.values.showPassword);
  }
  function handleReset() {
    formik.resetForm();
  }
  return (
    <React.Fragment>
      <Paper
        style={{
          padding: 24,
          maxWidth: 600,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <form
          className={classes.rootForm}
          noValidate
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <div>
            <TextField
              error={
                formik.touched.userName && formik.errors.userName ? true : false
              }
              helperText={formik.errors.userName}
              label={t("fieldData.userName")}
              id="userName"
              name="userName"
              value={formik.values.userName}
              onChange={formik.handleChange}
              style={{ width: "100%" }}
              placeholder="Username"
              margin="normal"
              variant="outlined"
            />
          </div>
          <div>
            <TextField
              error={
                formik.touched.password && formik.errors.password ? true : false
              }
              helperText={formik.errors.password}
              label={t("fieldData.password")}
              id="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              style={{ width: "100%" }}
              placeholder="Password"
              margin="normal"
              variant="outlined"
              type={formik.values.showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {formik.values.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div>
            <TextField
              error={
                formik.touched.confirmPassword && formik.errors.confirmPassword
                  ? true
                  : false
              }
              helperText={formik.errors.confirmPassword}
              label={t("fieldData.confirmPassword")}
              id="confirmPassword"
              name="confirmPassword"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              style={{ width: "100%" }}
              placeholder="Confirm Password"
              margin="normal"
              variant="outlined"
              type={formik.values.showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {formik.values.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <Button
            style={{ width: "100%", borderRadius: 10, marginTop: 30 }}
            color="primary"
            variant="contained"
            type="submit"
          >
            {t("authentication.signUp")}
          </Button>
        </form>
      </Paper>
    </React.Fragment>
  );
};

export default withRouter(LoginForm);
