import { Button, makeStyles, TextField } from "@material-ui/core";
import styled, { css } from "styled-components";
interface IBtnStyle {
  text: string;
}
interface IBtnColor {
  customColor: string;
}
export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    // height: "100%",
    paddingLeft: 20,
    paddingRight: 20,
  },
  slider: {
    width: 55,
    marginLeft: 13,
    "& .MuiOutlinedInput-input": {
      padding: 5,
    },
  },
  iconBtn: {
    "& .MuiSvgIcon-root": {
      width: 15,
      height: 15,
    },
  },
  btnAdd: {
    "& .MuiButton-root": {
      border: " 1.5px solid #51B2DA",
      color: "#51B2DA",
      backgroundColor: "white",
      textTransform: "none",
      padding: "3px 9px 3px 9px",
      fontWeight: 500,
    },
  },
  backgroundScenario: {
    height: 590,
    backgroundColor: "#F6FAFF",
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "8px",
    width: 340,
  },
  buttonCard: {
    // display: "block",
    backgroundColor: "white",
    display: 'flex',
    justifyContent: 'flex-end',
    height: 68,
    width: 300,
    // paddingLeft: 1000,
    borderRadius: 8,
    marginTop: 10,
    position: 'relative',
  },
  selectedCard: {
    border: "2px solid #51B2DA",
  },
  typography: {
    padding: theme.spacing(2),
  },
}));

