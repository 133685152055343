import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { ISrot } from ".";
import { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
interface IProps {
  list?: any;
  setSortValue: any;
  sortValue: ISrot;
  type: "filterByRole" | "sortByDate" | "sortByStatus" | "filterByMode";
}

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.guess();

const SortData = ({ list, setSortValue, sortValue, type }: IProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [sort, setSort] = useState<string>(()=>{
    const sort = list.find((x:any)=> x.value === sortValue[type])
    return sort.title;
  });
  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }
  function handleChange(event: any) {
    setSortValue({ ...sortValue, [type]: event.value });
    setSort(event.title);
    handleClose();
  }
  function handleClose() {
    setAnchorEl(null);
  }
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <IconButton
          onClick={handleClick}
          style={{ padding: 0, marginRight: "5px" }}
        >
          <KeyboardArrowDownIcon
            fontSize="small"
            style={{ color: "#9C9B9D" }}
          />
        </IconButton>
        <Typography
          style={{
            color: "#9C9B9D",
            fontWeight: 400,
            fontSize: 13,
          }}
        >
          {sort}
        </Typography>
      </div>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        disableAutoFocusItem
        onClose={handleClose}
        PaperProps={{
          style: {
            left: "50%",
            transform: "translateX(0%) translateY(30%)",
          },
        }}
      >
        {list.map((item: any, index: number) => (
          <MenuItem key={index} onClick={() => handleChange(item)}>
            {item.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
export default SortData;
