import { Avatar, Box, Grid, MenuItem, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import { convertStringToImg } from "../../../../commons/ConvertImg";
interface props {
  selectData: any;
}
function Preview({ selectData }: props) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Avatar
            style={{ width: 180, height: 180 }}
            src={convertStringToImg(selectData)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Grid
            container
            spacing={2}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Grid item xs={3}>
              <Typography
                style={{
                  color: "#5A5A5A",
                  fontWeight: 500,
                  fontSize: 14,
                }}
              >
                Role
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography
                style={{
                  color: "#5A5A5A",
                  fontWeight: 300,
                  fontSize: 14,
                }}
              >
                {selectData?.userRoles}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                style={{
                  color: "#5A5A5A",
                  fontWeight: 500,
                  fontSize: 14,
                }}
              >
                Name
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography
                style={{
                  color: "#5A5A5A",
                  fontWeight: 300,
                  fontSize: 14,
                }}
              >
                {selectData?.firstName + " " + selectData?.lastName}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                style={{
                  color: "#5A5A5A",
                  fontWeight: 500,
                  fontSize: 14,
                }}
              >
                Gender
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography
                style={{
                  color: "#5A5A5A",
                  fontWeight: 300,
                  fontSize: 14,
                }}
              >
                {selectData?.gender}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                style={{
                  color: "#5A5A5A",
                  fontWeight: 500,
                  fontSize: 14,
                }}
              >
                Organization
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography
                style={{
                  color: "#5A5A5A",
                  fontWeight: 300,
                  fontSize: 14,
                }}
              >
                {selectData?.organization?.orgName}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                style={{
                  color: "#5A5A5A",
                  fontWeight: 500,
                  fontSize: 14,
                }}
              >
                Occupation
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography
                style={{
                  color: "#5A5A5A",
                  fontWeight: 300,
                  fontSize: 14,
                }}
              >
                {selectData?.occupation}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                style={{
                  color: "#5A5A5A",
                  fontWeight: 500,
                  fontSize: 14,
                }}
              >
                Email
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography
                style={{
                  color: "#5A5A5A",
                  fontWeight: 300,
                  fontSize: 14,
                }}
              >
                {selectData?.email}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                style={{
                  color: "#5A5A5A",
                  fontWeight: 500,
                  fontSize: 14,
                }}
              >
                BirthDay
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography
                style={{
                  color: "#5A5A5A",
                  fontWeight: 300,
                  fontSize: 14,
                }}
              >
                {dayjs(selectData?.birthDate).format("DD/MM/YYYY")}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                style={{
                  color: "#5A5A5A",
                  fontWeight: 500,
                  fontSize: 14,
                }}
              >
                Telephone
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography
                style={{
                  color: "#5A5A5A",
                  fontWeight: 300,
                  fontSize: 14,
                }}
              >
                {selectData?.tel}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
export default Preview;
