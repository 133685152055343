import Router from "./routers/index";
import { ThemeProvider } from "styled-components";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  MuiThemeProvider,
  StylesProvider,
  createMuiTheme,
} from "@material-ui/core/styles";
// import useWindowSize from "./helpers/WindowSize";
import "./App.scss";

import "./i18n/config";
import { SnackbarProvider } from "notistack";
import { Notifications } from "react-push-notification";

const App = () => {
  // const winSize = useWindowSize();
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#00000",
        dark: "#105B3F",
        light: "#62CFA0",
        contrastText: "#ffff",
      },
      secondary: {
        main: "#11548D",
        dark: "#0D4575",
        light: "#1D80D4",
        contrastText: "#ffff",
      },
      action: {
        hover: "#0000000A",
        selected: "#00000014",
      },
      info: {
        main: "rgba(17, 84, 141, 1)",
        dark: "rgba(13, 69, 117, 1)",
        light: "rgba(29, 128, 212, 1)",
        contrastText: "#ffff",
      },
      error: {
        main: "rgba(244, 67, 54, 1)",
        dark: "rgba(227, 27, 12, 1)",
        light: "rgba(248, 128, 120, 1)",
        contrastText: "#ffff",
      },
      warning: {
        main: "rgba(255, 152, 0, 1)",
        dark: "rgba(199, 119, 0, 1)",
        light: "rgba(255, 181, 71, 1)",
        contrastText: "rgba(0, 0, 0, 0.87)",
      },
      success: {
        main: "rgba(48, 158, 111, 1)",
        dark: "rgba(16, 91, 63, 1)",
        light: "rgba(98, 207, 160, 1)",
        contrastText: "#FFFFFF",
      },
    },
  });
  return (
    <div>
      <Notifications />
      <DndProvider backend={HTML5Backend}>
        <StylesProvider injectFirst>
          <MuiThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
              <SnackbarProvider
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                maxSnack={3}
              >
                <Router />
              </SnackbarProvider>
            </ThemeProvider>
          </MuiThemeProvider>
        </StylesProvider>
      </DndProvider>
    </div>
  );
};

export default App;
