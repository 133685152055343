import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Layout from "../../../commons/Layout";
import { useStyles } from "./style";
import SelectPatient from "./SelectPatient";
import CreatePatient from "./CreatePatient";
import AlertDialog from "../../../commons/AlertDialog";
import CreatePatientDialog from "./Dialog/CreatePatientDialog";
import {
  ButtonContained,
  ButtonOutlined,
  ButtonText,
} from "../../../commons/Buttons";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import { ITextConfirmDetail } from "../../ProfileManagement/Profile";
import RecheckDialog from "../../../commons/RecheckDialog";
import { useAuth } from '../../../../contexts/AuthContext';
import { checkPagePermission } from '../../../commons/CheckRole';
type Props = {};
interface IBreath {
  rr: number;
  obs: boolean;
}
interface ICirculation {
  pr: number;
  cr: number;
}
interface ICloths {
  paint: string;
  shirt: string;
  shoes: string;
}
interface INjuries {
  arms: number;
  chest: number;
  head: number;
  legs: number;
}

interface ISelectPatient {
  age: string;
  breath: IBreath;
  caseColor: string;
  circulation: ICirculation;
  cloths: ICloths;
  code: string;
  gender: string;
  injuries: INjuries;
  pose: string;
}
interface ITextConfirm {
  DELETE?: ITextConfirmDetail;
  CREATE?: ITextConfirmDetail;
  UPDATE?: ITextConfirmDetail;
  DELETE_ALL?: ITextConfirmDetail;
  QUITEDITING?: ITextConfirmDetail;
}

const TextConfirmPopup: ITextConfirm = {
  DELETE_ALL: {
    title: "Delete all patients?",
    subTitle: "This action cannot be rolled back.",
    yes: "Delete",
    no: "Cancel",
    color: "red",
  },
  DELETE: {
    title: "Delete this patient?",
    subTitle: "This action cannot be rolled back.",
    yes: "Delete",
    no: "Cancel",
    color: "red",
  },
  CREATE: {
    title: "Create this Patient?",
    subTitle: "Please check information, you can edit more later.",
    yes: "Yes",
    no: "No",
    color: "blue",
  },
  UPDATE: {
    title: "Edit this patient?",
    subTitle: "Please check information, you can edit more later.",
    yes: "Yes",
    no: "No",
    color: "blue",
  },
  QUITEDITING: {
    title: "Quit editing?",
    subTitle: "Changes you made so far will not be saved.",
    yes: "Yes",
    no: "No",
    color: "red",
  },
};
export const ListOfPatient = (props: Props) => {
  const classes = useStyles();
  const { currentUser } = useAuth();
  const [openRecheck, setOpenRecheck] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState<ITextConfirmDetail>();
  const [open, setOpen] = useState<boolean>(false);
  const [page, setPage] = useState<string>("PAGE_SELECT");
  const [selectPatient, setSelectPatient] = useState<ISelectPatient>(
    {} as ISelectPatient
  );
  const [reCheckOnExit, setRecheckOnExit] = useState(false) 
  const [state, setState] = useState<string>("SELECT");
  const [selectColor, setSelectColor] = useState<string>("");
  const [forceUpdateData, setForceUpdateData] = useState<number>(0);
  const [func, setFunc] = useState<() => void | undefined>();

  function handleColor(color: string): void {
    setSelectColor(color);
  }
  function handleCloseRecheck() {
    setOpenRecheck(false);
  }
  function handleClose() {
    setOpen(false);
  }
  function handleOpen() {
    setOpen(true);
  }
  function handleSubmit() {
    if(reCheckOnExit && state === "UPDATE"){
      changeState("SELECT");
    }
    else if(reCheckOnExit && state === "CREATE"){
      changeState("SELECT");
    }
    else if ((state === "CREATE" || state === "UPDATE" || state === "DELETE" || state === "DELETE_ALL") && func) {
      func();
    }
    handleCloseRecheck();
  }
  function handleOpenRecheck(callback: () => void) {
    setOpenRecheck(true);
    setFunc(() => () => callback());
  }
  function changeState(state: string, color?: string) {
    setState(state);
    if (state === "SELECT") {
      setSelectColor("");
      setPage("PAGE_SELECT");
    } else if (state === "CREATE") {
      setConfirmPopup(TextConfirmPopup[state]);
      setPage("PAGE_CREATE");
    } else if (state === "DELETE") {
      setConfirmPopup(TextConfirmPopup[state]);
    } else if (state === "UPDATE" && color) {
      setConfirmPopup(TextConfirmPopup[state]);
      handleColor(color);
      setPage("PAGE_UPDATE");
    } else if (state === "DELETE_ALL") {
      setPage("PAGE_SELECT");
      setConfirmPopup(TextConfirmPopup[state]);
    }
    setRecheckOnExit(false);
  }

  return (
    <Layout
      pageAt={"listOfPatient"}
      render={(props: any) => (
        <div className={classes.root}>
          <Box display="flex" alignItems="center">
            <Typography
              style={{
                color: "#5A5A5B",
                fontWeight: 700,
                fontSize: 32,
                marginRight: 10,
              }}
            >
              List of Patient
            </Typography>
            {page === "PAGE_SELECT" && checkPagePermission(currentUser?.roles, "HANDLEPATIENT") 
            && (
              <div className={classes.btnAdd} onClick={handleOpen}>
                <Button
                  onClick={handleOpen}
                  startIcon={<AddCircleRoundedIcon />}
                >
                  Create Patient
                </Button>
              </div>
            )}
          </Box>
          {page === "PAGE_SELECT" ? (
            <SelectPatient
              changeState={changeState}
              selectPatient={selectPatient}
              setSelectPatient={setSelectPatient}
              handleColor={handleColor}
              setForceUpdateData={setForceUpdateData}
              forceUpdateData={forceUpdateData}
              handleOpenRecheck={handleOpenRecheck}
            />
          ) : (
            <CreatePatient
              selectColor={selectColor}
              changeState={changeState}
              selectPatient={selectPatient}
              state={state}
              setForceUpdateData={setForceUpdateData}
              forceUpdateData={forceUpdateData}
              handleOpenRecheck={handleOpenRecheck}
              page={page}
              TextConfirmPopup={TextConfirmPopup}
              setConfirmPopup={setConfirmPopup}
              setRecheckOnExit={setRecheckOnExit}
              setOpenRecheck={setOpenRecheck}
            />
          )}
          <AlertDialog
            open={open}
            onClose={handleClose}
            handleClick={handleClose}
            title={"Select Tag Color to Create"}
          >
            <DialogContent>
              <CreatePatientDialog
                handleColor={handleColor}
                selectColor={selectColor}
              />
            </DialogContent>
            <DialogActions style={{ display: "flex", justifyContent: "end" }}>
              {selectColor && (
                <>
                  <ButtonText colorText="blue" onClick={handleClose}>
                    Cancel
                  </ButtonText>
                  <ButtonContained
                    colorText="blue"
                    onClick={() => {
                      changeState("CREATE");
                      handleClose();
                    }}
                  >
                    Create Patient
                  </ButtonContained>
                </>
              )}
            </DialogActions>
          </AlertDialog>
          <RecheckDialog
            open={openRecheck}
            handleClose={handleCloseRecheck}
            confirmNo={handleCloseRecheck}
            confirmYes={handleSubmit}
            confirmPopup={confirmPopup}
          />
        </div>
      )}
    />
  );
};
export default ListOfPatient;
