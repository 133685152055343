import React from "react";
import Layout from "../../commons/Layout";
import { useTranslation } from "react-i18next";

const Test = () => {
  const { t } = useTranslation();
  return (
    <Layout
      render={(props: any) => (
        <div>
          <div style={{ color: "red" }}>
            {t("login:title", { defaultValue: "About Page" })}
          </div>
        </div>
      )}
    />
  );
};

export default Test;
