import { Button, makeStyles, TextField } from "@material-ui/core";
import styled, { css } from "styled-components";
interface IBtnStyle {
  text: string;
}
interface IBtnColor {
  customColor: string;
}
export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    height: "100%",
    paddingLeft: 20,
    // paddingRight: 80,
  },
  btnAdd: {
    "& .MuiButton-root": {
      border: " 1.5px solid #51B2DA",
      color: "#51B2DA",
      backgroundColor: "white",
      textTransform: "none",
      padding: "3px 9px 3px 9px",
      fontWeight: 500,
    },
  },
  iconBtn: {
    "& .MuiSvgIcon-root": {
      width: 15,
      height: 15,
    },
  },
  buttonCard: {
    display: "block",
    backgroundColor: "white",
    height: 65,
    width: "100%",
    borderRadius: 8,
    marginTop: 10,
    padding:0,
    textTransform:'none'
  },
  selectedCard: {
    border: "2px solid #51B2DA"
  },
  selectedCardPatient: {
    borderRight: "2px solid #51B2DA",
    borderTop: "2px solid #51B2DA",
    borderBottom: "2px solid #51B2DA"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  backgroundScenario: {
    height: 518,
    backgroundColor: "#F6FAFF",
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "0px 15px",
    marginTop: "8px",
    width: 345,
  }
}));
