import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { withRouter } from "react-router-dom";

const Index = (props) => {
  const { t } = useTranslation();
  const [counter, setCounter] = useState(5);
  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      props.history.push({
        pathname: "/login",
      });
    }
    return () => clearInterval(timer);
  }, [counter]);
  return (
    <div>
      <div
        style={{
          paddingTop: 40,
          width: "100%",
          maxWidth: 800,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <h1 style={{ color: "rgb(23, 43, 77)", marginBottom: 30 }}>
          {t("errorPage.500")}
        </h1>
        <h3 style={{ color: "rgb(23, 43, 77)", marginBottom: 30 }}>
          {t("errorPage.willRedirect")} {"  "}
          <strong style={{ color: "red" }}>{counter}</strong>
        </h3>
        <h6 style={{ color: "rgb(107, 119, 140)", marginBottom: 30 }}>
          You either tried some shady route or you came here by mistake.
          Whichever it is, try using the navigation.
        </h6>
        <div style={{ textAlign: "center" }}>
          <img
            alt="500"
            src="https://material-kit-pro-react.devias.io/static/error/error500_light.svg"
          ></img>
        </div>
      </div>
    </div>
  );
};
export default withRouter(Index);
