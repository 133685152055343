import React from "react";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import { AuthProvider } from "../contexts/AuthContext";
import PrivateRoute from "./PrivateRoute";
import {
  About,
  Landing,
  ClassroomManagement,
  VrSpectatorManagement,
  UserManagement,
  ApprovedManagement,
  GroupManagement,
  Login,
  Test,
  UserManagementTable,
  UserManagementEdit,
  Error404,
  Error401,
  Error500,
  ProfileManagement,
  AssignClassroom,
  AttendClassroom,
  ListOfPatient,
  ListOfScenario,
  Dashboard,
  VerifyAccount,
  InstallTutorial,
  DashboardManual
} from "../components/pages";
export const Routes = {
  error404: "*",
  home: "/",
  about: "/about",
  test: "/test",
  dashboard:"/dashboard",
  classroomManagement: "/classroom-management",
  vrSpectator: "/vr-spectator",
  attendClassroom: "/attend-classroom",
  assignClassroom: "/assign-classroom",
  userManagement: "/user-management",
  approvedManagement: "/approved-management",
  groupManagement: "/group-management",
  profileManagement: "/profile-me",
  listOfPatient: '/list-of-patient',
  ListOfScenario:'/list-of-scenario',
  installTutorial: '/install-tutorial',
  dashboardManual:'/dashboard-manual',
  verifyAccount:'/verify-account/:id',
  signin: "/login",
  signup: "/signUp",
  account: "/management/account",
  accountId: "/management/account:id",
  managementTableUser: "/management/user",
  managementTableUserData: "/management/user/:id",
  managementTableRole: "/management/role",
  managementTableRoleData: "/management/role/:id",
  error401: "/401authorization",
  error500: "/error500",
};
const Router = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Switch>
          <PrivateRoute exact path={Routes.home} component={Landing} />
          <Route path={Routes.about} component={About} />
          <PrivateRoute path={Routes.test} component={Test} />
          <PrivateRoute
            path={Routes.dashboard}
            component={Dashboard}
          />
          <PrivateRoute
            path={Routes.classroomManagement}
            component={ClassroomManagement}
          />
           <PrivateRoute
            path={Routes.vrSpectator}
            component={VrSpectatorManagement}
          />
          <PrivateRoute
            path={Routes.userManagement}
            component={UserManagement}
          />
          <PrivateRoute
            path={Routes.approvedManagement}
            component={ApprovedManagement}
          />
          <PrivateRoute
            path={Routes.groupManagement}
            component={GroupManagement}
          />
           <PrivateRoute
            path={Routes.ListOfScenario}
            component={ListOfScenario}
          />
           <PrivateRoute
            path={Routes.listOfPatient}
            component={ListOfPatient}
          />
          <PrivateRoute
            path={Routes.profileManagement}
            component={ProfileManagement}
          />
          <Route path={Routes.signin} component={Login} />
          <PrivateRoute
            exact
            path={Routes.managementTableUserData}
            component={UserManagementEdit}
          />
          <PrivateRoute
            exact
            path={Routes.assignClassroom}
            component={AssignClassroom}
          />
           <PrivateRoute
            exact
            path={Routes.installTutorial}
            component={InstallTutorial}
          />
            <PrivateRoute
            exact
            path={Routes.dashboardManual}
            component={DashboardManual}
          />
           <PrivateRoute
            exact
            path={Routes.attendClassroom}
            component={AttendClassroom}
          />
          <Route path={Routes.verifyAccount} component={VerifyAccount} />
          <Route path={Routes.error401} component={Error401} />
          <Route path={Routes.error500} component={Error500} />
          <Route path={Routes.error404} component={Error404} />
        </Switch>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Router;
