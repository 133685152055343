import { CSSProperties, FC, useEffect, useState } from "react";
import { useDrop } from "react-dnd";
import { handleCaseColor } from "./CreateScenario";
import { ItemTypes } from "./itemType";

const style: CSSProperties = {
  height: "15px",
  width: "15px",
  borderRadius: 50,
};
interface IProps {
  index: number;
  bgColor?: string;
}
interface Iitem {
  name: string;
  color: string;
}
export const PatientPositionPoint: FC<any> = ({ index, bgColor }: IProps) => {
  const [item, setItem] = useState<any>();
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.BOX,
    drop: () => ({ name: index }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    hover: (item: any, monitor) => {
      setItem(item);
    },
  }));

  const isActive = canDrop && isOver;
  let backgroundColor = bgColor ? bgColor : "rgba(0,0,0,0)";

  if (isActive && item) {
    backgroundColor = handleCaseColor(item.color);
  }

  return (
    <div
      ref={drop}
      style={{
        ...style,
        backgroundColor,
        border: bgColor ? "" : "1px solid white",
      }}
    ></div>
  );
};
