import React from "react";
import Layout from "../../commons/Layout";
import { useHistory } from "react-router-dom";

const Landing = (props: any) => {
  const history = useHistory();
  return (
    <Layout
      pageAt={"dashBoard"}
      render={(props: any) => (
        <div style={{ color: "blue" }}>
          <div>{"Landing Page"}</div>
          <div
            onClick={() => {
              history.push({ pathname: "/test" });
            }}
          >
            TEST page
          </div>
          <div
            onClick={() => {
              history.push({ pathname: "/classroom-management" });
            }}
          >
            Classroom page
          </div>
        </div>
      )}
    />
  );
};

export default Landing;
