import {
  Box,
  FormControl,
  makeStyles,
  TextFieldProps,
  TooltipProps,
} from "@material-ui/core";
import { TextFieldStyleAutocomplete } from "./style";
import { ReactComponent as Warning } from "../../../images/icons/warning.svg";
import Tooltip from "@material-ui/core/Tooltip";
interface IselectCustomProp {
  textAlert?: any;
}
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: "#F57373",
  },
  tooltip: {
    backgroundColor: "#F57373",
    fontSize: 12,
    padding: 10,
  },
}));
function BootstrapTooltip(props: TooltipProps) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}
const TextFieldAutocomplete = ({
  fullWidth = true,
  error,
  children,
  textAlert,
  ...rest
}: TextFieldProps & IselectCustomProp) => {
  return (
    <FormControl fullWidth={fullWidth} error={error}>
      {/* <SelectLabel shrink>{label}</SelectLabel> */}
      <Box display="flex" alignItems="center">
        <TextFieldStyleAutocomplete {...rest}>
          {children}
        </TextFieldStyleAutocomplete>
        {error && (
          <BootstrapTooltip title={textAlert} arrow placement="bottom-end">
            {
              <Warning
                style={{
                  marginLeft: "5px",
                  marginRight: "5px",
                  minWidth: 20,
                  minHeight: 20,
                }}
              />
            }
          </BootstrapTooltip>
        )}
      </Box>
    </FormControl>
  );
};

export default TextFieldAutocomplete;
