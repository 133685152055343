import { v4 as uuid } from "uuid";
import { IState } from "./userManagement";

export const usersInitialState: IState = {
  users: [
    {
      _id: uuid(),
      id: 1,
      data: {
        userName: "JohnSnow001",
        cid: "123456",
        fullName: "JohnTest",
        roles: [{ code: "Admin", display: "Admin" }],
        active: true,
        createAt: new Date(new Date().setDate(new Date().getDate() - 5)),
        updateAt: new Date(new Date().setDate(new Date().getDate() - 5)),
      },
    },
    {
      _id: uuid(),
      id: 2,
      data: {
        userName: "JohnSnow002",
        cid: "147369",
        fullName: "John22",
        roles: [
          { code: "Admin", display: "Admin" },
          { code: "user", display: "User" },
        ],
        active: false,
        createAt: new Date(new Date().setDate(new Date().getDate() - 3)),
        updateAt: new Date(new Date().setDate(new Date().getDate() - 3)),
      },
    },
    {
      _id: uuid(),
      id: 3,
      data: {
        userName: "JohnSnow003",
        cid: "369",
        fullName: "John233",
        roles: [{ code: "user", display: "User" }],
        active: false,
        createAt: new Date(new Date().setDate(new Date().getDate() - 2)),
        updateAt: new Date(new Date().setDate(new Date().getDate() - 2)),
      },
    },
  ],
};
