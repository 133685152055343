import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import LoginFrom from "./loginForm";
import CreateFrom from "./createForm";
import LogoLogin from "../../../../images/pages/loginPage/vector_login.png";
import {
  Box,
  Button,
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
// const CircleDIV = styled.div`
//   width: 100px;
//   height: 200px;
//   background-color: gold;
//   border-bottom-right-radius: 100px;
//   border-top-right-radius: 100px;
//   border: 10px solid gray;
//   border-left: 0;

//   -webkit-box-sizing: border-box;
//   -moz-box-sizing: border-box;
//   box-sizing: border-box;
//   -webkit-transform: rotate(180deg);
// `;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      height: "100vh",
      width: "100vw",
      backgroundColor: "white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      // backgroundColor: "white",
      // height: "100%",
      // width: "100%",
      // width: 1440,
      // height: 1024,
      // [theme.breakpoints.down("md")]: {
      //   maxWidth: 1024,
      //   maxHeight: 768,
      // },
      // [theme.breakpoints.down("sm")]: {
      //   maxWidth: 360,
      //   maxHeight: 640,
      // },
    },
    containerHeader: {
      // padding: "50px 40px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      // width: "100%",
      // display: "flex",
      // justifyContent: "space-between",
      // position: "absolute",
      // [theme.breakpoints.down("sm")]: {
      //   padding: "13px 36px",
      // },
    },
    imageRight: {
      height: 672,
    },
    divIcon: {
      color: "white",
      borderRadius: 15,
      background: "#11548D",
      width: 49,
      height: 49,
      boxShadow: "1px 2px 2px rgba(0, 0, 0, 0.25)",
      fontSize: 14,
      textAlign: "center",
      textTransform: "uppercase",
      [theme.breakpoints.down("sm")]: {
        width: 30,
        height: 30,
        borderRadius: 8,
      },
    },
    iconButton: {
      marginTop: 14,
      [theme.breakpoints.down("sm")]: {
        marginTop: 3,
      },
    },
    btnBack: {
      color: "#9C9B9D",
      fontSize: 16,
      position: "absolute",
      left: 60,
      bottom: 20,
      textTransform: "none",
    },
    btnNext: {
      color: "#51B2DA",
      fontSize: 16,
      position: "absolute",
      right: 60,
      bottom: 20,
      textTransform: "none",
    },
    bgPaper: {
      width: 1180,
      height: 672,
      display: "flex",
      borderRadius: 16,
      boxShadow: "0px 8px 24px rgba(112, 144, 176, 0.15)",
      "@media (max-width: 1200px)": {
        width: "85%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "85%",
        // heig: 30,
        borderRadius: 8,
      },
    },
  })
);
const LoginPage = () => {
  const { i18n } = useTranslation();
  const classes = useStyles();
  const [state, setState] = useState("login");
  const matches = useMediaQuery("(min-width:1200px)");

  const [lang, setLang] = useState("th");
  function handleState(text: string) {
    setState(text);
  }
  return (
    <div className={classes.mainContainer}>
      <div className={classes.bgPaper}>
        {matches && <img src={LogoLogin} className={classes.imageRight} />}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <Grid container alignItems="center" style={{marginBottom:50}} >
            <Grid xs={2}></Grid>
            <Grid item xs={8}>
              {state === "login" ? (
                <LoginFrom handleState={handleState} />
              ) : (
                <CreateFrom handleState={handleState} />
              )}
            </Grid>
            <Grid xs={2}></Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
