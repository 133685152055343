import { Button, makeStyles, TextField, withStyles } from "@material-ui/core";
import styled, { css } from "styled-components";
export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    paddingLeft: 20,
    paddingRight: 0,
    // minHeight:"90vh",
    [theme.breakpoints.up("lg")]: {
      paddingRight: 130,
    },
  },
  dialogPaper: {
    // minHeight: "400px",
    maxHeight: "450px",
  },
  dialogContent: {
    ".& .MuiDialogContent-root": {
      height: "200px",
    },
  },
}));
