import { Button, makeStyles, TextField } from "@material-ui/core";
import styled, { css } from "styled-components";
export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    paddingLeft: 50,
    paddingRight: 50,
    minHeight: "90vh",
    // maxHeight:"100vh"
  },
  textTitle20: {
    color: "#5A5A5A",
    fontWeight: 500,
    fontSize: "16px",
    textTransform: "capitalize",
    marginTop: 20,
  },
  textTitle30: {
    color: "#5A5A5A",
    fontWeight: 500,
    fontSize: "16px",
    textTransform: "capitalize",
    marginTop: 33,
  },
  textContent: {
    color: "#5A5A5A",
    fontWeight: 300,
    fontSize: "16px",
    // textTransform: 'capitalize',
  },
  hintText: {
    color: "rgba(0, 0, 0, 0.35)",
    fontSize: 14,
    fontWeight: 300,
  },
  btn: {
    marginTop: 16,
    height: 40,
  },
  colorIcon: {
    color: "#9C9B9D",
  },
  content: {
    width:'80%'
    // "@media (max-width: 1000px)": {
    //   width: "500px",
    // },
    // "@media (min-width: 700px)": {
    //   width: "700px",
    // },
  },
  rootInput: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));
