import { Button, Typography } from "@material-ui/core";
import React from "react";
import CardSelectColor from "./cardSelectColor";
const COLOR_ARRAY = ["GREEN", "YELLOW", "RED", "BLACK"];

interface IProps {
  handleColor: (color: string) => void;
  selectColor:string;
}
export default function CreatePatientDialog({ handleColor,selectColor }: IProps) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {COLOR_ARRAY.map((item) => {
        return <CardSelectColor name={item} handleColor={handleColor} selectColor={selectColor}/>;
      })}
    </div>
  );
}
