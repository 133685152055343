import React from "react";
import Divider from "@material-ui/core/Divider";

export default function VideoReplay() {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", marginTop: 17 }}>
        <video
          src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
          width="700px"
          height="400px"
          controls
        />
      </div>
    </>
  );
}
