import React from "react";
import "./Header.scss";

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { AppBar } from "@material-ui/core/";

import { withRouter } from "react-router-dom";

import Toolbar from "./toolbar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
  })
);
const Header = (props: any) => {
  const classes = useStyles();
  return (
    <div className={classes.grow}>
      <AppBar position="fixed" elevation={0} >
        <Toolbar {...props} />
      </AppBar>
    </div>
  );
};

export default withRouter(Header);
