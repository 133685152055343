import { IUserAuthentication } from "../../../contexts/AuthContext";
/////////////
export function checkPagePermission(roles: Array<string>,pageName: string): boolean {
  return (Array.isArray(roles) && roles.some((x: any) => PAGE_PERMISSION[pageName].includes(x)));
}
const PAGE_PERMISSION: any = {
  ADDCLASS: ["SUPER_ADMIN", "ADMIN", "INSTRUCTOR"],
  CLASSROOM: ["SUPER_ADMIN", "ADMIN", "INSTRUCTOR"],
  CLASSROOMSTUDENT: ["STUDENT"],
  GROUP: ["SUPER_ADMIN", "ADMIN", "INSTRUCTOR"],
  ADDUSER: ["SUPER_ADMIN", "ADMIN"],
  ADMIN: ["SUPER_ADMIN", "ADMIN"],
  USER: ["INSTRUCTOR"],
  USERAPPROVED: ["SUPER_ADMIN", "ADMIN", "REGISTRATION_APPROVER"],
  SCENARIO: ["SUPER_ADMIN", "ADMIN", "INSTRUCTOR"],
  FAQ: ["SUPER_ADMIN", "ADMIN", "INSTRUCTOR", "STUDENT"],
  HANDLESCENARIO: ["SUPER_ADMIN", "ADMIN", "MODIFY_SCENARIOS"],
  HANDLEPATIENT: ["SUPER_ADMIN", "ADMIN", "MODIFY_PATIENT"]

};

export function getSubgroupUser(roles: Array<string>) {
  if (checkPagePermission(roles, "USERAPPROVED")) {
    return [
      {
        title: "Account Management",
        id: "userManagement",
        path: "/user-management",
        height: 32,
      },
      {
        title: "Registration Management",
        id: "approvedManagement",
        path: "/approved-management",
        height: 32,
      },
      {
        title: "Group Management",
        id: "groupManagement",
        path: "/group-management",
        height: 32,
      },
    ];
  }
  else if (checkPagePermission(roles, "USER")) {
    return [
      {
        title: "Account Management",
        id: "userManagement",
        path: "/user-management",
        height: 32,
      },
      {
        title: "Group Management",
        id: "groupManagement",
        path: "/group-management",
        height: 32,
      },
    ];
  } 
}
