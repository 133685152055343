import React, { FC, ReactNode, useState } from "react";
import { Box, DialogContent, Typography, Zoom } from "@material-ui/core";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { ButtonContained, ButtonText } from "../Buttons";

interface IAlertDialog extends DialogProps {
  open: boolean;
  handleClose: () => void;
  title?: any;
  confirmPopup: any;
  confirmNo: () => void;
  confirmYes: () => void;
}
const RecheckDialog: FC<IAlertDialog> = ({
  open,
  handleClose,
  maxWidth,
  fullWidth,
  children,
  scroll,
  confirmPopup,
  confirmNo,
  confirmYes,
  ...rest
}) => {
  return (
    <div>
      <Dialog
        open={open}
        scroll={"paper"}
        onClose={handleClose}
        maxWidth={"sm"}
        fullWidth
        PaperProps={{
          style: {borderRadius:16}
        }}
        {...rest}
      >
        <DialogContent>
          <Box textAlign="center" padding="40px 0px 20px 0px">
            <Typography
              style={{
                color: "#5A5A5B",
                fontWeight: 700,
                fontSize: "24px",
              }}
            >
              {confirmPopup?.title}
            </Typography>
            <Typography
              style={{
                color: "#9C9B9D",
                fontWeight: 400,
                fontSize: "14px",
              }}
            >
              {confirmPopup?.subTitle}
            </Typography>
            <Box marginTop="33px">
              <ButtonText colorText={confirmPopup?.color} onClick={confirmNo}>
                {confirmPopup?.no}
              </ButtonText>
              <ButtonContained
                colorText={confirmPopup?.color}
                onClick={confirmYes}
              >
                {confirmPopup?.yes}
              </ButtonContained>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default RecheckDialog;
