import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Zoom, useScrollTrigger, Fab } from "@material-ui/core/";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Header from "../Header";

interface IProps {
  render?: any;
  leftDrawer?: () => void;
  pageAt?: string;
  group?: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    children: {
      marginTop: "100px",
    },
    drawer: {
      [theme.breakpoints.up("lg")]: {
        marginLeft: 290,
        flexShrink: 0,
      },
    },
    backTop: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  })
);
function ScrollTop(props: any) {
  const { children, window } = props;
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector("#back-to-top-anchor");

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  return (
    <Zoom in={trigger}>
      <div
        onClick={handleClick}
        role="presentation"
        className={classes.backTop}
      >
        {children}
      </div>
    </Zoom>
  );
}
const Layout = (props: IProps) => {
  const { leftDrawer, render, pageAt, group } = props;

  const classes = useStyles();

  return (
    <div className={classes.drawer}>
      <Header pageAt={pageAt} leftDrawer={leftDrawer} group={group} />
      <div className={classes.children}>{render()}</div>
      {/* <ScrollTop {...props}>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop> */}
    </div>
  );
};

export default Layout;
