import React, { useEffect, useState } from "react";
import Layout from "../../../commons/Layout";
import { v4 as uuid } from "uuid";

import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
} from "@material-ui/data-grid";
import {
  InputAdornment,
  Paper,
  TextField,
  MenuItem,
  Avatar,
  Chip,
  IconButton,
  Breadcrumbs,
  Link,
  Typography,
  Button,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";
import styled from "styled-components";
import moment from "moment";

import EditIcon from "@material-ui/icons/Edit";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  IRoles,
  IUserData,
} from "../../../../stores/userManagement/userManagement";
import {
  listUserData,
  createUser,
  deleteUser,
} from "../../../../stores/userManagement/userManagement.slice";
import { validateYupSchema } from "formik";
import { withRouter, RouteComponentProps } from "react-router-dom";

interface IProps extends RouteComponentProps {}
const StyledTextField = styled(TextField)`
  fieldset {
    border-radius: 25px;
  }
  margin-bottom: 15px;
`;

const memberRule = [
  {
    value: "All",
    label: "All",
  },
  {
    value: "Admin",
    label: "Admin",
  },
  {
    value: "User",
    label: "User",
  },
];
const memberStatus = [
  {
    value: "All",
    label: "All",
  },
  {
    value: "Active",
    label: "Active",
  },
  {
    value: "Deactive",
    label: "Deactive",
  },
];

const Index = (props: IProps) => {
  const { t } = useTranslation();

  const [searchUser, setSearchUser] = useState("");
  const [searchRule, setSearchRule] = useState("All");
  const [searchStatus, setSearchStatus] = useState("All");
  const dispatch = useDispatch<any>();
  const reduxUserData = useSelector(listUserData);
  const [userData, setUserData] = useState<IUserData[]>([]);

  useEffect(() => {
    if (reduxUserData.length > 0) {
      setUserData(reduxUserData);
    }
  }, [reduxUserData]);

  const columns: GridColDef[] = [
    { field: "id", headerName: t("fieldData.id"), width: 90 },
    {
      field: "userName",
      headerName: t("fieldData.fullName"),
      description: "This column has a value getter and is not sortable.",
      sortable: true,
      width: 300,
      renderCell: (params: GridValueGetterParams) => (
        <div style={{ display: "flex" }}>
          <Avatar
            variant="square"
            style={{
              width: 50,
              height: 50,
              marginRight: 15,
              borderRadius: 10,
              alignSelf: "center",
            }}
            src="https://material-kit-pro-react.devias.io/static/mock-images/avatars/avatar-jane_rotanson.png"
          />
          <div>{`${params.row.data.userName} `}</div>
        </div>
      ),
    },
    {
      field: "fullName",
      headerName: t("fieldData.fullName"),
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 300,
      renderCell: (params: GridValueGetterParams) => (
        <div>{`${params.row.data.fullName}`}</div>
      ),
    },

    {
      field: "roles",
      headerName: t("fieldData.role"),
      description: t("fieldData.role"),
      sortable: false,
      width: 250,
      renderCell: (params: GridValueGetterParams) => (
        <div>
          {params.row.data.roles.map((data: IRoles, index: number) => {
            return (
              <Chip
                style={{ marginRight: 10 }}
                variant={"outlined"}
                color={data.code === "Admin" ? "primary" : "secondary"}
                label={data.display}
              />
            );
          })}
        </div>
      ),
    },
    {
      field: "active",
      headerName: t("fieldData.status"),
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 125,
      renderCell: (params: GridValueGetterParams) => (
        <div>
          {params.row.data.active ? (
            <Chip color="primary" label="Active" />
          ) : (
            <Chip color="secondary" label="Deactive" />
          )}
        </div>
      ),
    },
    {
      field: "Create at",
      headerName: t("fieldData.createAt"),
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 180,
      renderCell: (params: GridValueGetterParams) => (
        <div>{`${moment(params.row.data.createAt).format(
          "YYYY-MM-DD,HH:mm A"
        )}`}</div>
      ),
    },
    {
      field: "Last update",
      headerName: t("fieldData.updateAt"),
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 180,
      renderCell: (params: GridValueGetterParams) => (
        <div>{`${moment(params.row.data.updateAt).format(
          "YYYY-MM-DD,HH:mm A"
        )}`}</div>
      ),
    },
    {
      field: "action",
      headerName: t("userManagement.action"),
      width: 150,
      renderCell: (params: GridValueGetterParams) => (
        <div>
          <IconButton
            onClick={() => {
              props.history.push({
                pathname: `/management/user/${params.row.id}`,
              });
            }}
            color="inherit"
          >
            <EditIcon />
          </IconButton>

          <IconButton
            onClick={() => dispatch(deleteUser(userData[params.rowIndex]))}
            color="inherit"
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];
  return (
    <Layout
      pageAt="userManagementTable"
      render={(props: any) => (
        <div style={{ padding: 40 }}>
          <div style={{ marginBottom: 20 }}>
            <Typography
              variant="h4"
              color="textPrimary"
              style={{ marginBottom: 10 }}
            >
              {t("userManagement.list")}
            </Typography>
          </div>

          <Paper style={{ padding: 24 }}>
            {/* <div style={{ marginBottom: 20 }}>
              <StyledTextField
                label={t("fieldData.userName")}
                value={searchUser}
                onChange={(e) => setSearchUser(e.target.value)}
                variant="outlined"
                style={{
                  width: "100%",
                  maxWidth: 450,
                  borderRadius: 15,
                  marginRight: 20,
                }}
                placeholder={t("userManagement.searchByUsername")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <StyledTextField
                value={searchRule}
                onChange={(e) => {
                  setSearchRule(e.target.value);
                }}
                style={{
                  width: "100%",
                  maxWidth: 200,
                  borderRadius: 15,
                  marginRight: 20,
                }}
                variant="outlined"
                id="standard-select-currency"
                select
                label={t("fieldData.role")}
              >
                {memberRule.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </StyledTextField>
              <StyledTextField
                value={searchStatus}
                onChange={(e) => {
                  setSearchStatus(e.target.value);
                }}
                style={{ width: "100%", maxWidth: 200, borderRadius: 15 }}
                variant="outlined"
                id="standard-select-currency"
                select
                label={t("fieldData.status")}
              >
                {memberStatus.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </StyledTextField>
            </div> */}
            <div style={{ marginBottom: 20 }}>
              <Button
                onClick={() => {
                  dispatch(
                    createUser({
                      _id: uuid(),
                      id: userData[userData.length - 1].id + 1,
                      data: {
                        userName: "JohnAddByButton",
                        cid: "111333",
                        fullName: "JohnAddByButton",
                        roles: [{ code: "user", display: "User" }],
                        active: true,
                        createAt: new Date(),
                        updateAt: new Date(),
                      },
                    })
                  );
                }}
                color="primary"
                variant="outlined"
              >
                Add New Data
              </Button>
            </div>

            <div style={{ height: 600, width: "100%" }}>
              <DataGrid
                key={"_id"}
                rowHeight={80}
                pagination
                rowsPerPageOptions={[5, 10, 20]}
                rows={userData}
                columns={columns}
                pageSize={5}
              />
            </div>
          </Paper>
        </div>
      )}
    />
  );
};

export default withRouter(Index);
