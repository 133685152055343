import { Box, FormControl, FormHelperText, makeStyles, SelectProps, Tooltip, TooltipProps } from "@material-ui/core";

import { CustomSelectField, SelectLabel } from "./style";
import { ReactComponent as Warning } from "../../../images/icons/warning.svg";
interface IselectCustomProp {
  textAlert?: any;
}
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: "#F57373",
  },
  tooltip: {
    backgroundColor: "#F57373",
    fontSize:12,
    padding:10,
  },
}));
function BootstrapTooltip(props: TooltipProps) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}
const SelectFieldComponent = ({
  fullWidth = true,
  label,
  error,
  children,
  textAlert,
  ...rest
}: SelectProps & IselectCustomProp) => {
  return (
    <FormControl fullWidth={fullWidth} error={error}>
      {/* <SelectLabel shrink>{label}</SelectLabel> */}
      <Box display="flex" alignItems="center">
        <CustomSelectField {...rest}>{children}</CustomSelectField>
        {error && (
          <BootstrapTooltip title={textAlert} arrow placement="bottom-end">
            {<Warning style={{  marginLeft: "5px",marginRight:"5px", width: 20, height: 20 }} />}
          </BootstrapTooltip>
        )}
      </Box>
    </FormControl>
  );
};

export default SelectFieldComponent;
