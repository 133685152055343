import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import MaterialTable from "material-table";
import { useStyles } from "./style";
import {
  ButtonContained,
  ButtonContainedAction,
} from "../../../commons/Buttons";
import { Avatar, IconButton, Menu, MenuItem, useMediaQuery } from "@material-ui/core";
import { ISrot, IUsers } from ".";
import SortData from "./SortData";
interface IProps {
  users: IUsers[];
  changeState: any;
  convertStringToImg: any;
  loading: boolean;
  setUsers: any;
  sortValue: ISrot;
  setSortValue: React.Dispatch<React.SetStateAction<ISrot>>;
}
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.guess();
const Table = ({
  users,
  changeState,
  convertStringToImg,
  loading,
  setUsers,
  setSortValue,
  sortValue,
}: IProps) => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-height:950px)");
  const headerStyle1: any = {
    textAlign: "center",
    color: "#5A5A5B",
    backgroundColor: "#FFFFFF",
    fontSize: 16,
    fontWeight: 700,
  };
  const headerStyle: any = {
    color: "#5A5A5B",
    fontSize: 16,
    fontWeight: 700,
  };
  return (
    <div className={classes.rootTable}>
      <MaterialTable
        isLoading={loading}
        columns={[
          {
            title: "Name",
            field: "name",
            cellStyle: {
              backgroundColor: "#F6FAFF",
              borderRadius: "16px 0px 0px 16px",
              color: "#5A5A5B",
              width: "20%",
            },
            headerStyle: headerStyle,
            render: (rowData: any) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Avatar
                  style={{ width: 50, height: 50, marginRight: 20 }}
                  src={convertStringToImg(rowData)}
                />
                <div style={{ fontSize: 14, fontWeight: 700 }}>
                  {rowData?.firstName + " " + rowData?.lastName}
                </div>
              </div>
            ),
          },
          {
            title: (
              <div>
                <div>Role</div>
                <SortData
                  setSortValue={setSortValue}
                  sortValue={sortValue}
                  list={[
                    { title: "All", value: "" },
                    { title: "Instructor", value: "INSTRUCTOR" },
                    { title: "Student", value: "STUDENT" },
                  ]}
                  type={"filterByRole"}
                />
              </div>
            ),
            field: "role",
            cellStyle: {
              backgroundColor: "#F6FAFF",
              color: "#5A5A5B",
              fontSize: 12,
              width: "10%",
              textAlign: "center",
            },
            headerStyle: headerStyle1,
            render: (rowData: IUsers) => (
              <div style={{ fontSize: 14, fontWeight: 400 }}>
                {rowData?.userRoles[0]
                  .toLowerCase()
                  .split(" ")
                  .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
                  .join(" ")}
              </div>
            ),
          },
          {
            title: (
              <div>
                <div>Registration Date</div>
                <SortData
                  setSortValue={setSortValue}
                  sortValue={sortValue}
                  list={[
                    { title: "Ascending", value: "asc" },
                    { title: "Descending", value: "desc" },
                  ]}
                  type={"sortByDate"}
                />
              </div>
            ),
            field: "registrationDate",
            cellStyle: {
              backgroundColor: "#F6FAFF",
              color: "#5A5A5B",
              fontSize: 12,
              width: "15%",
              textAlign: "center",
            },
            headerStyle: headerStyle1,
            render: (rowData: IUsers) => (
              <div>{dayjs(rowData?.createdAt).format("DD/MM/YYYY")}</div>
            ),
          },
          {
            title: "Action",
            field: "action",
            cellStyle: {
              backgroundColor: "#F6FAFF",
              color: "#5A5A5B",
              borderRadius: "0px 16px 16px 0px",
              fontSize: 12,
              width: "40%",
            },
            headerStyle: headerStyle,
            render: (rowData: IUsers) => (
              <div style={{ display: "flex", flexDirection: "row" }}>
                {rowData.action.map((item: any, index: number) => (
                  <ButtonContainedAction
                    key={index}
                    colorText={item.color}
                    onClick={() => changeState(item.code, rowData)}
                    style={{
                      fontWeight: 400,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item.display}
                  </ButtonContainedAction>
                ))}
              </div>
            ),
          },
        ]}
        data={users}
        style={{
          boxShadow: "none",
          border: "none",
        }}
        options={{
          selection: false,
          grouping: false,
          search: false,
          sorting: false,
          paging: false,
          showTitle: false,
          toolbar: false,
          headerStyle: {
            position: "sticky",
            top: "0"
          },
          maxBodyHeight: matches ? "900px" : '600px',
          overflowY:'initial'
        }}
      />
    </div>
  );
};
export default Table;
