import { v1 as uuid } from 'uuid'
import { ITests } from './type'

export const testsInitialState: ITests[] = [
	{
		id: uuid(),
		desc: 'Learn Redux-ToolKit',
		isComplete: false
	}
]
