import GirlAvatar from "../asset/girl.png";
import BoyAvatar from "../asset/boy.png";
import GroupAvatar from "../asset/group.png";
interface IdataImg {
  img: IdetailImg;
  gender: string;
}
interface IdetailImg {
  contentType: string;
  data: string;
}
interface IPermissionTag {
  uri: string;
  method: string;
  headers: any;
}
export function convertStringToImg(data: any): string {
 if (data?.imgBackendURL) {
    return data.imgBackendURL;
  } else if (data?.groupName) {
    return GroupAvatar;
  } else {
    if (data.gender === "F") {
      return GirlAvatar;
    } else {
      return BoyAvatar;
    }
  }
}
