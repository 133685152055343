import React, { useEffect, useState } from "react";
import Single from "../../commons/asset/single.png";
import Multiple from "../../commons/asset/multiple.png";
import Simulation from "../../commons/asset/simulate.png";
import { IUser } from "./Dashboard";
import OurUsers from "./ourElement/ourUsers";
import { fetchClassroomMeApi, IClassroom } from "../../../services/Classrooms/classroom.service";
interface Props {
  user: Array<IUser> | undefined;
}
export default function Overview({ user }: Props) {
  const [classrooms, setClassrooms] = useState<IClassroom[]>();
  function findLengthClassroom(mode: string, classrooms: IClassroom[], player?:string) {
    const classFilterByMode = classrooms.filter((ele) => ele.mode === mode);
    if(mode === 'PRACTICE'){
      if(player === 'single'){
        return classFilterByMode.filter((ele)=> ele.students.length === 1).length
      }
      else { 
        return classFilterByMode.filter((ele)=> ele.students.length !== 1).length
      }
    }
    else {
      return classFilterByMode.length;
    }
  }
  useEffect(() => {
    fetchClassroomMeApi().then((res) => {
      if(res?.data?.result){
        setClassrooms(res?.data.result);
      }
    });
  }, []);
  return (
    <div
      style={{
        height: 365,
        backgroundColor: "rgba(96, 208, 161, 0.15)",
        borderRadius: 16,
      }}
    >
      <div style={{ padding: 10 }}>
        <div
          style={{
            fontSize: 22,
            fontWeight: 700,
            color: "#5A5A5B",
            margin: 10,
          }}
        >
          Overview
        </div>
        <div style={{ display: "flex" }}>
          <div
            style={{
              width: "222px",
              height: "117px",
              backgroundColor: "white",
              borderRadius: 16,
              marginRight: 8,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", padding: "10px 0px 0px 10px" }}>
              <div>
                <img src={Single} alt="" />
              </div>
              <div style={{ marginLeft: 15 }}>
                <div style={{ fontSize: 14, fontWeight: 700 }}>Practice</div>
                <div style={{ fontSize: 11, color: "rgba(90, 90, 90, 1)" }}>
                  Single Player
                </div>
              </div>
            </div>
            <div style={{ fontSize: 50, marginTop: -8, marginLeft: 60 }}>
              {classrooms && findLengthClassroom("PRACTICE", classrooms,'single')}
            </div>
          </div>
          <div
            style={{
              width: "222px",
              height: "117px",
              backgroundColor: "white",
              borderRadius: 16,
              marginRight: 8,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", padding: "10px 0px 0px 10px" }}>
              <div>
                <img src={Multiple} alt="" />
              </div>
              <div style={{ marginLeft: 15 }}>
                <div style={{ fontSize: 14, fontWeight: 700 }}>Practice</div>
                <div style={{ fontSize: 11, color: "rgba(90, 90, 90, 1)" }}>
                  Multiplayer
                </div>
              </div>
            </div>
            <div style={{ fontSize: 50, marginTop: -8, marginLeft: 60 }}>
              {classrooms && findLengthClassroom("PRACTICE", classrooms,'multi')}
            </div>
          </div>
          <div
            style={{
              width: "222px",
              height: "117px",
              backgroundColor: "white",
              borderRadius: 16,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", padding: "10px 0px 0px 10px" }}>
              <div>
                <img src={Simulation} alt="" />
              </div>
              <div style={{ marginLeft: 15 }}>
                <div style={{ fontSize: 14, fontWeight: 700 }}>Simulation</div>
              </div>
            </div>
            <div style={{ fontSize: 50, marginTop: -8, marginLeft: 60 }}>
              {classrooms && findLengthClassroom("SIMULATION", classrooms)}
            </div>
          </div>
        </div>
      </div>
      <div style={{ padding: 10 }}>
        <div
          style={{
            margin: "30px 10px 10px 10px",
            fontSize: 12,
            fontWeight: 500,
          }}
        >
          Our Instructors
        </div>
        <div
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "16px",
            height: "60px",
            marginTop: 10,
            margin: "10px auto",
            width: "98%",
          }}
        >
          <div>
            <OurUsers user={user} />
          </div>
        </div>
      </div>
    </div>
  );
}
