import {
  Avatar,
  Box,
  Button,
  DialogActions,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { IOrganization } from "..";
import { useStyles } from "../style";
import {
  ButtonContained,
  ButtonOutlined,
  ButtonText,
} from "../../../../commons/Buttons";
import { SelectField, TextFieldCustom } from "../../../../commons/Inputs";
import { TextFieldStyle } from "../style";
import { ReactComponent as SelectIcon } from "../asset/editphoto.svg";
import ImgLock from "../asset/resetpw.png";
import { Permission } from "./ManagementUser";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { checkPagePermission } from "../../../../commons/CheckRole";
import { useAuth } from "../../../../../contexts/AuthContext";
import { addRoleModifyPatientApi, addRoleModifyScenarioApproveApi, addRoleRegistrationApproveApi, removeRoleModifyPatientsApi, removeRoleModifyScenariosApi, removeRoleRegistrationApproveApi } from '../../../../../services/Users/users.servies';
import { useSnackbar } from 'notistack';

export const Occupations = ['Medical Student','Nursing Student','Paramedic Student','Doctor','Nurse','First responder','']
interface props {
  changeState: any;
  formik: any;
  organizationAll: IOrganization[];
  convertStringToImg: any;
  generatePasswordOnApi: () => void;
  handleEdit: () => void;
  edit: boolean;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  handleCloseBackdrop: ()=> void;
  handleOpenBackdrop: ()=> void
  setForceUpdateData:any;
}
function Preview({
  changeState,
  formik,
  organizationAll,
  convertStringToImg,
  generatePasswordOnApi,
  handleEdit,
  edit,
  value,
  setValue,
  handleCloseBackdrop,
  handleOpenBackdrop,
  setForceUpdateData
}: props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useAuth();
  const textfieldRef = useRef<any>(null);
  const [addPermission, setAddPermission] = useState<boolean[]>(() => {
    return [formik.values.addPermissionApprove, formik.values.addPermissionModifyPatients, formik.values.addPermissionModifyScenarios];
  });
  function handleChange(event: React.ChangeEvent<{}>, newValue: string) {
    setValue(newValue);
  }
  function handleClickPermission(indexSelected: number) {
    addPermission.forEach(async(item: any, index: number) => {
      if (indexSelected === index) {
        switch (indexSelected) {
          case 0:
            await handleRoleApprove(!addPermission[index])
            break;
          case 1:
            await handleRoleModifyPatients(!addPermission[index])
            break;
          case 2:
            await handleRoleModifyScenarios(!addPermission[index])
            break;
        
          default:
            break;
        }
        addPermission[index] = !addPermission[index];
        setAddPermission([...addPermission]);
        formik.setFieldValue("addPermission" + index, addPermission[index]);
        setForceUpdateData((prev: any) => prev + 1);
      }
    
    });
  }
  const copy = async () => {
    await navigator.clipboard.writeText(formik.values.password);
  };
  function selectText() {
    if (textfieldRef && textfieldRef) {
      textfieldRef.current.focus();
      textfieldRef.current.select();
      copy();
    }
  }
  const handleImage = (name: any) => (event: any) => {
    if (event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        formik.setFieldValue("img", event.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  function handleRoleApprove(permissionApprove: boolean): void {
    handleOpenBackdrop();
    const data: any = { userid: formik.values.userid }
    if (permissionApprove) {
      addRoleRegistrationApproveApi(data)
        .then((res) => {
          if (res.data) {
            handleCloseBackdrop();
            enqueueSnackbar("Added Role Success", {
              variant: "success",
            });
          } else {
            enqueueSnackbar("Added Role Error", {
              variant: "error",
            });
          }
        })
        .catch(() => {
          enqueueSnackbar("Added Role Error", {
            variant: "error",
          });
        });
    } else if (!permissionApprove) {
      removeRoleRegistrationApproveApi(data)
        .then((res) => {
          if (res.data) {
            handleCloseBackdrop();
            enqueueSnackbar("Removed Role Success", {
              variant: "success",
            });
          } else {
            enqueueSnackbar("Removed Role Error", {
              variant: "error",
            });
          }
        })
        .catch(() => {
          enqueueSnackbar("Removed Role Error", {
            variant: "error",
          });
        });
    }
    handleCloseBackdrop();
  }
  function handleRoleModifyPatients(permissionApprove: boolean): void {
    handleOpenBackdrop();
    const data: any = { userid: formik.values.userid };
    if (permissionApprove) {
      addRoleModifyPatientApi(data)
        .then((res) => {
          if (res.data) {
            handleCloseBackdrop();
            enqueueSnackbar("Added Role Success", {
              variant: "success",
            });
            handleCloseBackdrop();
          } else {
            enqueueSnackbar("Added Role Error", {
              variant: "error",
            });
            handleCloseBackdrop();
          }
        })
        .catch(() => {
          enqueueSnackbar("Added Role Error", {
            variant: "error",
          });
        });
    } else if (!permissionApprove) {
      removeRoleModifyPatientsApi(data)
        .then((res) => {
          if (res.data) {
            enqueueSnackbar("Removed Role Success", {
              variant: "success",
            });
            handleCloseBackdrop();
          } else {
            enqueueSnackbar("Removed Role Error", {
              variant: "error",
            });
            handleCloseBackdrop();
          }
        })
        .catch(() => {
          enqueueSnackbar("Removed Role Error", {
            variant: "error",
          });
          handleCloseBackdrop();
        });
    }
    handleCloseBackdrop();
  }
  function handleRoleModifyScenarios(permissionApprove: boolean): void {
    handleOpenBackdrop();
    const data: any = { userid: formik.values.userid }
    if (permissionApprove) {
      addRoleModifyScenarioApproveApi(data)
        .then((res) => {
          if (res.data) {
            enqueueSnackbar("Added Role Success", {
              variant: "success",
            });
            handleCloseBackdrop();
          } else {
            enqueueSnackbar("Added Role Error", {
              variant: "error",
            });
            handleCloseBackdrop();
          }
        })
        .catch(() => {
          enqueueSnackbar("Added Role Error", {
            variant: "error",
          });
          handleCloseBackdrop();
        });
    } else if (!permissionApprove) {
      removeRoleModifyScenariosApi(data)
        .then((res) => {
          if (res.data) {
            enqueueSnackbar("Removed Role Success", {
              variant: "success",
            });
            handleCloseBackdrop();
          } else {
            enqueueSnackbar("Removed Role Error", {
              variant: "error",
            });
            handleCloseBackdrop();
          }
        })
        .catch(() => {
          enqueueSnackbar("Removed Role Error", {
            variant: "error",
          });
          handleCloseBackdrop();
        });
    }
  }

  const EditAccountRender = () => {
    return (
      <>
        <Grid container>
          <Grid
            item={true}
            xs={12}
            style={{ margin: edit ? "20px 0px" : "0px 0px 20px 0px" }}
          >
            <div style={{ fontSize: 32, fontWeight: 700, color: "#5A5A5A" }}>
              Edit Account
            </div>
          </Grid>
          <Grid
            item={true}
            xs={12}
            sm={12}
            md={4}
            lg={4}
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <>
              <Box display="flex" marginLeft="40px">
                <Box
                  style={{
                    display: "flex",
                    // flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ position: "relative" }}>
                    <Avatar
                      style={{ width: 130, height: 130 }}
                      src={formik.values.img ? formik.values.img : convertStringToImg(formik.values)}
                    />

                    {edit && (
                      <div className={classes.rootInput}>
                        <input
                          accept="image/*"
                          className={classes.input}
                          id="icon-button-file"
                          type="file"
                          onChange={handleImage("file")}
                        />
                        <label htmlFor="icon-button-file">
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                            style={{
                              position: "absolute",
                              bottom: 10,
                              right: 0,
                            }}
                          >
                            <SelectIcon fontSize="small" />
                          </IconButton>
                        </label>
                      </div>
                    )}
                  </div>
                </Box>
              </Box>
              {/* <Box display="flex" flexDirection="column">
                {!edit && (
                  <>
                    <ButtonContained colorText="blue" onClick={handleEdit}>
                      Edit
                    </ButtonContained>
                    <ButtonOutlined
                      style={{ marginTop: 8, fontSize: 12 }}
                      colorText="red"
                      onClick={() => changeState("DELETE", null)}
                    >
                      Delete Account
                    </ButtonOutlined>
                  </>
                )}
              </Box> */}
            </>
          </Grid>
          <Grid item={true} xs={12} sm={12} md={8} lg={8}>
            <Grid
              container
              spacing={2}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Grid item={true} xs={3}>
                <Typography
                  style={{
                    color: "#5A5A5A",
                    fontWeight: 500,
                    fontSize: 14,
                  }}
                >
                  Role
                </Typography>
              </Grid>
              <Grid item={true} xs={9}>
                <Typography
                  style={{
                    color: "#5A5A5A",
                    fontWeight: 300,
                    fontSize: 14,
                  }}
                >
                  <SelectField
                    disabled
                    style={{ color: "rgba(156, 155, 157, 1)" }}
                    name="role"
                    value={formik.values?.role[0]}
                    onClick={formik.handleChange}
                  >
                    <MenuItem value={"INSTRUCTOR"}>Instructor</MenuItem>
                    <MenuItem value={"STUDENT"}>Student</MenuItem>
                  </SelectField>
                </Typography>
              </Grid>
              <Grid item={true} xs={3}>
                <Typography
                  style={{
                    color: "#5A5A5A",
                    fontWeight: 500,
                    fontSize: 14,
                  }}
                >
                  Name
                </Typography>
              </Grid>
              <Grid item={true} xs={9}>
                <Grid container spacing={2}>
                  <Grid item={true} xs={6}>
                    <Typography
                      style={{
                        color: "#5A5A5A",
                        fontWeight: 300,
                        fontSize: 14,
                      }}
                    >
                      <TextFieldCustom
                        size="small"
                        name="firstName"
                        variant="outlined"
                        onChange={formik.handleChange}
                        value={formik.values?.firstName}
                      />
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={6}>
                    <Typography
                      style={{
                        color: "#5A5A5A",
                        fontWeight: 300,
                        fontSize: 14,
                      }}
                    >
                      <TextFieldCustom
                        size="small"
                        name="lastName"
                        variant="outlined"
                        onChange={formik.handleChange}
                        value={formik.values?.lastName}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item={true} xs={3}>
                <Typography
                  style={{
                    color: "#5A5A5A",
                    fontWeight: 500,
                    fontSize: 14,
                  }}
                >
                  Gender
                </Typography>
              </Grid>
              <Grid item={true} xs={9}>
                <Typography
                  style={{
                    color: "#5A5A5A",
                    fontWeight: 300,
                    fontSize: 14,
                  }}
                ></Typography>
                <SelectField
                  name="gender"
                  onChange={formik.handleChange}
                  value={formik.values?.gender}
                >
                  <MenuItem value={"M"}>Male</MenuItem>
                  <MenuItem value={"F"}>Female</MenuItem>
                </SelectField>
              </Grid>
              <Grid item={true} xs={3}>
                <Typography
                  style={{
                    color: "#5A5A5A",
                    fontWeight: 500,
                    fontSize: 14,
                  }}
                >
                  Organization
                </Typography>
              </Grid>
              <Grid item={true} xs={9}>
                <Typography
                  style={{
                    color: "#5A5A5A",
                    fontWeight: 300,
                    fontSize: 14,
                  }}
                ></Typography>
                <SelectField
                  name="organization"
                  style={{ color: "rgba(156, 155, 157, 1)" }}
                  onChange={formik.handleChange}
                  disabled
                  value={
                    formik.values?.organization._id
                      ? formik.values?.organization._id
                      : formik.values?.organization
                  }
                >
                  {organizationAll.map((item: any, index: number) => (
                    <MenuItem key={index} value={item._id}>
                      {item.orgName}
                    </MenuItem>
                  ))}
                </SelectField>
              </Grid>
              <Grid item={true} xs={3}>
                <Typography
                  style={{
                    color: "#5A5A5A",
                    fontWeight: 500,
                    fontSize: 14,
                  }}
                >
                  Occupation
                </Typography>
              </Grid>
              <Grid item={true} xs={9}>
                <Typography
                  style={{
                    color: "#5A5A5A",
                    fontWeight: 300,
                    fontSize: 14,
                  }}
                ></Typography>
                <SelectField
                  name="occupation"
                  onChange={formik.handleChange}
                  value={formik.values.occupation}
                >
                  <MenuItem value={"Medical Student"}>Medical Student</MenuItem>
                  <MenuItem value={"Nursing Student"}>Nursing Student</MenuItem>
                  <MenuItem value={"Paramedic Student"}>Paramedic Student</MenuItem>
                  <MenuItem value={"Doctor"}>Doctor</MenuItem>
                  <MenuItem value={"Nurse"}>Nurse</MenuItem>
                  <MenuItem value={"First responder"}>First responder</MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                </SelectField>
              </Grid>
              {!Occupations.includes(formik.values.occupation) && (
                <>
                  <Grid item={true} xs={3}></Grid>
                  <Grid item={true} xs={9}>
                    <TextFieldCustom
                      name="occupationTmp"
                      size="small"
                      variant="outlined"
                      onChange={formik.handleChange}
                      value={formik.values.occupationTmp}
                      error={formik.touched.occupationTmp && formik.errors.occupationTmp ? true : false}
                      textAlert={
                        formik.errors.occupationTmp && formik.touched.occupationTmp && formik.errors.occupationTmp
                      }
                    />
                  </Grid>
                </>
              )}
              <Grid item={true} xs={3}>
                <Typography
                  style={{
                    color: "#5A5A5A",
                    fontWeight: 500,
                    fontSize: 14,
                  }}
                >
                  Email
                </Typography>
              </Grid>
              <Grid item={true} xs={9}>
                <Typography
                  style={{
                    color: "#5A5A5A",
                    fontWeight: 300,
                    fontSize: 14,
                  }}
                ></Typography>
                <TextFieldCustom
                  size="small"
                  name="email"
                  variant="outlined"
                  disabled={true}
                  onChange={formik.handleChange}
                  value={formik.values?.email}
                />
              </Grid>
              <Grid item={true} xs={3}>
                <Typography
                  style={{
                    color: "#5A5A5A",
                    fontWeight: 500,
                    fontSize: 14,
                  }}
                >
                  BirthDay
                </Typography>
              </Grid>
              <Grid item={true} xs={9}>
                <Typography
                  style={{
                    color: "#5A5A5A",
                    fontWeight: 300,
                    fontSize: 14,
                  }}
                >
                  <TextFieldCustom
                    size="small"
                    name="birthDate"
                    variant="outlined"
                    type="date"
                    onChange={formik.handleChange}
                    value={formik.values?.birthDate}
                  />
                </Typography>
              </Grid>
              <Grid item={true} xs={3}>
                <Typography
                  style={{
                    color: "#5A5A5A",
                    fontWeight: 500,
                    fontSize: 14,
                  }}
                >
                  Telephone
                </Typography>
              </Grid>
              <Grid item={true} xs={9}>
                {/* Sutdent */}
                <TextFieldCustom
                size="small"
                name="tel"
                variant="outlined"
                placeholder='Telephone'
                InputProps={{ classes: { input: classes.inputText } }}
                onChange={formik.handleChange}
                value={formik.values.tel}
                error={formik.touched.tel && formik.errors.tel ? true : false}
                textAlert={
                  formik.errors.tel && formik.touched.tel && formik.errors.tel
                }
              />
              </Grid>
              {/* <Grid item={true} xs={12}>
                {edit && (
                  <Box display="flex" justifyContent="flex-end" marginTop="8px">
                    <ButtonText colorText="blue" onClick={handleEdit}>
                      Cancel
                    </ButtonText>
                    <ButtonContained
                      colorText="blue"
                      onClick={handleOpenRecheck}
                    >
                      Save
                    </ButtonContained>
                  </Box>
                )}
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };
  const EditPermissionRender = () => {
    return (
      <Grid container spacing={2}>
        <Grid
          item={true}
          xs={12}
          style={{ margin: edit ? "20px 0px" : "0px 0px 20px 0px" }}
        >
          <div style={{ fontSize: 32, fontWeight: 700, color: "#5A5A5A" }}>
            Edit Permission
          </div>
        </Grid>
        {Permission.map((item: any, index: number) => {
          return (
            <Grid container style={{ marginTop: 20 }} key={index}>
              <Grid item={true} xs={2}></Grid>
              <Grid item={true} xs={8}>
                <Button
                  fullWidth
                  className={classes.btnSelectPermission}
                  style={{
                    backgroundColor: addPermission[index]
                      ? "#E2FFF3"
                      : "#F5F5F7",
                    height: 73,
                  }}
                  onClick={() => handleClickPermission(index)}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <div style={{ fontSize: 16, fontWeight: 500 }}>
                        {item.title}
                      </div>
                      <div style={{ fontSize: 12, fontWeight: 300 }}>
                        {item.subTitle}
                      </div>
                    </div>
                    <CheckCircleIcon
                      style={{
                        fontSize: 45,
                        color: addPermission[index] ? "#60D0A1" : "#B9B9B9",
                      }}
                    />
                  </div>
                </Button>
              </Grid>
              <Grid item={true} xs={2}></Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  };
  const GeneratePasswordRender = () => {
    return (
      <Grid>
        <Grid
          item={true}
          xs={12}
          style={{ margin: edit ? "20px 0px" : "0px 0px 20px 0px" }}
        >
          <div style={{ fontSize: 32, fontWeight: 700, color: "#5A5A5A" }}>
            New Password
          </div>
        </Grid>
        <Grid item={true} xs={12}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <img src={ImgLock} alt="" />
            <Box marginTop="30px" display="flex">
              <TextFieldStyle
                inputRef={textfieldRef}
                style={{ width: "185px" }}
                size="small"
                variant="outlined"
                value={formik.values.password}
                placeholder={'Generate Password'}
                // disabled
              />
              {formik.values.password ? (
                <Button className={classes.btnSave} onClick={selectText}>
                  Copy
                </Button>
              ) : (
                <Button
                  className={classes.btnGenerateKey}
                  onClick={generatePasswordOnApi}
                >
                  Generate
                </Button>
              )}
            </Box>
            <FormHelperText style={{ color: "#F15F5F", fontSize: 10 }}>
              *Please remember your password, If you close this page, then you
              won't be able to return to view.
            </FormHelperText>
          </Box>
        </Grid>
      </Grid>
    );
  };
  return (
    <>
      <Grid container>
        {edit && (
          <Grid item={true} xs={12}>
            <TabContext value={value}>
              <Tabs
                value={value}
                TabIndicatorProps={{
                  style: { display: "none" },
                }}
                className={classes.indicator}
                textColor="primary"
                onChange={handleChange}
                aria-label="disabled tabs example"
              >
                <Tab
                  value="account"
                  style={{ minHeight: "100%" }}
                  label="Edit Account"
                />
                {formik.values?.role &&
                  formik.values?.role.length > 0 &&
                  formik.values?.role.includes("INSTRUCTOR") && (
                    <Tab
                      value="permission"
                      style={{ minHeight: "100%" }}
                      label="Edit Permission"
                    />
                  )}
                <Tab
                  value="password"
                  style={{ minHeight: "100%" }}
                  label="Generate Password"
                />
              </Tabs>
              <TabPanel value="account">{EditAccountRender()}</TabPanel>
              <TabPanel value="permission">{EditPermissionRender()}</TabPanel>
              <TabPanel value="password">{GeneratePasswordRender()}</TabPanel>
            </TabContext>
          </Grid>
        )}
        {!edit && (
          <>
            <Grid
              item={true}
              xs={12}
              sm={12}
              md={4}
              lg={4}
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <>
                <Box display="flex" justifyContent="center">
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ position: "relative" }}>
                      <Avatar
                        style={{ width: 130, height: 130 }}
                        src={convertStringToImg(formik.values)}
                      />
                      {edit && (
                        <div className={classes.rootInput}>
                          <input
                            accept="image/*"
                            className={classes.input}
                            id="icon-button-file"
                            type="file"
                            onChange={handleImage("file")}
                          />
                          <label htmlFor="icon-button-file">
                            <IconButton
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                              style={{
                                position: "absolute",
                                bottom: 10,
                                right: 0,
                              }}
                            >
                              <SelectIcon fontSize="small" />
                            </IconButton>
                          </label>
                        </div>
                      )}
                    </div>
                  </Box>
                </Box>
              </>
            </Grid>
            <Grid item={true} xs={12} sm={12} md={8} lg={8}>
              <Grid
                container
                spacing={2}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Grid item={true} xs={3}>
                  <Typography
                    style={{
                      color: "#5A5A5A",
                      fontWeight: 500,
                      fontSize: 14,
                    }}
                  >
                    Role
                  </Typography>
                </Grid>
                <Grid item={true} xs={9}>
                  <Typography
                    style={{
                      color: "#5A5A5A",
                      fontWeight: 300,
                      fontSize: 14,
                    }}
                  >
                    {formik.values?.role
                      ? formik.values?.role[0]
                          .toLowerCase()
                          .split(" ")
                          .map(
                            (s: any) =>
                              s.charAt(0).toUpperCase() + s.substring(1)
                          )
                          .join(" ")
                      : ""}
                  </Typography>
                </Grid>
                <Grid item={true} xs={3}>
                  <Typography
                    style={{
                      color: "#5A5A5A",
                      fontWeight: 500,
                      fontSize: 14,
                    }}
                  >
                    Name
                  </Typography>
                </Grid>
                <Grid item={true} xs={9}>
                  <Typography
                    style={{
                      color: "#5A5A5A",
                      fontWeight: 300,
                      fontSize: 14,
                    }}
                  >
                    {formik.values?.firstName + " " + formik.values?.lastName}
                  </Typography>
                </Grid>
                <Grid item={true} xs={3}>
                  <Typography
                    style={{
                      color: "#5A5A5A",
                      fontWeight: 500,
                      fontSize: 14,
                    }}
                  >
                    Gender
                  </Typography>
                </Grid>
                <Grid item={true} xs={9}>
                  <Typography
                    style={{
                      color: "#5A5A5A",
                      fontWeight: 300,
                      fontSize: 14,
                    }}
                  >
                    {formik.values?.gender == "F" ? "Female" : "Male"}
                  </Typography>
                </Grid>
                <Grid item={true} xs={3}>
                  <Typography
                    style={{
                      color: "#5A5A5A",
                      fontWeight: 500,
                      fontSize: 14,
                    }}
                  >
                    Organization
                  </Typography>
                </Grid>
                <Grid item={true} xs={9}>
                  <Typography
                    style={{
                      color: "#5A5A5A",
                      fontWeight: 300,
                      fontSize: 14,
                    }}
                  >
                    {formik.values?.organization?.orgName}
                  </Typography>
                </Grid>
                <Grid item={true} xs={3}>
                  <Typography
                    style={{
                      color: "#5A5A5A",
                      fontWeight: 500,
                      fontSize: 14,
                    }}
                  >
                    Occupation
                  </Typography>
                </Grid>
                <Grid item={true} xs={9}>
                  <Typography
                    style={{
                      color: "#5A5A5A",
                      fontWeight: 300,
                      fontSize: 14,
                    }}
                  >
                    {formik.values?.occupationTmp ? formik.values?.occupationTmp : formik.values?.occupation}
                  </Typography>
                </Grid>
                <Grid item={true} xs={3}>
                  <Typography
                    style={{
                      color: "#5A5A5A",
                      fontWeight: 500,
                      fontSize: 14,
                    }}
                  >
                    Email
                  </Typography>
                </Grid>
                <Grid item={true} xs={9}>
                  <Typography
                    style={{
                      color: "#5A5A5A",
                      fontWeight: 300,
                      fontSize: 14,
                    }}
                  >
                    {formik.values?.email}
                  </Typography>
                </Grid>
                <Grid item={true} xs={3}>
                  <Typography
                    style={{
                      color: "#5A5A5A",
                      fontWeight: 500,
                      fontSize: 14,
                    }}
                  >
                    BirthDay
                  </Typography>
                </Grid>
                <Grid item={true} xs={9}>
                  <Typography
                    style={{
                      color: "#5A5A5A",
                      fontWeight: 300,
                      fontSize: 14,
                    }}
                  >
                    {dayjs(formik.values?.birthDate).format("DD/MM/YYYY")}
                  </Typography>
                </Grid>
                <Grid item={true} xs={3}>
                  <Typography
                    style={{
                      color: "#5A5A5A",
                      fontWeight: 500,
                      fontSize: 14,
                    }}
                  >
                    Telephone
                  </Typography>
                </Grid>
                <Grid item={true} xs={9}>
                  <Typography
                    style={{
                      color: "#5A5A5A",
                      fontWeight: 300,
                      fontSize: 14,
                    }}
                  >
                    {formik.values?.tel}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true} xs={12} sm={12} md={4} lg={4}>
              <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                marginTop="60px"
                // position="absolute"
                // bottom="20px"
              >
                {checkPagePermission(currentUser?.roles, "ADDUSER") && (
                  <>
                    <ButtonContained colorText="blue" onClick={handleEdit}>
                      Edit
                    </ButtonContained>
                    <ButtonOutlined
                      style={{ marginTop: 8, fontSize: 12 }}
                      colorText="red"
                      onClick={() => changeState("DELETE", null)}
                    >
                      Delete Account
                    </ButtonOutlined>
                  </>
                )}
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
export default Preview;
