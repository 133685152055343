import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import MaterialTable from "material-table";
import { useStyles } from "./style";
import { ISrot, IUsers } from "../User";
import { ButtonContainedAction } from "../../../commons/Buttons";
import { IGroup } from ".";
import SortData from "../User/SortData";
import { useMediaQuery } from '@material-ui/core';
import { useEffect } from 'react';
interface IProps {
  groupAll: any[];
  changeState: (arg0: string, arg1: IGroup) => void;
  convertStringToImg?: any;
  loading?: boolean;
  sortValue: ISrot;
  setSortValue: React.Dispatch<React.SetStateAction<ISrot>>;
}
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.guess();
const Table = ({
  groupAll,
  changeState,
  loading,
  sortValue,
  setSortValue,
}: IProps) => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-height:950px)");
  const headerStyle = {
    color: "#5A5A5B",
    backgroundColor: "#FFFFFF",
    fontSize: 16,
    fontWeight: 700,
  };
  const headerStyle1: any = {
    textAlign: "center",
    color: "#5A5A5B",
    backgroundColor: "#FFFFFF",
    fontSize: 16,
    fontWeight: 700,
  };
  
  return (
    <div className={classes.rootTable}>
      <MaterialTable
        isLoading={loading}
        columns={[
          {
            title: "Group",
            field: "group",
            cellStyle: {
              backgroundColor: "#F6FAFF",
              borderRadius: "16px 0px 0px 16px",
              color: "#5A5A5B",
              width: "15%",
            },
            headerStyle: headerStyle,
            render: (rowData: any) => (
              <div style={{ fontSize: 14, fontWeight: 700, color: "#5A5A5B" }}>
                {rowData.groupName}
              </div>
            ),
          },
          {
            title: (
              <div>
                <div>Role</div>
                <SortData
                  setSortValue={setSortValue}
                  sortValue={sortValue}
                  list={[
                    { title: "All", value: "" },
                    { title: "Instructor", value: "Instructor" },
                    { title: "Student", value: "Student" },
                  ]}
                  type={"filterByRole"}
                />
              </div>
            ),
            field: "role",
            cellStyle: {
              backgroundColor: "#F6FAFF",
              color: "#5A5A5B",
              fontSize: 12,
              width: "10%",
              textAlign: "center",
            },
            headerStyle: headerStyle1,
            render: (rowData: any) => (
              <div style={{ fontSize: 14, fontWeight: 400, color: "#5A5A5B" }}>
                {rowData.groupRoles[0]}
              </div>
            ),
          },
          {
            title: "Member",
            field: "member",
            cellStyle: {
              backgroundColor: "#F6FAFF",
              color: "#5A5A5B",
              fontSize: 12,
              width: "10%",
              textAlign: "center",
            },
            headerStyle: headerStyle1,
            render: (rowData: any) => (
              <div style={{ fontSize: 12, fontWeight: 400, color: "#5A5A5B" }}>
                {rowData.members.length}
              </div>
            ),
          },
          {
            title: (
              <div>
                <div>Create Date</div>
                <SortData
                  setSortValue={setSortValue}
                  sortValue={sortValue}
                  list={[
                    { title: "Ascending", value: "asc" },
                    { title: "Descending", value: "desc" },
                  ]}
                  type={"sortByDate"}
                />
              </div>
            ),
            field: "createDete",
            cellStyle: {
              backgroundColor: "#F6FAFF",
              color: "#5A5A5B",
              borderRadius: "0px 16px 16px 0px",
              fontSize: 12,
              width: "15%",
              textAlign: "center",
            },
            headerStyle: headerStyle1,
            render: (rowData: any) => (
              <div style={{ fontSize: 12, fontWeight: 400, color: "#5A5A5B" }}>
                {dayjs(rowData.createdAt).format("DD/MM/YYYY")}
              </div>
            ),
          },
          {
            title: "Action",
            field: "action",
            cellStyle: {
              backgroundColor: "#F6FAFF",
              color: "#5A5A5B",
              borderRadius: "0px 16px 16px 0px",
              fontSize: 12,
              width: "40%",
            },
            headerStyle: headerStyle,
            render: (rowData: any) => (
              <div style={{ display: "flex", flexDirection: "row" }}>
                {rowData.action.map((item: any, index: number) => (
                  <ButtonContainedAction
                    key={index}
                    colorText={item.color}
                    onClick={() => changeState(item.code, rowData)}
                    style={{ fontWeight: 400,minWidth: 175 }}
                  >
                    {item.display}
                  </ButtonContainedAction>
                ))}
              </div>
            ),
          },
        ]}
        data={groupAll}
        style={{
          boxShadow: "none",
          border: "none",
        }}
        options={{
          selection: false,
          grouping: false,
          search: false,
          sorting: false,
          paging: false,
          showTitle: false,
          toolbar: false,
          headerStyle: { position: "sticky", top: 0 },
          maxBodyHeight: matches ? "900px" : '600px',
          overflowY:'initial'
        }}
      />
    </div>
  );
};
export default Table;
