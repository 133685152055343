import {
  Avatar,
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import dayjs from "dayjs";
import _ from "lodash";
import { ButtonContained } from "../../../../commons/Buttons";
import { convertStringToImg } from "../../../../commons/ConvertImg";
import { ReactComponent as CertificateIcon } from "../../../../commons/asset/Certificate.svg";
import { ReactComponent as ExportClassResultIcon } from "../../../../commons/asset/ExportClassResult.svg";
import { ReactComponent as PatientsDetailIcon } from "../../../../commons/asset/PatientsDetail.svg";
import { ReactComponent as VideoReplayIcon } from "../../../../commons/asset/VideoReplay.svg";
import { ReactComponent as DurationIcon } from "../../../../commons/asset/Duration.svg";
import { ReactComponent as PatientIcon } from "../../../../commons/asset/Patient.svg";
import { ReactComponent as ScenarioIcon } from "../../../../commons/asset/Scenario.svg";
import { ReactComponent as SituationIcon } from "../../../../commons/asset/Situation.svg";
import { ReactComponent as Correct } from "../../../../commons/asset/Correct.svg";
import { ReactComponent as NotImplement } from "../../../../commons/asset/notImplement.svg";
import { ReactComponent as Wrong } from "../../../../commons/asset/Wrong.svg";
import { ReactComponent as DateTime } from "../../../../commons/asset/IconDateTime.svg";
import { useAuth } from "../../../../../contexts/AuthContext";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { useEffect } from "react";
import { checkPagePermission } from "../../../../commons/CheckRole";
interface IProps {
  formik: any;
  changeState: (state: string, data: any) => void;
  dataLogTriagePatients?: any;
}
const Preview = ({ formik, changeState, dataLogTriagePatients }: IProps) => {
  const detailsWaiting = [
    {
      content: "Date & Time",
      value: `${dayjs(formik.values.date + formik.values.time).format(
        "DD/MM/YYYY HH:mm A"
      )}`,
      icon: <DateTime />,
      fontWeight: 700,
      color: "#5A5A5A",
    },
    {
      content: "Scenario",
      value: `${formik.values.scenario?.code}`,
      icon: <ScenarioIcon />,
      fontWeight: 700,
      color: "#5A5A5A",
    },
    {
      content: "Situation",
      value: `${formik.values.scenario?.desc}`,
      icon: <SituationIcon />,
      fontWeight: 700,
      color: "#5A5A5A",
    },
    {
      content: "Number of Patients",
      value: `${formik.values.scenario?.patientMaps?.length}`,
      icon: <PatientIcon />,
      fontWeight: 700,
      color: "#5A5A5A",
    },
  ];
  const detailsFinish = [
    {
      content: "Date & Time",
      value: `${dayjs(formik.values.date + formik.values.time).format(
        "DD/MM/YYYY HH:mm A"
      )}`,
      icon: <DateTime />,
      fontWeight: 700,
      color: "#5A5A5A",
    },
    {
      content: "Scenario",
      value: `${formik.values.scenario?.code}`,
      icon: <ScenarioIcon />,
      fontWeight: 700,
      color: "#5A5A5A",
    },
    {
      content: "Situation",
      value: `${formik.values.scenario?.desc}`,
      icon: <SituationIcon />,
      fontWeight: 700,
      color: "#5A5A5A",
    },
    {
      content: "Duration",
      value: "1 hr 30 min",
      icon: <DurationIcon />,
      fontWeight: 700,
      color: "#5A5A5A",
    },
  ];
  const { currentUser } = useAuth();
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <>
      {(formik.values?.status === "PENDING" ||
        formik.values?.status === "IN_PROGRESS") && (
        <Grid container>
          <Grid item={true} lg={6} xl={6} md={6} sm={6} xs={12} style={{paddingRight:matches ? 8 : 0}}>
            <Box>
              <Typography
                style={{
                  color: "#5A5A5A",
                  fontWeight: 700,
                  fontSize: "16px",
                }}
              >
                Details
              </Typography>
              <Box
                style={{
                  marginTop: "15px",
                  boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.15)",
                  borderRadius: 8,
                  padding: 15,
                }}
              >
                <Box padding="10px">
                  {detailsWaiting.map((item, index) => {
                    return (
                      <Grid container key={index} spacing={3}>
                        <Grid
                          item={true}
                          xs={6}
                          style={{
                            display: "flex",
                            fontWeight: item.fontWeight,
                            color: item.color,
                          }}
                        >
                          <div style={{ marginRight: 8 }}>{item.icon}</div>
                          <div> {item.content}</div>
                        </Grid>
                        <Grid item={true} xs={6} style={{ fontSize: 14 }}>
                          {item.value}
                        </Grid>
                      </Grid>
                    );
                  })}
                </Box>
              </Box>
              <Typography
                style={{
                  color: "#5A5A5A",
                  fontWeight: 700,
                  fontSize: "16px",
                  marginTop: "24px",
                }}
              >
                Instructors
              </Typography>
              <Box
                style={{
                  marginTop: "15px",
                  boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.15)",
                  borderRadius: 8,
                  height: 164,
                  overflowY: "auto",
                }}
              >
                <Box paddingTop="8px" paddingLeft="18px">
                  {formik.values &&
                    formik.values.instructors.length > 0 &&
                    formik.values.instructors.map(
                      (item: any, index: number) => {
                        return (
                          <Box key={index} display="flex" padding="6px 8px">
                            <Avatar src={convertStringToImg(item)}></Avatar>
                            <Box marginLeft="22px">
                              <Typography
                                style={{
                                  color: "#5A5A5A",
                                  fontWeight: 700,
                                  fontSize: "14px",
                                  textAlign: "start",
                                }}
                              >
                                {item.firstName} {item.lastName}
                              </Typography>
                              <Typography
                                style={{
                                  color: "#5A5A5A",
                                  fontWeight: 400,
                                  fontSize: "10px",
                                }}
                              >
                                {item.email}
                              </Typography>
                            </Box>
                          </Box>
                        );
                      }
                    )}
                </Box>
              </Box>
              {matches && checkPagePermission(currentUser?.roles, "CLASSROOM") &&
                (formik.values.status === "PENDING" || formik.values.status === "IN_PROGRESS") && (
                  <Box marginTop="25px">
                    <ButtonContained
                      colorText="blue"
                      onClick={() => changeState("UPDATE", null)}
                    >
                      Edit
                    </ButtonContained>
                  </Box>
                )}
            </Box>
          </Grid>
          {/* Student */}
          <Grid item={true} lg={6} xl={6} md={6} sm={6} xs={12}  style={{paddingLeft: matches ? 8 : 0}}>
            <Box>
              <Typography
                style={{
                  color: "#5A5A5A",
                  fontWeight: 700,
                  fontSize: "16px",
                  marginTop: matches ? 0 : 24,
                }}
              >
                Students
              </Typography>
              <Box
                style={{
                  marginTop: "15px",
                  boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.15)",
                  borderRadius: 8,
                  height: 467,
                  overflowY: "auto",
                }}
              >
                <Box padding="10px">
                  {formik.values &&
                    formik.values.students.length > 0 &&
                    formik.values.students.map((item: any, index: number) => {
                      return (
                        <Box key={index} display="flex" padding="6px 8px">
                          <Avatar src={convertStringToImg(item)}></Avatar>
                          <Box marginLeft="22px">
                            <Typography
                              style={{
                                color: "#5A5A5A",
                                fontWeight: 700,
                                fontSize: "14px",
                                textAlign: "start",
                              }}
                            >
                              {item.firstName} {item.lastName}
                            </Typography>
                            <Typography
                              style={{
                                color: "#5A5A5A",
                                fontWeight: 400,
                                fontSize: "10px",
                              }}
                            >
                              {item.email}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                </Box>
              </Box>
            </Box>
          </Grid>
          {!matches && checkPagePermission(currentUser?.roles, "CLASSROOM") &&
                (formik.values.status === "PENDING" || formik.values.status === "IN_PROGRESS") && (
                  <Box marginTop="25px">
                    <ButtonContained
                      colorText="blue"
                      onClick={() => changeState("UPDATE", null)}
                    >
                      Edit
                    </ButtonContained>
                  </Box>
                )}
        </Grid>
      )}
      {formik.values.status === "FINISHED" && (
        <Grid container spacing={2}>
          {/* Details*/}
          <Grid item={true} lg={6} xl={6} md={6} sm={6} xs={12}>
            <Box>
              <Typography
                style={{
                  color: "#5A5A5A",
                  fontWeight: 700,
                  fontSize: "16px",
                }}
              >
                Details
              </Typography>
              <Box
                style={{
                  marginTop: "15px",
                  boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.15)",
                  borderRadius: 8,
                  padding: 5,
                }}
              >
                <Box padding="10px">
                  {detailsFinish.map((item, index) => {
                    return (
                      <Grid container key={index} spacing={3}>
                        <Grid
                          item={true}
                          xs={6}
                          style={{
                            display: "flex",
                            fontWeight: item.fontWeight,
                            color: item.color,
                          }}
                        >
                          <div style={{ marginRight: 8 }}>{item.icon}</div>
                          <div> {item.content}</div>
                        </Grid>
                        <Grid item={true} xs={6}>
                          {item.value}
                        </Grid>
                      </Grid>
                    );
                  })}
                </Box>
              </Box>
              {/* Patient */}
              <Box
                style={{
                  marginTop: "15px",
                  boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.15)",
                  borderRadius: 8,
                  height: 165,
                }}
              >
                <Box padding="15px 15px 0px 15px">
                  <Grid container spacing={1}>
                    <Grid item={true} xs={12} style={{ marginBottom: 8 }}>
                      <Box
                        display="flex"
                        alignItems="center"
                        fontWeight="700"
                        color="#5A5A5A"
                      >
                        <PatientIcon style={{ marginRight: 10 }} />
                        Patient : {dataLogTriagePatients?.patientAll}
                      </Box>
                    </Grid>
                    <Grid item={true} xs={1}></Grid>
                    <Grid item={true} xs={5} style={{ display: "inline-flex" }}>
                      <Box>
                        <Correct />
                      </Box>
                      <Box paddingLeft="20px">Correct</Box>
                    </Grid>
                    <Grid item={true} xs={6}>
                      <Box>{dataLogTriagePatients?.correctTriage}</Box>
                    </Grid>
                    <Grid item={true} xs={1}></Grid>
                    <Grid item={true} xs={5} style={{ display: "inline-flex" }}>
                      <Box>
                        <Wrong />
                      </Box>
                      <Box paddingLeft="20px">Wrong</Box>
                    </Grid>
                    <Grid item={true} xs={6}>
                      <Box>{dataLogTriagePatients?.wrongTriage}</Box>
                    </Grid>
                    <Grid item={true} xs={1}></Grid>
                    <Grid item={true} xs={5} style={{ display: "inline-flex" }}>
                      <Box>
                        <NotImplement />
                      </Box>
                      <Box paddingLeft="20px">Not implemented</Box>
                    </Grid>
                    <Grid item={true} xs={6}>
                      <Box>{dataLogTriagePatients?.notImplementTriage}</Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
             {matches &&  <Grid container>
                <Grid
                  item={true}
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                >
                  <Box style={{display:'flex', justifyContent:'center'}}>
                    <ButtonContained
                      colorText="purple"
                      variant="contained"
                      style={{
                        fontWeight: 400,
                        marginTop: "16px",
                        width: "200px",
                        height: "40px",
                      }}
                      onClick={() => changeState("PATIENTDETAILS", null)}
                    >
                      <PatientsDetailIcon style={{ marginRight: 8 }} /> 
                      Patients Detail
                    </ButtonContained>
                  </Box>               
                </Grid>
                <Grid
                  item={true}
                  xs={12}
                  sm={6}   
                  md={6}
                  lg={6}              
                  >               
                  <Box style={{display:'flex', justifyContent:'center'}}>
                    <ButtonContained
                      colorText="green"
                      variant="contained"
                      style={{
                        fontWeight: 400,
                        marginTop: "16px",
                        width: "200px",
                        height: "40px",
                      }}
                      onClick={() => changeState("EXPORTCLASSRESULT", null)}
                    >
                      <ExportClassResultIcon style={{ marginRight: 8 }} />
                      Export Class Result
                    </ButtonContained>
                  </Box>
                </Grid>
                {/* {currentUser?.roles[0] === "STUDENT" && formik.values.certificate && (
                  <Grid
                    item={true}
                    xs={12}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <ButtonContained
                      fullWidth
                      colorText="yellow"
                      variant="contained"
                      style={{
                        fontWeight: 400,
                        marginTop: "11px",
                        width: "440px",
                        height: "40px",
                      }}
                      onClick={() => changeState("CERTIFICATE", null)}
                    >
                      <CertificateIcon style={{ marginRight: 8 }} /> Certificate
                    </ButtonContained>
                  </Grid>
                ) : (
                  <Grid
                    item={true}
                    xs={12}
                  >
                    <Box>
                      <ButtonContained
                        colorText="blue"
                        variant="contained"
                        fullWidth
                        style={{
                          fontWeight: 400,
                          marginTop: "11px",
                          height: "40px",
                        }}
                        onClick={() => changeState("VIDEOREPLAY", null)}
                      >
                        <VideoReplayIcon style={{ marginRight: 8 }} />
                        Video Replay
                      </ButtonContained>
                    </Box>
                  </Grid>
                )} */}
                {currentUser?.roles[0] === "STUDENT" ? (
                  <Grid
                    item={true}
                    xs={12}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    {formik.values.mode === "SIMULATION" &&  
                    <ButtonContained
                      fullWidth
                      disabled={!formik.values.certificate ? true : false}
                      colorText="yellow"
                      variant="contained"
                      style={{
                        fontWeight: 400,
                        marginTop: "11px",
                        width: "440px",
                        height: "40px",
                      }}
                      onClick={() => changeState("CERTIFICATE", null)}
                    >
                      <CertificateIcon style={{ marginRight: 8 }} /> Certificate
                    </ButtonContained>}
                  </Grid>
                ) : (
                  <Grid
                    item={true}
                    xs={12}
                  >
                    {/* <Box>
                      <ButtonContained
                        colorText="blue"
                        variant="contained"
                        fullWidth
                        style={{
                          fontWeight: 400,
                          marginTop: "11px",
                          height: "40px",
                        }}
                        onClick={() => changeState("VIDEOREPLAY", null)}
                      >
                        <VideoReplayIcon style={{ marginRight: 8 }} />
                        Video Replay
                      </ButtonContained>
                    </Box> */}
                  </Grid>
                )}

              </Grid>}
            </Box>
          </Grid>
          {/* Instructors */}
          <Grid item={true} lg={6} xl={6} md={6} sm={6} xs={12}>
            <Box>
              <Typography
                style={{ 
                  color: "#5A5A5A",
                  fontWeight: 700,
                  fontSize: "16px",
                }}
              >
                Instructors
              </Typography>
              <Box
                style={{
                  marginTop: "15px",
                  boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.15)",
                  borderRadius: 8,
                  height: 182,
                  overflowY: "auto",
                }}
              >
                <Box padding="10px">
                  {formik.values &&
                    formik.values.instructors.length > 0 &&
                    formik.values.instructors.map(
                      (item: any, index: number) => {
                        return (
                          <Box key={index} display="flex" padding="6px 8px">
                            <Avatar src={convertStringToImg(item)}></Avatar>
                            <Box marginLeft="22px">
                              <Typography
                                style={{
                                  color: "#5A5A5A",
                                  fontWeight: 700,
                                  fontSize: "14px",
                                  textAlign: "start",
                                }}
                              >
                                {item.firstName} {item.lastName}
                              </Typography>
                              <Typography
                                style={{
                                  color: "#5A5A5A",
                                  fontWeight: 400,
                                  fontSize: "10px",
                                }}
                              >
                                {item.email}
                              </Typography>
                            </Box>
                          </Box>
                        );
                      }
                    )}
                </Box>
              </Box>
            </Box>
            <Box>
              <Typography
                style={{
                  color: "#5A5A5A",
                  fontWeight: 700,
                  fontSize: "16px",
                  marginTop: 20,
                }}
              >
                Students
              </Typography>
              <Box
                style={{
                  marginTop: "15px",
                  height: 225,
                  boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.15)",
                  borderRadius: 8,
                  overflowY: "auto",
                }}
              >
                <Box padding="10px">
                  {formik.values &&
                    formik.values.students.length > 0 &&
                    formik.values.students.map((item: any, index: number) => {
                      return (
                        <Button
                        fullWidth
                        style={{
                          textTransform: "none",
                          justifyContent: "flex-start",
                        }}
                        key={index}
                        disabled={currentUser?.roles[0] === "STUDENT" ? item._id == currentUser?.userId ? false : true : false}
                        onClick={() => changeState("USERDETAILS", item)}
                      >
                        <Avatar src={convertStringToImg(item)}></Avatar>
                        <Box marginLeft="22px">
                          <Typography
                            style={{
                              color: "#5A5A5A",
                              fontWeight: 700,
                              fontSize: "14px",
                              textAlign: "start",
                            }}
                          >
                            {item.firstName} {item.lastName}
                          </Typography>
                          <Typography
                            style={{
                              color: "#5A5A5A",
                              fontWeight: 400,
                              fontSize: "10px",
                            }}
                          >
                            {item.email}
                          </Typography>
                        </Box>
                      </Button>);
                    })}
                </Box>
              </Box>
            </Box>
          </Grid>
          {!matches && (
            <Grid container style={{ paddingBottom: 10 }}>
              <Grid item={true} xs={12}>
                <Box style={{ display: "flex", justifyContent: "center" }}>
                  <ButtonContained
                    colorText="purple"
                    variant="contained"
                    fullWidth
                    style={{
                      fontWeight: 400,
                      marginTop: "16px",
                      height: "40px",
                    }}
                    onClick={() => changeState("PATIENTDETAILS", null)}
                  >
                    <PatientsDetailIcon style={{ marginRight: 8 }} />
                    Patients Detail
                  </ButtonContained>
                </Box>
              </Grid>
              <Grid item={true} xs={12}>
                <Box style={{ display: "flex", justifyContent: "center" }}>
                  <ButtonContained
                    colorText="green"
                    variant="contained"
                    fullWidth
                    style={{
                      fontWeight: 400,
                      marginTop: "16px",
                      height: "40px",
                    }}
                    onClick={() => changeState("EXPORTCLASSRESULT", null)}
                  >
                    <ExportClassResultIcon style={{ marginRight: 8 }} />
                    Export Class Result
                  </ButtonContained>
                </Box>
              </Grid>
              {currentUser?.roles[0] === "STUDENT" ? (
                <Grid
                  item={true}
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <ButtonContained
                    fullWidth
                    disabled={!formik.values.certificate ? true : false}
                    colorText="yellow"
                    variant="contained"
                    style={{
                      fontWeight: 400,
                      marginTop: "11px",
                      width: "440px",
                      height: "40px",
                    }}
                    onClick={() => changeState("CERTIFICATE", null)}
                  >
                    <CertificateIcon style={{ marginRight: 8 }} /> Certificate
                  </ButtonContained>
                </Grid>
              ) : (
                <Grid item={true} xs={12}>
                  {/* <Box>
                    <ButtonContained
                      colorText="blue"
                      variant="contained"
                      fullWidth
                      style={{
                        fontWeight: 400,
                        marginTop: "11px",
                        height: "40px",
                      }}
                      onClick={() => changeState("VIDEOREPLAY", null)}
                    >
                      <VideoReplayIcon style={{ marginRight: 8 }} />
                      Video Replay
                    </ButtonContained>
                  </Box> */}
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default Preview;
