import { Box, Button, Typography, useMediaQuery } from "@material-ui/core";
import { useStyles } from "../Classroom/styles/index";
import dayjs from "dayjs";
import MaterialTable from "material-table";
import {
  ButtonContained,
  ButtonContainedAction,
} from "../../../commons/Buttons";
import { ISrot } from "../../UserManagement/User";
import SortData from "../../UserManagement/User/SortData";
import { capitalizeFirstLetter } from '../../../commons/CapitalizeFirst';
interface IProps {
  changeState: (state: string, data: any) => void;
  classrooms: any;
  loading: boolean;
  sortValue: ISrot;
  setSortValue: React.Dispatch<React.SetStateAction<ISrot>>;
}
interface IClassRoom {
  classId: string;
  createdAt: string;
  classDate: string;
  status: string;
  instructorId: IInstructor;
  instructors: any;
  action: any;
  mode: string;
}
interface IInstructor {
  fullName: string;
}
const Table = ({
  changeState,
  classrooms,
  loading,
  sortValue,
  setSortValue,
}: IProps) => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-height:950px)");
  const headerStyle = {
    color: "#5A5A5B",
    backgroundColor: "#FFFFFF",
    fontSize: 16,
    fontWeight: 700,
  };
  const headerStyle1: any = {
    textAlign: "center",
    color: "#5A5A5B",
    backgroundColor: "#FFFFFF",
    fontSize: 16,
    fontWeight: 700,
  };
  return (
    <div className={classes.rootTable}>
      <MaterialTable
        columns={[
          {
            title: "Class ID",
            field: "calssID",
            cellStyle: {
              backgroundColor: "#F6FAFF",
              borderRadius: "16px 0px 0px 16px",
              color: "#5A5A5B",
              width: "12%",
            },
            headerStyle: headerStyle,
            render: (rowData: IClassRoom) => (
              <div style={{ fontSize: 14, fontWeight: 700 }}>
                {rowData.classId}
              </div>
            ),
          },
          {
            title: (
              <div>
                <div>Date</div>
                <SortData
                  setSortValue={setSortValue}
                  sortValue={sortValue}
                  list={[
                    { title: "Ascending", value: "asc" },
                    { title: "Descending", value: "desc" },
                  ]}
                  type={"sortByDate"}
                />
              </div>
            ),
            field: "date",
            cellStyle: {
              backgroundColor: "#F6FAFF",
              color: "#5A5A5B",
              fontSize: 14,
              width: "12%",
              textAlign: "center",
            },
            headerStyle: headerStyle1,
            render: (rowData: IClassRoom) => (
              <div>{dayjs(rowData.classDate).format("D/MM/YYYY")}</div>
            ),
          },
          {
            title: "Time",
            field: "time",
            cellStyle: {
              backgroundColor: "#F6FAFF",
              color: "#5A5A5B",
              fontSize: 14,
              width: "12%",
              textAlign: "center",
            },
            headerStyle: headerStyle1,
            render: (rowData: IClassRoom) => (
              <div>{dayjs(rowData.classDate).format("HH:mm")}</div>
            ),
          },
          {
            title: "Instuctor",
            field: "instuctor",
            cellStyle: {
              backgroundColor: "#F6FAFF",
              color: "#5A5A5B",
              fontSize: 14,
              width: "15%",
              textAlign: "center",
            },
            headerStyle: headerStyle1,
            render: (rowData: IClassRoom) => (
              <span>
                {rowData.instructors[0]?.firstName}{" "}
                {rowData.instructors[0]?.lastName}
              </span>
            ),
          },
          {
            title: 
            (
              <div>
                <div>Mode</div>
                <SortData
                  setSortValue={setSortValue}
                  sortValue={sortValue}
                  list={[
                    { title: "All", value: "" },
                    { title: "Simulation", value: "SIMULATION" },
                    { title: "Practice", value: "PRACTICE" },
                  ]}
                  type={"filterByMode"}
                />
              </div>
            ),
            field: "mode",
            cellStyle: {
              backgroundColor: "#F6FAFF",
              color: "#5A5A5B",
              fontSize: 14,
              width: "10%",
              textAlign: "center",
            },
            headerStyle: headerStyle1,
            render: (rowData: IClassRoom) => <span>{capitalizeFirstLetter(rowData.mode)}</span>,
          },
          {
            title: "Action",
            field: "action",
            cellStyle: {
              backgroundColor: "#F6FAFF",
              color: "#5A5A5B",
              borderRadius: "0px 16px 16px 0px",
              fontSize: 12,
              width: "25%",
            },
            headerStyle: headerStyle,
            render: (rowData: IClassRoom) => (
              <div style={{ display: "flex", flexDirection: "row" }}>
                {rowData.action.map((item: any, index: number) => (
                  <div key={index}>
                    <ButtonContainedAction
                      colorText={item.color}
                      onClick={() => {
                        changeState(item.code, rowData);
                      }}
                      style={{ fontWeight: 400 }}
                    >
                      {item.display}
                    </ButtonContainedAction>
                  </div>
                ))}
              </div>
            ),
          },
        ]}
        data={classrooms}
        isLoading={loading}
        style={{
          boxShadow: "none",
          border: "none",
        }}
        options={{
          selection: false,
          grouping: false,
          search: false,
          sorting: false,
          paging: false,
          showTitle: false,
          toolbar: false,
          headerStyle: { position: "sticky", top: 0 },
          maxBodyHeight: matches ? "900px" : '600px',
          overflowY:'initial'
        }}
      />
    </div>
  );
};
export default Table;
