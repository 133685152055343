import { ButtonProps } from "@material-ui/core";

import { BtnOutline } from "./styles";
interface BtnCustomProps {
  colorText: string;
}
const ButtonOutlined = ({
  colorText,
  ...props
}: ButtonProps & BtnCustomProps) => {
  return <BtnOutline customcolor={colorText} variant="outlined" {...props} />;
};

export default ButtonOutlined;
