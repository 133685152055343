import { Button, makeStyles, TextField } from "@material-ui/core";
import styled, { css } from "styled-components";
interface IBtnStyle {
  text: string;
}
interface IBtnColor {
  customColor: string;
}
export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    // minHeight: "90vh",
    paddingLeft: 20,
    paddingRight: 50,
  },
  calendar: {
    // '& .react-calendar__tile--now': {
    //   backgroundColor: 'white',
    //   color: 'black'
    // },
    // '& .react-calendar__tile--now:enabled:hover': {
    //   backgroundColor: '#a9d4ff',
    //   color: 'black'
    // },
    // '& .react-calendar__tile--now:enabled:focus': {
    //   backgroundColor: '#a9d4ff',
    //   color: 'black'
    // }
  },
  appointment: {
    '& .rbc-time-view': {
      border: 'none'
    },
    '& .rbc-time-view .rbc-row': {
      display: 'none'
    },
    "& .rbc-time-content": {
      border: 'none'
    }
  }
}));

