import React, { ReactElement, useEffect, useState } from "react";
import { Avatar, Box, Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { convertStringToImg } from "../../../../commons/ConvertImg";
import { ReactComponent as Correct } from "../../../../commons/asset/Correct.svg";
import { ReactComponent as NotImplement } from "../../../../commons/asset/notImplement.svg";
import { ReactComponent as Wrong } from "../../../../commons/asset/Wrong.svg";
import { ReactComponent as DateTime } from "../../../../commons/asset/IconDateTime.svg";
import { handleIconResult } from "..";
import { capitalizeFirstLetter } from '../../../../commons/CapitalizeFirst';
import dayjs from 'dayjs';
interface IuserDetails {
  selectDataUser: any;
  formik: any;
  dataLogTriagePatients?: any;
}
interface ITriageCase {
  breath: string;
  caseColor: string;
  patientId: string;
  pr: number;
  result: string;
  rr: number;
  userId: string;
}
export function handleTotalTriage(data: any, text: string) {
  if (data) {
    if (text === "WRONG") {
      return data.filter((item: any) => item.result === "FAIL").length;
    } else if (text === "CORRECT") {
      return data.filter((item: any) => item.result === "SUCCESS").length;
    } else if (text === "NA") {
      return data.filter((item: any) => item.result === "N/A").length;
    }
  }
}
// export function calInjuries(logTriagePatients: any) {
//   if (logTriagePatients.patient.injuries.arms !== "None" && !logTriagePatients.injuries.arms
//   ) {
//     return false;
//   }
//   if (logTriagePatients.patient.injuries.legs !== "None" && !logTriagePatients.injuries.legs
//   ) {
//     return false;
//   }
//   return true;
// }
export default function UserDetails({
  selectDataUser,
  formik,
  dataLogTriagePatients,
}: IuserDetails) {
  const [triageMe, setTriageMe] = useState<any>([]);
  useEffect(() => {
    const logPatientByUser = dataLogTriagePatients.logTriage.filter(
      (ele: any) => {
        return ele.studentId === selectDataUser._id;
      }
    );
    setTriageMe(logPatientByUser);
  }, []);
  
  return (
    <div>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex">
          <Avatar src={convertStringToImg(selectDataUser)}></Avatar>
          <Box marginLeft="28px">
            <div style={{ fontSize: 18, color: "#5A5A5A", fontWeight: 700 }}>
              {capitalizeFirstLetter(selectDataUser.userRoles[0])}
            </div>
            <div style={{ fontSize: 10, color: "#9C9B9D" }}>
              Classname : {formik.values.classId}
            </div>
          </Box>
        </Box>
      </Box>
      <Grid container style={{ marginTop: 24,minWidth: 650 }}>
        <Grid
          item
          xs={12}
          style={{
            boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.15)",
            borderRadius: 8,
            padding: 15,
          }}
        >
          <Grid item xs={12} style={{ marginTop: 18 }}>
            <div style={{ fontSize: 14, color: "#5A5A5A", fontWeight: 700,display:'flex' }}>
            <DateTime style={{marginRight:13}}/>
            <div> Date & Time</div>
            <div style={{marginLeft: 55, fontWeight: 400}}>{dayjs(formik.values.classDate).format('DD/MM/YYYY  HH.mm A')}</div>

            </div>
            <div style={{ fontSize: 14, color: "#5A5A5A", fontWeight: 700, marginTop:18 }}>
              Total Triage Amount : {triageMe?.length}
            </div>
          </Grid>
          <Grid container>
            <Grid item xs={8} style={{ marginTop: 18, display: "flex" }}>
              <Box marginLeft="39px" fontSize="14px">
                <Correct style={{ marginRight: "8px" }} />
                Correct Triage Amount
              </Box>
            </Grid>
            <Grid item xs={4} style={{ marginTop: 18, display: "flex" }}>
              <Box fontSize="14px">
                {handleTotalTriage(triageMe, "CORRECT")}
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={8} style={{ marginTop: 18, display: "flex" }}>
              <Box marginLeft="39px" fontSize="14px">
                <Wrong style={{ marginRight: "8px" }} />
                Wrong Triage Amount
              </Box>
            </Grid>
            <Grid item xs={4} style={{ marginTop: 18, display: "flex" }}>
              <Box fontSize="14px">{handleTotalTriage(triageMe, "WRONG")}</Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={8} style={{ marginTop: 18, display: "flex" }}>
              <Box marginLeft="39px" fontSize="14px">
                <NotImplement style={{ marginRight: "8px" }} />
                Not implemented Triage Amount
              </Box>
            </Grid>
            <Grid item xs={4} style={{ marginTop: 18, display: "flex" }}>
              <Box fontSize="14px">{handleTotalTriage(triageMe, "NA")}</Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} style={{ marginTop: 32 }}></Grid>
        <Divider />
        <Grid xs={1} style={{ textAlign: "center" }}>
          <Box fontSize="12px" fontWeight="700" color="#5A5A5A">
            Patient ID
          </Box>
        </Grid>
        <Grid xs={2} style={{ textAlign: "center" }}>
          <Box fontSize="12px" fontWeight="700" color="#5A5A5A">
            Assigned Color
          </Box>
        </Grid>
        <Grid xs={1} style={{ textAlign: "center" }}>
          <Box fontSize="12px" fontWeight="700" color="#5A5A5A">
            State
          </Box>
        </Grid>
        <Grid xs={2}>
          <Box
            fontSize="12px"
            fontWeight="700"
            color="#5A5A5A"
            textAlign="center"
          >
            Respiratory Rate
          </Box>
        </Grid>
        <Grid xs={1}>
          <Box
            fontSize="12px"
            fontWeight="700"
            color="#5A5A5A"
            textAlign="center"
          >
            Pulse Rate
          </Box>
        </Grid>
        <Grid xs={2}>
          <Box
            fontSize="12px"
            fontWeight="700"
            color="#5A5A5A"
            textAlign="center"
          >
            Capillary Refill
          </Box>
        </Grid>
        <Grid xs={1}>
          <Box
            fontSize="12px"
            fontWeight="700"
            color="#5A5A5A"
            textAlign="center"
          >
            Obstruct
          </Box>
        </Grid>
        <Grid xs={1}>
          <Box
            fontSize="12px"
            fontWeight="700"
            color="#5A5A5A"
            textAlign="center"
          >
            Injuries
          </Box>
        </Grid>
        <Grid xs={1}>
          <Box
            fontSize="12px"
            fontWeight="700"
            color="#5A5A5A"
            textAlign="center"
          >
            Result
          </Box>
        </Grid>
        {triageMe &&
          triageMe.length > 0 &&
          triageMe.map((item: any) => {
            return (
              <Grid
                container
                style={{
                  fontSize: 12,
                  fontWeight: 400,
                  color: "#5A5A5A",
                  marginTop: 5,
                }}
                key={item.patientId}
              >
                <Grid xs={1} style={{ textAlign: "center" }}>
                {item?.patientId}
                </Grid>
                <Grid xs={2} style={{ textAlign: "center" }}>
                  {item?.assignedColor ?? "null"}
                </Grid>
                <Grid xs={1} style={{ textAlign: "center" }}>
                  {item?.state ?? "null"}
                </Grid>
                <Grid xs={2} style={{ textAlign: "center" }}>
                  {item?.respiratoryRate ?? "null"}
                </Grid>
                <Grid xs={1} style={{ textAlign: "center" }}>
                  {item?.pulseRate ?? "null"}
                </Grid>
                <Grid xs={2} style={{ textAlign: "center" }}>
                  {item?.capillaryRefill ?? "null"}
                </Grid>
                <Grid xs={1} style={{ textAlign: "center" }}>
                  {item?.obstruct ?? "null"}
                </Grid>
                <Grid xs={1} style={{ textAlign: "center" }}>
                  {item?.injuries?.resultInjuries ?? "null"}
                </Grid>
                <Grid xs={1} style={{ textAlign: "center" }}>
                  {handleIconResult(item?.result)}
                </Grid>
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
}
