import React from "react";
import { useAuth } from "../../../contexts/AuthContext";
import Layout from "../../commons/Layout";
import { TextFieldSecondary } from "../../commons/TextFields";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";

const Test = () => {
  const { currentUser } = useAuth();
  const history = useHistory();
  
  return (
    <Layout
      pageAt={"test"}
      render={(props: any) => (
        <div style={{ color: "red" }}>
          <div style={{ marginBottom: 50 }}>
            <h4>Welcome back {currentUser?.username}</h4>
          </div>

          <div style={{ textAlign: "center" }}>
            <TextFieldSecondary
              helperText={"TEST"}
              label={"TEST"}
              id="userName"
              name="userName"
              style={{ width: "100%", maxWidth: 300 }}
              placeholder="Username"
              variant="outlined"
            />
          </div>

          <div
            onClick={() => {
              history.push({ pathname: "/" });
            }}
          >
            LANDING
          </div>
          <Button>Test123</Button>
        </div>
      )}
    />
  );
};

export default Test;
