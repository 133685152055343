import { Button, makeStyles, TextField, withStyles } from "@material-ui/core";
import styled, { css } from "styled-components";
export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    paddingLeft: 20,
    paddingRight: 0,
    // minHeight:"90vh",
    [theme.breakpoints.up("xl")]: {
      paddingRight: 130,
    },
  },
  rootInput: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  inputText: {
    "&::placeholder": {
      fontWeight: 300,
      fontSize: 14,
    },
  },
  autoComplete: {
    "& .MuiAutocomplete-inputRoot": {
      borderRadius: 10,
      "&:hover": {
        "&::before": {
          border: "none",
        },
      },
      "&::before": {
        border: "none",
      },
      "&::after": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        // borderColor: "none",
        // borderRadius: 10,
      },
    },
  },
  rootTable: {
    width: "100%",
    "& .MuiTableCell-root": {
      borderBottom: "5px solid #ffff",
    },
  },
  indicator: {
    "& .MuiTab-root": {
      color: "white",
      backgroundColor: "#9C9B9D",
      borderRadius: "8px 8px 0px 0px",
      marginLeft: 10,
      height: "40px",
      "&.Mui-selected": {
        backgroundColor: "#51B2DA",
      },
      "&.PrivateTabIndicator-root-1362": {
        display: "none",
      },
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    minHeight: "100vh",
    background: "#CCCCCC",
  },
  box: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  boxBlankHeader: {
    margin: "6vh 0 0 0",
  },
  boxContent: {
    margin: "10px calc(10vw + 20px)",
    fontSize: "30px",
    background: "#999999",
  },
  boxFooter: {
    margin: "0",
    padding: "10px",
    color: "white",
    flexGrow: 1,
    background: "red",
  },
  blueBorder: {
    border: "1px solid #33F",
    borderRadius: "10px",
  },
  btnAdd: {
    "& .MuiButton-root": {
      border: " 1.5px solid #51B2DA",
      color: "#51B2DA",
      backgroundColor: "white",
      textTransform: "none",
      padding: "3px 9px 3px 9px",
      fontWeight: 500,
    },
  },
  btnSelectPermission: {
    "& .MuiButton-label": {
      display: "block",
      textTransform: "none",
      padding: "0px 12px",
    },
  },
  btnGenerateKey: {
    " &.MuiButton-root": {
      width: 93,
      height: 34,
      backgroundColor: "#F9B233",
      padding: 0,
      textTransform: "none",
      color: "white",
    },
  },
  btnSave: {
    " &.MuiButton-root": {
      width: 93,
      height: 34,
      backgroundColor: "#60D0A1",
      padding: 0,
      textTransform: "none",
      color: "white",
    },
  },
  hintText: {
    color: "rgba(0, 0, 0, 0.35)",
    fontSize: 14,
    fontWeight: 300,
  },
  backdrop: {
    zIndex: 1251,
    color: "#fff",
  },
}));
export const TextFieldStyle = withStyles(() => ({
  root: {
    "&.MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiOutlinedInput-root": {
      height: 35,
      borderRadius: "0px 0px 8px 8px",
      backgroundColor: "#F5F5F7",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "&:not(:focus) .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "& input": {},
  },
}))(TextField);
