import { configureStore } from "@reduxjs/toolkit";
// import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import logger from "redux-logger";
import thunk from "redux-thunk";

import { reducer } from "./root-reducer";

// const middleware = [...getDefaultMiddleware(), logger];
const middleware = [thunk, logger];

export const store = configureStore({
  reducer,
  middleware,
});

export type RootState = ReturnType<typeof store.getState>;
