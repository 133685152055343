import React, { useEffect, useRef, useState } from "react";
import { Button, Divider, Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import dayjs from "dayjs";
import { useAuth } from "../../../../contexts/AuthContext";
import { handleIconResult } from ".";
import { useStyles } from "./styles";
import { capitalizeFirstLetter } from '../../../commons/CapitalizeFirst';

interface IProps {
  formik: any;
  dataLogTriagePatients: any;
}
export default function PDFResultTriage({
  formik,
  dataLogTriagePatients,
}: IProps) {
  const classes = useStyles();
  const headerStyle1: any = {
    textAlign: "center",
    color: "#5A5A5B",
    backgroundColor: "rgba(196, 196, 196, 0.27)",
    fontSize: 6,
    fontWeight: 700,
    border: "2px solid #C4C4C4",
  };
  const { currentUser } = useAuth();
  const [orderLogTriage, setOrderLogTriage] = useState<any>();

  useEffect(() => {
    const temp = Array.from({ length: dataLogTriagePatients?.logTriage.length },(_, i) => i + 1).map((e, index) => {
      return {
        value: dataLogTriagePatients?.logTriage[index],
        index: index + 1,
      };
    });
    setOrderLogTriage([...temp]);
  }, []);

  return (
    <>
      <Grid container spacing={1} id="test">
        <Grid item={true} xs={4}>
          {/* <div style={{ fontSize: 16, fontWeight: 700 }}>Profile</div>
          <Divider></Divider>
          <Grid container style={{ fontSize: 10, marginTop: 8 }}>
            <Grid item={true} xs={5} style={{ fontWeight: 700 }}>
              Firstname
            </Grid>
            <Grid item={true} xs={7}>
              {currentUser?.firstName}
            </Grid>
            <Grid item={true} xs={5} style={{ fontWeight: 700 }}>
              Lastname
            </Grid>
            <Grid item={true} xs={7}>
              {currentUser?.lastName}
            </Grid>
            <Grid item={true} xs={5} style={{ fontWeight: 700 }}>
              Organization
            </Grid>
            <Grid item={true} xs={7}>
              {currentUser?.organization}
            </Grid>
            <Grid item={true} xs={5} style={{ fontWeight: 700 }}>
              Occupation
            </Grid>
            <Grid item={true} xs={7}>
              {currentUser?.occupation}
            </Grid>
            <Grid item={true} xs={5} style={{ fontWeight: 700 }}>
              Email
            </Grid>
            <Grid item={true} xs={7}>
              {currentUser?.email}
            </Grid>
            <Grid item={true} xs={5} style={{ fontWeight: 700 }}>
              Telephone
            </Grid>
            <Grid item={true} xs={7}>
              {currentUser?.tel}
            </Grid>
          </Grid> */}
          <div style={{ fontSize: 18, fontWeight: 700 }}>
            Class
          </div>
          <Divider></Divider>
          <Grid container style={{ fontSize: 12, marginTop: 8 }}>
            <Grid item={true} xs={5} style={{ fontWeight: 700 }}>
              Class ID
            </Grid>
            <Grid item={true} xs={7}>
              {formik.values.classId}
            </Grid>
            <Grid item={true} xs={5} style={{ fontWeight: 700 }}>
              Date
            </Grid>
            <Grid item={true} xs={7}>
              {dayjs(formik.values.classDate).format("DD MMM YYYY / HH.mm A")}
            </Grid>
            <Grid item={true} xs={5} style={{ fontWeight: 700 }}>
              Mode
            </Grid>
            <Grid item={true} xs={7}>
              {capitalizeFirstLetter(formik.values?.mode)}
            </Grid>
            <Grid item={true} xs={5} style={{ fontWeight: 700 }}>
              Scenario
            </Grid>
            <Grid item={true} xs={7}>
              {formik.values.scenario.desc}
            </Grid>
            <Grid item={true} xs={5} style={{ fontWeight: 700 }}>
              Instructor
            </Grid>
            <Grid item={true} xs={7}>
              {formik.values.instructors[0]?.firstName + "  " + formik.values.instructors[0]?.lastName}
            </Grid>
            <Grid item={true} xs={5} style={{ fontWeight: 700 }}>
              Duration
            </Grid>
            <Grid item={true} xs={7}>
              1 hr
            </Grid>
            <Grid item={true} xs={5} style={{ fontWeight: 700 }}>
              <div>Number of</div>
              <div>Patients</div>
            </Grid>
            <Grid item={true} xs={7}>
              {dataLogTriagePatients?.patientAll}
            </Grid>
            <Grid item={true} xs={5} style={{ fontWeight: 700 }}>
              Correct
            </Grid>
            <Grid item={true} xs={7}>
              {dataLogTriagePatients?.correctTriage}
            </Grid>
            <Grid item={true} xs={5} style={{ fontWeight: 700 }}>
              Wrong
            </Grid>
            <Grid item={true} xs={7}>
              {dataLogTriagePatients?.wrongTriage}
            </Grid>
            <Grid item={true} xs={5} style={{ fontWeight: 700 }}>
              <div>No</div>
              <div>implemented</div>
            </Grid>
            <Grid item={true} xs={7}>
              {dataLogTriagePatients?.notImplementTriage}
            </Grid>
          </Grid>
          <div style={{ marginTop: 38, fontSize: 18, fontWeight: 700 }}>
            Teams
          </div>
          <Divider style={{ marginBottom: 8 }}></Divider>
          {formik.values.students.map((e: any,index:number) => {
            return (
              <div
                style={{ fontSize: 12 }}
                key={index}
              >{`${index+1}.  ${e.firstName} ${e.lastName}`}</div>
            );
          })}
        </Grid>
        <Grid item={true} xs={8}>
          <div className={classes.rootTablePDF}>
            <MaterialTable
              columns={[
                {
                  title: "",
                  field: "",
                  cellStyle: {
                    backgroundColor: "white",
                    borderRadius: "16px 0px 0px 16px",
                    color: "#5A5A5B",
                    border: "2px solid #C4C4C4",
                    width: "5%",
                    textAlign: "center",
                    fontSize: 10,
                  },
                  headerStyle: headerStyle1,
                  render: (rowData: any) => (
                    <div style={{ fontSize: 14, fontWeight: 700 }}>
                      {rowData?.index}
                    </div>
                  ),
                },
                {
                  title: "Assigned Color",
                  field: "assignColor",
                  cellStyle: {
                    backgroundColor: "white",
                    borderRadius: "16px 0px 0px 16px",
                    color: "#5A5A5B",
                    border: "2px solid #C4C4C4",
                    width: "10%",
                    textAlign: "center",
                    fontSize: 10,
                  },
                  headerStyle: headerStyle1,
                  render: (rowData: any) => (
                    <div>
                      {rowData?.value?.assignedColor !== 'null' ? capitalizeFirstLetter(rowData?.value?.assignedColor) : "null"}
                    </div>
                  ),
                },
                {
                  title: "Patient State",
                  field: "patientState",
                  cellStyle: {
                    backgroundColor: "white",
                    borderRadius: "16px 0px 0px 16px",
                    color: "#5A5A5B",
                    border: "2px solid #C4C4C4",
                    width: "10%",
                    textAlign: "center",
                    fontSize: 10,
                  },
                  headerStyle: headerStyle1,
                  render: (rowData: any) => (
                    <div>
                      {rowData?.value?.state ?? "null"}
                    </div>
                  ),
                },
                {
                  title: "Respiratory Rate",
                  field: "respiratoryRate",
                  cellStyle: {
                    backgroundColor: "white",
                    borderRadius: "16px 0px 0px 16px",
                    color: "#5A5A5B",
                    border: "2px solid #C4C4C4",
                    width: "10%",
                    textAlign: "center",
                    fontSize: 10,
                  },
                  headerStyle: headerStyle1,
                  render: (rowData: any) => (
                    <div>
                      {rowData?.value?.respiratoryRate ?? "null"}
                    </div>
                  ),
                },
                {
                  title: "Pulse Rate",
                  field: "pulseRate",
                  cellStyle: {
                    backgroundColor: "white",
                    borderRadius: "16px 0px 0px 16px",
                    color: "#5A5A5B",
                    border: "2px solid #C4C4C4",
                    width: "10%",
                    textAlign: "center",
                    fontSize: 10,
                  },
                  headerStyle: headerStyle1,
                  render: (rowData: any) => (
                    <div>
                      {rowData?.value?.pulseRate ?? "null"}
                    </div>
                  ),
                },
                {
                  title: "Capillary Refill",
                  field: "capillaryRefill",
                  cellStyle: {
                    backgroundColor: "white",
                    borderRadius: "16px 0px 0px 16px",
                    color: "#5A5A5B",
                    border: "2px solid #C4C4C4",
                    width: "10%",
                    textAlign: "center",
                    fontSize: 10,
                  },
                  headerStyle: headerStyle1,
                  render: (rowData: any) => (
                    <div>
                      {rowData?.value?.capillaryRefill ?? "null"}
                    </div>
                  ),
                },
                {
                  title: "Obstruct",
                  field: "obstruct",
                  cellStyle: {
                    backgroundColor: "white",
                    borderRadius: "16px 0px 0px 16px",
                    color: "#5A5A5B",
                    border: "2px solid #C4C4C4",
                    width: "10%",
                    textAlign: "center",
                    fontSize: 10,
                  },
                  headerStyle: headerStyle1,
                  render: (rowData: any) => (
                    <div>
                      {rowData?.value?.obstruct ?? "null"}
                    </div>
                  ),
                },
                {
                  title: "Injuries Arms",
                  field: "injuriesArms",
                  cellStyle: {
                    backgroundColor: "white",
                    borderRadius: "16px 0px 0px 16px",
                    color: "#5A5A5B",
                    border: "2px solid #C4C4C4",
                    width: "10%",
                    textAlign: "center",
                    fontSize: 10,
                  },
                  headerStyle: headerStyle1,
                  render: (rowData: any) => (
                    <div>
                      {rowData?.value?.injuries?.arms ?? "null"}
                    </div>
                  ),
                },
                {
                  title: "Injuries Legs",
                  field: "injuriesLegs",
                  cellStyle: {
                    backgroundColor: "white",
                    borderRadius: "16px 0px 0px 16px",
                    color: "#5A5A5B",
                    border: "2px solid #C4C4C4",
                    width: "10%",
                    textAlign: "center",
                    fontSize: 10,
                  },
                  headerStyle: headerStyle1,
                  render: (rowData: any) => (
                    <div>
                      {rowData?.value?.injuries?.arms ?? "null"}
                    </div>
                  ),
                },
                {
                  title: "Result Triage",
                  field: "resultTriage",
                  cellStyle: {
                    backgroundColor: "white",
                    borderRadius: "16px 0px 0px 16px",
                    color: "#5A5A5B",
                    border: "2px solid #C4C4C4",
                    width: "10%",
                    textAlign: "center",
                    fontSize: 10,
                  },
                  headerStyle: headerStyle1,
                  render: (rowData: any) => (
                    <div style={{ fontSize: 14, fontWeight: 700 }}>
                      {handleIconResult(rowData?.value?.result)}
                    </div>
                  ),
                },
              ]}
              data={orderLogTriage ? orderLogTriage : ""}
              // isLoading={loading}
              style={{
                boxShadow: "none",
                border: "none",
              }}
              options={{
                selection: false,
                grouping: false,
                search: false,
                sorting: false,
                paging: false,
                showTitle: false,
                toolbar: false,
                draggable: false,
                headerStyle: { position: "sticky", top: 0 },
              }}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
}
