
import axiosInstance from "..";
import { IGroup } from "../../components/pages/UserManagement/Group";

import { REACT_APP_API } from '../../config/config';

export async function addGroup(data: IGroup) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .post(`${REACT_APP_API}/v1/user-groups/insert-many`, [data], {
      headers: headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function fetchGroupApi() {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .get(`${REACT_APP_API}/v1/user-groups`, {
      headers: headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function fetchGroupApiPagination(inputQuery: any) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .get(`${REACT_APP_API}/v1/user-groups/pagination`, {
      headers: headers,
      params: inputQuery
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function updateGroup(data: IGroup) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .patch(`${REACT_APP_API}/v1/user-groups/update-data`, data, {
      headers: headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function deactivateGroup(data: { groupid: string | undefined }) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .patch(`${REACT_APP_API}/v1/user-groups/deactivate`, data, {
      headers: headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
