import React, { FC, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
  Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useStyles } from "./style";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import TableUserManage from "./Table";
import Layout from "../../../commons/Layout";
import ManageProfile from "./Dialog/ManagementUser";
import Preview, { Occupations } from "./Dialog/Preview";
import _, { forEach, fromPairs, isArray } from "lodash";
import generator from "generate-password";
import {
  addRegisterInstructorApi,
  addRegisterStudentApi,
  fetchUsersApi,
  fetchOrganizationsApi,
  IRegister,
  updateUserApi,
  deleteUserApi,
  addRoleRegistrationApproveApi,
  removeRoleRegistrationApproveApi,
  getImgApi,
  updateImageMeApi,
  updateImageOtherApi,
  fetchUsersApiPagination,
  addRoleModifyScenarioApproveApi,
  addRoleModifyPatientApi,
} from "../../../../services/Users/users.servies";
import { ButtonContained, ButtonText } from "../../../commons/Buttons";
import { convertStringToImg } from "../../../commons/ConvertImg";
import { useAuth } from "../../../../contexts/AuthContext";
import { checkPagePermission } from "../../../commons/CheckRole";
import { ITextConfirmDetail } from "../../ProfileManagement/Profile";
import { DataURIToBlob } from "../../../commons/ConvertToBlob";
import AlertDialog from "../../../commons/AlertDialog";
import RecheckDialog from "../../../commons/RecheckDialog";
import { Pagination } from "@material-ui/lab";
import {
  initialPagination,
  IPaginationData,
} from "../../../commons/Pagination";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.guess();
interface IProps {
  theme: Theme;
}
export interface IUsers {
  _id: string;
  fullName: string;
  userRoles: string;
  createdAt: string;
  action: any;
  img: string;
  confirmEmail: boolean;
}
export interface IOrganization {
  _id: string;
  orgName: string;
}
export interface ISrot {
  filterByRole?: "" | "STUDENT" | "INSTRUCTOR";
  sortByDate?: "desc" | "asc";
  sortByStatus?: "PENDING" | "IN_PROGRESS" | "FINISHED" | "";
  filterByMode?: "" | "SIMULATION" | "PRACTICE";
}
interface ITextConfirm {
  DELETE?: ITextConfirmDetail;
  CREATE?: ITextConfirmDetail;
  UPDATE?: ITextConfirmDetail;
  QUITEDITING?: ITextConfirmDetail;
}
const TextConfirmPopup: ITextConfirm = {
  DELETE: {
    title: "Delete this user?",
    subTitle: "This action cannot be rolled back",
    yes: "Delete",
    no: "Cancel",
    color: "red",
  },
  CREATE: {
    title: "Create this user?",
    subTitle: "This action cannot be rolled back.",
    yes: "Yes",
    no: "No",
    color: "blue",
  },
  UPDATE: {
    title: "Edit this user?",
    subTitle: "This action cannot be rolled back.",
    yes: "Yes",
    no: "No",
    color: "blue",
  },
  QUITEDITING: {
    title: "Quit editing?",
    subTitle: "Changes you made so far will not be saved.",
    yes: "Yes",
    no: "No",
    color: "red",
  },
};
interface IFormikRole {
  role: string;
}
// Des มากไปน้อย
const action = [{ display: "More Detail", color: "green", code: "PREVIEW" }];
const UserManagement = ({ theme }: IProps) => {
  const { currentUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [users, setUsers] = useState<IUsers[]>([]);
  const [value, setValue] = useState("account");
  const [state, setState] = useState("");
  const [organizationAll, setOrganizationAll] = useState<IOrganization[]>([]);
  const [selectData, setSelectData] = useState<any>({});
  const [open, setOpen] = useState(false);
  const [openRecheck, setOpenRecheck] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState<ITextConfirmDetail>();
  const [stepCreate, setStepCreate] = useState(0);
  const [forceUpdateData, setForceUpdateData] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
  const [edit, setEdit] = useState(false);
  const [reCheckOnExit, setRecheckOnExit] = useState(false) 
  const [sortValue, setSortValue] = useState<ISrot>({
    filterByRole: "",
    sortByDate: "desc",
  });
  const [pagination, setPagination] = useState<IPaginationData>(initialPagination);
  const classes = useStyles();
  const validationSchema = Yup.object().shape({
    role: Yup.string().required("Role is required"),
    email: Yup.string()
      .email("Must be a valid email")
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Must be a valid password")
      .required("Password is required"),
    firstName: Yup.string().required("Fristname is required"),
    lastName: Yup.string().required("Lastname is required"),
    gender: Yup.string().required("Gender is required"),
    organization: Yup.string().required("Organization is required"),
    occupation: Yup.string().required("Occupation is required"),
    birthDate: Yup.string().required("Birthdate is required"),
    tel: Yup.string().required("Telephone is required"),
    occupationTmp: Yup.string().when(['occupation'], {
      is: (occupation:any) => {
          return (occupation === "Other");
      },
      then: Yup.string().required('Occupation detail is required')
  }),
  });
  const validationUpdateSchema = Yup.object().shape({
    firstName: Yup.string().required("Fristname is required"),
    lastName: Yup.string().required("Lastname is required"),
    gender: Yup.string().required("Gender is required"),
    occupation: Yup.string().required("Occupation is required"),
    birthDate: Yup.string().required("Birthdate is required"),
    tel: Yup.string().required("Telephone is required"),
    occupationTmp: Yup.string().when(['occupation'], {
        is: (occupation:any) => {
            return (occupation === "Other");
        },
        then: Yup.string().required('Occupation detail is required')
    }),
  })
  const formik = useFormik({
    initialValues: !_.isEmpty(selectData)
      ? {
          role: selectData?.userRoles,
          img: "",
          email: selectData?.email,
          firstName: selectData?.firstName,
          lastName: selectData?.lastName,
          gender: selectData?.gender,
          organization: selectData?.organization,
          occupation: !Occupations.includes(selectData?.occupation) ? 'Other' :  selectData?.occupation,
          occupationTmp: !Occupations.includes(selectData?.occupation) ? selectData?.occupation : "",
          birthDate: dayjs(selectData?.birthDate).format("YYYY-MM-DD"),
          tel: selectData?.tel,
          userid: selectData?._id,
          imgBackendURL: selectData?.imgBackendURL,
          addPermissionApprove: selectData?.userRoles.includes("REGISTRATION_APPROVER") ? true : false,
          addPermissionModifyPatients: selectData?.userRoles.includes("MODIFY_PATIENT") ? true : false,
          addPermissionModifyScenarios: selectData?.userRoles.includes("MODIFY_SCENARIOS") ? true : false,
        }
      : {
          role: "",
          img: "",
          email: "",
          password: "",
          firstName: "",
          lastName: "",
          gender: "",
          organization: "",
          occupation: "",
          occupationTmp: "",
          birthDate: "",
          tel: "",
          imgBackendURL: "",
          addPermissionApprove: false,
          addPermissionModifyPatients: false,
          addPermissionModifyScenarios: false

        },
    enableReinitialize: true,
    validationSchema: state === "UPDATE" ? validationUpdateSchema : validationSchema,
    onSubmit: (values) => {
      handleOpenRecheck();
      changeState(state, undefined);
    },
  });
  function fetchUser() {
    fetchUsersApiPagination({
      page: pagination.page - 1,
      limit: 10,
      filterByRole: sortValue.filterByRole,
      sortByDate: sortValue.sortByDate
    }).then((res) => {
      if (res && res.data && res.data.result) {
        let mappedData = res.data.result.map((item: any) => {
          return { ...item, action };
        });
        setPagination({ ...pagination, totalPages: res.data.pageAmount });
        setUsers(mappedData);
        setLoading(false);
      }
    });
    fetchOrganizationsApi()
      .then((res) => {
        if (res && res.data && res.data.result) {
          setOrganizationAll(res.data.result);
        }
      })
      .catch(() => {});
  }
  function addRegisterInstructor(data: IRegister) {
    addRegisterInstructorApi(data)
      .then((res) => {
        if (res.data) {
          if (formik.values.img) {
            let formData = new FormData();
            formData.append(
              "file",
              DataURIToBlob(formik.values.img),
              "image/jpeg"
            );
            updateImageOtherApi(res.data._id, formData);
          }
          enqueueSnackbar("Created Success", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Created Error", {
            variant: "error",
          });
        }
      })
      .catch(() => {
        enqueueSnackbar("Created Error", {
          variant: "error",
        });
      });
  }
  function addRegisterStudent(data: IRegister) {
    addRegisterStudentApi(data)
      .then((res) => {
        if (res.data) {
          if (formik.values.img) {
            let formData = new FormData();
            formData.append(
              "file",
              DataURIToBlob(formik.values.img),
              "image/jpeg"
            );
            updateImageOtherApi(res.data._id, formData);
          }
          enqueueSnackbar("Created Student Success", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Created Student Error", {
            variant: "error",
          });
        }
      })
      .catch(() => {
        enqueueSnackbar("Created Student Error", {
          variant: "error",
        });
      });
  }
  function nextStep() {
    setStepCreate(stepCreate + 1);
  }
  function backStep() {
    setStepCreate(stepCreate - 1);
  }
  function changeState(state: string, data: any) {
    setState(state);
    if (state === "PREVIEW") {
      if (data) {
        setSelectData(data);
      }
      handleOpen();
    } else if (state === "CREATE") {
      setConfirmPopup(TextConfirmPopup[state]);
      handleOpen();
    } else if (state === "DELETE") {
      setConfirmPopup(TextConfirmPopup[state]);
      handleOpenRecheck();
    } else if (state === "UPDATE") {
      setConfirmPopup(TextConfirmPopup[state]);
    }
    setRecheckOnExit(false);
  }
  function handleSubmit() {
    if(reCheckOnExit && state === "CREATE"){
      forceClose();
    }
    else if(reCheckOnExit && state === "UPDATE"){
      handleEdit();
      handleCloseRecheck();
      setEdit(false);
      setRecheckOnExit(false);
      setStepCreate(0);
      setValue("account");
    }
    else {
      if (state === "CREATE") {
        createUser();
      } else if (state === "UPDATE") {
        updateUser();
      } else if (state === "DELETE") {
        deleteUser();
      }
      resetValue();
    }
  }
  function handleOpen() {
    setOpen(true);
  }
  function handleClose() {
    if(state === "CREATE" || state === "UPDATE"){
      handleRecheckOnExit();
    }
    else{
      forceClose();
    }
  }
  function forceClose() {
      setValue("account");
      setEdit(false);
      setStepCreate(0);
      setOpen(false);
      setSelectData("");
      setRecheckOnExit(false);
      setOpenRecheck(false);
      formik.resetForm();
  }
  function handleRecheckEdit() {
    handleEdit();
    handleCloseRecheck();
    setEdit(false);
    setRecheckOnExit(false);
    setStepCreate(0);
    setValue("account"); 
  }
  function handleRecheckOnExit() {
    if(state === "CREATE"){
      if(formik.values.firstName || formik.values.lastName || formik.values.role || formik.values.email || formik.values.password || formik.values.gender || formik.values.organization || formik.values.occupation || formik.values.birthDate || formik.values.tel){
        setConfirmPopup(TextConfirmPopup["QUITEDITING"]);
        setRecheckOnExit(true);
        handleOpenRecheck();   
      }
      else {
        forceClose();
      }
    }
    else if(state === "UPDATE"){
      if(formik.values.firstName !== selectData.firstName || formik.values.lastName !== selectData.lastName || formik.values.gender !== selectData.gender || formik.values.birthDate !== dayjs(selectData.birthDate).format('YYYY-MM-DD') || formik.values.tel !== selectData.tel || (Occupations.includes(formik.values.occupation) ? formik.values.occupation !== selectData.occupation : formik.values.occupationTmp !== selectData.occupation)){
        setConfirmPopup(TextConfirmPopup["QUITEDITING"]);
        setRecheckOnExit(true);
        handleOpenRecheck();   
      }
      else {
        handleRecheckEdit(); 
      }
    }
  }
  function handleCloseRecheck() {
    setOpenRecheck(false);
  }
  function handleOpenRecheck() {
    setOpenRecheck(true);
  }
  function createUser() {
    const data: IRegister = {
      email: formik.values.email,
      password: formik.values.password,
      firstName: formik.values.firstName,
      lastName: formik.values.lastName,
      gender: formik.values.gender,
      organization: formik.values.organization,
      occupation: Occupations.includes(formik.values.occupation) ? formik.values.occupation : formik.values.occupationTmp,
      birthDate: formik.values.birthDate,
      tel: formik.values.tel,
    };
    if (formik.values.role === "instructor") {
      addRegisterInstructor(data);
    } else {
      addRegisterStudent(data);
    }
    forceClose();
  }
  function deleteUser() {
    const data = {
      userid: formik.values.userid,
    };
    deleteUserApi(data)
      .then(() => {
        setForceUpdateData((prev: any) => prev + 1);
        enqueueSnackbar("Deleted Success", {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar("Updated Error", {
          variant: "error",
        });
      });
  }
  function updateUser() {
    const data: IRegister = {
      userid: formik.values.userid,
      firstName: formik.values.firstName,
      lastName: formik.values.lastName,
      gender: formik.values.gender,
      organization: formik.values.organization._id
        ? formik.values.organization._id
        : formik.values.organization,
      occupation: Occupations.includes(formik.values.occupation) ? formik.values.occupation : formik.values.occupationTmp,
      birthDate: formik.values.birthDate,
      tel: formik.values.tel,
    };
    updateUserApi(data)
      .then((res) => {
        if (res?.data) {
          if (formik.values.img) {
            let formData = new FormData();
            formData.append(
              "file",
              DataURIToBlob(formik.values.img),
              "image/jpeg"
            );
            updateImageOtherApi(formik.values.userid, formData).then((res) => {
              window.location.reload();
            });
          } else {
            forceClose();
            setForceUpdateData((prev: any) => prev + 1);          
          }
          enqueueSnackbar("Updated Success", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Update Error", {
            variant: "error",
          });
        }
      })
      .catch(() => {
        enqueueSnackbar("Updated Error", {
          variant: "error",
        });
      });
  }
  function resetValue() {
    handleClose();
    handleCloseRecheck();
  }
  function generatePasswordOnApi() {
    handleOpenBackdrop();
    const pwd = generator.generate({
      length: 10,
      lowercase: true,
      uppercase: true,
      numbers: true,
      symbols: false,
    });
    formik.setFieldValue("password", pwd);
    if (state === "UPDATE") {
      const data: any = {
        userid: formik.values.userid,
        password: pwd,
      };
      updateUserApi(data)
        .then((res) => {
          if (res.data) {
            setForceUpdateData((prev: any) => prev + 1);
            handleCloseBackdrop();
            enqueueSnackbar("Updated Password Success", {
              variant: "success",
            });
          } else {
            enqueueSnackbar("Update Password Error", {
              variant: "error",
            });
          }
        })
        .catch(() => {
          enqueueSnackbar("Update Password Error", {
            variant: "error",
          });
        });
    }
  }
  function generatePassword() {
    const pwd = generator.generate({
      length: 10,
      lowercase: true,
      uppercase: true,
      numbers: true,
      symbols: false,
    });
    formik.setFieldValue("password", pwd);
  }
  function handleOpenBackdrop() {
    setOpenBackdrop(true);
  }
  function handleCloseBackdrop(): void {
    setOpenBackdrop(false);
  }
  function handleCreate(values: IFormikRole): void {
    if (stepCreate === 0) {
      nextStep();
    } else if (stepCreate === 1 && values.role === "instructor") {
      nextStep();
    }
    if ((stepCreate === 1 && values.role === "student") || (stepCreate === 2 && values.role == "instructor")) {
      formik.handleSubmit();
    } else if (values.role === "" && stepCreate === 1) {
      formik.handleSubmit();
    }
  }
  function handleEdit() {
    if (!edit) {
      changeState("UPDATE", null);
    } else {
      formik.resetForm();
      changeState("PREVIEW", null);
    }
    setEdit(!edit);
  }
  function handleDialogAction(): ReactNode {
    return state === "CREATE" ? (
      <DialogActions style={{ padding: "10px 24px 10px 0px" }}>
        {stepCreate != 0 && (
          <ButtonContained
            style={{ marginRight: 20 }}
            onClick={backStep}
            colorText="white"
          >
            Back
          </ButtonContained>
        )}
        {state === "CREATE" && (
          <ButtonContained
            onClick={() => handleCreate(formik.values)}
            colorText="blue"
          >
            {(stepCreate === 2 && formik.values.role === "instructor") ||
            (stepCreate === 1 && formik.values.role === "student")
              ? "Create User"
              : "Next"}
          </ButtonContained>
        )}
      </DialogActions>
    ) : state === "UPDATE" &&
      (value === "account") ? (
      <DialogActions>
        <Box display="flex" justifyContent="flex-end" marginTop="8px">
          <ButtonText colorText="blue" onClick={handleRecheckOnExit}>
            Cancel
          </ButtonText>
          <ButtonContained
            colorText="blue"
            onClick={()=>formik.handleSubmit()}
          >
            Save
          </ButtonContained>
        </Box>
      </DialogActions>
    ) : (
      ""
    );
  }
  function handlePagination(event: React.ChangeEvent<unknown>, value: number) {
    setPagination({ ...pagination, page: value });
  }
  useEffect(() => {
    setLoading(true);
    fetchUser();
  }, [forceUpdateData, pagination.page, sortValue]);
  
  return (
    <Layout
      pageAt={"userManagement"}
      // group={"user"}
      render={(props: any) => (
        <div className={`${classes.root}`}>
          <Box display="flex" alignItems="center">
            <Typography
              style={{
                color: "#5A5A5B",
                fontWeight: 700,
                fontSize: 32,
                marginRight: 10,
              }}
            >
              User Management
            </Typography>
            <div className={classes.btnAdd}>
              {checkPagePermission(currentUser?.roles, "ADDUSER") && (
                <Button
                  onClick={() => changeState("CREATE", null)}
                  startIcon={<AddCircleRoundedIcon />}
                >
                  Create User
                </Button>
              )}
            </div>
          </Box>
          <TableUserManage
            users={users}
            setUsers={setUsers}
            changeState={changeState}
            convertStringToImg={convertStringToImg}
            loading={loading}
            setSortValue={setSortValue}
            sortValue={sortValue}
          />
          <div style={{ width: "100%", height: 50, backgroundColor: "white" }}>
            <Pagination
              count={pagination.totalPages}
              page={pagination.page}
              size="small"
              onChange={handlePagination}
              style={{
                padding: 10,
                position: "absolute",
                right: 0,
              }}
            />
          </div>
          <AlertDialog
            open={open}
            onClose={handleClose}
            handleClick={handleClose}
            style={{ zIndex: 1250, padding: 0 }}
            title={
              state === "CREATE" &&
              formik.values.role === "instructor" &&
              stepCreate === 2 &&
              state === "CREATE"
                ? "Select Permission"
                : state === "CREATE"
                ? "Create Account"
                : state === "PREVIEW" || state === "DELETE"
                ? "Account"
                : ""
            }
          >
            <DialogContent>
              {state === "CREATE" ? (
                <ManageProfile
                  formik={formik}
                  stepCreate={stepCreate}
                  organizationAll={organizationAll}
                  generatePassword={generatePassword}
                />
              ) : (
                <Preview
                  changeState={changeState}
                  formik={formik}
                  organizationAll={organizationAll}
                  convertStringToImg={convertStringToImg}
                  generatePasswordOnApi={generatePasswordOnApi}
                  handleEdit={handleEdit}
                  edit={edit}
                  value={value}
                  setValue={setValue}
                  handleCloseBackdrop={handleCloseBackdrop}
                  handleOpenBackdrop={handleOpenBackdrop}
                  setForceUpdateData={setForceUpdateData}
                />
              )}
            </DialogContent>
            {handleDialogAction()}
          </AlertDialog>
          {/* Dialog Recheck */}
          <RecheckDialog
            open={openRecheck}
            handleClose={handleCloseRecheck}
            confirmNo={handleCloseRecheck}
            confirmYes={handleSubmit}
            confirmPopup={confirmPopup}
          />
          <Backdrop
            className={classes.backdrop}
            open={openBackdrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    />
  );
};
export default UserManagement;
