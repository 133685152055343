import React, { useEffect } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

export default function PrivateRoute(props: any) {
  const { currentUser, verifyMyUser } = useAuth();
  const history = useHistory();

  if (props.path !== "/") {
    if (history.location.hash === "") {
      if (currentUser?.authenticated) {
        return <Route {...props} />;
      } else {
        return <Redirect to={"/login"} />;
      }
    } else {
      if (props.path === "/vr-spectator"){
        return <Redirect to={"/classroom-management"} />;
      }
      else if (currentUser?.authenticated) {
        return <Route {...props} />;
      }
      else {
        return <Redirect to={"/login"} />;
      }
    }
  } else {
    if (currentUser?.authenticated) {
      if (props && props.path == "/"){
        return <Redirect to={"/dashboard"} />
      }
      else if(props.path === "/vr-spectator"){
        return <Redirect to={"/classroom-management"} />;
      }
      else {
        return <Route {...props} />;
      }
    } else {
      return <Redirect to={"/login"} />;
    }
  }
}
