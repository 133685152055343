export interface IPaginationData {
  total: number;
  page: number;
  pageSize: number;
  totalPages: number;
  // sortByRole: "default" | "desc" | "asc";
  // sortByDate: "desc" | "asc";
}
export const initialPagination = {
  total: 1,
  page: 1,
  pageSize: 10,
  totalPages: 1,
  // sortByRole: "",
  // sortByDate: "",
};
