import dayjs from 'dayjs';
import { useAuth } from "../../../../../contexts/AuthContext";
import Certificate_Name from "../../../../commons/asset/Certificate_Name.png";

export default function Certificate({formik}:any) {
  const { currentUser } = useAuth();
  return (
    <>
      <div>
        <div className="bg-gray-200 p-6">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
              width: "100%",
            }}
          >
            <img src={Certificate_Name} alt="" width="600px" />
            <div
              style={{
                position: "absolute",
                bottom: 177,
                fontSize: 35,
                fontFamily: "Dancing Script",
              }}
            >
              {currentUser?.username}
            </div>
            <div
              style={{
                position: "absolute",
                bottom: 122,
                left: "50.2%",
                fontSize: 4,
                fontFamily: "Libre Baskerville",
              }}
            >
              {/* December 15th, 2022 */}
              {dayjs(formik.values.updatedAt).format('MMMM  D,YYYY')}
            </div>
            <div
              style={{
                position: "absolute",
                bottom: 50,
                fontSize: 4,
                fontFamily: "Roboto",
              }}
            >
              {currentUser?.username}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
