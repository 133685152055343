import React, { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { ReactComponent as Correct } from "../../../../commons/asset/Correct.svg";
import { ReactComponent as NotImplement } from "../../../../commons/asset/notImplement.svg";
import { ReactComponent as Wrong } from "../../../../commons/asset/Wrong.svg";
import { ReactComponent as DateTime } from "../../../../commons/asset/IconDateTime.svg";
import { handleIconResult } from "..";
interface IProps {
  formik: any;
  dataLogTriagePatients: any;
}

export default function PatientsDetail({
  formik,
  dataLogTriagePatients,
}: IProps) {
  return (
    <div>
      <Grid container style={{minWidth: 650}}>
        <Grid
          item
          xs={12}
          style={{
            boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.15)",
            borderRadius: 8,
            padding: 15,
          }}
        >
          {/* <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
            <DateTime />
            <div
              style={{
                marginLeft: 10,
                color: "#5A5A5A",
                fontWeight: 700,
                fontSize: 14,
              }}
            >
              Date & Time
            </div>
            <div style={{ marginLeft: 55, color: "#787878", fontSize: 14 }}>
              {formik.values?.sessions[0]?.date}
            </div>
          </Grid> */}
          <Grid item xs={12} style={{ marginTop: 18 }}>
            <div style={{ fontSize: 14, color: "#5A5A5A", fontWeight: 700 }}>
              Total Triage Amount : {dataLogTriagePatients?.patientAll}
            </div>
          </Grid>
          <Grid container>
            <Grid item xs={8} style={{ marginTop: 18, display: "flex" }}>
              <Box marginLeft="39px" fontSize="14px">
                <Correct style={{ marginRight: "8px" }} />
                Correct Triage Amount
              </Box>
            </Grid>
            <Grid item xs={4} style={{ marginTop: 18, display: "flex" }}>
              <Box fontSize="14px">{dataLogTriagePatients?.correctTriage} </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={8} style={{ marginTop: 18, display: "flex" }}>
              <Box marginLeft="39px" fontSize="14px">
                <Wrong style={{ marginRight: "8px" }} />
                Wrong Triage Amount
              </Box>
            </Grid>
            <Grid item xs={4} style={{ marginTop: 18, display: "flex" }}>
              <Box fontSize="14px">{dataLogTriagePatients?.wrongTriage} </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={8} style={{ marginTop: 18, display: "flex" }}>
              <Box marginLeft="39px" fontSize="14px">
                <NotImplement style={{ marginRight: "8px" }} />
                Not implemented Triage Amount
              </Box>
            </Grid>
            <Grid item xs={4} style={{ marginTop: 18, display: "flex" }}>
              <Box fontSize="14px">
                <Box fontSize="14px">
                  {dataLogTriagePatients?.notImplementTriage}{" "}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} style={{ marginTop: 32 }}></Grid>
        <Divider />
        <Grid xs={1} style={{ textAlign: "center" }}>
          <Box fontSize="12px" fontWeight="700" color="#5A5A5A">
            Patient ID
          </Box>
        </Grid>
        <Grid xs={2} style={{ textAlign: "center" }}>
          <Box fontSize="12px" fontWeight="700" color="#5A5A5A">
            Assigned Color
          </Box>
        </Grid>
        <Grid xs={1} style={{ textAlign: "center" }}>
          <Box fontSize="12px" fontWeight="700" color="#5A5A5A">
            State
          </Box>
        </Grid>
        <Grid xs={2}>
          <Box
            fontSize="12px"
            fontWeight="700"
            color="#5A5A5A"
            textAlign="center"
          >
            Respiratory Rate
          </Box>
        </Grid>
        <Grid xs={1}>
          <Box
            fontSize="12px"
            fontWeight="700"
            color="#5A5A5A"
            textAlign="center"
          >
            Pulse Rate
          </Box>
        </Grid>
        <Grid xs={2}>
          <Box
            fontSize="12px"
            fontWeight="700"
            color="#5A5A5A"
            textAlign="center"
          >
            Capillary Refill
          </Box>
        </Grid>
        <Grid xs={1}>
          <Box
            fontSize="12px"
            fontWeight="700"
            color="#5A5A5A"
            textAlign="center"
          >
            Obstruct
          </Box>
        </Grid>
        <Grid xs={1}>
          <Box
            fontSize="12px"
            fontWeight="700"
            color="#5A5A5A"
            textAlign="center"
          >
            Injuries
          </Box>
        </Grid>
        <Grid xs={1}>
          <Box
            fontSize="12px"
            fontWeight="700"
            color="#5A5A5A"
            textAlign="center"
          >
            Result
          </Box>
        </Grid>
        {dataLogTriagePatients?.logTriage.map((item: any) => {
          return (
            <Grid
              container
              style={{
                fontSize: 12,
                fontWeight: 400,
                color: "#5A5A5A",
                marginTop: 5,
              }}
              key={item.patientId}
            >
              <Grid xs={1} style={{ textAlign: "center" }}>
                {item?.patientId}
              </Grid>
              <Grid xs={2} style={{ textAlign: "center" }}>
                {item?.assignedColor ?? "null"}
              </Grid>
              <Grid xs={1} style={{ textAlign: "center" }}>
                {item?.state ?? "null"}
              </Grid>
              <Grid xs={2} style={{ textAlign: "center" }}>
                {item?.respiratoryRate ?? "null"}
              </Grid>
              <Grid xs={1} style={{ textAlign: "center" }}>
                {item?.pulseRate ?? "null"}
              </Grid>
              <Grid xs={2} style={{ textAlign: "center" }}>
                {item?.capillaryRefill ?? "null"}
              </Grid>
              <Grid xs={1} style={{ textAlign: "center" }}>
               {item?.obstruct ?? "null"}
              </Grid>
              <Grid xs={1} style={{ textAlign: "center" }}>
                {item?.injuries?.resultInjuries ?? '-'}
              </Grid>
              <Grid xs={1} style={{ textAlign: "center",fontWeight:'bold' }}>
                {handleIconResult(item?.result)}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
