import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useStyles } from "./style";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Table from "./Table";
import Preview from "./Dialog/Preview";
import Approve from "./Dialog/Approve";
import _ from "lodash";
import Layout from "../../../commons/Layout";
import {
  approvedApi,
  deleteUserApi,
  disApproveApi,
  fetchUsersApi,
  fetchUsersApiPagination,
  getImgApi,
} from "../../../../services/Users/users.servies";
import { ISrot, IUsers } from "../User";
import { ButtonContained, ButtonText } from "../../../commons/Buttons";
import RecheckDialog from "../../../commons/RecheckDialog";
import { ITextConfirmDetail } from "../../ProfileManagement/Profile";
import {
  initialPagination,
  IPaginationData,
} from "../../../commons/Pagination";
import { Pagination } from "@material-ui/lab";
import AlertDialog from "../../../commons/AlertDialog";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.guess();
interface IProps {}
interface ITextConfirm {
  DELETE?: ITextConfirmDetail;
  APPROVE?: ITextConfirmDetail;
}
const action = [
  { display: "More Information", color: "green", code: "PREVIEW" },
  { display: "Approved", color: "blue", code: "ADDUSER" },
  { display: "Deny", color: "red", code: "DELETE" },
];
const TextConfirmPopup: ITextConfirm = {
  DELETE: {
    title: "Deny?",
    subTitle: "Are you sure you want to deny this account?",
    yes: "Yes",
    no: "Cancel",
    color: "red",
  },
  APPROVE: {
    title: "Approve?",
    subTitle: "Are you sure you want to approve this account?",
    yes: "Yes",
    no: "No",
    color: "blue",
  },
};
const ApprovedManagement = ({}: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [users, setUsers] = useState<IUsers[]>([]);
  const [state, setState] = useState("");
  const [selectData, setSelectData] = useState<any>();
  const [confirmPopup, setConfirmPopup] = useState<ITextConfirmDetail>();
  const [openRecheck, setOpenRecheck] = useState(false);
  const [open, setOpen] = useState(false);
  const [forceUpdateData, setForceUpdateData] = useState(0);
  const [loading, setLoading] = useState(true);
  const [sortValue, setSortValue] = useState<ISrot>({
    filterByRole: "",
    sortByDate: "desc",
  });
  const [pagination, setPagination] = useState<IPaginationData>(
    initialPagination
  );
  const classes = useStyles();
  function fetchUser() {
    fetchUsersApiPagination({
      page: pagination.page - 1,
      limit: 10,
      filterByRole: sortValue.filterByRole,
      sortByDate: sortValue.sortByDate,
      isApproved: false,
    }).then((res) => {
      if (res && res.data && res.data.result) {
        let mappedData = res.data.result.map((item: any) => {
          return { ...item, action };
        });
        setPagination({ ...pagination, totalPages: res.data.pageAmount });
        setUsers(mappedData);
        setLoading(false);
      }
    });
  }
  function changeState(state: string, data: any) {
    if (state === "DELETE") {
      setConfirmPopup(TextConfirmPopup[state]);
      handleOpenRecheck();
    } else if (state === "ADDUSER") {
      handleOpen();
    } else if (state === "APPROVE") {
      setConfirmPopup(TextConfirmPopup[state]);
      handleOpenRecheck();
    } else if (state === "PREVIEW") {
      handleOpen();
    }
    setState(state);
    setSelectData(data);
  }
  function approvedUsers(id: string) {
    const data = { userid: id };
    approvedApi(data)
      .then(() => {
        setForceUpdateData((prev: any) => prev + 1);
        enqueueSnackbar("Approved Success", {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar("Approved Error", {
          variant: "error",
        });
      });
  }
  function UnapprovedUser(id: string) {
    const data = { userid: id };
    disApproveApi(data).then(() => {
      setForceUpdateData((prev: any) => prev + 1);
    });
  }
  function handleCloseRecheck() {
    setOpenRecheck(false);
    changeState('ADDUSER',selectData);
  }
  function handleOpenRecheck() {
    setOpenRecheck(true);
  }
  function handleSubmit() {
    if (state === "DELETE") {
      deleteUser(selectData?._id);
    } else if (state === "APPROVE") {
      approvedUsers(selectData?._id);
      handleCloseRecheck();   
      handleClose();   
    }
  }
  function handleClose() {
    setOpen(false);
  }
  function handleOpen() {
    setOpen(true);
  }
  function deleteUser(id: string) {
    const data = {
      userid: id,
    };
    deleteUserApi(data)
      .then(() => {
        setOpen(false);
        setForceUpdateData((prev: any) => prev + 1);
        enqueueSnackbar("Deleted Success", {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar("Updated Error", {
          variant: "error",
        });
      });
  }
  function handlePagination(event: React.ChangeEvent<unknown>, value: number) {
    setPagination({ ...pagination, page: value });
  }
  useEffect(() => {
    fetchUser();
  }, [forceUpdateData, sortValue, pagination.page]);
  return (
    <Layout
      pageAt={"approvedManagement"}
      // group={"user"}
      render={(props: any) => (
        <div className={`${classes.root}`}>
          <Typography
            style={{
              color: "#5A5A5B",
              fontWeight: 700,
              fontSize: 32,
              marginRight: 10,
            }}
          >
            Unapproved Register Management
          </Typography>
          <Table
            users={users}
            changeState={changeState}
            loading={loading}
            sortValue={sortValue}
            setSortValue={setSortValue}
          />
          <div style={{ width: "100%", height: 50, backgroundColor: "white" }}>
            <Pagination
              count={pagination.totalPages}
              page={pagination.page}
              size="small"
              onChange={handlePagination}
              style={{
                padding: 10,
                position: "absolute",
                right: 0,
              }}
            />
          </div>
          <AlertDialog
            open={open}
            onClose={handleClose}
            handleClick={handleClose}
            scroll={"paper"}
            maxWidth="md"
            classes={{ paper: classes.dialogPaper }}
            title={state === "PREVIEW" ? "Account" : "Add User to Application"}
          >
            <DialogContent>
              {state === "ADDUSER" || state === "APPROVE" ? (
                <Approve selectData={selectData} changeState={changeState} />
              ) : (
                state === "PREVIEW" && <Preview selectData={selectData} />
              )}
            </DialogContent>
          </AlertDialog>

          {/* Dialog Recheck */}
          <RecheckDialog
            open={openRecheck}
            handleClose={handleCloseRecheck}
            confirmNo={handleCloseRecheck}
            confirmYes={handleSubmit}
            confirmPopup={confirmPopup}
          />
        </div>
      )}
    />
  );
};
export default ApprovedManagement;
