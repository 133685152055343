import {
  Button,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import FaceBlack from "../asset/faceBlack.png";
import FaceGreen from "../asset/faceGreen.png";
import FaceRed from "../asset/faceRed.png";
import FaceYellow from "../asset/faceYellow.png";

import React, { CSSProperties, useEffect, useState } from "react";
import zIndex from "@material-ui/core/styles/zIndex";
interface IProps {
  name: string;
  handleColor: (color: string) => void;
  selectColor: string;
}

export default function CardSelectColor({
  name,
  handleColor,
  selectColor,
}: IProps) {
  const [style, setStyle] = useState<CSSProperties>();
  function convertColor(color: string) {
    if (color === "GREEN") {
      return ["#DFF6EC", "#60D0A1", FaceGreen];
    } else if (color === "YELLOW") {
      return ["#FFEFD3", "#F9B233", FaceYellow];
    } else if (color === "RED") {
      return ["#FCDFDF", "#F15F5F", FaceRed];
    } else if (color === "BLACK") {
      return ["rgba(90, 90, 90,0)", "#5A5A5A", FaceBlack];
    }
  }
  useEffect(() => {
    let colorText: string[] | undefined = convertColor(selectColor);
    const style: CSSProperties = {};
    if (colorText && selectColor === name) {
      style.background = `url(${colorText[2]}`;
      style.backgroundRepeat = "no-repeat";
      style.backgroundPosition = "90% 30%";
      style.backgroundColor = colorText[0];
      style.border = `solid 3px  ${colorText[1]}`;
    } else {
      style.backgroundColor = "#F5F5F7";
    }
    setStyle(style);
  }, [selectColor]);
  return (
    <div>
      <Button
        style={{
          ...style,
          width: 500,
          height: 73,
          justifyContent: "space-between",
          marginTop: 20,
        }}
        onClick={() => handleColor(name)}
      >
        {/* <img src={FaceGreen} /> */}
        <Typography
          style={{
            fontSize: 16,
            fontWeight: 500,
            marginLeft: 19,
          }}
        >
          {name}
        </Typography>
      </Button>
    </div>
  );
}
