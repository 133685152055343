import { Button, TextField, withStyles } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
      maxWidth: 566,
      marginLeft: "auto",
      marginRight: "auto",
      // marginTop: "36%",
    },
    colorIcon: {
      color: "#9C9B9D",
    },
    textTopic: {
      color: theme.palette.primary.dark,
      textAlign: "center",
      textDecoration: "underline 2px",
      marginBottom: 55,
      textUnderlineOffset: "20px",
      fontSize: 50,
    },
    test: {
      marginTop: "22px",
    },
    rootForm: {
      flexGrow: 1,
    },
    divSave: {
      width: "100%",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
    saveButton: {
      fontWeight: "bold",
      color: "#FFFFFF",
      background: "linear-gradient(180deg, #309E6F 0%, #105B3F 100%)",
      width: "100%",
      borderRadius: 21,
      maxWidth: 175,
      fontSize: 14,
      [theme.breakpoints.down("sm")]: {
        maxWidth: 132,
        borderRadius: 6,
      },
    },
    textForgetPassword: {
      fontWeight: "bold",
      color: "#0D4575",
      fontSize: 16,
      textAlign: "start",
      [theme.breakpoints.down("sm")]: {
        textAlign: "end",
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    modalPaper: {
      position: "absolute",
      width: "90%",
      maxWidth: 400,
      backgroundColor: theme.palette.background.paper,
      borderRadius: 20,
      boxShadow: "0px 2px 9px rgba(17, 84, 141, 0.25)",
      padding: theme.spacing(2, 4, 3),
    },
    textAlertError: {
      fontWeight: "bold",
      backgroundColor: "transparent",
      marginTop: 10,
      color: "#E31B0C",
      padding: 0,
      fontSize: 14,
      [theme.breakpoints.down("sm")]: {
        fontSize: 10,
      },
    },
    btnRole: {
      width: "231px",
      height: "208px",
      textTransform: "none",
      display: "block",
      fontSize: 16,
      fontWeight: 700,
      marginTop: 10,
    },
    formControl: {
      "& .MuiFormLabel-root": {
        padding: "0px",
        margin: "0px",
        fontSize: 14,
      },
    },
    containerBelow: {
      marginTop: 60,
      [theme.breakpoints.down("sm")]: {
        marginTop: 20,
      },
    },
    input: {
      "&::placeholder": {
        fontWeight: 300,
        fontSize: 14,
      },
    },
    hintText: {
      color: "rgba(0, 0, 0, 0.35)",
      fontSize: 14,
      fontWeight: 300,
    },
    btnBack: {
      color: "#9C9B9D",
      fontSize: 16,
      position: "absolute",
      left: 60,
      bottom: 10,
      textTransform: "none",
    },
    btnNext: {
      color: "#51B2DA",
      fontSize: 16,
      position: "absolute",
      right: 60,
      bottom: 10,
      textTransform: "none",
    },
  })
);

export const CustomTextField = withStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 16,
      paddingLeft: 34,
      [theme.breakpoints.down("sm")]: {
        borderRadius: 6,
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      // backgroundColor:'#F5F5F7',
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
      // backgroundColor:'#F5F5F7',
    },
    "& input": {
      borderRadius: 16,
      border: "0px",
      height: 48,
      padding: 0,
      [theme.breakpoints.down("md")]: {
        height: 34,
      },
      [theme.breakpoints.down("sm")]: {
        height: 1,
      },
    },
    "& .MuiFormHelperText-root": {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  },
}))(TextField);
export const ButtonSelect = withStyles(() => ({
  root: {
    width: "231px",
    height: "208px",
    border: (nameRole: any) =>
      nameRole === "instructor" ? "1px solid red" : "1px solid blue",
  },
}))(Button);
