import { ButtonProps } from "@material-ui/core";
import { BtnText } from "./styles";
interface BtnCustomProps {
  colorText: string;
}
const ButtonOutlined = ({
  colorText,
  ...props
}: ButtonProps & BtnCustomProps) => {
  return <BtnText customcolor={colorText} {...props} />;
};

export default ButtonOutlined;
