import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { SelectFieldScenarios } from "../../../commons/Inputs";
import DeleteIcon from "@material-ui/icons/Delete";
import { IFilter, IPatients } from "../ListOfScenario/CreateScenario";
import { useStyles } from "../ListOfScenario/style";
import {
  createPatientsApi,
  deletePatientsManyApi,
  getPatientsApi,
} from "../../../../services/Patient/patient.service";
import CreateIcon from "@material-ui/icons/Create";
import MenuColor, { colorCloth } from "./MenuColor";
import { ButtonContained } from "../../../commons/Buttons";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import { useAuth } from "../../../../contexts/AuthContext";
import { Unity, useUnityContext } from "react-unity-webgl";
import CardPatient from './cardPatient'
import { checkPagePermission } from '../../../commons/CheckRole';
interface IProp {
  changeState: (state: string, color?: string) => void;
  selectPatient: any;
  setSelectPatient: any;
  handleColor: any;
  setForceUpdateData: React.Dispatch<React.SetStateAction<number>>;
  forceUpdateData: number;
  handleOpenRecheck: (callback: () => void) => void;
}
export const convertNameToColor = (name: string): string => {
  let tempColor: Array<string> = [];
  colorCloth.forEach((item) => {
    if (item.name === name) {
      tempColor.push(item.color);
    }
  });
  return tempColor[0];
};
export default function SelectPatient({
  changeState,
  selectPatient,
  setSelectPatient,
  handleColor,
  setForceUpdateData,
  forceUpdateData,
  handleOpenRecheck,
}: IProp) {
  const {
    unityProvider,
    sendMessage,
    addEventListener,
    removeEventListener,
    loadingProgression
  } = useUnityContext({
    loaderUrl: "Build/05102022_RVRT_PATIENT_CUSTOMISATION.loader.js",
    dataUrl: "Build/05102022_RVRT_PATIENT_CUSTOMISATION.data",
    frameworkUrl: "Build/05102022_RVRT_PATIENT_CUSTOMISATION.framework.js",
    codeUrl: "Build/05102022_RVRT_PATIENT_CUSTOMISATION.wasm",
  });
  const classes = useStyles();
  const { currentUser } = useAuth();
  const matches = useMediaQuery("(max-width:1920px)");
  const { enqueueSnackbar } = useSnackbar();
  const [patients, setPatients] = useState<Array<IPatients>>([]);
  const [select, setSelect] = useState(false);
  const [isSelectAll, setIsSelect] = useState(false);
  const [checked, setChecked] = useState([{ id: "", check: false }]);
  const [filter, setFilter] = useState<IFilter>({
    color: "",
    gender: "",
    age: "",
    pose: "",
  });

  function handleSelectAll() {
    patients.forEach((item: any, index: any) => {
      checked[index].check = !isSelectAll;
    });
    setChecked([...checked]);
    setIsSelect(!isSelectAll);
  }
  function handleSelectPatient(data: any): void {
    setSelectPatient(data);
  }
  function handleSelect() {
    setSelect(!select);
  }
  function handleSelectCheckedBox(index: number) {
    if (checked[index]) {
      checked[index].check = !checked[index]?.check;
      setChecked([...checked]);
    }
  }
  function handleSelectForDelete() {
    var valueForDelete: any = [];
    const mappedIdPatients = patients.map((item: any) => {
      return { patientId: item._id };
    });

    mappedIdPatients.forEach((item: any, index) => {
      if (item.patientId === checked[index].id && checked[index].check) {
        valueForDelete.push({ patientId: item.patientId });
      }
    });

    deletePatientsManyApi(valueForDelete)
      .then((res) => {
        if(res.data.status === "Success"){
          enqueueSnackbar("Deleted Success", {
            variant: "success",
          });
          setForceUpdateData((prev: any) => prev + 1);
          setSelect(false);
          setIsSelect(false);
        }
        else {
          enqueueSnackbar("Deleted Error", {
            variant: "error",
          });
        }
      })
      .catch(() => {
        enqueueSnackbar("Deleted Error", {
          variant: "error",
        });
      });
  }
  function handleChangeSexReactToUnity(textPatientAppearance: string) {
    sendMessage("CustomisationManager", "LoadAvatar", textPatientAppearance);
  }
  useEffect(() => {
    if (patients) {
      const temp = patients.map((item: any) => {
        return { id: item._id, check: false };
      });
      setChecked(temp);
    }
  }, [patients]);
  useEffect(() => {
    getPatientsApi({
      caseColor: filter.color,
      gender: filter.gender,
      pose: filter.pose,
      age: filter.age,
    }).then((res) => {
      if (res.data) {
        setPatients(res.data.result);
      }
    });
  }, [filter, forceUpdateData]);
  useEffect(() => {
    if (selectPatient) {
      handleChangeSexReactToUnity(selectPatient.patientAppearance);
    }
  }, [selectPatient]);
  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])

  return (
    <>
      <div style={{ display: "flex", marginTop: 13 }}>
        <div style={{ width: 340, zIndex: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={6} style={{ marginTop: -15 }}>
              <label htmlFor="" style={{ fontSize: 12 }}>
                Color
              </label>
              <SelectFieldScenarios
                style={{ height: "20px" }}
                onChange={(e) => {
                  let temp = { ...filter };
                  temp.color = e.target.value as string;
                  setFilter(temp);
                }}
                displayEmpty
                value={filter.color}
              >
                <MenuItem value={"GREEN"}>Green</MenuItem>
                <MenuItem value={"RED"}>Red</MenuItem>
                <MenuItem value={"BLACK"}>Black</MenuItem>
                <MenuItem value={"YELLOW"}>Yellow</MenuItem>
                <MenuItem value={""}>All</MenuItem>
              </SelectFieldScenarios>
            </Grid>
            <Grid item xs={6} style={{ marginTop: -15 }}>
              <label htmlFor="" style={{ fontSize: 12 }}>
                Gender
              </label>
              <SelectFieldScenarios
                style={{ height: "20px" }}
                onChange={(e) => {
                  let temp = { ...filter };
                  temp.gender = e.target.value as string;
                  setFilter(temp);
                }}
                displayEmpty
                value={filter.gender}
              >
                <MenuItem value={"M"}>Male</MenuItem>
                <MenuItem value={"F"}>Female</MenuItem>
                <MenuItem value={""}>All</MenuItem>
              </SelectFieldScenarios>
            </Grid>
            <Grid item xs={6} style={{ marginTop: -15 }}>
              <label htmlFor="" style={{ fontSize: 12 }}>
                Age
              </label>
              <SelectFieldScenarios
                style={{ height: "20px" }}
                displayEmpty
                onChange={(e) => {
                  let temp = { ...filter };
                  temp.age = e.target.value as string;
                  setFilter(temp);
                }}
                value={filter.age}
              >
                <MenuItem value={"Child"}>Child</MenuItem>
                <MenuItem value={"Teen"}>Teen</MenuItem>
                <MenuItem value={"Adult"}>Adult</MenuItem>
                <MenuItem value={"Elder"}>Elder</MenuItem>
                <MenuItem value={""}>All</MenuItem>
              </SelectFieldScenarios>
            </Grid>
            <Grid item xs={6} style={{ marginTop: -15 }}>
              <label htmlFor="" style={{ fontSize: 12 }}>
                Pose
              </label>
              <SelectFieldScenarios
                style={{ height: "20px" }}
                displayEmpty
                onChange={(e) => {
                  let temp = { ...filter };
                  temp.pose = e.target.value as string;
                  setFilter(temp);
                }}
                value={filter.pose}
              >
                <MenuItem value={"Sit"}>Sit</MenuItem>
                <MenuItem value={"Walking"}>Walking</MenuItem>
                <MenuItem value={"Lie down"}>Lie down</MenuItem>
                <MenuItem value={""}>All</MenuItem>
              </SelectFieldScenarios>
            </Grid>
          </Grid>
          <div
            className={classes.backgroundScenario}
            style={{
              height: matches ? 826 : 586,
              overflowY: "auto",
              position: "relative",
              padding: "0px 15px",
            }}
          >
            {patients.map((item: any, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  {select && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <FormControlLabel
                        style={{ marginRight: 0 }}
                        control={
                          <Checkbox
                            inputProps={{
                              "aria-label": "uncontrolled-checkbox",
                            }}
                            checked={checked[index]?.check || false}
                            name="gilad"
                            onChange={() => handleSelectCheckedBox(index)}
                          />
                        }
                        label=""
                      />
                    </div>
                  )}
                  <CardPatient
                    data={item}
                    handleSelectPatient={handleSelectPatient}
                    selectPatient={selectPatient}
                  />
                </div>
              );
            })}
            {select && (
              <div
                style={{
                  position: "absolute",
                  bottom: 12,
                  right: 18,
                  display: "flex",
                  flexDirection: "column",
                  zIndex: 111,
                }}
              >
                <IconButton
                  style={{
                    color: "white",
                    marginTop: 12,
                    backgroundColor:checked?.filter((x)=>x?.check === true).length === 0?'rgba(179, 222, 240, 1)' : '#67BDE0'
                  }}
                  disabled={checked?.filter((x)=>x?.check === true).length === 0}
                  // onClick={handleSelectForDelete}
                  onClick={() => {
                    changeState("DELETE_ALL");
                    handleOpenRecheck(handleSelectForDelete);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            )}
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {!select ? (
              <Button
                variant="text"
                style={{ color: "#67BDE0", textTransform: "none" }}
                onClick={handleSelect}
              >
                Select
              </Button>
            ) : (
              <>
                {!isSelectAll ? (
                  <Button
                    variant="text"
                    style={{ color: "#67BDE0", textTransform: "none" }}
                    onClick={handleSelectAll}
                  >
                    Select all
                  </Button>
                ) : (
                  <Button
                    variant="text"
                    style={{ color: "#67BDE0", textTransform: "none" }}
                    onClick={handleSelectAll}
                  >
                    Deselect all
                  </Button>
                )}
              </>
            )}
            {select && (
              <Button
                variant="text"
                style={{ color: "#67BDE0", textTransform: "none" }}
                onClick={handleSelect}
              >
                Cancel
              </Button>
            )}
          </div>
        </div>
        <div
          style={{
            height: matches ? 950 : 660,
            minWidth: 600,
            width: "100%",
            marginLeft: 30,
            marginTop: 30,
            backgroundColor: "#F6FAFF",
          }}
        >
          <Grid container>
            <Grid item xs={12} md={12} xl={6} lg={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection:'column',
                  justifyContent: "center",
                  alignItems:'center',
                  height: "100%",
                  width: "100%",
                }}
              >
               {Math.round(loadingProgression * 100) !== 100 && <p style={{width: matches ? "300px" : "300px",marginTop:30}}>Loading Application... {Math.round(loadingProgression * 100)}%</p>}
                <Unity
                  unityProvider={unityProvider}
                  style={{
                    width: matches ? "300px" : "300px",
                    height: matches ? "500px" : "500px",
                  }}
                />
              </div>
            </Grid>
           {!isEmpty(selectPatient) &&  
           <Grid
              item
              xs={12}
              md={12}
              xl={6}
              lg={12}
              style={{ marginTop: 30, marginLeft: matches ? 20 : 0 }}
            >
              <Grid container>
                <Grid item xs={6}>
                  <div
                    style={{
                      height: 180,
                      backgroundColor: "white",
                      padding: 15,
                      width: "95%",
                      marginRight: 15,
                      borderRadius: 10,
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        top: -15,
                        backgroundColor: "#64DBA9",
                        padding: "3px 6px",
                        position: "absolute",
                        fontWeight: 700,
                        color: "#5A5A5A",
                      }}
                    >
                      General
                    </div>
                    <Grid container style={{ marginTop: 15 }}>
                      <Grid item xs={8}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 500,
                            color: "#5A5A5A",
                          }}
                        >
                          Patient Code
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 300,
                            color: "#5A5A5A",
                          }}
                        >
                          {selectPatient?.code}
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 500,
                            color: "#5A5A5A",
                            marginTop: 7,
                          }}
                        >
                          Case Color
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 300,
                            color: "#5A5A5A",
                            marginTop: 7,
                          }}
                        >
                          {selectPatient?.caseColor}
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 500,
                            color: "#5A5A5A",
                            marginTop: 7,
                          }}
                        >
                          Gender
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 300,
                            color: "#5A5A5A",
                            marginTop: 7,
                          }}
                        >
                          {selectPatient?.gender === "F" ? "Female" : "Male"}
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 500,
                            color: "#5A5A5A",
                            marginTop: 7,
                          }}
                        >
                          Pose
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 300,
                            color: "#5A5A5A",
                            marginTop: 7,
                          }}
                        >
                          {selectPatient?.pose}
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 500,
                            color: "#5A5A5A",
                            marginTop: 7,
                          }}
                        >
                          Age
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 300,
                            color: "#5A5A5A",
                            marginTop: 7,
                          }}
                        >
                          {selectPatient?.age}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                  <div
                    style={{
                      height: 120,
                      backgroundColor: "white",
                      padding: 15,
                      width: "95%",
                      marginRight: 15,
                      marginTop: 35,
                      borderRadius: 10,
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        top: -15,
                        backgroundColor: "#FFCA6C",
                        padding: "3px 6px",
                        position: "absolute",
                        fontWeight: 700,
                        color: "#5A5A5A",
                      }}
                    >
                      Breath
                    </div>
                    <Grid container>
                      <Grid item xs={8} style={{ marginTop: 15 }}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 500,
                            color: "#5A5A5A",
                          }}
                        >
                          Respiratory Rate
                        </Typography>
                      </Grid>
                      <Grid item xs={4} style={{ marginTop: 15 }}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 300,
                            color: "#5A5A5A",
                          }}
                        >
                          {selectPatient?.breath?.rr}
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 500,
                            color: "#5A5A5A",
                            marginTop: 7,
                          }}
                        >
                          Airway
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 300,
                            color: "#5A5A5A",
                            marginTop: 7,
                          }}
                        >
                          {selectPatient && selectPatient?.breath
                            ? selectPatient?.breath?.obs
                              ? "True"
                              : "False"
                            : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div
                    style={{
                      height: 180,
                      backgroundColor: "white",
                      padding: 15,
                      width: "95%",
                      marginRight: 15,
                      borderRadius: 10,
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        top: -15,
                        backgroundColor: "#F57373",
                        padding: "3px 6px",
                        position: "absolute",
                        fontWeight: 700,
                        color: "#5A5A5A",
                      }}
                    >
                      Injuries
                    </div>
                    <Grid container>
                      <Grid item xs={6} style={{ marginTop: 15 }}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 500,
                            color: "#5A5A5A",
                          }}
                        >
                          Head
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ marginTop: 15 }}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 300,
                            color: "#5A5A5A",
                          }}
                        >
                          {selectPatient?.injuries?.head}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 500,
                            color: "#5A5A5A",
                            marginTop: 7,
                          }}
                        >
                          Chest
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 300,
                            color: "#5A5A5A",
                            marginTop: 7,
                          }}
                        >
                          {selectPatient?.injuries?.chest}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 500,
                            color: "#5A5A5A",
                            marginTop: 7,
                          }}
                        >
                          Arms
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 300,
                            marginTop: 7,
                          }}
                        >
                          {selectPatient?.injuries?.arms}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 500,
                            marginTop: 7,
                            color: "#5A5A5A",
                          }}
                        >
                          Legs
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 300,
                            marginTop: 7,
                            color: "#5A5A5A",
                          }}
                        >
                          {selectPatient?.injuries?.legs}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                  <div
                    style={{
                      height: 120,
                      backgroundColor: "white",
                      padding: 15,
                      width: "95%",
                      marginRight: 15,
                      marginTop: 35,
                      borderRadius: 10,
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        top: -15,
                        backgroundColor: "#BFC0FF",
                        padding: "3px 6px",
                        position: "absolute",
                        fontWeight: 700,
                        color: "#5A5A5A",
                      }}
                    >
                      Circulation
                    </div>
                    <Grid container>
                      <Grid item xs={8} style={{ marginTop: 15 }}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 500,
                            color: "#5A5A5A",
                          }}
                        >
                          Pulse Rate
                        </Typography>
                      </Grid>
                      <Grid item xs={4} style={{ marginTop: 15 }}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 300,
                            color: "#5A5A5A",
                          }}
                        >
                          {selectPatient?.circulation?.pr}
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 500,
                            color: "#5A5A5A",
                            marginTop: 7,
                          }}
                        >
                          Capillary Refill
                        </Typography>
                      </Grid>
                      <Grid item xs={4} style={{ marginTop: 7 }}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 300,
                            color: "#5A5A5A",
                          }}
                        >
                          {selectPatient?.circulation?.cr}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>}
          </Grid>
          <div style={{display:'flex',justifyContent:'center',marginTop:30}}>
            {!isEmpty(selectPatient) && currentUser?.userId === selectPatient?.createdUser && checkPagePermission(currentUser?.roles, "HANDLEPATIENT")
            && (
                <ButtonContained
                  style={{
                    // position: "absolute",
                    // left: "calc(50% - 65px)",
                    // bottom: 15,
                    margin:'0 auto'
                  }}
                  startIcon={<CreateIcon />}
                  colorText="blue"
                  onClick={() => changeState("UPDATE", selectPatient.caseColor)}
                >
                  Edit
                </ButtonContained>
              )}
          </div>
         
        </div>
      </div>
    </>
  );
}
