import type { CSSProperties, FC } from "react";
import { useDrop } from "react-dnd";
import { ItemTypes } from "./itemType";

const style: CSSProperties = {
  height: "86px",
  width: "180px",
  borderRadius: 20,
  fontSize: 25,
  fontWeight: 600,
  textAlign: "center",
  color: "white",
  border: "dashed 5px  #FFFFFF",
  // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
};
interface IProps {
  name: string;
  bgColor?: string;
  isDrag: boolean;
}

export const DropToClear: FC<IProps> = ({ name, bgColor, isDrag }) => {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.BOX,
    drop: () => ({ name: name }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const isActive = canDrop && isOver;
  let backgroundColor = bgColor ? bgColor : "rgba(255, 255, 255, 0.3)";
  if (isActive) {
    backgroundColor = "green";
  }
  return (
    <div
      ref={!isDrag ? drop : undefined}
      role={name}
      style={{
        ...style,
        // backgroundColor,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      // onDrop={(e) => console.log(e)}
    >
      <div
        style={{
          backgroundColor: "rgba(255, 255, 255,0.3)",
          width: "100%",
          height: "100%",
        }}
      >
        <div> Drop it here to Clear</div>
      </div>
      {/* {isActive ? "Release to drop" : "Drag a box here"} */}
    </div>
  );
};
