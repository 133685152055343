import { createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { IUserData } from "./userManagement";
import { usersInitialState } from "./userManagement.initialState";
import { userReducer } from "./userManagement.reducer";

/* createSlice - a function that accepts an initial state, an object full of reducer 
functions, and a slice name that automatically generates action creators and action 
types that correspond to the reducers and state.  Internally it uses createAction and createReducer */

export const usersSlice = createSlice({
  name: "tests", // name used in action types
  initialState: usersInitialState, // initial state for the reducer
  reducers: userReducer,
});

export const { createUser, updateUser, deleteUser } = usersSlice.actions;
export const listUserData = (state: RootState) => state.users.users;
export const selectUserById = (id: number) => {
  return createSelector(listUserData, (items) =>
    items.filter((data) => data.id === id)
  );
};
