import React, { useEffect, useState, CSSProperties, FC } from "react";
import { Button, Checkbox, FormControlLabel, useMediaQuery } from "@material-ui/core";
import { useStyles } from "./style";
import { IPatients, IPosition } from "./CreateScenario";
import { useDrag } from "react-dnd";
import { ItemTypes } from "./itemType";
import { XYCoord, useDragLayer } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { handleCaseColor } from "./pointToTrash";
import useScrollPosition from "./useScrollPosition";

export interface BoxProps {
  name: string;
}
interface DropResult {
  name: string;
}
export interface IPatientMap {
  patientName?: string;
  pointMapName?: string;
}
interface IProp {
  data: IPatients;
  name: string;
  showEmptyPlaceholder: boolean;
  setPositionPoint: React.Dispatch<React.SetStateAction<Array<IPosition>>>;
  positionPoint: Array<IPosition>;
  setIsDrag: React.Dispatch<React.SetStateAction<boolean>>;
}
interface ITemp {
  patientName?: string;
  data?: any;
  pointMapName?: any;
}

export function handleColor(color: string) {
  if (color === "GREEN") {
    return ["#60D0A1", "#DFF6EC", "#60D0A1"];
  } else if (color === "YELLOW") {
    return ["#F9B233", "#FFEFD3", "#F9B233"];
  } else if (color === "RED") {
    return ["#F15F5F", "#FCDFDF", "#F15F5F"];
  } else if (color === "BLACK") {
    return ["#787878", "#E4E4E4", "#787878"];
  }
}
export default function CardPatient({
  data,
  name,
  showEmptyPlaceholder,
  setPositionPoint,
  positionPoint,
  setIsDrag,
}: IProp) {
  const [color, setColor] = useState<string>();
  const [temp, setTemp] = useState<ITemp>({});
  const [lengthPatient, setLengthPatient] = useState<string>();

  function chipColor(name: string) {
    const colorText: Array<string> | undefined = handleColor(name);
    var color, backgroundColor;
    if (colorText) {
      backgroundColor = colorText[0];
      color = colorText[1];
    }
    return (
      <div
        style={{
          backgroundColor: color,
          color: backgroundColor,
          padding: "3px 6px",
          marginLeft: 10,
          borderRadius: 2,
        }}
      >
        <div style={{ fontSize: 8, fontWeight: 500 }}>{name}</div>
      </div>
    );
  }

  useEffect(() => {
    let colorText = handleColor(data.caseColor);
    if (colorText) {
      setColor(colorText[2]);
    }
  }, [data]);

  useEffect(() => {
    let temp: any = [];
    positionPoint.forEach((item: any) => {
      if (item.data?.data?.code == data.code) {
        temp.push(item.data.patientName);
      }
    });
    setLengthPatient(temp);
  }, [positionPoint,data]);

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: ItemTypes.BOX,
      item: { name, color: data.caseColor },
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult<DropResult>();
        if (item && dropResult?.name !== "TEST") {
          const itemName: ITemp = {
            patientName: item.name,
            data: data,
            pointMapName: dropResult?.name,
          };
          setTemp(itemName);
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    }),
    [data]
  );

  useEffect(() => {
    setIsDrag(isDragging);
  }, [isDragging]);

  useEffect(() => {
    let notRepeat = false;
    if (Object.keys(temp).length !== 0) {
      positionPoint.forEach((item: any) => {
        if (
          item.data.patientName === temp.patientName &&
          item.data.pointMapName === temp.pointMapName
        ) {
          const findIdxColor = positionPoint.findIndex((item: any) => {
            return item.index === temp.pointMapName;
          });
          if (findIdxColor > -1) {
            positionPoint[findIdxColor].caseColor = temp.data.caseColor;
            positionPoint[findIdxColor].data = temp;
            setPositionPoint(positionPoint);
          }
          setTemp({});
          notRepeat = true;
        }
      });
      if (!notRepeat) {
        const findIdx = positionPoint.findIndex((item: any) => {
          return item.index === temp.pointMapName;
        });
        if (findIdx > -1) {
          positionPoint[findIdx].caseColor = temp.data.caseColor;
          positionPoint[findIdx].data = temp;
          setPositionPoint([...positionPoint]);
        }
        setTemp({});
      }
    }
  }, [temp]);

  useEffect(() => {
    if (showEmptyPlaceholder) {
      preview(getEmptyImage(), { captureDraggingState: true });
    }
  }, [showEmptyPlaceholder, preview]);

  return (
    <div style={{ display: "flex", marginTop: 10 }}>
      <div
        ref={drag}
        // role="Box"
        // style={{ ...style1, opacity }}
        // data-testid={`box-${name}`}
      >
        <div style={{ display: "flex", marginTop: 10 }}>
          <div
            style={{
              backgroundColor: color,
              height: 65,
              width: 6,
              borderRadius: "8px 0px 0px 8px",
            }}
          ></div>
          <div
            style={{
              backgroundColor: "white",
              height: 65,
              width: 290,
              borderRadius: "0px 8px 8px 0px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ marginTop: 10, fontSize: 12, marginLeft: 10 }}>
                {data.code}
              </div>
              {lengthPatient && lengthPatient.length > 0 && (
                <div
                  style={{
                    marginTop: 10,
                    fontSize: 12,
                    marginRight: 20,
                    width: 20,
                    height: 20,
                    backgroundColor: "#60D0A1",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "white",
                  }}
                >
                  {lengthPatient?.length}
                </div>
              )}
            </div>

            <div
              style={{
                display: "flex",
                marginTop: 4,
                fontWeight: 500,
                fontSize: 8,
              }}
            >
              {chipColor(data.caseColor)}
              <div
                style={{
                  border: "0.3px solid #E4E4E4",
                  color: "#5A5A5A",
                  padding: "2px 8px",
                  marginLeft: 5,
                }}
              >
                {data.gender === "F"
                  ? "Female"
                  : data.gender === "M"
                  ? "Male"
                  : "Orther"}
              </div>
              <div
                style={{
                  border: "0.3px solid #E4E4E4",
                  color: "#5A5A5A",
                  padding: "2px 8px",
                  marginLeft: 5,
                }}
              >
                {data.age}
              </div>
              <div
                style={{
                  border: "0.3px solid #E4E4E4",
                  color: "#5A5A5A",
                  padding: "2px 8px",
                  marginLeft: 5,
                }}
              >
                {data.pose}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function getDragLayerStyles(
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null,
  headerHeight: number,
  horizontal: number,
  matches: boolean
) {

  if (!initialOffset || !currentOffset) {
    return {
      display: "none",
    };
  }
  let { x, y } = currentOffset;
  const transform = `translate(${x - (matches ? 310 : 25) + horizontal}px, ${y - (matches ? 780 : 780) + headerHeight}px)`;
  // const transform = `translate(${x - 30 + horizontal}px, ${y - 780 + headerHeight}px)`;

  return { transform, WebkitTransform: transform };
}
export function CustomDragLayer() {
  const {
    itemType,
    isDragging,
    item,
    initialOffset,
    currentOffset,
  } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
  }));
  const scrollPos = useScrollPosition();
  const matches = useMediaQuery("(min-width:1280px)");
  const headerHeight = scrollPos;

  const renderItem = () => {
    switch (itemType) {
      case ItemTypes.BOX:
        return (
          <div
            className="dragitem"
            style={{
              backgroundColor: handleCaseColor(item.color),
              width: 25,
              height: 25,
              borderRadius: "50%",
            }}
          ></div>
        );
      default:
        return null;
    }
  };

  if (!isDragging) {
    return null;
  }

  return (
    <div className="draglayer" style={{ position: "absolute" }}>
      <div
        style={getDragLayerStyles(initialOffset, currentOffset, headerHeight.Y, headerHeight.X, matches)}
      >
        {renderItem()}
      </div>
    </div>
  );
}
