import { TextField, TextFieldProps, withStyles } from "@material-ui/core";

import { CustomInputTextField } from "./style";

export const InputSearch = withStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      height: 40,
      width: 280,
      borderRadius: "24px 0px 0px 24px",
      fontSize: 14,
      fontWeight: 500,
      //   color: theme.palette.textBlack[500],
      backgroundColor: theme.palette.grey[100],
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E7E9ED",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E7E9ED",
    },
    "& input": {},
  },
}))(TextField);

const TextFieldComponent = (props: TextFieldProps) => {
  return <CustomInputTextField {...props} />;
};

export default TextFieldComponent;
