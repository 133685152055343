import React, { FC, SetStateAction, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  Collapse,
  Divider,
  Hidden,
  Avatar,
  IconButton,
} from "@material-ui/core/";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ChatIcon from '@material-ui/icons/Chat';
import HomeIcon from "@material-ui/icons/Home";
import ClassIcon from "@material-ui/icons/Class";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import PeopleIcon from "@material-ui/icons/People";
import MessageIcon from "@material-ui/icons/Message";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Logo_Ever from "../asset/Ever_logo.svg";
import Logo_Rama from "../asset/Rama_Logo_Color1.svg";
import { useTranslation } from "react-i18next";
import { IUserAuthentication, useAuth } from "../../../contexts/AuthContext";
import clsx from "clsx";
import FiberManualRecordOutlinedIcon from "@material-ui/icons/FiberManualRecordOutlined";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { checkPagePermission, getSubgroupUser } from "../CheckRole";
import { convertStringToImg } from "../ConvertImg";
import { capitalizeFirstLetter } from '../CapitalizeFirst';
interface Props {
  history?: any;
  pageAt?: string;
  isShow: boolean;
  onClose: () => void;
  group?: string;
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
}
interface IsubGroup {
  title: string;
  id: string;
  path: string;
  height?: number;
}
interface Icollapse {
  title: string;
  icon: any;
  listSubGroup: Array<IsubGroup>;
}
interface Iitem {
  title: string;
  path: string;
  id: string;
  icon: any;
}
type UnionDrawer = Icollapse | Iitem;
const drawerWidth = 290;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      // width: drawerWidth,
      // flexShrink: 0,
      // "& .MuiDrawer-paperAnchorDockedLeft": {
      //   border: "none",
      // },
      [theme.breakpoints.up("md")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    listRoot: {
      marginTop: 70,
      height: "100%",
      width: "100%",
      backgroundColor: "#51B2DA", //this
      color: "#FFFFFF",
    },
    drawerDiv: {
      height: "100%",
      width: drawerWidth,
      backgroundColor: "#51B2DA", //this
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: "#51B2DA",
    },
    ListItem: {},
    listItemIcon: {
      marginLeft: 10,
      minWidth: 40,
    },
    toolbar: theme.mixins.toolbar,
    listItemText: {
      "& .MuiListItemText-primary": {
        fontWeight: 700,
        fontSize: 16,
        marginLeft: 0,
      },
    },
    btnHover: {
      "&:hover": {
        backgroundColor: "red",
      },
    },
    customIcon: {
      marginLeft: 4,
      marginTop: 10,
    },
    header: {
      paddingRight: 30,
      paddingTop: 5,
      height: 65,
      textAlign: "end",
      backgroundColor: "red",
      [theme.breakpoints.down("md")]: {
        height: 40,
      },
    },
    subText: {
      "& .MuiListItemText-primary": {
        fontWeight: 400,
        fontSize: 15,
      },
    },
    bodyDrawer: {
      marginTop: 0,
      borderRadius: "16px",
      width: 260,
      margin: "0 auto",
    },
    bodyDrawerHover: {
      backgroundColor: "#FFFFFF",
      width: 260,
      margin: "0 auto",
      "&:hover": {
        backgroundColor: "#F5F5F7",
      },
    },
    iconBtn: {
      "& .MuiSvgIcon-root": {
        width: 15,
        height: 15,
        padding: 0,
      },
    },
    bodyDrawerNotHover: {
      backgroundColor: "#51B2DA",
      width: 270,
      margin: "0 auto",
      "&:hover": {
        // backgroundColor: "#F5F5F7",
      },
    },
  })
);
const CollapseDataRender = ({
  data,
  history,
  pageAt,
  nameGroup,
  setNameGroup,
}: any) => {
  const classes = useStyles();
  const { setGroupDrawerAuth } = useAuth();
  const [open, setOpen] = useState<boolean>(() => {
    return nameGroup === data.title;
  });
  const handleClick = () => {
    if (nameGroup != data.title) {
      setNameGroup(data.title);
    } else {
      setNameGroup("");
    }
  };
  function checkPageAt(data: any) {
    let tempArry: Array<string> = [];
    data?.listSubGroup.map((item: any) => tempArry.push(item.id));
    return tempArry.includes(pageAt);
  }
  useEffect(() => {
    setGroupDrawerAuth(nameGroup);
    setOpen(() => {
      return nameGroup === data.title;
    });
  }, [nameGroup]);
  return (
    <>
      <ListItem
        button
        onClick={handleClick}
        className={classes.bodyDrawer}
        style={{
          backgroundColor: checkPageAt(data) && open ? "#FFFFFF" : "",
          borderRadius: "16px 16px 0px 0px",
          color: checkPageAt(data) ? "#51B2DA" : "#FFFFFF",
          marginTop: 15,
        }}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <div
            style={{ color: checkPageAt(data) && open ? "#51B2DA" : "#FFFFFF" }}
          >
            {data.icon}
          </div>
        </ListItemIcon>
        <ListItemText
          className={classes.listItemText}
          primary={data.title}
          style={{ color: checkPageAt(data) && open ? "#51B2DA" : "#FFFFFF" }}
        />
        {open ? <ExpandLess /> : <ExpandMore style={{ color: "white" }} />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {data.listSubGroup.map((item: any, index: number) => {
            return (
              <ListItem
                button
                key={index}
                className={
                  checkPageAt(data)
                    ? classes.bodyDrawerHover
                    : classes.bodyDrawerNotHover
                }
                style={{
                  borderRadius:
                    index === data.listSubGroup.length - 1
                      ? "0px 0px 16px 16px"
                      : "0px 0px 0px 0px",
                }}
              >
                <ListItemIcon
                  style={{
                    marginLeft: 10,
                    minWidth: 40,
                    color: checkPageAt(data) ? "#51B2DA" : "#FFFFFF",
                    position: "relative",
                  }}
                >
                  {checkPageAt(data) && item.id === pageAt ? (
                    <FiberManualRecordIcon />
                  ) : (
                    <FiberManualRecordOutlinedIcon />
                  )}
                  {data.listSubGroup.length - 1 > index && (
                    <div
                      style={{
                        position: "absolute",
                        width: 3,
                        left: 10,
                        top: 20,
                        height: item.height,
                        backgroundColor: checkPageAt(data)
                          ? "#51B2DA"
                          : "#FFFFFF",
                        zIndex: 1,
                      }}
                    ></div>
                  )}
                </ListItemIcon>
                <ListItemText
                  onClick={() => {
                    history.push({
                      pathname: item.path,
                    });
                  }}
                  className={classes.subText}
                  style={{
                    color: checkPageAt(data) ? "#51B2DA" : "#FFFFFF",
                  }}
                  primary={item.title}
                />
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    </>
  );
};
const ItemDataRender = ({ item, history, pageAt, selectTabsColor }: any) => {
  const { setGroupDrawerAuth } = useAuth();
  const classes = useStyles();
  return (
    <>
      <ListItem
        style={{
          backgroundColor: pageAt === item?.id ? "#FFFFFF" : "",
          marginTop: 15,
        }}
        className={clsx(classes.ListItem && classes.bodyDrawer)}
        button
        onClick={(e) => {
          history.push({
            pathname: item?.path,
          });
          setGroupDrawerAuth("");
        }}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <div>{item?.icon}</div>
        </ListItemIcon>
        <ListItemText
          className={classes.listItemText}
          style={{
            color: pageAt === item?.id ? selectTabsColor : "",
          }}
          primary={item?.title}
        />
      </ListItem>
    </>
  );
};
export const Index = (props: Props) => {
  const { pageAt, history, mobileOpen, handleDrawerToggle } = props;
  const { currentUser, groupDrawerAuth } = useAuth();
  const [ListAll, setListAll] = useState<any>([]);
  const [nameGroup, setNameGroup] = useState<string | undefined>(
    groupDrawerAuth
  );

  const classes = useStyles();
  let selectTabsColor = "#51B2DA";
  useEffect(() => {
    let option: Array<any> = [];
    const dashboard: UnionDrawer = {
      title: "Dashboard",
      path: "/dashboard",
      id: "dashboard",
      icon: (
        <HomeIcon
          style={{
            color: pageAt === "dashboard" ? selectTabsColor : "#FFFFFF",
          }}
        />
      ),
    };
    option.push(dashboard);
    if (checkPagePermission(currentUser?.roles, "USER") || checkPagePermission(currentUser?.roles, "USERAPPROVED")) {
      let usersManagement: any = {
        title: "User Management",
        icon: <DashboardIcon />,
        listSubGroup: getSubgroupUser(currentUser?.roles),
      };
      option.push(usersManagement);
    }
    if (checkPagePermission(currentUser?.roles, "CLASSROOM")) {
      const classroom: UnionDrawer = {
        title: "Class Management",
        path: "/classroom-management",
        id: "classManagement",
        icon: (
          <ClassIcon
            style={{
              color: pageAt === "classManagement" ? selectTabsColor : "#FFFFFF",
            }}
          />
        ),
      };
      option.push(classroom);
    }
    if (checkPagePermission(currentUser?.roles, "CLASSROOMSTUDENT")) {
      let usersManagement: any = {
        title: "Class Management",
        icon: <DashboardIcon />,
        listSubGroup: [
          {
            title: "Assigned Class",
            id: "assignedClass",
            path: "/assign-classroom",
            height: 32,
          },
          {
            title: "Attended Class History",
            id: "attendClassHistory",
            path: "/attend-classroom",
          },
        ],
      };
      option.push(usersManagement);
    }
    if (checkPagePermission(currentUser?.roles, "SCENARIO")) {
      let usersManagement: any = {
        title: "Scene Management",
        icon: <MessageIcon />,
        listSubGroup: [
          {
            title: "List of Scenario",
            id: "listOfScenario",
            path: "/list-of-scenario",
            height: 32,
          },
          {
            title: "List of Patient",
            id: "listOfPatient",
            path: "/list-of-patient",
          },
        ],
      };
      option.push(usersManagement);
    }
    if (checkPagePermission(currentUser?.roles, "FAQ")) {
      let faq: any = {
        title: "FAQ",
        icon: <ChatIcon />,
        listSubGroup: [
          {
            title: "RVRT Installation Tutorial",
            id: "rvrtInstallationTutorial",
            path: "/install-tutorial",
            height: 32,
          },
          {
            title: "Dashboard Manual",
            id: "dashboardManual",
            path: "/dashboard-manual",
          },
        ],
      };
      option.push(faq);
    }
    setListAll(option);
  }, [currentUser?.roles, groupDrawerAuth]);
  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <>
            <Avatar
              style={{
                width: 108,
                height: 108,
                margin: "0 auto",
                marginTop: 80,
              }}
              src={convertStringToImg(currentUser)}
            ></Avatar>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 10,
                alignItems:'center'
              }}
            >
              <div
                style={{
                  color: "white",
                  fontSize: 14,
                  fontWeight: 700,
                }}
              >
                {currentUser?.username}
              </div>
              <IconButton style={{ width: 25, height: 25 }} onClick={()=>history.push("/profile-me")}>
                <EditOutlinedIcon
                  fontSize="small"
                  style={{
                    color: "white",
                    marginLeft: 15,
                    width: 15,
                    height: 15,
                    margin: "0 auto",
                  }}
                />
              </IconButton>
            </div>
            <div
              style={{
                color: "white",
                margin: "0 auto",
                marginTop: 10,
                fontSize: 14,
                fontWeight: 300,
              }}
            >
              {capitalizeFirstLetter(currentUser?.roles[0])}
            </div>
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              className={classes.listRoot}
            >
              {ListAll.map((item: any, index: number) =>
                item?.listSubGroup?.length > 0 ? (
                  <CollapseDataRender
                    key={index}
                    data={item}
                    history={history}
                    pageAt={pageAt}
                    nameGroup={nameGroup}
                    setNameGroup={setNameGroup}
                    selectTabsColor={selectTabsColor}
                  />
                ) : (
                  <ItemDataRender
                    key={index}
                    item={item}
                    history={history}
                    pageAt={pageAt}
                    selectTabsColor={selectTabsColor}
                  />
                )
              )}
            </List>
          </>
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <>
            <Avatar
              style={{
                width: 108,
                height: 108,
                margin: "0 auto",
                marginTop: 80,
              }}
              src={convertStringToImg(currentUser)}
            ></Avatar>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 10,
                alignItems:'center'
              }}
            >
              <div
                style={{
                  color: "white",
                  fontSize: 14,
                  fontWeight: 700,
                }}
              >
                {currentUser?.username}
              </div>
              <IconButton style={{ width: 25, height: 25 }} onClick={()=>history.push("/profile-me")}>
                <EditOutlinedIcon
                  fontSize="small"
                  style={{
                    color: "white",
                    marginLeft: 15,
                    width: 15,
                    height: 15,
                    margin: "0 auto",
                  }}
                />
              </IconButton>
            </div>
            <div
              style={{
                color: "white",
                margin: "0 auto",
                marginTop: 10,
                fontSize: 14,
                fontWeight: 300,
              }}
            >
              {capitalizeFirstLetter(currentUser?.roles[0])}
            </div>
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              className={classes.listRoot}
            >
              {ListAll.map((item: any, index: number) =>
                item?.listSubGroup?.length > 0 ? (
                  <CollapseDataRender
                    key={index}
                    data={item}
                    history={history}
                    pageAt={pageAt}
                    nameGroup={nameGroup}
                    setNameGroup={setNameGroup}
                    selectTabsColor={selectTabsColor}
                  />
                ) : (
                  <ItemDataRender
                    key={index}
                    item={item}
                    history={history}
                    pageAt={pageAt}
                    selectTabsColor={selectTabsColor}
                  />
                )
              )}
            </List>
            <div style={{ position: "relative",minHeight:'100px', width:"200px" }}>
              <img
                src={Logo_Rama}
                alt="rama"
                style={{ position: "absolute", height: 50, bottom: 20, left: 20 }}
              />
              <img
                src={Logo_Ever}
                alt="ever"
                style={{ position: "absolute", height: 50, left: 106, bottom: 20 }}
              />
            </div>
          </>
        </Drawer>
      </Hidden>
    </nav>
    // <>
    //   <Drawer className={classes.drawer} variant="persistent" anchor="left" open={mobileOpen}>
    //     <div
    //       className={classes.drawerDiv}
    //       // style={{ borderRadius: "20px 20px 0px 0px" }}
    //     >
    //       <div
    //         className={classes.toolbar}
    //         style={{
    //           backgroundColor: "#51B2DA",
    //           borderRadius: "20px 20px 0px 0px",
    //         }}
    //       ></div>
    //       <List
    //         component="nav"
    //         aria-labelledby="nested-list-subheader"
    //         className={classes.listRoot}
    //       >
    //         {ListAll.map((item: any, index: number) =>
    //           item && item?.listSubGroup && item?.listSubGroup.length > 0 ? (
    //             <CollapseDataRender
    //               key={index}
    //               data={item}
    //               history={history}
    //               pageAt={pageAt}
    //               nameGroup={nameGroup}
    //               setNameGroup={setNameGroup}
    //               selectTabsColor={selectTabsColor}
    //             />
    //           ) : (
    //             <ItemDataRender
    //               key={index}
    //               item={item}
    //               history={history}
    //               pageAt={pageAt}
    //               selectTabsColor={selectTabsColor}
    //             />
    //           )
    //         )}
    //       </List>
    //     </div>
    //   </Drawer>
    // </>
  );
};

export default Index;
