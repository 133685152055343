import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import React, { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  fetchClassroomsMeApiPagination,
  fetchLogTriagePatient,
  fetctDisplayResultTriage,
} from "../../../../services/Classrooms/classroom.service";
import Layout from "../../../commons/Layout";
import { handleIconResult, IClassRoom } from "../Classroom";
import Table from "./Table";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import _ from "lodash";
import AlertDialog from "../../../commons/AlertDialog";
import { useFormik } from "formik";
import dayjs from "dayjs";
import Preview from "../Classroom/Dialog/Preview";
import PatientsDetail from "../Classroom/Dialog/PatientsDetails";
import Certificate from "../Classroom/Dialog/Certificate";
import ExportClassResultTest from "../Classroom/Dialog/ExportClassResult";
import { ButtonText } from "../../../commons/Buttons";
import { useStyles } from "../Classroom/styles";
import {
  initialPagination,
  IPaginationData,
} from "../../../commons/Pagination";
import { Pagination } from "@material-ui/lab";
import { ISrot } from "../../UserManagement/User";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../../../contexts/AuthContext";
import UserDetails from '../Classroom/Dialog/UserDetails';
import { ReactComponent as DownloadIcon } from "../../../commons/asset/downloadWhite.svg";

import { REACT_APP_API } from '../../../../config/config';

const action = [
  { display: "History Details", color: "green", code: "HISTORYDETAILS" },
];
const AttendClassroom = () => {
  const { t } = useTranslation();
  const location: any = useLocation();
  const { currentUser } = useAuth();
  const classes = useStyles();
  const [classrooms, setClassrooms] = useState<IClassRoom[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectData, setSelectData] = useState<any>({});
  const [open, setOpen] = useState<boolean>(false);
  const [state, setState] = useState("");
  const [dataLogTriagePatients, setDataLogTriagePatients] = useState<any>();
  const [acessLocation, setAccessLocation] = useState<Boolean>(false);
  const [selectDataUser, setSelectDataUser] = useState<any>({});
  const [pagination, setPagination] = useState<IPaginationData>(
    initialPagination
  );
  const [sortValue, setSortValue] = useState<ISrot>({
    sortByDate: "desc",
    filterByMode: "",
  });
  const formik = useFormik({
    initialValues: !_.isEmpty(selectData)
      ? {
          ...selectData,
          date: dayjs(selectData.classDate).format("YYYY-MM-DD"),
          time: dayjs(selectData.classDate).format("HH:mm"),
        }
      : {
          scenarios: [],
          certificate: "",
          date: "",
          time: "",
          classId: "",
          instructors: [],
          students: [],
          email: "",
        },
    enableReinitialize: true,
    onSubmit: (values) => {
      // handleOpenRecheck();
    },
  });
  function fetchClassrooms() {
    fetchClassroomsMeApiPagination({
      page: pagination.page - 1,
      limit: 10,
      status: "FINISHED",
      sortByDate: sortValue.sortByDate,
      mode: sortValue.filterByMode,
    }).then((res) => {
      if (res && res.data && res.data.result) {
        let mappedData = res.data.result.map((item: any) => {
          return { ...item, action };
        });
        setClassrooms(mappedData);
        setPagination({
          ...pagination,
          totalPages: res.data.pageAmount,
        });
        setLoading(false);
      }
    });
  }
  function changeState(state: string, data: any) {
    if (data && data.hasOwnProperty("classId")) {
      setSelectData(data);
    } else {
      setSelectDataUser(data);
    }
    setState(state);
    if (state === "HISTORYDETAILS") {
      handleOpen();
    }
  }
  function linkUrlCertificate(): string {
    return `${REACT_APP_API}/v1/pdf/read-pdf-certificate/${formik.values._id}/${currentUser?.userId}`;
  }
  function linkUrlClassroom(): string {
    return `${REACT_APP_API}/v1/pdf/read-pdf-result-triage-classroom/${formik.values._id}`;
  }
  function handleBtnDownload(): Array<string> | undefined {
    if (state === "CERTIFICATE") {
      return ["Download Certificate", linkUrlCertificate()];
    } else if (state === "EXPORTCLASSRESULT") {
      return ["Download Class Result", linkUrlClassroom()];
    }
  }
  function handleSubTitle(): Array<String> | string {
    if (state === "PATIENTDETAILS" || state === "HISTORYDETAILS") {
      return `Class ID : ${selectData?.classId}`;
    } else if (state === "EXPORTCLASSRESULT" || state === "CERTIFICATE") {
      return "Date  22/11/2021 01.00 PM ";
    } else {
      return "";
    }
  }
  function handleOpen() {
    setOpen(true);
  }
  useEffect(() => {
    fetchClassrooms();
  }, [pagination.page, sortValue]);
  function handleClose() {
    setOpen(false);
  }
  useEffect(() => {
    if (location.state && !loading && !acessLocation) {
      setSelectData(location.state.classroom);

      setState("HISTORYDETAILS");
      setAccessLocation(true);
      if (selectData) {
        handleOpen();
      }
    }
  }, [loading, formik.values]);
  
  function linkUrlClassroomByUser(): string {
    return `${REACT_APP_API}/v1/pdf/read-pdf-result-triage-user/${formik.values._id}/${selectDataUser._id}`;
  }
  function handleDialogAction(): ReactNode {
    return (
      <DialogActions style={{ padding: "10px 24px 10px 0px" }}>
        {state === "USERDETAILS" ? (
          <div style={{ justifyContent: "space-between" }}>
            <ButtonText
              onClick={() => changeState("HISTORYDETAILS", null)}
              style={{ marginRight: 20 }}
              colorText="blue"
            >
              Back
            </ButtonText>
            <Button style={{  backgroundColor: "#60D0A1", borderRadius: "40px", textTransform: "none", color: "white", padding: "8px 20px" }}
                href={linkUrlClassroomByUser()}
                target="_blink"
              >
                <DownloadIcon style={{ marginRight: 10 }} /> Export Class Result
              </Button>
          </div>
        ) : (
          state !== "HISTORYDETAILS" && (
            <ButtonText onClick={() => changeState("HISTORYDETAILS", null)}  style={{ marginRight: 20 }} colorText="blue">
              Back
            </ButtonText>
          )
        )}      
        </DialogActions>
    );
  }
  function handlePagination(event: React.ChangeEvent<unknown>, value: number) {
    setPagination({ ...pagination, page: value });
  }
  function handleDialogContent(state: string) {
    const components: any = {
      HISTORYDETAILS: (
        <Preview
          formik={formik}
          changeState={changeState}
          dataLogTriagePatients={dataLogTriagePatients}
        />
      ),
      PATIENTDETAILS: (
        <PatientsDetail
          formik={formik}
          dataLogTriagePatients={dataLogTriagePatients}
        />
      ),
      EXPORTCLASSRESULT: (
        <ExportClassResultTest
          formik={formik}
          dataLogTriagePatients={dataLogTriagePatients}
        />
      ),
      USERDETAILS: <UserDetails selectDataUser={selectDataUser} formik={formik} dataLogTriagePatients={dataLogTriagePatients} />,
      CERTIFICATE: <Certificate formik={formik} />,
    };
    const Component = components[state];
    return Component;
  }
  useEffect(() => {
    if (formik.values.status === "FINISHED") {
      fetctDisplayResultTriage(formik.values._id).then((res) => {
        setDataLogTriagePatients(res.data.result);
      });
    }
  }, [formik.values]);
  
  return (
    <Layout
      pageAt={"attendClassHistory"}
      render={(props: any) => (
        <div className={`${classes.root}`}>
          <Box display="flex" alignItems="center">
            <Typography
              style={{
                color: "#5A5A5B",
                fontWeight: 700,
                fontSize: 32,
                marginRight: 10,
              }}
            >
              Attended Class History
            </Typography>
          </Box>
          <Table
            changeState={changeState}
            classrooms={classrooms}
            loading={loading}
            sortValue={sortValue}
            setSortValue={setSortValue}
          />
          <div style={{ width: "100%", height: 50, backgroundColor: "white" }}>
            <Pagination
              count={pagination.totalPages}
              page={pagination.page}
              size="small"
              onChange={handlePagination}
              style={{
                padding: 10,
                position: "absolute",
                right: 0,
              }}
            />
          </div>
          <AlertDialog
            open={open}
            onClose={handleClose}
            handleClick={handleClose}
            title={
              state === "HISTORYDETAILS"
                ? `Overview`
                : state === "PATIENTDETAILS"
                ? `Patients Details Record`
                : state === "USERDETAILS" ? "" : `Class ID : ${selectData.classId}`
            }
            subTitle={handleSubTitle()}
            contentBtn={handleBtnDownload()}
            status={state === "MOREDETAILS" ? formik.values.status : ""}
          >
            <DialogContent>{handleDialogContent(state)}</DialogContent>
            {handleDialogAction()}
          </AlertDialog>
        </div>
      )}
    />
  );
};

export default AttendClassroom;
