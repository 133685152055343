import { Box, Typography } from '@material-ui/core';
import React from "react";
import { useHistory } from "react-router-dom";
import Layout from '../../../commons/Layout';
import { useStyles } from '../style';
import Dashboard from  '../asset/DashboardMaunal.png';
import Manual2 from "../asset/StudentManual.png";
import Manual from "../asset/Admin-manual.png";

import { useAuth } from '../../../../contexts/AuthContext';

const DashBoardManual = (props: any) => {
  const history = useHistory();
  const { currentUser } = useAuth();

  const classes = useStyles();
  return (
    <Layout
      pageAt={"dashboardManual"}
      render={(props: any) => (
        <div className={`${classes.root}`}>
          <Box display="flex" alignItems="center">
            <Typography
              style={{
                color: "#5A5A5B",
                fontWeight: 700,
                fontSize: 32,
                marginRight: 10,
              }}
            >
              Dashboard Manual
            </Typography>
          </Box>
        <div style={{display:'flex',justifyContent:'center'}}>
        <img src={currentUser?.roles[0] === "STUDENT" ? Manual2 : Manual} alt="" width={800}/>

        </div>
        </div>
      )}
    />
  );
};

export default DashBoardManual;
