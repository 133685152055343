import { Box, IconButton, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import Layout from "../../../commons/Layout";
import { useStyles } from "../style";
import Icon1 from "../asset/Icon1.png";
import Icon2 from "../asset/Icon2.png";
import Icon3 from "../asset/Icon3.png";
import Icon4 from "../asset/Icon4.png";
import Icon5 from "../asset/Icon5.png";
import Icon6 from "../asset/Icon6.png";
import Artboard from "../asset/Artboard.png";
import OculusDetail from "../asset/OculusDetail.png";

const InstallTutorial = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Layout
      pageAt={"rvrtInstallationTutorial"}
      render={(props: any) => (
        <div className={`${classes.root}`}>
          <Box display="flex" alignItems="center">
            <Typography
              style={{
                color: "#5A5A5B",
                fontWeight: 700,
                fontSize: 32,
                marginRight: 10,
              }}
            >
              Attended Class History
            </Typography>
          </Box>
          <Box>
            <Box display="flex" alignItems="center">
              <IconButton>
                <img src={Icon1} alt="" />
              </IconButton>
              <div>Register Oculus Developer account</div>
            </Box>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/WTn88X99L1U"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </Box>

          <Box display="flex" alignItems="center">
            <IconButton>
              <img src={Icon2} alt="" />
            </IconButton>
            <div>Register an account on RVRT Dashboard</div>
          </Box>
          <Box display="flex" alignItems="center">
            <IconButton>
              <img src={Icon3} alt="" />
            </IconButton>
            <div>
              When the registration is complete, wait for the admin to comfirm
              the activation of the account
            </div>
          </Box>
          <Box>
            <Box display="flex" alignItems="center">
              <IconButton>
                <img src={Icon4} alt="" />
              </IconButton>
              <div>
                Accept an email sent from Oculus titled ‘Release channel
                offering from MetaverseEver on Oculus’
              </div>
            </Box>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img src={OculusDetail} alt="" />
            </div>
          </Box>
          <Box>
            <Box display="flex" alignItems="center">
              <IconButton>
                <img src={Icon5} alt="" />
              </IconButton>
              <div>
                <div>
                  The application will be displayed on the app page on the VR
                  headset. If not found, restart the device or if still not
                  found, contact admin.
                </div>
              </div>
            </Box>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img src={Artboard} alt="" />
            </div>
          </Box>

          <Box display="flex" alignItems="center">
            <IconButton>
              <img src={Icon6} alt="" />
            </IconButton>
            <div>
              Users can Download and Install an app called ‘Ramathibodi Virtual
              Reality Triage’ to use.
            </div>
          </Box>
        </div>
      )}
    />
  );
};

export default InstallTutorial;
