import React from "react";
import { styled, Theme, withTheme } from "@material-ui/core/styles";
import { TextField, TextFieldProps } from "@material-ui/core";

interface TextFieldsCustomProps {
  theme: Theme;
}

export const TextFieldComponent = styled(
  (props: TextFieldsCustomProps & TextFieldProps) => <TextField {...props} />
)({
  borderColor: ({ theme }: TextFieldsCustomProps) =>
    theme.palette.secondary.main,
  height: 94,
});

export const TextFieldSecondary = withTheme(TextFieldComponent);
