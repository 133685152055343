import {
  Box,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import dayjs from "dayjs";
import { useFormik } from "formik";
import _ from "lodash";
import React, { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import {
  fetchClassroomMeApi,
  fetchClassroomsApi,
  fetchClassroomsMeApiPagination,
} from "../../../../services/Classrooms/classroom.service";
import AlertDialog from "../../../commons/AlertDialog";
import { ButtonContained, ButtonText } from "../../../commons/Buttons";
import Layout from "../../../commons/Layout";
import {
  initialPagination,
  IPaginationData,
} from "../../../commons/Pagination";
import { ISrot } from "../../UserManagement/User";
import { handleIconResult, IClassRoom } from "../Classroom";
import Preview from "../Classroom/Dialog/Preview";
import UserDetails from "../Classroom/Dialog/UserDetails";
import { useStyles } from "../Classroom/styles";
import Table from "./Table";
const action = [
  { display: "More Details", color: "green", code: "MOREDETAILS" },
];
const AttendClassroom = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location: any = useLocation();
  const [classrooms, setClassrooms] = useState<IClassRoom[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectData, setSelectData] = useState<any>({});
  const [state, setState] = useState("");
  const [acessLocation, setAccessLocation] = useState<Boolean>(false);
  const [open, setOpen] = useState(false);
  const [selectDataUser, setSelectDataUser] = useState<any>({});
  const [pagination, setPagination] = useState<IPaginationData>(
    initialPagination
  );
  const [sortValue, setSortValue] = useState<ISrot>({
    sortByDate: "desc",
    filterByMode: "",
  });

  const formik = useFormik({
    initialValues: !_.isEmpty(selectData)
      ? {
          ...selectData,
          date: dayjs(selectData.classDate).format("YYYY-MM-DD"),
          time: dayjs(selectData.classDate).format("HH:mm"),
        }
      : {
          scenarios: [],
          certificate: "",
          date: "",
          time: "",
          classId: "",
          instructors: [],
          students: [],
          email: "",
        },
    enableReinitialize: true,
    onSubmit: (values) => {},
  });
  function fetchClassrooms() {
    fetchClassroomsMeApiPagination({
      page: pagination.page - 1,
      limit: 10,
      status: ["PENDING", "IN_PROGRESS"],
      sortByDate: sortValue.sortByDate,
      mode: sortValue.filterByMode,
    }).then((res) => {
      if (res && res.data && res.data.result) {
        let mappedData = res.data.result.map((item: any) => {
          return { ...item, action };
        });
        setClassrooms(mappedData);
        setPagination({
          ...pagination,
          totalPages: res.data.pageAmount,
        });
        setLoading(false);
      }
    });
  }
  function changeState(state: string, data: any) {
    if (data && data.hasOwnProperty("classId")) {
      setSelectData(data);
    } else {
      setSelectDataUser(data);
    }
    setState(state);
    if (state === "DELETE") {
      // setConfirmPopup(TextConfirmPopup[state]);
      // handleOpenRecheck();
    } else if (state === "MOREDETAILS") {
      handleOpen();
    } else if (state === "CREATE") {
      // setConfirmPopup(TextConfirmPopup[state]);
      // handleOpen();
    }
  }
  function handleDialogContent(state: string) {
    const components: any = {
      MOREDETAILS: <Preview formik={formik} changeState={changeState} />,
      USERDETAILS: (
        <UserDetails selectDataUser={selectDataUser} formik={formik} />
      ),
    };
    const Component = components[state];
    return Component;
  }
  function handleDialogAction(): ReactNode {
    return (
      <DialogActions>
        {state === "MOREDETAILS" ? (
          ""
        ) : (
          <ButtonText
            onClick={() => changeState("MOREDETAILS", null)}
            style={{ marginRight: 20 }}
            colorText="blue"
          >
            Back
          </ButtonText>
        )}
      </DialogActions>
    );
  }
  function handleOpen() {
    setOpen(true);
  }
  useEffect(() => {
    fetchClassrooms();
  }, [pagination.page, sortValue]);
  function handleClose() {
    setOpen(false);
    // formik.resetForm();
    // setSelectData({});
  }
  function handlePagination(event: React.ChangeEvent<unknown>, value: number) {
    setPagination({ ...pagination, page: value });
  }
  useEffect(() => {
    if (location.state && !loading && !acessLocation) {
      setSelectData(location.state.classroom);
      setState("MOREDETAILS");
      setAccessLocation(true);
      if (selectData) {
        handleOpen();
      }
    }
  }, [loading, formik.values]);
  
  return (
    <Layout
      pageAt={"assignedClass"}
      render={(props: any) => (
        <div className={`${classes.root}`}>
          <Box display="flex" alignItems="center">
            <Typography
              style={{
                color: "#5A5A5B",
                fontWeight: 700,
                fontSize: 32,
                marginRight: 10,
              }}
            >
              Assigned Class
            </Typography>
          </Box>
          <Table
            changeState={changeState}
            classrooms={classrooms}
            loading={loading}
            sortValue={sortValue}
            setSortValue={setSortValue}
          />
          <div style={{ width: "100%", height: 50, backgroundColor: "white" }}>
            <Pagination
              count={pagination.totalPages}
              page={pagination.page}
              size="small"
              onChange={handlePagination}
              style={{
                padding: 10,
                position: "absolute",
                right: 0,
              }}
            />
          </div>
          <AlertDialog
            open={open}
            onClose={handleClose}
            handleClick={handleClose}
            title={
              state === "CREATE" || state === "UPDATE"
                ? state === "CREATE"
                  ? "Create Class"
                  : "Edit Class"
                : state === "PATIENTDETAILS"
                ? `Patient Details Record`
                : state === "USERDETAILS"
                ? ""
                : `Class ID : ${selectData?.classId}`
            }
            // subTitle={handleSubTitle()}
            status={state === "MOREDETAILS" ? formik.values.status : ""}
          >
            <DialogContent>{handleDialogContent(state)}</DialogContent>
            {handleDialogAction()}
          </AlertDialog>
        </div>
      )}
    />
  );
};

export default AttendClassroom;
