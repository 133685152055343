import { ButtonProps } from "@material-ui/core";
import { BtnContainedAction } from "./styles";
interface BtnCustomProps {
  colorText: string;
}
const ButtonContainedAction = ({ colorText, ...props }: ButtonProps & BtnCustomProps) => {
  return (
    <BtnContainedAction customcolor={colorText} variant="contained" {...props} />
  );
};

export default ButtonContainedAction;
