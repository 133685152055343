import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import _ from "lodash";
import {
  SelectField,
  TextFieldAutocomplete,
  TextFieldCustom,
} from "../../../../commons/Inputs";
import BoyIcon from "../asset/boy.svg";
import Avatar from "@material-ui/core/Avatar";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CloseIcon from "@material-ui/icons/Close";
import { useStyles } from "../style";
import { fetchUserAllApi } from "../../../../../services/Classrooms/classroom.service";
import { Autocomplete } from "@material-ui/lab";
import { convertStringToImg } from "../../../../commons/ConvertImg";
interface IProps {
  formik: any;
  state: string | undefined;
}
const CreateGroup = ({ formik, state }: IProps) => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:960px)");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [instructor, setInstructor] = useState<any[]>([]);
  const [student, setStudent] = useState<any[]>([]);
  const inputMembers = useRef<HTMLElement>(null);
  const [selectedMembers, setSelectedMembers] = useState<string>("");
  useEffect(() => {
    fetchUserAllApi().then((res) => {
      if (res && res.data && res.data.result) {
        const tempInstructor = _.chain(res.data.result)
          .filter((x) => _.indexOf(x.userRoles, "INSTRUCTOR") !== -1)
          .value();
        const tempStudent = _.chain(res.data.result)
          .filter((x) => _.indexOf(x.userRoles, "STUDENT") !== -1)
          .value();
        setInstructor(tempInstructor);
        setStudent(tempStudent);
      }
    });
  }, []);
  useEffect(() => {
    if (
      selectedMembers &&
      inputMembers.current &&
      inputMembers.current.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
      )[0]
    ) {
      const el = inputMembers.current.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
      )[0] as HTMLElement;
      el.click();
      setSelectedMembers("");
    }
  }, [selectedMembers]);
  function addData(data: any) {
    let tempData: any = data;
    let temp: any = [];
    temp.push(...formik.values["members"]);
    temp.push(tempData);
    formik.setFieldValue("members", temp);
  }
  function deleteData(index: number) {
    let temp: any = [];
    temp.push(...formik.values["members"]);
    temp.splice(index, 1);
    formik.setFieldValue("members", temp);
  }
  function handleClose() {
    setAnchorEl(null);
  }
  function handleRole(role: string) {
    let tempArry = [role];
    formik.setFieldValue("groupRoles", tempArry);
    setAnchorEl(null);
  }
  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={2}>
          Group name        
        </Grid>
        <Grid item lg={6} xl={6} md={6} sm={9} xs={9}>
          <TextFieldCustom
            size="small"
            variant="outlined"
            name="groupName"
            onChange={formik.handleChange}
            value={formik.values.groupName}
            disabled={state === "PREVIEW"}
            placeholder={'Group Name'}
            error={
              formik.touched.groupName && formik.errors.groupName ? true : false
            }
            textAlert={
              formik.errors.groupName &&
              formik.touched.groupName &&
              formik.errors.groupName
            }
          />
        </Grid>
        {matches && <Grid item xs={4}></Grid>}
        <Grid item xs={2}>
          {(state === "CREATE" || state === "UPDATE") && (
            <>
              {formik.values.groupRoles}
              {formik.values.members.length === 0 && (
                <IconButton onClick={handleClick} style={{ padding: 0 }}>
                  <KeyboardArrowDownIcon fontSize="small" />
                </IconButton>
              )}
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                getContentAnchorEl={null}
                disableAutoFocusItem
                onClose={handleClose}
                PaperProps={{
                  style: {
                    left: "50%",
                    transform: "translateX(-70%) translateY(30%)",
                  },
                }}
              >
                <MenuItem onClick={() => handleRole("Instructor")}>
                  Instructor
                </MenuItem>
                <MenuItem onClick={() => handleRole("Student")}>
                  Student
                </MenuItem>
              </Menu>
            </>
          )}
        </Grid>
        <Grid item lg={6} xl={6} md={6} sm={9} xs={9}>
          {(state === "CREATE" || state === "UPDATE") && (
            <Autocomplete
              // freeSolo
              // disabled={state === "PREVIEW"}
              ref={inputMembers}
              options={
                formik.values.groupRoles[0] === "Instructor"
                  ? instructor
                  : student
              }
              getOptionDisabled={(option) =>
                !!formik.values.members.find(
                  (useData: any) => useData._id === option._id
                )
              }
              getOptionSelected={(option, value) => option?._id === value?._id}
              getOptionLabel={(option) =>
                `${option.firstName} ${option.lastName}` || ""
              }
              renderOption={(option) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Avatar src={convertStringToImg(option)}></Avatar>
                  <div style={{ marginLeft: 20 }}></div>
                  {option.firstName} {option.lastName}
                </div>
              )}
              disableCloseOnSelect
              onChange={(value, newValue) => {
                if (newValue) {
                  addData(newValue);
                  setSelectedMembers("members");
                }
              }}
              renderInput={(params) => (
                <TextFieldAutocomplete
                  {...params}
                  error={
                    formik.touched.members && formik.errors.members
                      ? true
                      : false
                  }
                  textAlert={
                    formik.errors.members &&
                    formik.touched.members &&
                    formik.errors.members
                  }
                  size="small"
                  variant="outlined"
                />
              )}
            />
          )}
        </Grid>
        {matches && <Grid item xs={4}></Grid>}
        <Grid item xs={2}></Grid>
        <Grid lg={6} xl={6} md={6} sm={9} xs={9}>
          <Paper
            style={{
              width: "100%",
              height: 245,
              overflowY: "auto",
              marginTop: 2,
            }}
          >
            {formik.values.members.map((item: any, index: number) => {
              return (
                <div key={index} style={{ padding: "13px 20px" }}>
                  <Box display="flex" justifyContent="space-between">
                    <Box display="flex" alignItems="center">
                      <Avatar src={convertStringToImg(item)}></Avatar>
                      <Box style={{ marginLeft: 20 }}>
                        <Typography
                          style={{
                            color: "#5A5A5A",
                            fontWeight: 700,
                            fontSize: "14px",
                          }}
                        >
                          {item.firstName} {item.lastName}
                        </Typography>
                        <Typography
                          style={{
                            color: "#9C9B9D",
                            fontWeight: 400,
                            fontSize: "12px",
                          }}
                        >
                          {item.email}
                        </Typography>
                      </Box>
                    </Box>
                    {state !== "PREVIEW" && (
                      <IconButton
                        className={classes.iconBtn}
                        onClick={() => deleteData(index)}
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </Box>
                </div>
              );
            })}
          </Paper>
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>
    </>
  );
};
export default CreateGroup;
