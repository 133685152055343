import styled, { css } from "styled-components";
import { Theme, withStyles } from "@material-ui/core/styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
interface IChipStyle {
  type: string;
  theme: Theme;
  $toCapitalize?: boolean;
}
export const ChipStatusesStyle = styled.div<IChipStyle>`
  //   justify-content: center;
  font-size: 12px;
  font-weight: 400;
  border-radius: 4px;
  padding: 5px 5px;
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  width: 110px;
  color: #5a5a5a;

  ${({ theme, type }) =>
    type === "PENDING" &&
    css`
      background: #fef0d6;
    `}

  ${({ theme, type }) =>
    type === "FINISHED" &&
    css`
      background: #DCF0F8;
    `}
    ${({ theme, type }) =>
    type === "IN_PROGRESS" &&
    css`
      background: #DFF6EC;
    `}
  ${({ theme, type }) =>
    type === "error" &&
    css`
      color: ${theme.palette.error.main};
      border-color: ${theme.palette.error.light};
      background: ${theme.palette.error2[100]};
    `}

  ${({ theme, type }) =>
    type === "primary" &&
    css`
      color: ${theme.palette.primary.main};
      border-color: ${theme.palette.primary.light};
      background: ${theme.palette.primary2[100]};
    `}

  ${({ $toCapitalize }) =>
    $toCapitalize &&
    css`
      text-transform: capitalize;
    `}
`;

export const CricleTest = styled.div<IChipStyle>`
  border-radius: 30px;
  width: 10px;
  max-height:10px;
  ${({ theme, type }) =>
    type === "PENDING" &&
    css`
      background: #F9B233;
    `}

  ${({ theme, type }) =>
    type === "FINISHED" &&
    css`
      background: #51B2DA;
    `}
    ${({ theme, type }) =>
    type === "IN_PROGRESS" &&
    css`
      background: #60D0A1;
    `}
`;
