import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import Layout from "../../../commons/Layout";
import Table from "./Table";
import CreateGroup from "./Dialog/ManagementGroup";
import { useFormik } from "formik";

import {
  addGroup,
  deactivateGroup,
  fetchGroupApi,
  fetchGroupApiPagination,
  updateGroup,
} from "../../../../services/Group/group.service";
import { ButtonContained, ButtonText } from "../../../commons/Buttons";
import { useStyles } from "./style";
import { useSnackbar } from "notistack";
import { ITextConfirmDetail } from "../../ProfileManagement/Profile";
import RecheckDialog from "../../../commons/RecheckDialog";
import AlertDialog from "../../../commons/AlertDialog";
import { Pagination } from "@material-ui/lab";
import {
  initialPagination,
  IPaginationData,
} from "../../../commons/Pagination";
import { ISrot } from "../User";
export interface IGroup {
  groupid?: string;
  _id?: string;
  groupName?: string;
  groupRoles?: Array<string>;
  members: Array<string>;
}
const action = [
  { display: "More Information", color: "green", code: "PREVIEW" },
  { display: "Delete Group", color: "red", code: "DELETE" },
];
interface ITextConfirm {
  UPDATE?: ITextConfirmDetail;
  CREATE?: ITextConfirmDetail;
  DELETE?: ITextConfirmDetail;
  QUITEDITING?: ITextConfirmDetail;
}
const TextConfirmPopup: ITextConfirm = {
  UPDATE: {
    title: "Edit this group?",
    subTitle: "Please check information, you can edit more later.",
    yes: "Yes",
    no: "No",
    color: "blue",
  },
  CREATE: {
    title: "Create this group?",
    subTitle: "Please check information, you can edit more later.",
    yes: "Yes",
    no: "No",
    color: "blue",
  },
  DELETE: {
    title: "Delete this group?",
    subTitle: "This action cannot be rolled back.",
    yes: "Delete",
    no: "Cancel",
    color: "red",
  },
  QUITEDITING: {
    title: "Quit editing?",
    subTitle: "Changes you made so far will not be saved.",
    yes: "Yes",
    no: "No",
    color: "red",
  },
};
const ManagementGroup = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState<boolean>(false);
  const [groupAll, setGroupAll] = useState<any>();
  const [forceUpdateData, setForceUpdateData] = useState<number>(0);
  const [selectedData, setSelectedData] = useState<IGroup | undefined>();
  const [confirmPopup, setConfirmPopup] = useState<ITextConfirmDetail>();
  const [reCheckOnExit, setRecheckOnExit] = useState(false) 
  const [openRecheck, setOpenRecheck] = useState(false);
  const [state, setState] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState<IPaginationData>(
    initialPagination
  );
  const [sortValue, setSortValue] = useState<ISrot>({
    sortByDate: "desc",
    filterByRole: "",
  });
  const classes = useStyles();
  const validationSchema = Yup.object().shape({
    groupName: Yup.string().required("Groupname is required"),
    members: Yup.array().min(1).required("fieldData.fieldRequire"),
  });
  const formik = useFormik({
    initialValues: selectedData
      ? {
          groupName: selectedData?.groupName,
          groupRoles: selectedData?.groupRoles,
          members: selectedData?.members,
          groupId: selectedData?._id,
        }
      : {
          groupName: "",
          groupRoles: ["Instructor"],
          members: [],
        },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      changeState(state, undefined);
      handleOpenRecheck();
    },
  });
  function fetchGroup() {
    fetchGroupApiPagination({
      page: pagination.page - 1,
      limit: 10,
      filterByRole: sortValue.filterByRole,
      sortByDate: sortValue.sortByDate,
    }).then((res) => {
      let mappedData = res?.data?.result.map((item: any) => {
        return { ...item, action };
      });
      setPagination({ ...pagination, totalPages: res?.data?.pageAmount });
      setGroupAll(mappedData);
      setLoading(false);
    });
  }
  function handleCloseRecheck() {
    setOpenRecheck(false);
  }
  function handleOpenRecheck() {
    setOpenRecheck(true);
  }
  function handleClose() {
    if(state === "UPDATE"){
      changeState('PREVIEW', selectedData)
    }
    else {
      formik.resetForm();
      setOpen(false);
      setSelectedData(undefined);
    }
  }
  function handleOpen() {
    setOpen(true);
  }
  function resetValue() {
    setRecheckOnExit(false);
    handleClose();
    handleCloseRecheck();
  }
  function changeState(state: string, data: IGroup | undefined) {
    setState(state);
    if (state === "DELETE") {
      setSelectedData(data);
      setConfirmPopup(TextConfirmPopup[state]);
      handleOpenRecheck();
    } else if (state === "PREVIEW") {
      setSelectedData(data);
      handleOpen();
    } else if (state === "CREATE") {
      setConfirmPopup(TextConfirmPopup[state]);
      handleOpen();
    } else if (state === "UPDATE") {
      setConfirmPopup(TextConfirmPopup[state]);
    }
    setRecheckOnExit(false);
  }
  function createGroupManagement() {
    let listMemberId = formik.values?.members.map((item: any) => item._id);
    const data: IGroup = {
      groupName: formik.values.groupName,
      groupRoles: formik.values.groupRoles,
      members: listMemberId,
    };
    addGroup(data)
      .then((res) => {
        setForceUpdateData((prev: any) => prev + 1);
        if (res?.data) {
          enqueueSnackbar("Added Group Success", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Added Group Error", {
            variant: "error",
          });
        }
      })
      .catch(() => {});
  }
  function updateGroupManagement() {
    let listMemberId = formik.values?.members.map((item: any) => item._id);
    let data: IGroup = {
      groupid: formik.values.groupId,
      groupRoles: formik.values.groupRoles,
      members: listMemberId,
    };
    if (selectedData?.groupName !== formik.values.groupName) {
      data = { ...data, groupName: formik.values.groupName };
    }
    updateGroup(data)
      .then((res) => {
        setForceUpdateData((prev: any) => prev + 1);
        if (res?.data) {
          enqueueSnackbar("Updated Group Success", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Updated Group Error", {
            variant: "error",
          });
        }
      })
      .catch(() => {});
  }
  function deleteGroupManagement() {
    const data: { groupid: string | undefined } = {
      groupid: formik.values.groupId,
    };
    deactivateGroup(data)
      .then((res) => {
        if (res?.data) {
          enqueueSnackbar("Delete Group Success", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Delete Group Error", {
            variant: "error",
          });
        }
        setForceUpdateData((prev: any) => prev + 1);
      })
      .catch(() => {
        enqueueSnackbar("Delete Group Error", {
          variant: "error",
        });
      });
  }
  function handleSubmit() {
    if(reCheckOnExit){
      handleClose();
      formik.setFieldValue('members',selectedData?.members)
    }
    else if (state === "DELETE") {
      deleteGroupManagement();
    } else if (state === "UPDATE") {
      updateGroupManagement();
    } else if (state === "CREATE") {
      createGroupManagement();
    }
    resetValue();
  }
  function handlePagination(event: React.ChangeEvent<unknown>, value: number) {
    setPagination({ ...pagination, page: value });
  }
  function handleRecheckOnExit() {
    if(state === "CREATE"){
      if(formik.values.groupName || formik.values.members.length > 0){
        setConfirmPopup(TextConfirmPopup["QUITEDITING"]);
        setRecheckOnExit(true);
        handleOpenRecheck();   
      }
      else {
        handleClose();
      }
    }
    else if(state === "UPDATE"){
      const members = selectedData?.members.map((ele:any)=>ele._id);
      const membersUpdated = formik.values?.members.map((ele:any)=>ele._id);
      const haveMember = membersUpdated?.every((ele)=> members?.includes(ele));

      if(formik.values.groupName !== selectedData?.groupName || !haveMember || formik.values.members.length === 0 || members?.length !== membersUpdated?.length) {
        setConfirmPopup(TextConfirmPopup["QUITEDITING"]);
        setRecheckOnExit(true);
        handleOpenRecheck();   
      }
      else {
        handleClose();
      }
    }
    else{
      handleClose();
    }
  }
  useEffect(() => {
    fetchGroup();
  }, [forceUpdateData, pagination.page, sortValue]);
  
  return (
    <Layout
      pageAt={"groupManagement"}
      render={(props: any) => (
        <div className={`${classes.root}`}>
          <Box display="flex" alignItems="center">
            <Typography
              style={{
                color: "#5A5A5B",
                fontWeight: 700,
                fontSize: 32,
                marginRight: 10,
              }}
            >
              Group Management
            </Typography>
            <div className={classes.btnAdd}>
              <Button
                onClick={() => changeState("CREATE", undefined)}
                startIcon={<AddCircleRoundedIcon />}
              >
                Create Group
              </Button>
            </div>
          </Box>
          <AlertDialog
            open={open}
            onClose={handleRecheckOnExit}
            handleClick={handleRecheckOnExit}
            style={{ zIndex: 1250 }}
            title={"Create Group"}
          >
            <DialogContent>
              <CreateGroup formik={formik} state={state} />
            </DialogContent>
            <DialogActions style={{ padding: "10px 24px 10px 0px" }}>
              {state === "CREATE" ? (
                <>
                  <ButtonText colorText="blue" onClick={handleRecheckOnExit}>
                    Cancel
                  </ButtonText>
                  <ButtonContained
                    onClick={() => formik.handleSubmit()}
                    colorText="blue"
                  >
                    Create
                  </ButtonContained>
                </>
              ) : state === "UPDATE" ? (
                <>
                  <ButtonText colorText="blue" onClick={handleRecheckOnExit}>
                    Cancel
                  </ButtonText>
                  <ButtonContained
                    onClick={() => formik.handleSubmit()}
                    colorText="blue"
                  >
                    Save
                  </ButtonContained>
                </>
              ) : (
                <ButtonContained
                  onClick={() => changeState("UPDATE", undefined)}
                  colorText="blue"
                >
                  Edit
                </ButtonContained>
              )}
            </DialogActions>
          </AlertDialog>
          <Table
            groupAll={groupAll}
            changeState={changeState}
            loading={loading}
            sortValue={sortValue}
            setSortValue={setSortValue}
          />
          <div style={{ width: "100%", height: 50, backgroundColor: "white" }}>
            <Pagination
              count={pagination.totalPages}
              page={pagination.page}
              size="small"
              onChange={handlePagination}
              style={{
                padding: 10,
                position: "absolute",
                right: 0,
              }}
            />
          </div>
          {/* Dialog Recheck */}
          <RecheckDialog
            open={openRecheck}
            handleClose={handleCloseRecheck}
            confirmNo={handleCloseRecheck}
            confirmYes={handleSubmit}
            confirmPopup={confirmPopup}
          />
        </div>
      )}
    />
  );
};

export default ManagementGroup;
