import {
  FormControl,
  FormLabel,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { ButtonContained } from "../../commons/Buttons";
import { useHistory, useParams } from "react-router-dom";
import {
  checkConfirmEmail,
  vefifyEmail,
} from "../../../services/Users/users.servies";
import logo_ever from "../../../images/pages/loginPage/logo_ever.png";
import logo_rama from "../../../images/pages/loginPage/logo_rama.png";
import { ReactComponent as ArrowRight } from "../../../images/pages/loginPage/arrowRight.svg";
import imgConfirm from "../../commons/asset/imgConfirm.png";
import LogoLogin from "../../../images/pages/loginPage/vector_login.png";
import { useSnackbar } from "notistack";
import React, { ReactNode, useEffect, useState } from "react";
import { useStyles } from "./style";
import Logo from "../../commons/asset/Logo 2.png";

interface IConfirmEmail {
  firstName: string;
  lastName: string;
  email: string;
  confirmEmail: boolean;
}

const VerifyAccount = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();
  const [data, setData] = useState<IConfirmEmail>();
  const [state, setState] = useState<React.ReactElement>();
  const { enqueueSnackbar } = useSnackbar();
  const matches = useMediaQuery("(min-width:1200px)");
  const verifyEmail = () => {
    vefifyEmail(id)
      .then((res) => {
        if (res.status === 200) {
          enqueueSnackbar("Confirm Success", {
            variant: "success",
          });
          setState(stateEnd());
        } else {
          enqueueSnackbar("Confirm Error", {
            variant: "error",
          });
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    setState(stateConfirm());
    checkCheckConfirmEmai();
  }, []);

  const checkCheckConfirmEmai = () => {
    checkConfirmEmail(id).then((res) => {
      if (res?.data?.result) {
        setData(res.data.result);
        if (res.data.result.confirmEmail) {
          setState(stateEnd());
        }
      }
    });
  };
  const stateConfirm = () => {
    return (
      <div>
        <img src={Logo} alt="" />
        <Typography
          style={{
            fontSize: 31,
            color: "#5A5A5B",
            fontWeight: 700,
            marginTop: 26,
          }}
        >
          Confirm your email address to get started on RVRT
        </Typography>
        {/* <img style={{ margin: "70px 0px" }} src={seeYou} alt="" /> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 46,
          }}
        >
          <div style={{ color: "rgba(90, 90, 90, 1)", fontSize: 12 }}>
            You’ve created a RVRT account with{" "}
            <span style={{ color: "rgba(81, 178, 218, 1)" }}>
              {data?.email}
            </span>
            . Please take a moment to confirm that we can use this address to
            send you emails.
          </div>
          <div></div>
          <ButtonContained
            colorText="blue"
            onClick={verifyEmail}
            style={{ marginLeft: 20, width: 250, marginTop: 36 }}
            // onClick={() => changeState("UPDATE")}
          >
            Confirm your email address
          </ButtonContained>
          <div
            style={{
              color: "rgba(90, 90, 90, 1)",
              fontSize: 12,
              marginTop: 46,
            }}
          >
            If you didn’t request this email, there’s nothing to worry about -
            you can safely ignore it.
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 140,
          }}
        >
          <div style={{ color: "#9C9B9D", fontSize: 12 }}>
            Contact : Mail@admin.com
          </div>
          <div style={{ color: "#5A5A5A", fontSize: 12 }}>
            <div>RVRT admin Team</div>
          </div>
        </div>
      </div>
    );
  };
  const stateEnd = () => {
    return (
      <div>
        <Typography
          style={{
            fontSize: 31,
            color: "#5A5A5B",
            fontWeight: 700,
            marginTop: 26,
          }}
        >
          Almost done!
        </Typography>
        {/* <img style={{ margin: "70px 0px" }} src={seeYou} alt="" /> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 46,
          }}
        >
          <div style={{ color: "rgba(90, 90, 90, 1)", fontSize: 18 }}>
            Please wait for confirmation from admin. We will reply to you via
            registered email within 1-3 days
          </div>
          <img src={imgConfirm} alt="" style={{ marginTop: 60 }} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 140,
          }}
        >
          <div style={{ color: "#9C9B9D", fontSize: 12 }}>
            Contact : Mail@admin.com
          </div>
          <div style={{ color: "#5A5A5A", fontSize: 12 }}>
            <div>
              <a
                style={{
                  fontSize: 14,
                  color: "#51B2DA",
                  marginTop: 48,
                }}
                href="#"
                onClick={() =>
                  history.push({
                    pathname: "/login",
                  })
                }
              >
                Go back to login
              </a>
              <ArrowRight style={{ marginLeft: 5 }} />
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className={classes.mainContainer}>
      <div className={classes.bgPaper}>
        {matches && <img src={LogoLogin} className={classes.imageRight} />}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <Grid container alignItems="center" style={{ marginBottom: 50 }}>
            <Grid xs={2}></Grid>
            <Grid item xs={8}>
              <div className={classes.container}>{state}</div>
            </Grid>
            <Grid xs={2}></Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default VerifyAccount;
