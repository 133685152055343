import { Button, Grid, IconButton, MenuItem } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import {
  IScenario,
  map_Bus_hits_Electric_pole,
  map_Chemical_Factory_Explosion,
  stateName,
} from ".";
import {
  createScenarios,
  deactivateScenarios,
  updateScenarios,
} from "../../../../services/Scenarios/scenario.service";
import { ButtonContained, ButtonOutlined } from "../../../commons/Buttons";
import { SelectFieldScenarios, TextFieldCustom } from "../../../commons/Inputs";
import { TextFieldStyleSerch } from "../../../commons/Inputs/style";
import CardPatient, { CustomDragLayer, IPatientMap } from "./cardPatient";
import { useStyles } from "./style";
import { PatientPositionPoint } from "./patientPositionPoint";
import { PointToTrash } from "./pointToTrash";
import Bus_hits_Electric_pole from "../assetMock/Map-1.png";
import Bus_accident from "../assetMock/Map-2.png";
import { DropToClear } from "./dropToClear";
import { useSnackbar } from "notistack";
import generator from "generate-password";
import Compass from "./asset/compass.png";
import { getPatientsApi } from "../../../../services/Patient/patient.service";
import HarmFactor from "../assetMock/HarmFactor.png";
import HarmFactorActive from '../assetMock/HarmFactorActive.png'
interface IProp {
  changeState: (state: stateName) => void;
  selectedScenario: any;
  state: string;
  positionPoint: any;
  setPositionPoint: any;
  page: string;
  handleOpenRecheck: (callback: () => void) => void;
  setPage: React.Dispatch<React.SetStateAction<string>>;
  setForceUpdateData: React.Dispatch<React.SetStateAction<number>>;
  setSelectedScenario: any;
  CalculateAxisXPatient: (axisX: number) => number;
  CalculateAxisYPatient: (axisY: number) => number;
  setHarmFactors: React.Dispatch<React.SetStateAction<any>>;
  harmFactors: any;
  setConfirmPopup: any;
  TextConfirmPopup: any;
  setRecheckOnExit: any;
  setOpenRecheck: React.Dispatch<React.SetStateAction<boolean>>;
}
export interface IPatients {
  _id: string;
  gender: string;
  code: string;
  desc: string;
  caseColor: string;
  age: string;
  pose: string;
  patientMaps?: string;
}
export interface IPosition {
  index: number;
  caseColor: string;
  data: any;
}
export interface IFilter {
  color: string;
  gender: string;
  age: string;
  pose: string;
}
export function handleCaseColor(color: string) {
  if (color === "RED") {
    return "#F15F5F";
  } else if (color === "YELLOW") {
    return "#F9B233";
  } else if (color === "GREEN") {
    return "#60D0A1";
  } else if (color === "BLACK") {
    return "#787878";
  } else {
    return "BLACK";
  }
}
export default function CreateScenario({
  changeState,
  selectedScenario,
  state,
  positionPoint,
  setPositionPoint,
  handleOpenRecheck,
  page,
  setPage,
  setForceUpdateData,
  setSelectedScenario,
  CalculateAxisXPatient,
  CalculateAxisYPatient,
  setHarmFactors,
  harmFactors,
  setConfirmPopup,
  TextConfirmPopup,
  setRecheckOnExit,
  setOpenRecheck
}: IProp) {
  const { enqueueSnackbar } = useSnackbar();
  const [patients, setPatients] = useState<Array<IPatients>>([]);
  const [isDrag, setIsDrag] = useState<boolean>(false);
  const [isDragTrash, setIsDragTrash] = useState<boolean>(false);
  const [transitionNum, setTransitionNum] = useState<number>(() => {
    if (selectedScenario?.direction === "EAST") {
      return 0;
    } else if (selectedScenario?.direction === "WEST") {
      return 180;
    } else {
      return 0;
    }
  });
  const [directionState, setDirectionState] = useState<string>(() => {
    if (selectedScenario?.direction === "EAST") {
      return "right";
    } else if (selectedScenario?.direction === "WEST") {
      return "left";
    } else {
      return "right";
    }
  });
  const [filter, setFilter] = useState<IFilter>({
    color: "",
    gender: "",
    age: "",
    pose: "",
  });
  const classes = useStyles();
  const formik = useFormik({
    initialValues:
      selectedScenario && state === "UPDATE"
        ? {
            id: selectedScenario._id,
            sceneName: selectedScenario.code,
            desc: selectedScenario.desc,
            patientsCount: selectedScenario.patientMaps?.length
              ? selectedScenario.patientMaps?.length
              : 0,
            public: selectedScenario.accessLevel === "PUBLIC" ? true : false,
            practice: selectedScenario.practice,
            patients: selectedScenario.patientMaps.map((item: any) => {
              return { _id: item.patient?._id };
            }),
            direction: selectedScenario?.direction,
          }
        : {
            sceneName: generateNameScene(),
            desc: "Bus hits Electric pole",
            patientsCount: 0,
            public: false,
            practice: false,
            patients: [],
            direction: "EAST",
          },
    onSubmit: (values) => {},
  });
  useEffect(() => {
    getPatientsApi({
      caseColor: filter.color,
      gender: filter.gender,
      pose: filter.pose,
      age: filter.age,
    }).then((res) => {
      if (res?.data?.result) setPatients(res.data.result);
    });
  }, [filter]);
  function convertPositionPointToPatientMaps(
    positionPoint: IPosition[]
  ): IPosition[] {
    let temp: IPosition[] = [];
    positionPoint.forEach((item: any, index: number) => {
      if (item && item.data && item.data.data && item.data.data._id) {
        temp.push(item);
      }
    });
    return temp;
  }
  function createScenario() {
    const data: Array<IScenario> = [
      {
        code: formik.values.sceneName,
        desc: formik.values.desc,
        mapCode: "MAP000",
        patientMaps: convertPositionPointToPatientMaps(positionPoint).map(
          (item: IPosition) => {
            return { index: item.index, patient: item.data?.data?._id };
          }
        ),
        harmFactors: harmFactors,
        obstacles: [],
        accessLevel: formik.values.public ? "PUBLIC" : "PRIVATE",
        practice: formik.values.practice,
        direction: formik.values.direction,
      },
    ];
    createScenarios(data)
      .then((res) => {
        enqueueSnackbar("Created Success", {
          variant: "success",
        });
        setForceUpdateData((prev: any) => prev + 1);
        changeState("SELECT");
      })
      .catch(() => {
        enqueueSnackbar("Created Error", {
          variant: "error",
        });
      });
  }
  function updateScenario() {
    const data = {
      scenarioid: formik.values.id,
      code: formik.values.sceneName,
      desc: formik.values.desc,
      mapCode: "MAP000",
      patientMaps: convertPositionPointToPatientMaps(positionPoint).map(
        (item: IPosition) => {
          return { index: item.index, patient: item.data.data?._id };
        }
      ),
      harmFactors: harmFactors,
      obstacles: [],
      accessLevel: formik.values.public ? "PUBLIC" : "PRIVATE",
      practice: formik.values.practice,
      direction: formik.values.direction,
    };
    updateScenarios(data)
      .then(() => {
        enqueueSnackbar("Updated Success", {
          variant: "success",
        });
        setForceUpdateData((prev: any) => prev + 1);
        changeState("SELECT");
      })
      .catch(() => {
        enqueueSnackbar("Updated Error", {
          variant: "error",
        });
      });
  }
  function deactivateScenario() {
    deactivateScenarios(formik.values.id)
      .then((res) => {
        if(res.data.status === "Success"){
          enqueueSnackbar("Deleted Success", {
            variant: "success",
          });
          setForceUpdateData((prev: any) => prev + 1);
          changeState("SELECT");
        }
        else{
          enqueueSnackbar("Deleted Error", {
            variant: "error",
          });
          changeState("UPDATE");
        }
      })
      .catch(() => {
        enqueueSnackbar("Deleted Error", {
          variant: "error",
        });
        changeState("UPDATE");
      });
  }
  function generateNameScene(): string {
    const name = generator.generate({
      length: 5,
      lowercase: false,
      uppercase: true,
      numbers: true,
      symbols: false,
    });
    return `SC${name}`;
  }
  function handleRotateDirection(text: string) {
    if (directionState !== text) {
      setDirectionState(text);
      setTransitionNum(transitionNum + 180);
      formik.setFieldValue("direction", text === "left" ? "WEST" : "EAST");
    }
  }
  function handleRecheckOnExit() {
    if(state === "CREATE") {
      if(formik.values.patients.length > 0 || harmFactors.length > 0 ||  formik.values.practice || formik.values.public) {
        setConfirmPopup(TextConfirmPopup["QUITEDITING"]);
        setRecheckOnExit(true);
        setOpenRecheck(true);
      }
      else {
        changeState("SELECT");
      }
    }
    else if(state === "UPDATE") {
      const patientMaps = selectedScenario.patientMaps.map((ele:any)=>ele.patient._id);
      const harmFactors = selectedScenario.harmFactors.map((ele:any)=>ele._id);

      const patientMapsNew = formik.values.patients.map((ele:any)=>ele.data.data._id);
      const harmFactorsNew = harmFactors.map((ele:any)=> ele);
  
      const havePatientMaps = patientMapsNew.every((ele:any) => patientMaps.includes(ele));
      const haveHarmFactors = harmFactorsNew.every((ele:any)=> harmFactors.includes(ele));

      const havePatientMaps2 = patientMaps.every((ele:any) => harmFactorsNew.includes(ele));
      const haveHarmFactors2 = harmFactors.every((ele:any)=> harmFactorsNew.includes(ele));

      if(formik.values.public !== (selectedScenario.accessLevel === "PUBLIC" ? true : false) || formik.values.practice !== selectedScenario.practice || !havePatientMaps || !havePatientMaps2 || patientMaps.length !== patientMapsNew.length || !haveHarmFactors || !havePatientMaps2 || harmFactors.length !== harmFactorsNew.length) {
        setConfirmPopup(TextConfirmPopup["QUITEDITING"]);
        setRecheckOnExit(true);
        setOpenRecheck(true);
      }
      else {
        changeState("SELECT");
      }
    }
  }
  useEffect(() => {
    const updatePositionPoint = positionPoint.filter((item: IPosition) => {
      if (item.caseColor) {
        return { item };
      }
    });
    if (updatePositionPoint.length > 0) {
      formik.setFieldValue("patients", updatePositionPoint);
      formik.setFieldValue("patientsCount", updatePositionPoint.length);
    }
  }, [positionPoint]);
  useEffect(() => {
    if (state === "CREATE") {
      setSelectedScenario({});
      formik.resetForm();
      positionPoint.forEach((item: any, index: number) => {
        positionPoint[index].caseColor = "";
        positionPoint[index].data = {};
      });
      setPositionPoint(positionPoint);
    }
  }, []);
  useEffect(() => {
    setPositionPoint(
      formik.values.desc === "Chemical Factory Explosion"
        ? map_Chemical_Factory_Explosion
        : map_Bus_hits_Electric_pole
    );
  }, [formik.values.desc]);

  return (
    <>
      <div style={{ display: "flex", marginTop: 13 }}>
        <div style={{ width: 340, zIndex: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={6} style={{ marginTop: -15 }}>
              <label htmlFor="" style={{ fontSize: 12 }}>
                Color
              </label>
              <SelectFieldScenarios
                style={{ height: "20px" }}
                onChange={(e) => {
                  let temp = { ...filter };
                  temp.color = e.target.value as string;
                  setFilter(temp);
                }}
                displayEmpty
                value={filter.color}
              >
                <MenuItem value={"GREEN"}>Green</MenuItem>
                <MenuItem value={"RED"}>Red</MenuItem>
                <MenuItem value={"BLACK"}>Black</MenuItem>
                <MenuItem value={"YELLOW"}>Yellow</MenuItem>
                <MenuItem value={""}>All</MenuItem>
              </SelectFieldScenarios>
            </Grid>
            <Grid item xs={6} style={{ marginTop: -15 }}>
              <label htmlFor="" style={{ fontSize: 12 }}>
                Gender
              </label>
              <SelectFieldScenarios
                style={{ height: "20px" }}
                onChange={(e) => {
                  let temp = { ...filter };
                  temp.gender = e.target.value as string;
                  setFilter(temp);
                }}
                displayEmpty
                value={filter.gender}
              >
                <MenuItem value={"M"}>Male</MenuItem>
                <MenuItem value={"F"}>Female</MenuItem>
                <MenuItem value={""}>All</MenuItem>
              </SelectFieldScenarios>
            </Grid>
            <Grid item xs={6} style={{ marginTop: -15 }}>
              <label htmlFor="" style={{ fontSize: 12 }}>
                Age
              </label>
              <SelectFieldScenarios
                style={{ height: "20px" }}
                displayEmpty
                onChange={(e) => {
                  let temp = { ...filter };
                  temp.age = e.target.value as string;
                  setFilter(temp);
                }}
                value={filter.age}
              >
                <MenuItem value={"Child"}>Child</MenuItem>
                <MenuItem value={"Teen"}>Teen</MenuItem>
                <MenuItem value={"Adult"}>Adult</MenuItem>
                <MenuItem value={"Elder"}>Elder</MenuItem>
                <MenuItem value={""}>All</MenuItem>
              </SelectFieldScenarios>
            </Grid>
            <Grid item xs={6} style={{ marginTop: -15 }}>
              <label htmlFor="" style={{ fontSize: 12 }}>
                Pose
              </label>
              <SelectFieldScenarios
                style={{ height: "20px" }}
                displayEmpty
                onChange={(e) => {
                  let temp = { ...filter };
                  temp.pose = e.target.value as string;
                  setFilter(temp);
                }}
                value={filter.pose}
              >
                <MenuItem value={"Sit"}>Sit</MenuItem>
                <MenuItem value={"Walking"}>Walking</MenuItem>
                <MenuItem value={"Lie down"}>Lie down</MenuItem>
                <MenuItem value={""}>All</MenuItem>
              </SelectFieldScenarios>
            </Grid>
          </Grid>
          <div
            className={classes.backgroundScenario}
            style={{ overflowY: "auto" }}
          >
            {patients.map((item: any, index) => {
              return (
                <div key={index}>
                  <CardPatient
                    key={index}
                    data={item}
                    name={item.code}
                    showEmptyPlaceholder={true}
                    setPositionPoint={setPositionPoint}
                    positionPoint={positionPoint}
                    setIsDrag={setIsDrag}
                  />
                </div>
              );
            })}
          </div>
          <div style={{ zIndex: 1 }}>
            <CustomDragLayer />
          </div>
        </div>
        <div style={{ marginLeft: 20 }}>
          <Grid container spacing={1}>
            <Grid item xs={2} style={{ marginTop: 3 }}>
              {formik.values.sceneName}
            </Grid>
            <Grid item xs={3}>
              <SelectFieldScenarios
                style={{ height: "10px", marginTop: 10 }}
                name="desc"
                onChange={formik.handleChange}
                value={formik.values.desc}
                displayEmpty
              >
                <MenuItem value={"Bus hits Electric pole"}>
                  Bus hits Electric pole
                </MenuItem>
                <MenuItem value={"Chemical Factory Explosion"}>
                  Chemical Factory Explosion
                </MenuItem>
              </SelectFieldScenarios>
            </Grid>
            <Grid item xs={1} style={{ marginBottom: 10 }}>
              <TextFieldStyleSerch
                size="small"
                variant="outlined"
                value={formik.values?.patientsCount}
                disabled
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                style={{
                  fontSize: 13,
                  border: formik.values.public ? "" : "0.5px solid #E4E4E4",
                  textTransform: "none",
                  height: 30,
                  padding: "4px 11px",
                  backgroundColor: formik.values.public ? "#E0F2F9" : "",
                  color: formik.values.public ? "#67BDE0" : "#5A5A5A",
                }}
                onClick={() =>
                  formik.setFieldValue("public", !formik.values.public)
                }
              >
                Public
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                style={{
                  fontSize: 10,
                  border: formik.values.practice ? "" : "0.5px solid #E4E4E4",
                  textTransform: "none",
                  height: 30,
                  padding: "4px 11px",
                  color: formik.values.practice ? "#67BDE0" : "#5A5A5A",
                  backgroundColor: formik.values.practice ? "#E0F2F9" : "",
                }}
                onClick={() =>
                  formik.setFieldValue("practice", !formik.values.practice)
                }
              >
                Available on Practice
              </Button>
            </Grid>
          </Grid>
          <div
            style={{
              height: 586,
              width: 1040,
              backgroundColor: "#F6FAFF",
              position: "relative",
            }}
          >
            <img
              src={
                formik.values.desc === "Chemical Factory Explosion"
                  ? Bus_accident
                  : Bus_hits_Electric_pole
              }
              height={586}
              style={{ position: "absolute" }}
            />
            <div>
              {positionPoint.map((item: any, index: number) =>
                item.caseColor ? (
                  <div key={index}>
                    {!isDrag && (
                      <div
                        style={{
                          left: CalculateAxisXPatient(item.x),
                          top: CalculateAxisYPatient(item.y),
                          position: "absolute",
                          zIndex: 3,
                        }}
                      >
                        <PointToTrash
                          data={item.data}
                          positionPoint={positionPoint}
                          setPositionPoint={setPositionPoint}
                          bgColor={handleCaseColor(item.caseColor)}
                          index={index}
                          setIsDragTrash={setIsDragTrash}
                        />
                      </div>
                    )}
                    <div
                      style={{
                        left: CalculateAxisXPatient(item.x),
                        top: CalculateAxisYPatient(item.y),
                        position: "absolute",
                        zIndex: 0.5,
                      }}
                    >
                      <PatientPositionPoint
                        index={index}
                        bgColor={handleCaseColor(item.caseColor)}
                      />
                    </div>
                  </div>
                ) : item.type === "HarmFactor" ? (
                  <IconButton
                    key={index}
                    style={{
                      left: CalculateAxisXPatient(item.x),
                      top: CalculateAxisYPatient(item.y),
                      position: "absolute",
                      zIndex: 0.5,
                      padding:0
                    }}
                    onClick={()=> {
                      if(harmFactors.some((ele:any)=> ele.index === item.index)){
                        const tmp = harmFactors.filter((ele:any)=> ele.index !== item.index);
                        setHarmFactors(tmp)
                      }
                      else{
                        setHarmFactors([...harmFactors,{index:item.index}])
                      }
                    }}
                  >
                    <img src={harmFactors.some((ele:any)=> ele.index === item.index) ? HarmFactorActive : HarmFactor} alt="" />
                  </IconButton>
                ) : (
                  <div style={{ position: "relative" }} key={index}>
                    <div
                      style={{
                        // left: `calc( ${item.left}px)`,
                        // top: `calc(${item.top}px)`,
                        left: CalculateAxisXPatient(item.x),
                        top: CalculateAxisYPatient(item.y),
                        position: "absolute",
                      }}
                    >
                      <PatientPositionPoint index={index} />
                    </div>
                  </div>
                )
              )}
              <div
                style={{
                  width: 162,
                  height: 110,
                  backgroundColor: "white",
                  position: "absolute",
                  borderRadius: 10,
                  bottom: 5,
                  left: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  style={{
                    backgroundColor:
                      directionState === "left"
                        ? "rgba(228, 228, 228, 1)"
                        : "rgba(245, 245, 247, 1)",
                    width: 30,
                    height: 30,
                    fontWeight: 700,
                    marginRight: 6,
                    fontSize: 15,
                  }}
                  onClick={() => {
                    handleRotateDirection("left");
                  }}
                >
                  W
                </IconButton>
                <img
                  src={Compass}
                  width="70px"
                  height="70px"
                  alt=""
                  style={{
                    transform: `rotate(${transitionNum}deg)`,
                    transition: "transform 500ms ease",
                  }}
                />
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  style={{
                    backgroundColor:
                      directionState === "right"
                        ? "rgba(228, 228, 228, 1)"
                        : "rgba(245, 245, 247, 1)",
                    width: 30,
                    height: 30,
                    fontWeight: 700,
                    marginLeft: 6,
                    fontSize: 15,
                  }}
                  onClick={() => {
                    handleRotateDirection("right");
                  }}
                >
                  E
                </IconButton>
              </div>
              <div style={{ position: "absolute", right: 20, top: 20 }}>
                {isDragTrash && <DropToClear name={"TEST"} isDrag={isDrag} />}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: page === "PAGE_CREATE" ? "end" : "space-between",
              marginTop: 10,
            }}
          >
            {page === "PAGE_UPDATE" && (
              <ButtonOutlined
                style={{ marginTop: 8, fontSize: 12 }}
                colorText="red"
                onClick={() => {
                  changeState("DELETE");
                  handleOpenRecheck(deactivateScenario);
                }}
              >
                Delete Scenario
              </ButtonOutlined>
            )}
            <div>
              <ButtonContained
                style={{ marginRight: 20 }}
                colorText="white"
                onClick={() => {
                  // changeState("SELECT");
                  handleRecheckOnExit();
                }}
              >
                Cancel
              </ButtonContained>
              <ButtonContained
                colorText="blue"
                onClick={() => {
                  changeState(page === "PAGE_CREATE" ? "CREATE" : "UPDATE");
                  handleOpenRecheck(
                    page === "PAGE_CREATE" ? createScenario : updateScenario
                  );
                }}
              >
                {page === "PAGE_CREATE" ? "Create" : "Save"}
              </ButtonContained>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
