import { Button, Grid, useMediaQuery } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import Layout from "../../commons/Layout";
import { useStyles } from "./style";
import Overview from "./Overview";
import Schedule from "./Schedule";
import Certificates from "./Certificates";
import Scenarios from "./Scenarios";
import Congratulation from "../../../images/pages/loginPage/vector_login.png";
import Welcome from "../../../images/pages/loginPage/FrameWelcome.png";
import { ReactComponent as ArrowRight } from "../../../images/pages/loginPage/arrowRight.svg";
import {
  fetchCertificateById,
  fetchClassroomMeApi,
  fetchClassroomsApi,
} from "../../../services/Classrooms/classroom.service";
import Dialog from "@material-ui/core/Dialog";
import { getScenario } from "../../../services/Scenarios/scenario.service";
import { handleFirstTimeService } from '../../../services/Users/users.servies';
export interface IUser {
  _id: string;
  approved: string;
  active: boolean;
  useRoles: Array<string>;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  gender: string;
}
export interface ICertificate {
  _id: string;
  classId: string;
  userId: string;
  certificateURL: string;
}
export default function Dashboard() {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:1260px)");
  const [user, setUsers] = useState<Array<IUser>>();
  const [certificates, setCerificates] = useState<any>();
  const [scenarios, setScenarios] = useState<any>();
  const [classrooms, setClassrooms] = useState<any>();
  const { currentUser } = useAuth();
  const [open, setOpen] = useState<boolean>(() => {
    if (currentUser?.firstTime) {
      return currentUser?.firstTime;
    } else {
      return false;
    }
  });

  const handleClose = () => {
    setOpen(false);
  };

  const filterByReference = (arr1: any, arr2: any) => {
    let res = [];
    res = arr1.filter((el: any) => {
      return !arr2.find((el2: any) => {
        return el2._id === el._id;
      });
    });
    return res;
  };
  useEffect(() => {
    let users: Array<IUser> = [];
    if (currentUser?.roles[0] === "ADMIN") {
      fetchClassroomsApi({ mode: "SIMULATION" }).then((res) => {
        if (res?.data?.result) {
          setClassrooms(res.data.result);
        }
      });
    } else {
      fetchClassroomMeApi().then((res) => {
        if (res?.data?.result) {
          setClassrooms(res.data.result);
          res.data.result.map((classroom: any, index: number) => {
            if (index === 0) {
              currentUser?.roles[0] === "STUDENT"
                ? users.push(...classroom.instructors)
                : users.push(...classroom.students);
            } else {
              users.push(
                ...filterByReference(
                  currentUser?.roles[0] === "STUDENT"
                    ? classroom.instructors
                    : classroom.students,
                  users
                )
              );
            }
          });
          const certificate = res.data.result.filter((classroom: any) => {
            return classroom.certificate && classroom.status === "FINISHED";
          });
          setCerificates(certificate);
          setUsers(users);
        }
      });
    }
    fetchScenarios();
  }, [currentUser?.userId]);
  const fetchScenarios = () => {
    getScenario().then((res) => {
      if (res?.data?.result) {
        setScenarios(res.data.result);
      }
    });
  };
  const handleFirstTime = () => {
    if(currentUser?.userId){
      handleFirstTimeService(currentUser?.userId).then((res) => {
        handleClose();
      });
    }
  }

  return (
    <Layout
      pageAt={"dashboard"}
      render={(props: any) => (
        <div className={classes.root}>
          <div
            style={{ fontSize: 30, fontWeight: 700, color: "#5A5A5B" }}
          >{`Hello,  ${currentUser?.firstName}`}</div>
          <div> Let’s get ready to next class.</div>
          <Grid container spacing={2} style={{ marginTop: 40 }}>
            <Grid item xs={12} lg={6}>
              <Overview user={user} />
              <div style={{ marginTop: "20px" }}>
                {currentUser?.roles[0] === "STUDENT" ? (
                  <Certificates
                    certificates={certificates}
                    userName={currentUser?.username}
                  />
                ) : (
                  <Scenarios scenarios={scenarios} />
                )}
              </div>
            </Grid>
            <Grid item lg={6} xs={12} style={{ height: "100%" }}>
              <Schedule classrooms={classrooms} />
            </Grid>
          </Grid>
          <Dialog
            open={open}
            aria-labelledby="form-dialog-title"
            PaperProps={{
              style: {
                backgroundColor: "transparent",
                maxWidth: 1180,
                borderRadius: 16,
              },
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
            {matches && <img src={Congratulation} alt="" width={500} />} 
              <div style={{ minWidth: 680,height: 670, backgroundColor: "white" }}>
                <div
                  style={{
                    fontSize: 45,
                    fontWeight: 700,
                    color: "rgba(90, 90, 91, 1)",
                    textAlign: "center",
                    marginTop: 99,
                  }}
                >
                  Welcome to RVRT.
                </div>
                <div
                  style={{
                    fontSize: 16,
                    color: "rgba(90, 90, 91, 1)",
                    width: 455,
                    margin: "0 auto",
                    marginTop: 20,
                  }}
                >
                  Please accept an email sent from Oculus titled
                </div>
                <div
                  style={{
                    fontSize: 16,
                    color: "rgba(90, 90, 91, 1)",
                    width: 455,
                    margin: "0 auto",
                  }}
                >
                  'Release channel offering from MetaverseEver on Oculus'
                </div>
                <div style={{ display: "flex" }}>
                  <img style={{ margin: "0 auto" }} src={Welcome} alt="" />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginTop: 60,
                  }}
                >
                  <div
                    style={{ color: "rgba(156, 155, 157, 1)", fontSize: 12 }}
                  >
                    Contact : Mail@admin.com
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {/* <div style={{color:'rgba(81, 178, 218, 1)',marginRight:5}}>Continue</div> */}
                    <a
                      style={{ fontSize: 14, color: "#51B2DA", marginRight: 5 }}
                      href="#"
                      onClick={handleFirstTime}
                    >
                      Continue
                    </a>
                    <ArrowRight />
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
        </div>
      )}
    />
  );
}
