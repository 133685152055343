import { Button, makeStyles, TextField } from "@material-ui/core";
import styled, { css } from "styled-components";
interface IBtnStyle {
  text: string;
}
interface IBtnColor {
  customColor: string;
}
export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white", 
    paddingLeft: 20,
    paddingRight: 0,
    // minHeight:"90vh",
    [theme.breakpoints.up("xl")]: {
      paddingRight: 130,
    },
  },
  autoComplete: {
    "& .MuiAutocomplete-inputRoot": {
      borderRadius: 16,
      padding: "20px 13px",

      "&:hover": {
        "&::before": {
          border: "none",
        },
      },
      "&::before": {
        border: "none",
      },
      "&::after": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        // borderColor: "none",
        // borderRadius: 10,
      },
    },
  },
  rootTable: {
    width: "100%",
    "& .MuiTableCell-root": {
      borderBottom: "5px solid #ffff",
    },
  },
  rootTablePDF: {
    width: "100%",
    "& .MuiTableCell-root": {
      padding: 2
      // borderBottom: "5px solid #ffff",
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    minHeight: "100vh",
    background: "#CCCCCC",
  },
  box: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  boxBlankHeader: {
    margin: "6vh 0 0 0",
  },
  boxContent: {
    margin: "10px calc(10vw + 20px)",
    fontSize: "30px",
    background: "#999999",
  },
  boxFooter: {
    margin: "0",
    padding: "10px",
    color: "white",
    flexGrow: 1,
    background: "red",
  },
  blueBorder: {
    border: "1px solid #33F",
    borderRadius: "10px",
  },
  btnAdd: {
    "& .MuiButton-root": {
      border: " 1.5px solid #51B2DA",
      color: "#51B2DA",
      backgroundColor: "white",
      textTransform: "none",
      padding: "3px 9px 3px 9px",
      fontWeight: 500,
    },
  },
  iconBtn: {
    "& .MuiSvgIcon-root": {
      width: 15,
      height: 15,
    },
  },
  test: {
    "& .recharts-layer.recharts-cartesian-axis-tick line": {
      display: 'none'
    }
  }
}));
