import {
  Box,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import React from "react";
import { useStyles } from "../style";
import ImgLock from "../../../UserManagement/User/asset/resetpw.png";
import { TextFieldCustom } from "../../../../commons/Inputs";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import HttpsIcon from "@material-ui/icons/Https";
interface IProps {
  formik: any;
}
const ResetPassword = ({ formik }: IProps) => {
  const classes = useStyles();
  function handleClickShowPassword() {
    formik.setFieldValue("showPassword", !formik.values.showPassword);
  }
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container>
        {/* <Grid
        item
        xs={12}
        // style={{ margin: edit ? "20px 0px" : "0px 0px 20px 0px" }}
      >
        <div style={{ fontSize: 25, fontWeight: "bold", color: "#5A5A5A" }}>
          New Password
        </div>
      </Grid> */}
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <img src={ImgLock} alt="" />
          </Box>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid
          item
          xs={2}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          Password
        </Grid>
        <Grid item xs={6}>
          <TextFieldCustom
            //   onKeyDown={(event) => {
            //     if (event.key === "Enter") {
            //       formik.submitForm();
            //     }
            //   }}
            //   error={
            //     formik.errors.password && formik.touched.password ? true : false
            //   }
            error={
              formik.errors.password && formik.touched.password ? true : false
            }
            textAlert={
              formik.errors.password &&
              formik.touched.password &&
              formik.errors.password
            }
            id="password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            style={{ width: "100%", maxWidth: 566 }}
            placeholder="Password"
            margin="normal"
            variant="outlined"
            type={formik.values.showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    size="small"
                    edge="end"
                  >
                    <div className={classes.colorIcon}>
                      {formik.values.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </div>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={1}></Grid>
        <Grid
          item
          xs={2}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          New Password
        </Grid>
        <Grid item xs={6}>
          <TextFieldCustom
            error={
              formik.errors.newPassword && formik.touched.newPassword
                ? true
                : false
            }
            textAlert={
              formik.errors.newPassword &&
              formik.touched.newPassword &&
              formik.errors.newPassword
            }
            name="newPassword"
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            style={{ width: "100%", maxWidth: 566 }}
            placeholder="New Password"
            margin="normal"
            variant="outlined"
            type={formik.values.showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    size="small"
                    edge="end"
                  >
                    <div className={classes.colorIcon}>
                      {formik.values.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </div>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={1}></Grid>
        <Grid
          item
          xs={2}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          Confirm Password
        </Grid>
        <Grid item xs={6}>
          <TextFieldCustom
            error={
              formik.errors.confirmPassword && formik.touched.confirmPassword
                ? true
                : false
            }
            textAlert={
              formik.errors.confirmPassword &&
              formik.touched.confirmPassword &&
              formik.errors.confirmPassword
            }
            id="confirmPassword"
            name="confirmPassword"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            style={{ width: "100%", maxWidth: 566 }}
            placeholder="Confirm Password"
            margin="normal"
            variant="outlined"
            type={formik.values.showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    size="small"
                    edge="end"
                  >
                    <div className={classes.colorIcon}>
                      {formik.values.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </div>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
    </form>
  );
};
export default ResetPassword;
