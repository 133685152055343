import React, { useEffect, useState } from "react";
import Layout from "../../../commons/Layout";
import { useStyles } from "../Classroom/styles";
import VrSpectator from "./VrSpectator";

export default function VrSpectatorManagement() {
  const classes = useStyles();

  return (
    <>
      <Layout
        pageAt={"classManagement"}
        render={(props: any) => (
          <div className={`${classes.root}`} style={{width:'100%',display:'flex',justifyContent:'center'}}>
            <VrSpectator />
          </div>
        )}
      />
    </>
  );
}
