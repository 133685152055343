import React, { useEffect, useContext, useState, createContext } from "react";
import {
  signInApi,
  signOutService,
  verifyMyTokenApi,
} from "../services/Management/Users/user.service.ts";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { NotificationDiv } from "../components/commons/Notification";
import {
  getMyAccountMenuPermission,
  IMenuPermission,
} from "../services/Management/Users/permission.service";
import { useLocation } from "react-router-dom";
export interface IUserAuthentication {
  haveTokenId: boolean;
  authenticated: boolean;
  username: string;
  roles: any;
  passwordAt?: string;
  _id?: string;
  imgBackendURL?: string;
  userId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  tel?: string;
  occupation?: string;
  organization?: string;
  gender?: string;
  firstTime?: boolean;
}
interface IContextProps {
  currentUser: IUserAuthentication;
  login: any;
  signUp: any;
  logOut: any;
  groupDrawerAuth: any;
  setGroupDrawerAuth: any;
  verifyMyUser: any;
}
const AuthContext = createContext<Partial<IContextProps>>({});
const tokenStorageAt = "tokenUser";

function setLocalStorage(key: string, value: string) {
  try {
    localStorage.setItem(key, value);
  } catch (e) {}
}
function getLocalStorage(key: string) {
  try {
    return localStorage.getItem(key);
  } catch (e) {}
}
function getTokenData() {
  try {
    const value = localStorage.getItem(tokenStorageAt);
    return {
      haveTokenId: value ? true : false,
      authenticated: value ? true : false,
      username: value ? "NEED LOAD" : "",
      roles: value ? "NEED LOAD" : "",
      userId: value ? "NEED LOAD" : "",
    };
  } catch (e) {}
}
export function useAuth() {
  return useContext(AuthContext);
}
export function AuthProvider({ children }: any) {
  const history = useHistory();
  const [groupDrawerAuth, setGroupDrawerAuth] = useState("");
  const [currentUser, setCurrentUser] = useState<
    IUserAuthentication | undefined
  >(getTokenData());
  const [loadingAccessToken, setLoadingAccessToken] = useState(true);
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  async function verifyMyUser() {
    verifyMyTokenApi(getLocalStorage(tokenStorageAt))
      .then((response) => {
        if (response.data && response.data.status === "Success") {
          setCurrentUser({
            haveTokenId: true,
            authenticated: true,
            username: `${response.data.result.firstName} ${response.data.result.lastName}`,
            roles: response.data.result.userRoles,
            firstTime: response.data.result.firstTime,
            imgBackendURL: response.data.result.imgBackendURL,
            userId: response.data.result._id,
            firstName: response.data.result.firstName,
            lastName: response.data.result.lastName,
            gender: response.data.result.gender,
            email: response.data.result.username,
            tel: response.data.result.tel,
            occupation: response.data.result.occupation
          });
          if (location.pathname === "/login") {
            history.push({
              pathname: "/dashboard",
            });
          }
          setTimeout(function () {
            setLoadingAccessToken(false);
          }, 1000);
        } else {
          setLoadingAccessToken(false);
          setCurrentUser({
            haveTokenId: false,
            authenticated: false,
            username: "",
            roles: "",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    if (currentUser?.haveTokenId) {
      verifyMyUser();
    } else {
      setTimeout(function () {
        setLoadingAccessToken(false);
      }, 1000);
    }
  }, [currentUser?.haveTokenId, currentUser?.authenticated]);
  function signUp(username: string, password: string) {
    setCurrentUser({
      haveTokenId: true,
      authenticated: true,
      username: "test",
      roles: "test",
    });
  }
  async function login(username: string, password: string) {
    let response = await signInApi({
      username: username,
      password: password,
    });
    if (response.data) {
      setLocalStorage(tokenStorageAt, response.data.token);
      setCurrentUser({
        haveTokenId: true,
        authenticated: true,
        username: response.data.username,
        roles: response.data.userRoles,
        userId: response.data._id,
      });
      return response;
    } else {
      return response.response;
    }
  }
  function logOut(type: string) {
    enqueueSnackbar(<NotificationDiv topic={"Sign Out"} />, {
      variant: "success",
    });
    localStorage.removeItem(tokenStorageAt);
    setGroupDrawerAuth("");
    setCurrentUser({
      haveTokenId: false,
      authenticated: false,
      username: "",
      roles: "",
      userId: "",
    });
    history.push({
      pathname: "/login",
      state: { detail: "LogoutSuccess" },
    });
  }

  const value = {
    currentUser,
    login,
    signUp,
    logOut,
    setGroupDrawerAuth,
    groupDrawerAuth,
    verifyMyUser
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
