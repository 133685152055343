import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Layout from "../../../commons/Layout";
import { useStyles } from "./style";
import SelectScenario from "./SelectScenario";
import CreateScenario, { IPosition } from "./CreateScenario";
import { getScenario } from "../../../../services/Scenarios/scenario.service";
import { ITextConfirmDetail } from "../../ProfileManagement/Profile";
import RecheckDialog from "../../../commons/RecheckDialog";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import { useLocation } from "react-router-dom";
import { useAuth } from '../../../../contexts/AuthContext';
import { checkPagePermission } from '../../../commons/CheckRole';
type Props = {};
export interface IScenario {
  accessLevel?: string;
  active?: boolean;
  code?: string;
  desc?: string;
  mapCode?: string;
  _id?: string;
  obstacles: Array<any>;
  patientMaps?: any;
  practice?: string;
  favBy?: any;
  direction?: string;
  harmFactors?:any;
}
interface ITextConfirm {
  DELETE?: ITextConfirmDetail;
  CREATE?: ITextConfirmDetail;
  UPDATE?: ITextConfirmDetail;
  DELETE_ALL?: ITextConfirmDetail;
  QUITEDITING?: ITextConfirmDetail;
}
export type stateName =
  | "SELECT"
  | "CREATE"
  | "UPDATE"
  | "DELETE"
  | "DELETE_ALL";

const TextConfirmPopup: ITextConfirm = {
  DELETE: {
    title: "Delete this scenario?",
    subTitle: "This action cannot be rolled back.",
    yes: "Delete",
    no: "Cancel",
    color: "red",
  },
  DELETE_ALL: {
    title: "Delete all scenarios?",
    subTitle: "This action cannot be rolled back.",
    yes: "Delete",
    no: "Cancel",
    color: "red",
  },
  CREATE: {
    title: "Create this Scenario?",
    subTitle: "Please check information, you can edit more later.",
    yes: "Yes",
    no: "No",
    color: "blue",
  },
  UPDATE: {
    title: "Edit this scenario?",
    subTitle: "Please check information, you can edit more later.",
    yes: "Yes",
    no: "No",
    color: "blue",
  },
  QUITEDITING: {
    title: "Quit editing?",
    subTitle: "Changes you made so far will not be saved.",
    yes: "Yes",
    no: "No",
    color: "red",
  },
};
export const map_Bus_hits_Electric_pole = [
  { index: 0, caseColor: "", data: {}, x: 0, y: 3 },
  { index: 1, caseColor: "", data: {}, x: 2, y: 4 },
  { index: 2, caseColor: "", data: {}, x: 0.5, y: 6 },
  { index: 3, caseColor: "", data: {}, x: 1, y: 9 },
  { index: 4, caseColor: "", data: {}, x: 4, y: 7 },
  { index: 5, caseColor: "", data: {}, x: 3.5, y: 10 },
  { index: 6, caseColor: "", data: {}, x: 5, y: 10.5 },
  { index: 7, caseColor: "", data: {}, x: 6, y: 9 },
  { index: 8, caseColor: "", data: {}, x: 6, y: 3 },
  { index: 9, caseColor: "", data: {}, x: 8.5, y: 5.5 },
  { index: 10, caseColor: "", data: {}, x: 9, y: 11 },
  { index: 11, caseColor: "", data: {}, x: 10, y: 9 },
  { index: 12, caseColor: "", data: {}, x: 10, y: 3 },
  { index: 13, caseColor: "", data: {}, x: 11, y: 5 },
  { index: 14, caseColor: "", data: {}, x: 12, y: 3 },
  { index: 15, caseColor: "", data: {}, x: 12, y: 7 },
  { index: 16, caseColor: "", data: {}, x: 12, y: 12 },
  { index: 17, caseColor: "", data: {}, x: 13.5, y: 10 },
  { index: 18, caseColor: "", data: {}, x: 15.5, y: 4 },
  { index: 19, caseColor: "", data: {}, x: 16, y: 7 },
  { index: 20, caseColor: "", data: {}, x: 15, y: 13 },
  { index: 21, caseColor: "", data: {}, x: 15, y: 8.5 },
  { index: 22, caseColor: "", data: {}, x: 17, y: 10 },
  { index: 23, caseColor: "", data: {}, x: 17, y: 3 },
  { index: 24, caseColor: "", data: {}, x: 19, y: 4.5 },
  { index: 25, caseColor: "", data: {}, x: 17, y: 14 },
  { index: 26, caseColor: "", data: {}, x: 18.5, y: 11 },
  { index: 27, caseColor: "", data: {}, x: 20, y: 12 },
  { index: 28, caseColor: "", data: {}, x: 22, y: 13 },
  { index: 29, caseColor: "", data: {}, x: 22, y: 10 },
  { index: 30, caseColor: "", data: {}, x: 22, y: 7 },
  { index: 31, caseColor: "", data: {}, x: 20, y: 3 },
  { index: 32, caseColor: "", data: {}, x: 22, y: 4 },
  { index: 33, caseColor: "", data: {}, x: 24, y: 5 },
  { index: 34, caseColor: "", data: {}, x: 24, y: 6 },
  { index: 35, caseColor: "", data: {}, x: 23, y: 11.5 },
  { index: 36, caseColor: "", data: {}, x: 24, y: 10 },
  { index: 37, caseColor: "", data: {}, x: 25, y: 11 },
  { index: 38, caseColor: "", data: {}, x: 26, y: 12 },
  { index: 39, caseColor: "", data: {}, x: 25, y: 4 },
  { index: 40, caseColor: "", data: {}, x: 26, y: 2.5 },
  { index: 41, caseColor: "", data: {}, x: 27, y: 5 },
  { index: 42, caseColor: "", data: {}, x: 28, y: 6 },
  { index: 43, caseColor: "", data: {}, x: 29, y: 11 },
  { index: 44, caseColor: "", data: {}, x: 28, y: 14 },
  { index: 45, caseColor: "", data: {}, x: 30, y: 13 },
  { index: 46, caseColor: "", data: {}, x: 31, y: 12 },
  { index: 47, caseColor: "", data: {}, x: 31, y: 9.5 },
  { index: 48, caseColor: "", data: {}, x: 29, y: 3 },
  { index: 49, caseColor: "", data: {}, x: 29, y: 4.5 },
  { index: 50, caseColor: "", data: {}, x: 30.5, y: 5 },
  { index: 51, caseColor: "", data: {}, x: 31, y: 3.5 },
  { index: 52, caseColor: "", data: {}, x: 32.5, y: 3 },
  { index: 53, caseColor: "", data: {}, x: 33, y: 4 },
  { index: 54, caseColor: "", data: {}, x: 34, y: 2 },
  { index: 55, caseColor: "", data: {}, x: 34, y: 5 },
  { index: 56, caseColor: "", data: {}, x: 35, y: 7 },
  { index: 57, caseColor: "", data: {}, x: 33, y: 9.5 },
  { index: 58, caseColor: "", data: {}, x: 35, y: 10 },
  { index: 59, caseColor: "", data: {}, x: 35.5, y: 12 },
  { index: 60, caseColor: "", data: {}, x: 37, y: 12.5 },
  { index: 61, caseColor: "", data: {}, x: 37, y: 11 },
  { index: 62, caseColor: "", data: {}, x: 36, y: 9 },
  { index: 63, caseColor: "", data: {}, x: 37, y: 5.5 },
  { index: 64, caseColor: "", data: {}, x: 37.5, y: 4 },
  { index: 65, caseColor: "", data: {}, x: 40, y: 3 },
  { index: 66, caseColor: "", data: {}, x: 40, y: 6 },
  { index: 67, caseColor: "", data: {}, x: 38, y: 10 },
  { index: 68, caseColor: "", data: {}, x: 39, y: 10 },
  { index: 69, caseColor: "", data: {}, x: 39, y: 12 },
  { index: 70, caseColor: "", data: {}, x: 20, y: 10, type: 'HarmFactor' },
  { index: 71, caseColor: "", data: {}, x: 14, y: 12, type: 'HarmFactor' },
  { index: 72, caseColor: "", data: {}, x: 12, y: 10, type: 'HarmFactor' },
  { index: 73, caseColor: "", data: {}, x: 14, y: 7, type: 'HarmFactor' },
];
export const map_Chemical_Factory_Explosion = [
  { index: 0, caseColor: "", data: {}, x: 7, y: 6 },
  { index: 1, caseColor: "", data: {}, x: 8, y: 7 },
  { index: 2, caseColor: "", data: {}, x: 7.5, y: 9 },
  { index: 3, caseColor: "", data: {}, x: 9, y: 8 },
  { index: 4, caseColor: "", data: {}, x: 11, y: 4.5 },
  { index: 5, caseColor: "", data: {}, x: 12, y: 9 },
  { index: 6, caseColor: "", data: {}, x: 13, y: 6 },
  { index: 7, caseColor: "", data: {}, x: 13, y: 10 },
  { index: 8, caseColor: "", data: {}, x: 6, y: 13 },
  { index: 9, caseColor: "", data: {}, x: 10, y: 12 },
  { index: 10, caseColor: "", data: {}, x: 7, y: 14 },
  { index: 11, caseColor: "", data: {}, x: 14, y: 13 },
  { index: 12, caseColor: "", data: {}, x: 6, y: 16 },
  { index: 13, caseColor: "", data: {}, x: 10, y: 14.5 },
  { index: 14, caseColor: "", data: {}, x: 6, y: 20 },
  { index: 15, caseColor: "", data: {}, x: 10, y: 17 },
  { index: 16, caseColor: "", data: {}, x: 8, y: 17.5 },
  { index: 17, caseColor: "", data: {}, x: 10, y: 19.5 },
  { index: 18, caseColor: "", data: {}, x: 12.5, y: 18 },
  { index: 19, caseColor: "", data: {}, x: 16, y: 19 },
  { index: 20, caseColor: "", data: {}, x: 15, y: 18 },
  { index: 21, caseColor: "", data: {}, x: 16, y: 14 },
  { index: 22, caseColor: "", data: {}, x: 19, y: 13 },
  { index: 23, caseColor: "", data: {}, x: 18, y: 15 },
  { index: 24, caseColor: "", data: {}, x: 19, y: 18 },
  { index: 25, caseColor: "", data: {}, x: 17, y: 12 },
  { index: 26, caseColor: "", data: {}, x: 21, y: 12.5 },
  { index: 27, caseColor: "", data: {}, x: 15, y: 8.5 },
  { index: 28, caseColor: "", data: {}, x: 21.5, y: 19 },
  { index: 29, caseColor: "", data: {}, x: 25.5, y: 15 },
  { index: 30, caseColor: "", data: {}, x: 15, y: 7 },
  { index: 31, caseColor: "", data: {}, x: 17, y: 6 },
  { index: 32, caseColor: "", data: {}, x: 19, y: 6.5 },
  { index: 33, caseColor: "", data: {}, x: 24, y: 13 },
  { index: 34, caseColor: "", data: {}, x: 18.5, y: 9 },
  { index: 35, caseColor: "", data: {}, x: 18, y: 4 },
  { index: 36, caseColor: "", data: {}, x: 22, y: 6 },
  { index: 37, caseColor: "", data: {}, x: 20, y: 8 },
  { index: 38, caseColor: "", data: {}, x: 21, y: 5 },
  { index: 39, caseColor: "", data: {}, x: 21, y: 9 },
  { index: 40, caseColor: "", data: {}, x: 9, y: 2 },
  { index: 41, caseColor: "", data: {}, x: 8, y: 1 },
  { index: 42, caseColor: "", data: {}, x: 9, y: 0 },
  { index: 43, caseColor: "", data: {}, x: 12, y: 0 },
  { index: 44, caseColor: "", data: {}, x: 12, y: 4 },
  { index: 45, caseColor: "", data: {}, x: 13, y: 2 },
  { index: 46, caseColor: "", data: {}, x: 16, y: 3 },
  { index: 47, caseColor: "", data: {}, x: 24, y: 7 },
  { index: 48, caseColor: "", data: {}, x: 17, y: 1 },
  { index: 49, caseColor: "", data: {}, x: 20.5, y: 3 },
  { index: 50, caseColor: "", data: {}, x: 25, y: 6 },
  { index: 51, caseColor: "", data: {}, x: 29.5, y: 6 },
  { index: 52, caseColor: "", data: {}, x: 29, y: 7 },
  { index: 53, caseColor: "", data: {}, x: 27, y: 8.5 },
  { index: 54, caseColor: "", data: {}, x: 26, y: 3.5 },
  { index: 55, caseColor: "", data: {}, x: 30, y: 12 },
  { index: 56, caseColor: "", data: {}, x: 31, y: 5 },
  { index: 57, caseColor: "", data: {}, x: 24, y: 10 },
  { index: 58, caseColor: "", data: {}, x: 30, y: 10 },
  { index: 59, caseColor: "", data: {}, x: 32, y: 9 },
  { index: 60, caseColor: "", data: {}, x: 32, y: 7 },
  { index: 61, caseColor: "", data: {}, x: 33, y: 12 },
  { index: 62, caseColor: "", data: {}, x: 36.5, y: 7 },
  { index: 63, caseColor: "", data: {}, x: 33, y: 7 },
  { index: 64, caseColor: "", data: {}, x: 34, y: 4.5 },
  { index: 65, caseColor: "", data: {}, x: 34, y: 9 },
  { index: 66, caseColor: "", data: {}, x: 37, y: 6 },
  { index: 67, caseColor: "", data: {}, x: 32, y: 13.5 },
  { index: 68, caseColor: "", data: {}, x: 36, y: 10 },
  { index: 69, caseColor: "", data: {}, x: 37, y: 9 },
  { index: 70, caseColor: "", data: {}, x: 10, y: 2, type: 'HarmFactor' },
  { index: 71, caseColor: "", data: {}, x: 25, y: 12, type: 'HarmFactor' },
  { index: 72, caseColor: "", data: {}, x: 16, y: 10, type: 'HarmFactor' },
  { index: 73, caseColor: "", data: {}, x: 2, y: 7, type: 'HarmFactor' },
];

const ListOfScenario = (props: Props) => {
  const location: any = useLocation();
  const { currentUser } = useAuth();
  const [openRecheck, setOpenRecheck] = useState(false);
  const [positionPoint, setPositionPoint] = useState<Array<IPosition>>(
    map_Bus_hits_Electric_pole
  );
  const [harmFactors, setHarmFactors] = useState<any>([])
  const [confirmPopup, setConfirmPopup] = useState<ITextConfirmDetail>();
  const [forceUpdateData, setForceUpdateData] = useState(0);
  const [scenarios, setScenarios] = useState([]);
  const [load, setLoad] = useState<boolean>(false);
  const [selectedScenario, setSelectedScenario] = useState<any>();
  const [state, setState] = useState("SELECT");
  const [page, setPage] = useState<string>("PAGE_SELECT");
  const [filterFav, setFilterFav] = useState(false);
  const [accessLocation, setAccessLocation] = useState<Boolean>(false);
  const [func, setFunc] = useState<() => void | undefined>();
  const [reCheckOnExit, setRecheckOnExit] = useState(false) 
  const classes = useStyles();
  function fetchScenario() {
    try {
      getScenario().then((res) => {
        if (res?.data?.result) {
          setScenarios(res.data.result);
          if (selectedScenario) {
            res.data.result.forEach((item: any) => {
              if (item._id === selectedScenario._id) {
                setSelectedScenario(item);
              }
            });
          }
        }
      });
    } catch (err) {
      console.log("Something wrong" + err);
    }
  }
  function changeState(state: stateName) {
    setState(state);
    if (state === "SELECT") {
      setPage("PAGE_SELECT");
    } else if (state === "CREATE") {
      setHarmFactors([]);
      setPage("PAGE_CREATE");
      setConfirmPopup(TextConfirmPopup[state]);
    } else if (state === "UPDATE") {
      setPage("PAGE_UPDATE");
      setConfirmPopup(TextConfirmPopup[state]);
    } else if (state === "DELETE") {
      setConfirmPopup(TextConfirmPopup[state]);
    } else if (state === "DELETE_ALL") {
      setPage("PAGE_SELECT");
      setConfirmPopup(TextConfirmPopup[state]);
    }
    setRecheckOnExit(false);
  }
  function handleSelectScenario(scenario: any) {
    const temp =
      scenario.desc === "Chemical Factory Explosion"
        ? map_Chemical_Factory_Explosion
        : map_Bus_hits_Electric_pole;
    if (scenario && scenario.patientMaps) {
      temp.forEach((item: any, index: number) => {
        temp[index].caseColor = "";
        temp[index].data = {};
      });
      scenario.patientMaps.forEach((item: any, index: number) => {
        temp[item.index].caseColor = item.patient.caseColor;
        temp[item.index].data = {
          data: item.patient,
          patientName: item.patient.code,
          pointMapName: item.index,
        };
      });
      setPositionPoint(temp);
    }
    setHarmFactors([...scenario.harmFactors])
    setSelectedScenario(scenario);
  }
  function handleOpenRecheck(callback: () => void) {
    setOpenRecheck(true);
    setFunc(() => () => callback());
  }
  function handleCloseRecheck() {
    setOpenRecheck(false);
  }
  function handleSubmit() {
    if(reCheckOnExit && state === "CREATE"){
      changeState("SELECT");
    }
    else if(reCheckOnExit && state === "UPDATE"){
      changeState("SELECT");
      handleSelectScenario(selectedScenario)
    }
    else if ((state === "CREATE" || state === "UPDATE" || state === "DELETE" || state === "DELETE_ALL") && func) {
      func();
    }
    handleCloseRecheck();
  }
  useEffect(() => {
    if (!filterFav && !load) {
      fetchScenario();
    }
  }, [filterFav, load]);
  useEffect(() => {
    fetchScenario();
  }, [forceUpdateData]);
  useEffect(() => {
    if (location.state && !accessLocation) {
      scenarios.forEach(async (ele: any) => {
        if (ele._id === location.state.scenarioId) {
          await setSelectedScenario(ele);
          await handleSelectScenario(ele);
          setAccessLocation(true);
        }
      });
    }
  }, [scenarios]);
  function CalculateAxisXPatient(axisX: number) {
    const rangeX = 946 - 100;
    const x = (rangeX / 40) * axisX + 100;
    return x;
  }
  function CalculateAxisYPatient(axisY: number) {
    const rangeY = 530 - 125;
    const y = (rangeY / 20) * axisY + 125;
    return y;
  }
  
    return (
    <Layout
      pageAt={"listOfScenario"}
      render={(props: any) => (
        <div className={classes.root}>
          <Box display="flex" alignItems="center">
            <Typography
              style={{
                color: "#5A5A5B",
                fontWeight: 700,
                fontSize: 32,
                marginRight: 10,
              }}
            >
              {state === "CREATE" ? "Create Scenario" : "List of Scenario"}
            </Typography>
            {page === "PAGE_SELECT" && checkPagePermission(currentUser?.roles, "HANDLESCENARIO") && (
              <div className={classes.btnAdd}>
                <Button
                  onClick={() => changeState("CREATE")}
                  startIcon={<AddCircleRoundedIcon />}
                >
                  Create Scenario
                </Button>
              </div>
            )}
          </Box>
          {page === "PAGE_SELECT" ? (
            <SelectScenario
              scenarios={scenarios}
              setScenarios={setScenarios}
              changeState={changeState}
              handleSelectScenario={handleSelectScenario}
              selectedScenario={selectedScenario}
              filterFav={filterFav}
              setFilterFav={setFilterFav}
              setLoad={setLoad}
              setForceUpdateData={setForceUpdateData}
              handleOpenRecheck={handleOpenRecheck}
              setPage={setPage}
              positionPoint={positionPoint}
              setPositionPoint={setPositionPoint}
              CalculateAxisXPatient={CalculateAxisXPatient}
              CalculateAxisYPatient={CalculateAxisYPatient}
              harmFactors={harmFactors}
            />
          ) : (
            <CreateScenario
              changeState={changeState}
              selectedScenario={selectedScenario}
              state={state}
              positionPoint={positionPoint}
              setPositionPoint={setPositionPoint}
              handleOpenRecheck={handleOpenRecheck}
              page={page}
              setPage={setPage}
              setForceUpdateData={setForceUpdateData}
              setSelectedScenario={setSelectedScenario}
              CalculateAxisXPatient={CalculateAxisXPatient}
              CalculateAxisYPatient={CalculateAxisYPatient}
              setHarmFactors={setHarmFactors}
              harmFactors={harmFactors}
              setConfirmPopup={setConfirmPopup}
              TextConfirmPopup={TextConfirmPopup}
              setRecheckOnExit={setRecheckOnExit}
              setOpenRecheck={setOpenRecheck}
            />
          )}
          <RecheckDialog
            open={openRecheck}
            handleClose={handleCloseRecheck}
            confirmNo={handleCloseRecheck}
            confirmYes={handleSubmit}
            confirmPopup={confirmPopup}
          />
          <Backdrop className={classes.backdrop} open={load}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    />
  );
};
export default ListOfScenario;
