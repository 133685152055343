import { PayloadAction } from "@reduxjs/toolkit";
import { v1 as uuid } from "uuid";

import { IState, IUserData, IUserDataDetail } from "./userManagement";

export const userReducer = {
  createUser: (state: IState, action: PayloadAction<IUserData>) => {
    state.users.push(action.payload);
  },
  updateUser: (state: IState, action: PayloadAction<IUserData>) => {
    let id = action.payload.id;
    let FoundSearchById = state.users.find(
      (search: IUserData) => search.id === id
    );
    if (FoundSearchById) {
      let data = action.payload;
      FoundSearchById.data = data.data;
    } else {
      console.log(action.payload);
    }
  },
  deleteUser: (state: IState, action: PayloadAction<IUserData>) => {
    let id = action.payload.id;
    let filterData = state.users.filter((search: any) => search.id !== id);

    state.users = filterData;
  },
};
