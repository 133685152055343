import {
  Button,
  Grid,
  MenuItem,
  Slider,
  Switch,
  Typography,
  useMediaQuery,
  withStyles,
} from "@material-ui/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { SelectFieldScenarios } from "../../../commons/Inputs";
import { useStyles } from "./style";
import { TextFieldStyleSerch } from "../../../commons/Inputs/style";
import {
  ButtonContained,
  ButtonOutlined,
  ButtonText,
} from "../../../commons/Buttons";
import generator from "generate-password";
import { useFormik } from "formik";
import {
  createPatientsApi,
  deletePatientsApi,
  updatePatientsApi,
} from "../../../../services/Patient/patient.service";
import { Unity, useUnityContext } from "react-unity-webgl";
import { createMuiTheme, Theme, ThemeProvider } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
interface IProp {
  selectColor: string;
  changeState: (state: string) => void;
  selectPatient: any;
  state: string;
  setForceUpdateData: React.Dispatch<React.SetStateAction<number>>;
  forceUpdateData: number;
  handleOpenRecheck: (callback: () => void) => void;
  page: string;
  TextConfirmPopup: any;
  setConfirmPopup: any;
  setRecheckOnExit: any;
  setOpenRecheck: any;
}

export default function SelectPatient({
  selectColor,
  changeState,
  selectPatient,
  state,
  setForceUpdateData,
  handleOpenRecheck,
  page,
  TextConfirmPopup,
  setConfirmPopup,
  setRecheckOnExit,
  setOpenRecheck
}: IProp) {
  const {
    unityProvider,
    sendMessage,
    addEventListener,
    removeEventListener,
    loadingProgression
  } = useUnityContext({
    loaderUrl: "Build/05102022_RVRT_PATIENT_CUSTOMISATION.loader.js",
    dataUrl: "Build/05102022_RVRT_PATIENT_CUSTOMISATION.data",
    frameworkUrl: "Build/05102022_RVRT_PATIENT_CUSTOMISATION.framework.js",
    codeUrl: "Build/05102022_RVRT_PATIENT_CUSTOMISATION.wasm",
  });
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const AmountSlider = createMuiTheme({
    overrides: {
      MuiSlider: {
        root: {
          // color: 'red',
          height: 8,
        },
        thumb: {
          height: 16,
          width: 16,
          // backgroundColor: "#A5A6F6",
          border: "2px solid currentColor",
          marginTop: -4,
          marginLeft: -12,
          "&:focus,&:hover,&$active": {
            boxShadow: "inherit",
          },
        },
        active: {},
        valueLabel: {
          left: "calc(-50% + 14px)",
          top: -22,
          "& *": {
            background: "transparent",
            color: "#000",
          },
        },
        track: {
          height: 8,
          borderRadius: 4,
        },
        rail: {
          height: 8,
          borderRadius: 4,
        },
      },
    },
  });
  const CustomSwitch = withStyles({
    switchBase: {
      color: "white",
      "&$checked": {
        color: "#F9B233",
      },
      "&$checked + $track": {
        backgroundColor: "red",
      },
    },
    checked: {},
    track: {
      opacity: 1,
      backgroundColor: "#E4E4E4",
      "$checked$checked + &": {
        opacity: 1,
        backgroundColor: "#FFEFD3",
      },
    },
  })(Switch);
  const formik = useFormik({
    initialValues:
      selectPatient && state === "UPDATE"
        ? {
            patientCode: selectPatient.code,
            caseColor: selectPatient.caseColor,
            gender: selectPatient.gender,
            pose: selectPatient.pose,
            age: selectPatient.age,
            // shirt: selectPatient.cloths.shirt,
            // pants: selectPatient.cloths.panth,
            // shoes: selectPatient.cloths.shoes,
            patientAppearance: '',
            respiratoryRate: selectPatient.breath.rr,
            obstruct: selectPatient.breath.obs,
            pulseRate: selectPatient.circulation.pr,
            capillaryRefill: selectPatient.circulation.cr,
            head: selectPatient.injuries.head,
            chest: selectPatient.injuries.chest,
            arms: selectPatient.injuries.arms,
            legs: selectPatient.injuries.legs,
            public: selectPatient.accessLevel === "PUBLIC" ? true : false,
          }
        : {
            patientCode: generatePlayerName(),
            caseColor: selectColor,
            gender: "M",
            pose: selectColor === "GREEN" ? "Walking" : "BLACK" ? "Lie down" : "Sit",
            age: "Child",
            patientAppearance: '',
            // shirt: "Black",
            // pants: "Black",
            // shoes: "Black",
            obstruct: false,
            pulseRate: "",
            capillaryRefill: "",
            head: "None",
            chest: "None",
            arms: "None",
            legs: "None",
            public: false,
          },
    onSubmit: (values) => {},
  });
  const matches = useMediaQuery("(max-width:1920px)");
  const [rr, setRr] = useState<number | string | Array<number | string>>(2);
  const [pr, setPr] = useState<number | string | Array<number | string>>(3);
  const [cr, setCr] = useState<number | string | Array<number | string>>(10);
  const [orderRRRed, setOrderRRRed] = useState<string>("LOWER");

  const [minMaxRR, setMinMaxRR] = useState<Array<number>>(() => {
    if (selectColor === "BLACK") {
      return [0, 10];
    } else if (selectColor === "RED") {
      return orderRRRed === "LOWER" ? [0, 10] : [30, 60];
    } else if (selectColor === "YELLOW") {
      return [10, 29];
    } else {
      return [10, 29];
    }
  });
  const [minMaxPR, setMinMaxPR] = useState<Array<number>>(() => {
    if (selectColor === "BLACK") {
      return [0, 0];
    } else if (selectColor === "RED") {
      return [120, 250];
    } else if (selectColor === "YELLOW") {
      return [30, 120];
    } else {
      return [30, 120];
    }
  });
  const [minMaxCR, setMinMaxCR] = useState<Array<number>>(() => {
    if (selectColor === "BLACK") {
      return [30, 30];
    } else if (selectColor === "RED") {
      return [2, 5];
    } else if (selectColor === "YELLOW") {
      return [1, 2];
    } else {
      return [1, 2];
    }
  });
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
    if (type === "rr") {
      setRr(event.target.value === "" ? "" : Number(event.target.value));
    } else if (type === "pr") {
      setPr(event.target.value === "" ? "" : Number(event.target.value));
    } else if (type === "cr") {
      setCr(event.target.value === "" ? "" : Number(event.target.value));
    }
  };
  const handleSliderChange = (event: any, newValue: number | number[], type: string) => {
    if (type === "rr") {
      setRr(newValue);
    } else if (type === "cr") {
      setCr(newValue);
    } else if (type === "pr") {
      setPr(newValue);
    }
  };
  function createPatient() {
    const dataPatient = [
      {
        code: formik.values.patientCode,
        gender: formik.values.gender,
        caseColor: selectColor,
        age: formik.values.age,
        pose: formik.values.pose,
        accessLevel: formik.values.public ? "PUBLIC" : "PRIVATE",
        breath: {
          rr: rr,
          obs: formik.values.obstruct,
        },
        patientAppearance: formik.values.patientAppearance,
        circulation: {
          pr: pr,
          cr: cr,
        },
        // cloths: {
        //   shirt: "",
        //   pants: "",
        //   shoes: "",
        // },
        injuries: {
          head: formik.values.head,
          chest: formik.values.chest,
          arms: formik.values.arms,
          legs: formik.values.legs,
        },
      },
    ];
    createPatientsApi(dataPatient)
      .then((res) => {
        setForceUpdateData((prev: any) => prev + 1);
        changeState("SELECT");
        enqueueSnackbar("Created Success", {
          variant: "success",
        });
      })
      .catch((err) => {
        enqueueSnackbar("Created Error", {
          variant: "error",
        });
      });
  }
  function updatePatient() {
    const dataPatient = {
      code: formik.values.patientCode,
      gender: formik.values.gender,
      caseColor: selectColor,
      age: formik.values.age,
      pose: formik.values.pose,
      accessLevel: formik.values.public ? "PUBLIC" : "PRIVATE",
      breath: {
        rr: rr,
        obs: formik.values.obstruct,
      },
      circulation: {
        pr: pr,
        cr: cr,
      },
      patientAppearance: formik.values.patientAppearance,
      // cloths: {
      //   shirt: "",
      //   pants: "",
      //   shoes: "",
      // },
      injuries: {
        head: formik.values.head,
        chest: formik.values.chest,
        arms: formik.values.arms,
        legs: formik.values.legs,
      },
    };
    updatePatientsApi(dataPatient)
      .then(() => {
        setForceUpdateData((prev: any) => prev + 1);
        changeState("SELECT");
        enqueueSnackbar("Updated Success", {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar("Updated Error", {
          variant: "error",
        });
      });
  }
  function deactivatePatient() {
    deletePatientsApi({ code: formik.values.patientCode })
      .then((res) => {
        if(res.data.status === "Success"){
          enqueueSnackbar("Deleted Success", {
            variant: "success",
          });
          setForceUpdateData((prev: any) => prev + 1);
          changeState("SELECT");
        }
        else {
          enqueueSnackbar("Deleted Error", {
            variant: "error",
          });
          changeState("UPDATE");
        }
      })
      .catch(() => {
        enqueueSnackbar("Deleted Error", {
          variant: "error",
        });
        changeState("UPDATE");
      });
  }
  function generatePlayerName(): string {
    const name = generator.generate({
      length: 4,
      lowercase: false,
      uppercase: true,
      numbers: true,
      symbols: false,
    });
    return `PT${name}`;
  }
  const COLOR = {
    Breath: {
      color: "#F9B233",
    },
    Circulation: {
      color: "#A5A6F6",
    },
  };
  const handleBlur = (type: string) => {
    if (type === "rr") {
      if (rr < minMaxRR[0]) {
        setRr(minMaxRR[0]);
      } else if (rr > minMaxRR[1]) {
        setRr(minMaxRR[1]);
      }
    } else if (type === "cr") {
      if (cr < minMaxCR[0]) {
        setCr(minMaxCR[0]);
      } else if (cr > minMaxCR[1]) {
        setCr(minMaxCR[1]);
      }
    } else if (type === "pr") {
      if (pr < minMaxPR[0]) {
        setPr(minMaxPR[0]);
      } else if (pr > minMaxPR[1]) {
        setPr(minMaxPR[1]);
      }
    }
  };
  function handleChangeSexReactToUnity(typeGender: unknown, appearance: string) {
    sendMessage( "CustomisationManager", "ChangeSex",(typeGender as string) === "M" ? "HumanMale" : "HumanFemale");
    sendMessage("CustomisationManager", "LoadAvatar", appearance);
  }
  function resetAppearance(typeGender: unknown){
    sendMessage("CustomisationManager", "ChangeSex",(typeGender as string) === "M" ? "HumanMale" : "HumanFemale");
    const codeAppearance = (typeGender as string) === "M" ? 'AA*|R:HumanMale|W:MaleUnderwear,MaleJeans 1,MaleHair2,M_ThinShirt,M_SportShoes,|C:Hair,3=0,FF5B271E;1,FF8C8C8C;2,FFFF0000;|C:Eyes,3=0,FFF3F3E8;|C:Skin,3=0,FFFDEFE7;2,FFFFFFFF,38000000;|C:Shirt,3=0,FFCF9425;2,FFFFFFFF,6F000000;|C:Pants1,3=0,FF434E78;|C:ShirtAccent,3=0,FFE80808;|C:Shoes,3=0,FF4B4B4B;2,FFFFFFFF,51930000;|C:Pants,3=0,FF6D9CAD;|C:Lipstick,3=0,FFFF0039;|C:Shirt1,3=0,FF741D1D;|C:Shirt2,3=0,FF0C9540;|D:height=1319;|' : 'AA*|R:HumanFemale|W:FemalePants1,FemaleShirt1,FemaleHair3,F_Shoes2_1,|C:Hair,3=0,FF5B271E;1,FF8C8C8C;2,FFFF0000;|C:Eyes,3=0,FFF3F3E8;|C:Skin,3=0,FFFDEFE7;2,FFFFFFFF,38000000;|C:Shirt,3=0,FFCF9425;2,FFFFFFFF,6F000000;|C:Pants1,3=0,FF434E78;|C:ShirtAccent,3=0,FFE80808;|C:Shoes,3=0,FF4B4B4B;2,FFFFFFFF,51930000;|C:Pants,3=0,FF6D9CAD;|C:Lipstick,3=0,FFFF0039;|C:Shirt1,3=0,FF741D1D;|C:Shirt2,3=0,FF0C9540;|D:height=1319;|';
    formik.setFieldValue('patientAppearance', codeAppearance);
  }
  function handleRecheckOnExit() {
    if(state === "CREATE") {
      if(formik.values.gender !== "M" || formik.values.pose !== (selectColor === "GREEN" ? "Walking" : "BLACK" ? "Lie down" : "Sit") || formik.values.age !== "Child" || rr !== minMaxRR[0] ||
        formik.values.obstruct !== false || pr !== minMaxPR[0] || cr !== minMaxCR[0] || formik.values.head !== "None" || formik.values.chest !== "None" || formik.values.arms !== "None" || formik.values.legs !=="None" || formik.values.public !== false) {
        setConfirmPopup(TextConfirmPopup["QUITEDITING"]);
        setRecheckOnExit(true);
        setOpenRecheck(true);
      }
      else {
        changeState("SELECT");
      }
    }
    else if(state === "UPDATE") {
      if(formik.values.gender !== selectPatient.gender || formik.values.pose !== selectPatient.pose || formik.values.age !== selectPatient.age || rr !== selectPatient.breath.rr ||
        formik.values.obstruct !== selectPatient.breath.obs || pr !== selectPatient.circulation.pr || cr !== selectPatient.circulation.cr || formik.values.head !== selectPatient.injuries.head ||
        formik.values.chest !== selectPatient.injuries.chest || formik.values.arms !== selectPatient.injuries.arms || formik.values.legs !== selectPatient.injuries.legs || formik.values.public !== (selectPatient.accessLevel === "PUBLIC" ? true : false)) {
        setConfirmPopup(TextConfirmPopup["QUITEDITING"]);
        setRecheckOnExit(true);
        setOpenRecheck(true);
      }
      else {
          changeState("SELECT");
      }
    }
  }
  function openBtn() {
    sendMessage("CustomisationManager","ShowCustomiseBtn");
  }
  useEffect(() => {
    if (state === "UPDATE") {
      setRr(selectPatient.breath.rr);
      setPr(selectPatient.circulation.pr);
      setCr(selectPatient.circulation.cr);
    } else {
      setRr(minMaxRR[0]);
      setPr(minMaxPR[0]);
      setCr(minMaxCR[0]);
    }
  }, []);
  const handleGetPatientAppearance = useCallback((text: string) => {
    if(selectPatient.patientAppearance){
      formik.setFieldValue('patientAppearance',selectPatient.patientAppearance);
      selectPatient.patientAppearance = ""
    }
    else{
      formik.setFieldValue('patientAppearance',text);
    }
  }, []);
  useEffect(() => {
    addEventListener("GetPatientAppearance", handleGetPatientAppearance);
    return () => {
      removeEventListener("GetPatientAppearance", handleGetPatientAppearance);
    };
  }, [addEventListener, removeEventListener, handleGetPatientAppearance]);
  useEffect(() => {
    if (selectColor === "RED") {
      setMinMaxRR(orderRRRed === "LOWER" ? [0, 10] : [30, 60]);
      setRr(orderRRRed === "LOWER" ? 0 : 30);
    }
  }, [orderRRRed]);
  function PatientStateFromColor(color: string) {
    if (color === "GREEN") {
      return ["Walking"];
    } else if (color === "BLACK") {
      return ["Lie down"];
    } else if (color === "YELLOW" || color === "RED") {
      return ["Sit", "Lie down"];
    }
  }
  useEffect(() => {
    if(Math.round(loadingProgression * 100) === 100){
      const timer = setTimeout(async() => {
        if(state === "UPDATE"){
          await handleChangeSexReactToUnity(formik.values.gender, formik.values.patientAppearance);
          await openBtn();
        }
        else {
          openBtn();
        }
      }, 0);
      return () => clearTimeout(timer);
    }
  }, [loadingProgression, formik.values])
  
  return (
    <>
      <div style={{ display: "flex", marginTop: 13 }}>
        <Button
          style={{
            fontSize: 13,
            position: "absolute",
            right: 80,
            border: formik.values.public ? "" : "0.5px solid #E4E4E4",
            textTransform: "none",
            height: 30,
            padding: "4px 11px",
            backgroundColor: formik.values.public ? "#E0F2F9" : "",
            color: formik.values.public ? "#67BDE0" : "#5A5A5A",
          }}
          onClick={() => formik.setFieldValue("public", !formik.values.public)}
        >
          Public
        </Button>
        <div
          style={{
            height: matches ? 1100 : 660,
            width: "100%",
            marginTop: 40,
            backgroundColor: "#F6FAFF",
            position: "relative",
          }}
        >
          <Grid container>
            <Grid item xs={12} md={12} xl={6} lg={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection:'column',
                  justifyContent: "center",
                  alignItems:'center',
                  height: "100%",
                  width: "100%",
                }}
              >
                {Math.round(loadingProgression * 100) !== 100 && <p style={{width: matches ? "300px" : "300px",marginTop:30}}>Loading Application... {Math.round(loadingProgression * 100)}%</p>}
                <Unity
                  unityProvider={unityProvider}
                  style={{
                    width: matches ? "300px" : "300px",
                    height: matches ? "500px" : "500px",
                  }}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              xl={6}
              lg={12}
              style={{ marginTop: 30, marginLeft: matches ? 20 : 0 }}
            >
              <Grid container>
                <Grid item xs={6}>
                  {/* General */}
                  <div
                    style={{
                      height: 300,
                      backgroundColor: "white",
                      padding: 15,
                      width: "95%",
                      borderRadius: 10,
                      position: "relative",
                      boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.05)",
                    }}
                  >
                    <div
                      style={{
                        top: -15,
                        left: 16,
                        backgroundColor: "#64DBA9",
                        padding: "3px 6px",
                        position: "absolute",
                        fontWeight: 700,
                        color: "#5A5A5A",
                      }}
                    >
                      General
                    </div>
                    <Grid container style={{ marginTop: 33 }}>
                      <Grid item xs={6}>
                        <Typography style={{ fontSize: 14, fontWeight: 500 }}>
                          Patient Code
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextFieldStyleSerch
                          size="small"
                          variant="outlined"
                          value={formik.values.patientCode}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6} style={{ marginTop: 24 }}>
                        <Typography style={{ fontSize: 14, fontWeight: 500 }}>
                          Case Color
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ marginTop: 24 }}>
                        <TextFieldStyleSerch
                          size="small"
                          variant="outlined"
                          value={formik.values.caseColor}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6} style={{ marginTop: 24 }}>
                        <Typography style={{ fontSize: 14, fontWeight: 500 }}>
                          Gender
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ marginTop: 24 }}>
                        <SelectFieldScenarios
                          name="gender"
                          style={{ height: "20px" }}
                          onChange={(e) => {
                            formik.handleChange(e);
                            resetAppearance(e.target.value);
                          }}
                          displayEmpty
                          value={formik.values.gender}
                        >
                          <MenuItem value={"M"}>Male</MenuItem>
                          <MenuItem value={"F"}>Female</MenuItem>
                        </SelectFieldScenarios>
                      </Grid>
                      <Grid item xs={6} style={{ marginTop: 24 }}>
                        <Typography style={{ fontSize: 14, fontWeight: 500 }}>
                          Patient State
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ marginTop: 24 }}>
                        <SelectFieldScenarios
                          style={{ height: "20px" }}
                          name="pose"
                          onChange={formik.handleChange}
                          displayEmpty
                          value={formik.values.pose}
                          disabled={selectColor === "GREEN" ? true : false}
                        >
                          {PatientStateFromColor(selectColor)?.map((option) => {
                            return <MenuItem value={option}>{option}</MenuItem>;
                          })}
                        </SelectFieldScenarios>
                      </Grid>
                      <Grid item xs={6} style={{ marginTop: 24 }}>
                        <Typography style={{ fontSize: 14, fontWeight: 500 }}>
                          Age
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ marginTop: 24 }}>
                        <SelectFieldScenarios
                          name="age"
                          style={{ height: "20px" }}
                          onChange={formik.handleChange}
                          displayEmpty
                          value={formik.values.age}
                        >
                          <MenuItem value={"Child"}>Child</MenuItem>
                          <MenuItem value={"Teen"}>Teen</MenuItem>
                          <MenuItem value={"Adult"}>Adult</MenuItem>
                          <MenuItem value={"Elder"}>Elder</MenuItem>
                        </SelectFieldScenarios>
                      </Grid>
                    </Grid>
                  </div>
                  {/* Breath */}
                  {/* <Button
                    onClick={() => {
                      handleChangeSexReactToUnity(formik.values.gender);
                    }}
                  >
                    Test
                  </Button> */}
                  <div
                    style={{
                      width: "95%",
                      backgroundColor: "white",
                      marginTop: 30,
                      borderRadius: 10,
                      position: "relative",
                      boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.05)",
                    }}
                  >
                    <div
                      style={{
                        top: -15,
                        left: 16,
                        backgroundColor: "#F9B233",
                        padding: "3px 6px",
                        position: "absolute",
                        fontWeight: 700,
                        color: "#5A5A5A",
                      }}
                    >
                      Breath
                    </div>
                    <Grid container style={{ padding: 10 }}>
                      {selectColor === "RED" && (
                        <div
                          style={{ position: "absolute", top: 10, right: 10 }}
                        >
                          <Button
                            onClick={() => setOrderRRRed("LOWER")}
                            style={{
                              maxWidth: "20px",
                              maxHeight: "20px",
                              minWidth: "20px",
                              minHeight: "20px",
                              backgroundColor:
                                orderRRRed === "LOWER" ? "#B9B9B9" : "#F5F5F7",
                            }}
                          >
                            L
                          </Button>
                          <Button
                            onClick={() => setOrderRRRed("UPPER")}
                            style={{
                              maxWidth: "20px",
                              maxHeight: "20px",
                              minWidth: "20px",
                              minHeight: "20px",
                              backgroundColor:
                                orderRRRed === "UPPER" ? "#B9B9B9" : "#F5F5F7",
                              marginLeft: 3,
                            }}
                          >
                            U
                          </Button>
                        </div>
                      )}
                      <Grid
                        item
                        xs={5}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: 33,
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 500,
                          }}
                        >
                          Respiratory Rate
                        </Typography>
                      </Grid>
                      {/* <Grid item xs>
                        <Slider
                          value={typeof value === "number" ? value : 10}
                          onChange={handleSliderChange1}
                          aria-labelledby="input-slider"
                          min={10}
                        />
                      </Grid>
                      <Grid item>
                        <Input
                          value={value}
                          margin="dense"
                          onChange={handleInputChange1}
                          onBlur={handleBlur1}
                          inputProps={{
                            step: 10,
                            min: 10,
                            max: 100,
                            type: "number",
                            "aria-labelledby": "input-slider",
                          }}
                        />
                      </Grid> */}
                      <Grid
                        item
                        xs={4}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: 33,
                        }}
                      >
                        <ThemeProvider theme={AmountSlider}>
                          <Slider
                            style={COLOR.Breath}
                            value={typeof rr === "number" ? rr : minMaxRR[0]}
                            onChange={(value, newValue) => {
                              handleSliderChange(value, newValue, "rr");
                            }}
                            onChangeCommitted={(value, newValue) => {
                              handleSliderChange(value, newValue, "rr");
                            }}
                            min={minMaxRR[0]}
                            max={minMaxRR[1]}
                          />
                        </ThemeProvider>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: 33,
                        }}
                      >
                        <TextFieldStyleSerch
                          className={classes.slider}
                          value={rr}
                          onChange={(e: any) => handleInputChange(e, "rr")}
                          onBlur={() => handleBlur("rr")}
                          variant="outlined"
                          type="number"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography style={{ fontSize: 14, fontWeight: 500 }}>
                          Obstruct
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        style={{
                          display: "flex",
                          alignContent: "center",
                          justifyContent: "right",
                        }}
                      >
                        <CustomSwitch
                          name="obstruct"
                          checked={formik.values.obstruct}
                          onChange={formik.handleChange}
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        Yes
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  {/* Injuries */}
                  <div
                    style={{
                      height: 300,
                      width: "95%",
                      backgroundColor: "white",
                      padding: 15,
                      borderRadius: 10,
                      position: "relative",
                      boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.05)",
                    }}
                  >
                    <div
                      style={{
                        top: -15,
                        backgroundColor: "#F15F5F",
                        padding: "3px 6px",
                        position: "absolute",
                        fontWeight: 700,
                        color: "#5A5A5A",
                      }}
                    >
                      Injuries
                    </div>
                    <Grid container style={{ marginTop: 33 }}>
                      <Grid item xs={6}>
                        <Typography style={{ fontSize: 14, fontWeight: 500 }}>
                          Head
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <SelectFieldScenarios
                          name="head"
                          style={{ height: "20px" }}
                          value={formik.values.head}
                          onChange={formik.handleChange}
                          displayEmpty
                        >
                          <MenuItem value={"None"}>None</MenuItem>
                          <MenuItem value={"Abrasion"}>Abrasion</MenuItem>
                          <MenuItem value={"Laceration"}>Laceration</MenuItem>
                          <MenuItem value={"Burn"}>Burn</MenuItem>
                          <MenuItem value={"Incision"}>Incision</MenuItem>
                          <MenuItem value={"Bruise"}>Bruise</MenuItem>
                        </SelectFieldScenarios>
                      </Grid>
                      <Grid item xs={6} style={{ marginTop: 24 }}>
                        <Typography style={{ fontSize: 14, fontWeight: 500 }}>
                          Chest
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ marginTop: 24 }}>
                        <SelectFieldScenarios
                          name="chest"
                          style={{ height: "20px" }}
                          value={formik.values.chest}
                          onChange={formik.handleChange}
                          displayEmpty
                        >
                          <MenuItem value={"None"}>None</MenuItem>
                          <MenuItem value={"Abrasion"}>Abrasion</MenuItem>
                          <MenuItem value={"Laceration"}>Laceration</MenuItem>
                          <MenuItem value={"Burn"}>Burn</MenuItem>
                          <MenuItem value={"Incision"}>Incision</MenuItem>
                          <MenuItem value={"Bruise"}>Bruise</MenuItem>
                        </SelectFieldScenarios>
                      </Grid>
                      <Grid item xs={6} style={{ marginTop: 24 }}>
                        <Typography style={{ fontSize: 14, fontWeight: 500 }}>
                          Arms
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ marginTop: 24 }}>
                        <SelectFieldScenarios
                          style={{ height: "20px" }}
                          name="arms"
                          value={formik.values.arms}
                          onChange={formik.handleChange}
                          displayEmpty
                        >
                          <MenuItem value={"None"}>None</MenuItem>
                          <MenuItem value={"Abrasion"}>Abrasion</MenuItem>
                          <MenuItem value={"Laceration"}>Laceration</MenuItem>
                          <MenuItem value={"Burn"}>Burn</MenuItem>
                          <MenuItem value={"Incision"}>Incision</MenuItem>
                          <MenuItem value={"Bruise"}>Bruise</MenuItem>
                          <MenuItem value={"Open FX"}>Open FX</MenuItem>
                        </SelectFieldScenarios>
                      </Grid>
                      <Grid item xs={6} style={{ marginTop: 24 }}>
                        <Typography style={{ fontSize: 14, fontWeight: 500 }}>
                          Legs
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ marginTop: 24 }}>
                        <SelectFieldScenarios
                          style={{ height: "20px" }}
                          name="legs"
                          value={formik.values.legs}
                          onChange={formik.handleChange}
                          displayEmpty
                        >
                          <MenuItem value={"None"}>None</MenuItem>
                          <MenuItem value={"Abrasion"}>Abrasion</MenuItem>
                          <MenuItem value={"Laceration"}>Laceration</MenuItem>
                          <MenuItem value={"Burn"}>Burn</MenuItem>
                          <MenuItem value={"Incision"}>Incision</MenuItem>
                          <MenuItem value={"Bruise"}>Bruise</MenuItem>
                          <MenuItem value={"Open FX"}>Open FX</MenuItem>
                        </SelectFieldScenarios>
                      </Grid>
                    </Grid>
                  </div>
                  {/* Circulation */}
                  <div
                    style={{
                      width: "95%",
                      backgroundColor: "white",
                      padding: 10,
                      marginTop: 30,
                      borderRadius: 10,
                      position: "relative",
                      boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.05)",
                    }}
                  >
                    <div
                      style={{
                        top: -15,
                        backgroundColor: "#BFC0FF",
                        padding: "3px 6px",
                        position: "absolute",
                        fontWeight: 700,
                        color: "#5A5A5A",
                      }}
                    >
                      Circulation
                    </div>
                    <Grid container style={{ marginTop: 33 }}>
                      {/* {selectColor === "RED" && (
                        <div
                          style={{ position: "absolute", top: 10, right: 10 }}
                        >
                          <Button
                            onClick={() => setOrderPrRed("LOWER")}
                            style={{
                              maxWidth: "20px",
                              maxHeight: "20px",
                              minWidth: "20px",
                              minHeight: "20px",
                              backgroundColor:
                                orderPrRed === "LOWER" ? "#B9B9B9" : "#F5F5F7",
                            }}
                          >
                            L
                          </Button>
                          <Button
                            onClick={() => setOrderPrRed("UPPER")}
                            style={{
                              maxWidth: "20px",
                              maxHeight: "20px",
                              minWidth: "20px",
                              minHeight: "20px",
                              backgroundColor:
                                orderPrRed === "UPPER" ? "#B9B9B9" : "#F5F5F7",
                              marginLeft: 3,
                            }}
                          >
                            U
                          </Button>
                        </div>
                      )} */}
                      <Grid
                        item
                        xs={5}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography style={{ fontSize: 14, fontWeight: 500 }}>
                          Pulse Rate
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <ThemeProvider theme={AmountSlider}>
                          <Slider
                            style={COLOR.Circulation}
                            value={typeof pr === "number" ? pr : minMaxPR[0]}
                            onChange={(value, newValue) => {
                              handleSliderChange(value, newValue, "pr");
                            }}
                            aria-labelledby="input-slider"
                            onChangeCommitted={(value, newValue) => {
                              handleSliderChange(value, newValue, "pr");
                            }}
                            min={minMaxPR[0]}
                            max={minMaxPR[1]}
                          />
                        </ThemeProvider>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        // justify="center"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <TextFieldStyleSerch
                          className={classes.slider}
                          value={pr}
                          onChange={(e: any) => handleInputChange(e, "pr")}
                          onBlur={() => handleBlur("pr")}
                          variant="outlined"
                          type="number"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: 13,
                            fontWeight: 500,
                            marginTop: 20,
                          }}
                        >
                          Capillary Refill
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: 20,
                        }}
                      >
                        <ThemeProvider theme={AmountSlider}>
                          <Slider
                            style={COLOR.Circulation}
                            value={typeof cr === "number" ? cr : minMaxCR[0]}
                            onChange={(value, newValue) => {
                              handleSliderChange(value, newValue, "cr");
                            }}
                            aria-labelledby="input-slider"
                            onChangeCommitted={(value, newValue) => {
                              handleSliderChange(value, newValue, "cr");
                            }}
                            min={minMaxCR[0]}
                            max={minMaxCR[1]}
                          />
                        </ThemeProvider>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        // justify="center"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: 20,
                        }}
                      >
                        <TextFieldStyleSerch
                          className={classes.slider}
                          value={cr}
                          onChange={(e: any) => handleInputChange(e, "cr")}
                          onBlur={() => handleBlur("cr")}
                          variant="outlined"
                          type="number"
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: 20,
        }}
      >
        <div>
          {(state === "UPDATE" || state === "DELETE") && (
            <ButtonOutlined
              style={{ marginTop: 8, fontSize: 12 }}
              colorText="red"
              onClick={() => {
                changeState("DELETE");
                handleOpenRecheck(deactivatePatient);
                // handleOpenRecheck(deactivateScenario);
              }}
            >
              Delete Patient
            </ButtonOutlined>
          )}
        </div>
        <div>
          <ButtonText
            colorText="blue"
            onClick={() => {
              handleRecheckOnExit();
            }}
          >
            Cancel
          </ButtonText>
          <ButtonContained
            colorText="blue"
            onClick={() => {
              changeState(page === "PAGE_CREATE" ? "CREATE" : "UPDATE");
              handleOpenRecheck(
                page === "PAGE_CREATE" ? createPatient : updatePatient
              );
            }}
          >
            Save
          </ButtonContained>
        </div>
      </div>
    </>
  );
}
