import {
  Avatar,
  Button,
  IconButton,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Certificate from "../../../commons/asset/Certificate_Name.png";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { capitalizeFirstLetter } from '../../../commons/CapitalizeFirst';

const responsive = {
  4000: {
    items: 5,
  },
  1550: {
    items: 2,
  },
  0: {
    items: 1,
  },
};
interface Props {
  certificates: any | undefined;
  userName: string;
}

const renderPrevButton = ({ isDisabled, certificates }: any) => {
  return (
    <>
      <IconButton
        style={{
          backgroundColor: "#B9B9B9",
          width: 40,
          height: 40,
          position: "absolute",
          top: "calc(50% - 60px)",
          left: -10,
          opacity: isDisabled ? "0.5" : 1,
        }}
        disabled={isDisabled}
      >
        <ArrowBackIcon style={{ color: "white", width: 25, height: 25 }} />
      </IconButton>
    </>
  );
};

const renderNextButton = ({ isDisabled }: any) => {
  return (
    <>
      <IconButton
        style={{
          backgroundColor: "#B9B9B9",
          width: 40,
          height: 40,
          position: "absolute",
          top: "calc(50% - 60px)",
          right: -10,
          opacity: isDisabled ? "0.5" : 1,
        }}
        disabled={isDisabled}
      >
        <ArrowForwardIcon style={{ color: "white", width: 25, height: 25 }} />
      </IconButton>
    </>
  );
};

const handleItem = (certificates: any | undefined, userName: string, history: any) => {
  if (Array.isArray(certificates)) {
    const InstructorMapped = certificates.map((el: any) => {
      return (
        <div
          className="item"
          data-value="1"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            style={{
              backgroundColor: "rgba(81, 178, 218, 1)",
              width: 281,
              height: 283,
              borderRadius: 8,
            }}
            onClick={() => {
              history.push({
                pathname: "/attend-classroom",
                state: { classId: el.classId },
              });
            }}
          >
            <div>
              <div
                style={{
                  width: "246px",
                  height: "178px",
                  position: "relative",
                  margin: "auto",
                }}
              >
                <img
                  src={Certificate}
                  alt=""
                  width="100%"
                  height="100%"
                  style={{
                    marginTop: -15,
                    border: "3px solid white",
                    borderRadius: 8,
                  }}
                ></img>
                <div
                  style={{
                    position: "absolute",
                    left: "35%",
                    top: "43%",
                    fontSize: 10,
                    fontFamily: "Dancing Script",
                    textTransform:'none'
                  }}
                >
                  {`${capitalizeFirstLetter(userName)}`}
                </div>
              </div>
              <div
                style={{
                  color: "white",
                  fontSize: 16,
                  fontWeight: 700,
                  textAlign: "left",
                  textTransform: "none",
                }}
              >
                {el?.mode} {el?.classId}
              </div>
              <div
                style={{
                  color: "white",
                  marginTop: 5,
                  fontSize: 12,
                  fontWeight: 400,
                  textAlign: "left",
                }}
              >
                {dayjs(el.updatedAt).format("DD/MM/YYYY")}
              </div>
            </div>
          </Button>
        </div>
      );
    });
    return InstructorMapped;
  }
};
export default function OurCertificates({ certificates, userName }: Props) {
  const history = useHistory();

  return (
    <div>
      <AliceCarousel
        mouseTracking
        disableDotsControls
        items={handleItem(certificates, userName, history)}
        responsive={responsive}
        renderPrevButton={renderPrevButton}
        renderNextButton={renderNextButton}
        //   autoWidth
      />
    </div>
  );
}
