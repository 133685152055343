import { createSlice } from '@reduxjs/toolkit'
import { testsInitialState } from './tests.initialState'
import { testsReducer } from './tests.reducer'

/* createSlice - a function that accepts an initial state, an object full of reducer 
functions, and a slice name that automatically generates action creators and action 
types that correspond to the reducers and state.  Internally it uses createAction and createReducer */

export const testsSlice = createSlice({
	name: 'tests', // name used in action types
	initialState: testsInitialState, // initial state for the reducer
	reducers: testsReducer
})

export const {
	actions: {
		// action creators exported from testsSlice.actions available out of the box
		create: createtestActionCreator
	}
} = testsSlice
