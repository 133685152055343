import React, { useEffect, useState } from "react";

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Divider,
  Button,
  Paper,
  Typography,
  Breadcrumbs,
  Link,
  Switch,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";

import { useSnackbar } from "notistack";
import * as Yup from "yup";
import Layout from "../../../commons/Layout";
import { v4 as uuidv4 } from "uuid";
import { withRouter, RouteComponentProps } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import {
  selectUserById,
  updateUser,
} from "../../../../stores/userManagement/userManagement.slice";
import { IUserData } from "../../../../stores/userManagement/userManagement";

interface IProps extends RouteComponentProps {}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    test: {
      marginTop: "22px",
    },
    rootForm: {
      flexGrow: 1,
    },
  })
);
const validationSchema = Yup.object().shape({});

const dropDownUserRole = ["Admin", "User"];
const Index = (props: IProps) => {
  const { t } = useTranslation();
  const hiddenFileInput: any = React.useRef();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const dispatch = useDispatch<any>();
  const [loading, setLoading] = useState(true);

  const ReduxDataById = useSelector(
    selectUserById(parseInt((props.match.params as any)?.id))
  );

  useEffect(() => {
    if (ReduxDataById.length === 1) {
      setLoading(false);
      console.log(formik.values);
    } else {
      console.log("NOT FOUND");
    }
  }, [ReduxDataById]);
  const formik = useFormik({
    initialValues: {
      ...ReduxDataById[0],
      imagePhoto:
        "https://ipfs.vajira.evernetwork.io/ipfs/QmfYb2iy2fuB4vyXAS9tCxxi9hox5uLr8C3NhtTC2iARz7",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let updateValue = JSON.parse(JSON.stringify(values));
      updateValue.data.updateAt = new Date();
      console.log(updateValue);
      dispatch(updateUser(updateValue));
      enqueueSnackbar("User update", { variant: "success" });
    },
  });

  function handleReset() {
    formik.resetForm();
  }
  const handleClick = (event: any) => {
    hiddenFileInput.current.click();
  };
  function handleUpload(event: any) {
    if (event.target.files.length > 0) {
      formik.setFieldValue("imagePhoto", event.target.files[0]);
    }
  }
  if (loading) {
    return null;
  }
  return (
    <Layout
      pageAt="userManagementTable"
      render={(propsLayout: any) => (
        <div style={{ padding: 40 }}>
          <div style={{ marginBottom: 20 }}>
            <Typography
              variant="h4"
              color="textPrimary"
              style={{ marginBottom: 10 }}
            >
              {t("userManagement.listEdit")}
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                onClick={() => {
                  props.history.push({
                    pathname: `/`,
                  });
                }}
                color="inherit"
              >
                {t("userManagement.list")}
              </Link>
              <Typography color="textPrimary">
                {t("drawerDashboard.user")}
              </Typography>
            </Breadcrumbs>
          </div>
          <Paper style={{ padding: 24 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                <div
                  style={{
                    width: "90%",
                    border: "1px solid #E0E5EB",
                    borderRadius: 5,
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      backgroundColor: "#F8FAFA",
                    }}
                  >
                    <span
                      style={{
                        fontSize: 11,
                        fontWeight: "bold",
                        marginBottom: 0,
                        padding: 11,
                      }}
                    >
                      {t("drawerDashboard.user")}
                    </span>
                  </div>
                  <div style={{ height: "45%", textAlign: "center" }}>
                    <img
                      alt="imagePHoto"
                      title="Paella dish"
                      src={formik.values.imagePhoto}
                    />
                  </div>

                  <div style={{ textAlign: "center", marginTop: 10 }}>
                    <div
                      onClick={handleClick}
                      style={{
                        textAlign: "center",
                        background: "#F8F8F8",
                        color: "#7A7F8C",
                        borderTop: "1px solid #E0E5EB",
                        boxSizing: "border-box",
                        padding: 10,
                        fontWeight: "bold",
                        fontSize: 11,
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      <span> {t("userManagement.uploadPicture")}</span>
                    </div>

                    <input
                      type="file"
                      onChange={handleUpload}
                      ref={hiddenFileInput}
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={8} xl={6}>
                <form
                  className={classes.rootForm}
                  noValidate
                  autoComplete="off"
                  onSubmit={formik.handleSubmit}
                >
                  <div>
                    <TextField
                      label={t("fieldData.userName")}
                      id="data.userName"
                      name="data.userName"
                      value={formik.values.data.userName}
                      onChange={formik.handleChange}
                      style={{ margin: 8, width: "100%" }}
                      placeholder="Username"
                      margin="normal"
                      variant="outlined"
                    />
                    <TextField
                      label={t("fieldData.cid")}
                      id="data.cid"
                      name="data.cid"
                      value={formik.values.data.cid}
                      onChange={formik.handleChange}
                      style={{ margin: 8, width: "100%" }}
                      placeholder="cid"
                      margin="normal"
                      variant="outlined"
                    />
                    <TextField
                      label={t("fieldData.fullName")}
                      id="data.fullName"
                      name="data.fullName"
                      value={formik.values.data.fullName}
                      onChange={formik.handleChange}
                      style={{ margin: 8, width: "100%" }}
                      placeholder="FullName"
                      margin="normal"
                      variant="outlined"
                    />
                    <div style={{ margin: 8, marginBottom: 40 }}>
                      <Typography> {t("fieldData.role")}</Typography>
                      {formik.values.data.roles !== undefined &&
                        formik.values.data.roles.map((data2, index) => (
                          <React.Fragment key={index}>
                            <div style={{ marginTop: 10 }}>
                              {index + 1}
                              <FormControl
                                variant="outlined"
                                style={{ marginLeft: 20, minWidth: 200 }}
                              >
                                <InputLabel>{t("fieldData.role")}</InputLabel>
                                <Select
                                  label={t("fieldData.role")}
                                  value={data2.display}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      `data.roles[${index}].code`,
                                      e.target.value
                                    );
                                    formik.setFieldValue(
                                      `data.roles[${index}].display`,
                                      e.target.value
                                    );
                                  }}
                                >
                                  {dropDownUserRole.map(
                                    (dataDropdown, index2) => {
                                      return (
                                        <MenuItem
                                          key={index2}
                                          value={dataDropdown}
                                        >
                                          {dataDropdown}
                                        </MenuItem>
                                      );
                                    }
                                  )}
                                </Select>
                              </FormControl>

                              {index !== 0 && (
                                <Button
                                  style={{
                                    marginLeft: 20,
                                    borderColor: "red",
                                    color: "red",
                                  }}
                                  variant="outlined"
                                  onClick={() => {
                                    if (
                                      formik.values.data.roles !== undefined
                                    ) {
                                      let newValue = formik.values.data.roles.filter(
                                        (data3) => {
                                          return data3.code !== data2.code;
                                        }
                                      );

                                      formik.setFieldValue(
                                        "data.roles",
                                        newValue
                                      );
                                    }
                                  }}
                                >
                                  {t("userManagement.deleteRole")}
                                </Button>
                              )}
                            </div>

                            {formik.values.data.roles !== undefined &&
                            index + 1 === formik.values.data.roles.length ? (
                              formik.values.data.roles.length === 0 ? null : (
                                <Button
                                  style={{ marginTop: 20, marginLeft: 30 }}
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => {
                                    if (
                                      formik.values.data.roles !== undefined
                                    ) {
                                      let newValue = [
                                        ...formik.values.data.roles,
                                      ];
                                      newValue.push({
                                        code: uuidv4(),
                                        display: "",
                                      });
                                      formik.setFieldValue(
                                        "data.roles",
                                        newValue
                                      );
                                    }
                                  }}
                                >
                                  {t("userManagement.addNewRole")}
                                </Button>
                              )
                            ) : null}
                          </React.Fragment>
                        ))}
                    </div>
                    <div style={{ margin: 8 }}>
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={(event) =>
                              formik.setFieldValue(
                                "data.active",
                                event.target.checked
                              )
                            }
                            checked={formik.values.data.active}
                            color="primary"
                          />
                        }
                        label={
                          <div style={{ fontWeight: "bold" }}>
                            {t("userManagement.activeAccount")}
                          </div>
                        }
                        labelPlacement="top"
                      />
                    </div>
                  </div>

                  <Divider style={{ margin: "30px 0px", width: "100%" }} />
                  <Grid
                    container
                    spacing={3}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                      <Button
                        style={{ width: "100%", borderRadius: 10 }}
                        variant="outlined"
                        color="primary"
                        onClick={handleReset}
                      >
                        {t("reset")}
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                      <Button
                        style={{ width: "100%", borderRadius: 10 }}
                        color="primary"
                        variant="contained"
                        type="submit"
                      >
                        {t("update")}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </Paper>
        </div>
      )}
    />
  );
};

export default withRouter(Index);
