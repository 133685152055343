import React, { useEffect, useState } from "react";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import { ReactComponent as PatientIcon } from "../../../commons/asset/Patient.svg";
import { Button, Checkbox, FormControlLabel } from "@material-ui/core";
import { IScenario } from ".";
import { useStyles } from "./style";
import clsx from "clsx";
import { useAuth } from "../../../../contexts/AuthContext";
import {
  addFavScenario,
  deleteFavScenarios,
} from "../../../../services/Scenarios/scenario.service";
import { capitalizeFirstLetter } from '../../../commons/CapitalizeFirst';
interface IProp {
  handleSelectScenario: (scenario: IScenario) => void;
  data: any;
  selectedScenario: IScenario | undefined;
  setLoad: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function CardScenario({
  handleSelectScenario,
  data,
  selectedScenario,
  setLoad,
}: IProp) {
  const classes = useStyles();
  const { currentUser } = useAuth();
  const [checked, setChecked] = useState(handleCheckFav());
  useEffect(() => {
    setChecked(handleCheckFav());
  }, [data]);
  function handleCheckFav(): boolean {
    if (data.favBy) {
      for (let i = 0; i < data.favBy.length; i++) {
        if (data.favBy[i] === currentUser?.userId) {
          return true;
        }
      }
      return false;
    } else {
      return false;
    }
  }
  function chipColor(text: string) {
    return (
      <div
        style={{
          backgroundColor: text === "PUBLIC" ? "#DFF6EC" : "#FFEFD3",
          color: text === "PUBLIC" ? "#60D0A1" : "#F9B233",
          padding: "1px 6px",
          marginLeft: 10,
          borderRadius: 2,
        }}
      >
        <div style={{ fontSize: 10 }}>
          {typeof text === "boolean" ? "Practice" : capitalizeFirstLetter(text)}
        </div>
      </div>
    );
  }
  function addFav(data: IScenario) {
    setLoad(true);
    if (data._id && !checked) {
      addFavScenario(data._id).then(() => {
        setLoad(false);
      });
    } else if (data._id && checked) {
      deleteFavScenarios(data._id).then(() => {
        setLoad(false);
      });
    }
    setChecked(!checked);
  }
  return (
    <div style={{ display: "flex", width: "100%", position: "relative" }}>
      <Button
        className={clsx(
          classes.buttonCard,
          data._id === selectedScenario?._id && classes.selectedCard
        )}
        onClick={() => handleSelectScenario(data)}
      >
        <div style={{ padding: "0px 14px" }}>
          <div
            style={{
              fontSize: 14,
              fontWeight: 700,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                textTransform: "none",
              }}
            >
              {data.code}
              <div>
                {data.accessLevel === "PUBLIC" && chipColor(data.accessLevel)}
              </div>
              <div>{data.practice && chipColor(data.practice)}</div>
            </div>
          </div>
          <div
            style={{
              fontSize: 12,
              fontWeight: 400,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              color: "#B9B9B9",
            }}
          >
            <div>
              <LocationOnIcon style={{ width: 12, marginRight: 3 }} />
              {data.desc}
            </div>
            <div>
              <PatientIcon style={{ width: 8 }} /> {data?.patientMaps?.length}
            </div>
          </div>
        </div>
      </Button>
      <FormControlLabel
        style={{
          width: "10px",
          height: "10px",
          zIndex: 111,
          position: "absolute",
          right: 25,
          top: 20,
        }}
        control={
          <Checkbox
            icon={<StarBorderIcon fontSize="small" />}
            checkedIcon={
              <StarIcon fontSize="small" style={{ color: "#F9B233" }} />
            }
            checked={checked}
            onClick={() => addFav(data)}
            name="checkedH"
          />
        }
        label=""
      />
    </div>
  );
}
