import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from "@material-ui/core";
import dayjs from "dayjs";
import ButtonContainedAction from "../../../../commons/Buttons/ButtonContainedAction";
import { convertStringToImg } from "../../../../commons/ConvertImg";
import Copy from "../../../../commons/asset/copy.png";
import { useRef } from "react";
interface props {
  selectData: any;
  changeState: any;
}
function Approve({ selectData, changeState }: props) {
  const textfieldRef = useRef<any>(null);
  const copy = async () => {
    await navigator.clipboard.writeText(selectData?.oculusEmail);
  };
  function selectText() {
    if (textfieldRef && textfieldRef) {
      textfieldRef.current.focus();
      copy();
    }
  }
  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Avatar
            style={{ width: 180, height: 180 }}
            src={convertStringToImg(selectData)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Grid
            container
            spacing={2}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Grid item xs={3}>
              <Typography
                style={{
                  color: "#5A5A5A",
                  fontWeight: 500,
                  fontSize: 14,
                }}
              >
                Role
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography
                style={{
                  color: "#5A5A5A",
                  fontWeight: 300,
                  fontSize: 14,
                }}
              >
                {selectData?.userRoles[0]}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                style={{
                  color: "#5A5A5A",
                  fontWeight: 500,
                  fontSize: 14,
                }}
              >
                Name
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography
                style={{
                  color: "#5A5A5A",
                  fontWeight: 300,
                  fontSize: 14,
                }}
              >
                {selectData?.firstName + " " + selectData?.lastName}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                style={{
                  color: "#5A5A5A",
                  fontWeight: 500,
                  fontSize: 14,
                }}
              >
                Email
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography
                style={{
                  color: "#5A5A5A",
                  fontWeight: 300,
                  fontSize: 14,
                }}
              >
                {selectData?.email}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                style={{
                  color: "#5A5A5A",
                  fontWeight: 500,
                  fontSize: 14,
                }}
              >
                Oculus Email
              </Typography>
            </Grid>
            <Grid item xs={9} style={{ display: "flex" }}>
              <Typography
                style={{
                  color: "#5A5A5A",
                  fontWeight: 300,
                  fontSize: 14,
                }}
                ref={textfieldRef}
              >
                {selectData?.oculusEmail}
              </Typography>
              <IconButton
                style={{
                  padding: 0,
                  height: "20px",
                  width: "20px",
                  marginLeft: 5,
                }}
                onClick={selectText}
              >
                <img src={Copy} alt="" width={15} height={15} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              marginTop: 50,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              href="https://developer.oculus.com/manage/applications/5751266504903503/release-channels/1097562047538617/users/"
              target="_blank"
              style={{
                fontWeight: 400,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textTransform: "none",
                backgroundColor: "#51B2DA",
                textOverflow: "ellipsis",
                color: "white",
                borderRadius: 40,
                padding: "9px 30px",
              }}
            >
              Add User to Application
            </Button>
            <ButtonContainedAction
              colorText="blue"
              disabled={selectData?.confirmEmail ? false : true}
              onClick={() => changeState("APPROVE", selectData)}
              style={{
                fontWeight: 400,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              Approved
            </ButtonContainedAction>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
export default Approve;
