import { ButtonProps } from "@material-ui/core";
import { BtnContained } from "./styles";
interface BtnCustomProps {
  colorText: string;
}
const ButtonContained = ({ colorText, ...props }: ButtonProps & BtnCustomProps) => {
  return (
    <BtnContained customcolor={colorText} variant="contained" {...props} />
  );
};

export default ButtonContained;
