import styled, { StyledComponent } from "styled-components";
import {
  InputLabel,
  Select,
  TextField,
  TextFieldProps,
} from "@material-ui/core";
import { Theme, withStyles } from "@material-ui/core/styles";
interface ICustomInput {
  theme: Theme;
}
export const CustomInputTextField: StyledComponent<
  (props: TextFieldProps & ICustomInput) => JSX.Element,
  any,
  {},
  never
> = styled(TextField)`
  && {
    .MuiFormLabel-root.Mui-focused {
      // color: ${({ theme }) => theme.palette.primary.main};
    }
    .MuiFormLabel-root {
      // color: ${({ theme }) => theme.palette.textBlack[600]};
      .Mark-required {
        // color: ${({ theme }) => theme.palette.error.main};
      }
    }
    label + .MuiInput-formControl {
      margin-top: 24px;
    }
    .MuiInputBase-input {
      border-radius: 8px;
      padding: 10px 16px;
      transition: all 0.3s;
      // border: 1px solid ${({ theme }) => theme.palette.action2[700]};
      font-size: 14px;
      font-weight: 400;
      // color: ${({ theme }) => theme.palette.textBlack[800]};
      :hover {
        // border: 1px solid ${({ theme }) => theme.palette.grey[600]};
        border-right-width: 1px !important;
      }
    }
    .Mui-focused {
      .MuiInputBase-input {
        // border: 1px solid ${({ theme }) => theme.palette.primary.main};

        border-right-width: 1px !important;
        outline: 0;
        /* box-shadow: 0 0 0 2px rgb(29 165 122 / 20%); */
        transition: all 0.3s;
      }
    }
    .Mui-error {
      .MuiInputBase-input {
        /* background: rgba(244, 67, 54, 0.4); */
        // border-color: ${({ theme }) => theme.palette.error.main};
        box-shadow: none;
        transition: all 0.3s;
      }
    }
    .MuiInputLabel-root {
      &.Mui-error {
        // color: ${({ theme }) => theme.palette.error.main};
      }
    }
    .MuiInputLabel-shrink {
      transform: translate(0, 1.5px) scale(1);
    }
    input[type="date"]::-webkit-calendar-picker-indicator {
    }
    input[type="time"]::-webkit-calendar-picker-indicator {
    }

    /* input no border */
    &.No-outlined {
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type="number"] {
        -moz-appearance: textfield;
      }
      .MuiInputBase-input {
        text-align: right;
        padding: 0px 4px 0px 0px;
        border: none;
        font-size: 14px;
        font-weight: 400;
        // color: ${({ theme }) => theme.palette.textBlack[800]};
        :hover {
          border: none;
        }
      }
      .Mui-focused {
        .MuiInputBase-input {
          border: none;
        }
      }
    }

    /* input with adornment */
    &.Adornment-Input {
      .MuiInput-root {
        border-radius: 8px;
        // border: 1px solid ${({ theme }) => theme.palette.action2[700]};
        :hover {
          // border: 1px solid ${({ theme }) => theme.palette.grey[600]};
          border-right-width: 1px !important;
        }
      }

      .Mui-focused.MuiInput-root {
        // border: 1px solid ${({ theme }) => theme.palette.primary.main};
        border-right-width: 1px !important;
        outline: 0;
        transition: all 0.3s;
      }

      .Mui-error.MuiInput-root {
        // border-color: ${({ theme }) => theme.palette.error.main};
        box-shadow: none;
        transition: all 0.3s;
      }

      .MuiInputBase-input {
        border-radius: 8px;
        /* padding: 10px 16px; */
        transition: all 0.3s;
        border: none;
        font-size: 14px;
        font-weight: 400;
        color: ${({ theme }) => theme.palette.textBlack[800]};
        :hover {
          border: none;
          border-right-width: 1px !important;
        }
      }

      .MuiInputBase-inputAdornedEnd {
        padding: 10px 8px 10px 16px;
      }

      .MuiInputBase-inputAdornedStart {
        padding: 10px 16px 10px 0px;
      }

      .MuiInputAdornment-root {
        margin: 4px;
      }
    }
  }
`;
export const CustomSelectField = styled(Select)`
  && {
    width: 100%;
    color: #555555;
    border-radius: 8px;
    :hover {
      .MuiInputBase-input {
        background: #eeeef1;
      }
    }
    .MuiInputBase-input {
      font-size: 16px;
      font-weight: 400;
      padding: 13px 16px;
      border-radius: 16px;
      background: #f5f5f7;
      &.Mui-disabled {
        background: #f5f5f7;
      }
    }
    .MuiSelect-select {
      :focus {
        border: 2px solid #51b2da;
        // border-color: red;
      }
    }
    &.Mui-error {
      .MuiInputBase-input {
        // border: 1px solid red;
      }
    }
    :hover {
      ::before {
        border-bottom: none;
      }
    }

    ::before {
      border-bottom: none;
    }
    ::after {
      border-bottom: none;
    }
  }
`;

export const CustomSelectFieldScenario = styled(Select)`
  && {
    width: 100%;
    color: #555555;
    border-radius: 8px;
    :hover {
      .MuiInputBase-input {
        background: #eeeef1;
      }
    }
    .MuiInputBase-input {
      padding: 5px 14px;
      font-size: 16px;
      font-weight: 400;
      background: #f5f5f7;
      &.Mui-disabled {
        background: #f5f5f7;
        color: #9C9B9D ;
      }
    }
    .MuiSelect-select {
      :focus {
        border: 2px solid #51b2da;
        // border-color: red;
      }
    }
    &.Mui-error {
      .MuiInputBase-input {
        // border: 1px solid red;
      }
    }
    :hover {
      ::before {
        border-bottom: none;
      }
    }

    ::before {
      border-bottom: none;
    }
    ::after {
      border-bottom: none;
    }
  }
`;

export const SelectLabel = styled(InputLabel)<ICustomInput>`
  && {
    &.MuiInputLabel-root {
      font-size: 12px;
      font-weight: 700;
      color: ${({ theme }) => theme.palette.textBlack[600]};
      &.Mui-error {
        color: ${({ theme }) => theme.palette.error.main};
      }
      &.Mui-focused {
        color: ${({ theme }) => theme.palette.primary.main};
        &.Mui-error {
          color: ${({ theme }) => theme.palette.error.main};
        }
      }
      .Mark-required {
        color: ${({ theme }) => theme.palette.error.main};
      }
    }
    &.MuiInputLabel-shrink {
      transform: translate(0, 4.5px) scale(1);
    }
  }
`;

export const TextFieldStyle = withStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiOutlinedInput-input": {
      padding: "13px 20px",
      width: "100%",
    },
    "&.MuiFormControl-root": {
      // width: "100%",
    },
    "& .MuiOutlinedInput-root": {
      // padding: 20,
      height: 45,
      borderRadius: 16,
      backgroundColor: "#F5F5F7",
      "&.Mui-disabled": {
        backgroundColor: "#F5F5F7",
      },
    },
    "& .MuiFormHelperText-root": {
      color: theme.palette.error.main,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "&:hover .MuiOutlinedInput-root": {
      backgroundColor: "#EEEEF1",
    },
    ".MuiOutlinedInput-root": {
      "&.Mui-error": {
        backgroundColor: "red",
      },
    },
    "& .MuiInputBase-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#51B2DA",
      },
    },
    "&:not(:focus) .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
  },
}))(TextField);

export const TextFieldStyleSerch = withStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiOutlinedInput-input": {
      // padding: "13px 20px",
      width: "100%",
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
      },
    },
    "&.MuiFormControl-root": {
      // width: "100%",
    },
    "& .MuiOutlinedInput-root": {
      // padding: 20,
      height: 30,
      borderRadius: 7,
      backgroundColor: "#F5F5F7",
      "&.Mui-disabled": {
        backgroundColor: "#F5F5F7",
      },
    },
    "& .MuiFormHelperText-root": {
      color: theme.palette.error.main,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "&:hover .MuiOutlinedInput-root": {
      backgroundColor: "#EEEEF1",
    },
    ".MuiOutlinedInput-root": {
      "&.Mui-error": {
        backgroundColor: "red",
      },
    },
    "& .MuiInputBase-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#51B2DA",
      },
    },
    "&:not(:focus) .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
  },
}))(TextField);

export const TextFieldStyleAutocomplete = withStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiInputBase-input": {
      // padding: "13px 20px",
      // height: 45,
      padding: 0,
    },
    "& .MuiAutocomplete-inputRoot[class*=MuiOutlinedInput-root][class*=MuiOutlinedInput-marginDense]": {
      borderRadius: 16,
      backgroundColor: "#F5F5F7",
      padding: "13px 20px",
    },
    "& .MuiAutocomplete-inputRoot[class*=MuiOutlinedInput-root] .MuiAutocomplete-input:first-child": {
      padding: 0,
    },
    "&:hover .MuiAutocomplete-inputRoot[class*=MuiOutlinedInput-root][class*=MuiOutlinedInput-marginDense]": {
      backgroundColor: "#EEEEF1",
    },
    // "&:focus .MuiOutlinedInput-root": {
    //   backgroundColor: "red",
    // },
    "& .MuiInputBase-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#51B2DA",
      },
    },
    "& .MuiFormHelperText-root": {
      color: theme.palette.error.main,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "&:not(:focus) .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
  },
}))(TextField);
export const Mark_Required = styled.span`
  && {
    color: ${({ theme }) => theme.palette.error.main};
  }
`;
