import React, { useEffect, useState } from "react";
import { Notifications } from "react-push-notification";
import addNotification from "react-push-notification";
import minMax from "dayjs/plugin/minMax";
import utc from "dayjs/plugin/utc";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { Calendar, momentLocalizer, DateLocalizer } from "react-big-calendar";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import "react-calendar/dist/Calendar.css";
import CalendarBtn from "react-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import dayjs from "dayjs";
import { formats } from "./dayjsLocalizer";
import timezone from "dayjs/plugin/timezone";
import localeData from "dayjs/plugin/localeData";
import { Fade, IconButton, Popover, Popper, Toolbar } from "@material-ui/core";
import { useStyles } from "./style";
import EventWrapper from "./EventWrapper";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
dayjs.locale("en");
dayjs.extend(isSameOrBefore);
dayjs.extend(localeData);
dayjs.extend(minMax);
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
interface IClassroom {
  classrooms: any;
}
export default function Overview({ classrooms }: IClassroom) {
  const classes = useStyles();
  const { currentUser } = useAuth();
  const history = useHistory();
  const [events, setEvents] = useState<any>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [date, setDate] = useState(new Date());
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  const buttonClick = () => {
    addNotification({
      title: "Warning",
      subtitle: "This is a subtitle",
      message: "This is a very long message TEST",
      theme: "darkblue",
      duration: 500000,
      native: true, // when using native, your OS will handle theming.
    });
  };
  const dayjsLocalizer = () => {
    let locale = (m: any, c: any) => (c ? m.locale(c) : m);

    return new DateLocalizer({
      formats,
      firstOfWeek(culture) {
        let data = dayjs.localeData();
        return data ? data.firstDayOfWeek() : 0;
      },

      format(value, format, culture) {
        return locale(dayjs(value), culture).format(format);
      },
    });
  };
  dayjs.extend(localeData);
  const localizer = dayjsLocalizer();
  useEffect(() => {
    if (classrooms) {
      const mappedClassrooms = classrooms.map((ele: any) => {
        return {
          start: dayjs(ele.classDate).toDate(),
          end: dayjs(ele.classDate).add(90, "m").toDate(),
          title: ele.classId,
          detail: ele,
        };
      });
      setEvents(mappedClassrooms);
    }
  }, [classrooms]);

  const calendarStyle = () => {
    return {
      style: {
        backgroundColor: "rgba(245, 245, 245, 1)", //this works
        border: "none",
      },
    };
  };
  const getter = () => {
    return {
      style: {
        border: "3px solid white",
        borderLeft: "3px solid red",
      },
    };
  };

  return (
    <div
      style={{
        backgroundColor: "rgba(245, 245, 245, 1)",
        borderRadius: 16,
      }}
    >
      <div style={{ padding: "10px 20px 10px 20px" }}>
        <div
          style={{
            fontSize: 22,
            fontWeight: 700,
            color: "#5A5A5B",
            margin: 10,
          }}
        >
          Schedule
        </div>
        <div style={{ display: "flex", alignItems: "center", marginLeft: 30 }}>
          <div>{dayjs(date).format("dddd D MMM, YYYY")}</div>
          <IconButton onClick={handleClick}>
            <CalendarTodayOutlinedIcon />
          </IconButton>
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div style={{ width: 300 }}>
            <CalendarBtn
              className={classes.calendar}
              value={date}
              onChange={(e: any) => {
                setDate(e);
                setAnchorEl(null);
              }}
            />
          </div>
        </Popover>
        <Calendar
          components={{
            toolbar: (props) => <Toolbar {...props} />,
          }}
          className={classes.appointment}
          // onSelectEvent={function noRefCheck() {}}
          onSelectEvent={(slotInfo: any) => {
            history.push({
              pathname:
                currentUser?.roles[0] === "ADMIN" ||
                currentUser?.roles[0] === "INSTRUCTOR"
                  ? "/classroom-management"
                  : slotInfo.detail.status === "FINISHED"
                  ? "/attend-classroom"
                  : "/assign-classroom",
              state: { classroom: slotInfo.detail },
            });
          }}
          onNavigate={() => {
            console.log("AA");
          }}
          toolbar={false}
          localizer={localizer}
          events={events}
          onView={() => {}}
          step={60}
          style={{ height: 630 }}
          date={date}
          view="day"
          startAccessor="start"
          endAccessor="end"
          timeslots={1}
          dayPropGetter={calendarStyle}
          dayLayoutAlgorithm="no-overlap"
        />
      </div>
    </div>
  );
}
