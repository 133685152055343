import OurScenarios from "./ourElement/ourScenarios";

const Scenarios = ({ scenarios }: any) => (
  <div
    style={{
      height: 365,
      backgroundColor: "rgba(165, 166, 246, 0.15)",
      borderRadius: 16,
    }}
  >
    <div style={{ padding: 10 }}>
      <div
        style={{
          fontSize: 22,
          fontWeight: 700,
          color: "#5A5A5B",
          margin: 10,
        }}
      >
        Scenario
      </div>
      <div>
        {scenarios?.length > 0 && <OurScenarios scenarios={scenarios} />}
      </div>
    </div>
  </div>
);
export default Scenarios;
