import {
  Button,
  Checkbox,
  debounce,
  FormControlLabel,
  IconButton,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { TextFieldStyleSerch } from "../../../commons/Inputs/style";
import CardScenario from "./cardScenario";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";
import StarIcon from "@material-ui/icons/Star";
import {  IScenario, stateName } from ".";
import { ButtonContained } from "../../../commons/Buttons";
import { useAuth } from "../../../../contexts/AuthContext";
import {
  addFavScenario,
  deleteScenarioMany,
  getScenario,
} from "../../../../services/Scenarios/scenario.service";
import { useSnackbar } from "notistack";
import { PatientPositionPoint } from "./patientPositionPoint";
import { handleCaseColor } from "./pointToTrash";
import CreateIcon from "@material-ui/icons/Create";
import Bus_hits_Electric_pole from "../assetMock/Map-1.png";
import Bus_accident from "../assetMock/Map-2.png";
import { isEmpty } from "lodash";
import { checkPagePermission } from '../../../commons/CheckRole';
import HarmFactor from "../assetMock/HarmFactor.png";
import HarmFactorActive from '../assetMock/HarmFactorActive.png'
interface IProp {
  scenarios: never[];
  setScenarios: React.Dispatch<React.SetStateAction<never[]>>;
  changeState: (state: stateName) => void;
  handleSelectScenario: (scenario: IScenario) => void;
  selectedScenario: any;
  filterFav: boolean;
  setFilterFav: React.Dispatch<React.SetStateAction<boolean>>;
  setLoad: React.Dispatch<React.SetStateAction<boolean>>;
  setForceUpdateData: React.Dispatch<React.SetStateAction<number>>;
  handleOpenRecheck: (callback: () => void) => void;
  setPage: React.Dispatch<React.SetStateAction<string>>;
  positionPoint: any;
  setPositionPoint: any;
  CalculateAxisXPatient: (axisX: number) => number;
  CalculateAxisYPatient: (axisY: number) => number;
  harmFactors: any;
}
export default function SelectScenario({
  scenarios,
  setScenarios,
  changeState,
  handleSelectScenario,
  selectedScenario,
  filterFav,
  setFilterFav,
  setLoad,
  setForceUpdateData,
  handleOpenRecheck,
  setPage,
  positionPoint,
  setPositionPoint,
  CalculateAxisXPatient,
  CalculateAxisYPatient,
  harmFactors
}: IProp) {
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useAuth();
  const [select, setSelect] = useState(false);
  const [isSelectAll, setIsSelect] = useState(false);
  const [checked, setChecked] = useState([{ id: "", check: false }]);
  const debounceTime = 300;
  function handleSelect() {
    setSelect(!select);
  }
  function handleFav() {
    var filterSenarios: any = [];
    if (!filterFav) {
      scenarios.forEach((item: any) => {
        if (item.favBy && item.favBy.length > 0) {
          item.favBy.forEach((item2: any) => {
            if (item2 === currentUser?.userId) {
              filterSenarios.push(item);
            }
          });
        }
      });
      setScenarios(filterSenarios);
    } else {
      setScenarios([]);
    }
    setFilterFav(!filterFav);
  }
  function handleSelectAll() {
    scenarios.forEach((item: any, index: any) => {
      checked[index].check = !isSelectAll;
    });
    setChecked([...checked]);
    setIsSelect(!isSelectAll);
  }
  function handleSelectCheckedBox(index: number) {
    if (checked[index]) {
      checked[index].check = !checked[index]?.check;
      setChecked([...checked]);
    }
  }
  function handleSelectForDelete() {
    var valueForDelete: any = [];
    const mappedIdScenario = scenarios.map((item: any) => {
      return { scenarioid: item._id };
    });

    mappedIdScenario.forEach((item: any, index) => {
      if (item.scenarioid === checked[index].id && checked[index].check) {
        valueForDelete.push({ scenarioid: item.scenarioid });
      }
    });
    deleteScenarioMany(valueForDelete)
      .then((res) => {
        if(res.data.status === "Success"){
          enqueueSnackbar("Deleted Success", {
            variant: "success",
          });
          setForceUpdateData((prev: any) => prev + 1);
          setPage("PAGE_SELECT");
          setSelect(false);
          setIsSelect(false);
        }
        else {
          enqueueSnackbar("Deleted Error", {
            variant: "error",
          });
        }
      })
      .catch(() => {
        enqueueSnackbar("Deleted Error", {
          variant: "error",
        });
      });
  }
  async function handleSelectForAddFav() {
    var valueForAddFav: any = [];

    scenarios.forEach((item: any, index) => {
      if (checked[index].check) {
        if (!item.favBy.includes(currentUser?.userId)) {
          valueForAddFav.push(item._id);
        }
      }
    });

    if (valueForAddFav.length > 0) {
      setLoad(true);
      for (let i = 0; i < valueForAddFav.length; i++) {
        const value = valueForAddFav[i];
        await addFavScenario(value);
      }
      setLoad(false);
      setForceUpdateData((prev: any) => prev + 1);
      setSelect(false);
      setIsSelect(false);
    }
  }
  useEffect(() => {
    if (scenarios) {
      const temp = scenarios.map((item: any) => {
        return { id: item._id, check: false };
      });
      setChecked(temp);
    }
  }, [scenarios]);
  const getScenarioBySearch = debounce(function (inputData) {
    getScenario({ search: inputData }).then((res) => {
      if (res?.data?.result) {
        setScenarios(res.data.result);
      }
    });
  }, debounceTime);
  return (
    <>
      <div style={{ display: "flex", marginTop: 13 }}>
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <TextFieldStyleSerch
              style={{ width: 190 }}
              size="small"
              name="lastName"
              variant="outlined"
              onChange={(e) => getScenarioBySearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    size="small"
                    edge="end"
                  >
                    <SearchIcon fontSize="small" />
                  </IconButton>
                ),
              }}
            />
            <Button
              style={{
                backgroundColor: filterFav ? "#FFEFD3" : "#F5F5F7",
                color: filterFav ? "#F9B233" : "#B9B9B9",
                width: 120,
                height: 30,
                textTransform: "none",
                fontSize: 12,
                fontWeight: 400,
              }}
              onClick={handleFav}
              startIcon={<StarIcon />}
            >
              My Favourites
            </Button>
          </div>
          <div
            style={{
              height: 586,
              width: 345,
              backgroundColor: "#F6FAFF",
              borderRadius: 8,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              padding: "0px 15px",
              marginTop: "8px",
              overflowY: "auto",
              position: "relative",
            }}
          >
            {scenarios.map((item: any, index: number) => (
              <div style={{ display: "flex", width: "100%" }} key={index}>
                {select && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <FormControlLabel
                      style={{ marginRight: 0 }}
                      control={
                        <Checkbox
                          inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                          checked={checked[index]?.check || false}
                          name="gilad"
                          onChange={() => handleSelectCheckedBox(index)}
                        />
                      }
                      label=""
                    />
                  </div>
                )}
                <CardScenario
                  data={item}
                  handleSelectScenario={handleSelectScenario}
                  selectedScenario={selectedScenario}
                  setLoad={setLoad}
                />
              </div>
            ))}
            {select && (
              <div
                style={{
                  position: "absolute",
                  bottom: 12,
                  right: 18,
                  display: "flex",
                  flexDirection: "column",
                  zIndex: 111,
                }}
              >
                <IconButton
                  onClick={handleSelectForAddFav}
                  disabled={checked?.filter((x)=>x?.check === true).length === 0}
                  style={{  color: "white", backgroundColor:checked?.filter((x)=>x?.check === true).length === 0?'rgba(179, 222, 240, 1)' : '#67BDE0'
                }}
                >
                  <StarIcon />
                </IconButton>
                <IconButton
                  style={{
                    color: "white",
                    marginTop: 12,
                    backgroundColor:checked?.filter((x)=>x?.check === true).length === 0?'rgba(179, 222, 240, 1)' : '#67BDE0'
                  }}
                  disabled={checked?.filter((x)=>x?.check === true).length === 0}
                  // onClick={handleSelectForDelete}
                  onClick={() => {
                    changeState("DELETE_ALL");
                    handleOpenRecheck(handleSelectForDelete);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            )}
            {/* {select && (
              <div
                style={{
                  position: "absolute",
                  bottom: 12,
                  right: 18,
                  display: "flex",
                  flexDirection: "column",
                  zIndex: 111,
                }}
              >
                <IconButton
                  style={{
                    color: "white",
                    marginTop: 12,
                    backgroundColor:checked?.filter((x)=>x?.check === true).length === 0?'rgba(179, 222, 240, 1)' : '#67BDE0'
                  }}
                  disabled={checked?.filter((x)=>x?.check === true).length === 0}
                  // onClick={handleSelectForDelete}
                  onClick={() => {
                    changeState("DELETE_ALL");
                    handleOpenRecheck(handleSelectForDelete);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            )} */}
          </div>
        </div>
        <div
          style={{
            marginTop: 37,
            marginLeft: 20,
            height: 590,
            width: 900,
            backgroundColor: "#F6FAFF",
          }}
        >
          {selectedScenario && Object.keys(selectedScenario).length > 0 ? (
            <div>
              <div style={{ position: "relative", width: 900, height: 590 }}>
                <img
                  src={
                    selectedScenario.desc === "Chemical Factory Explosion"
                      ? Bus_accident
                      : Bus_hits_Electric_pole
                  }
                  height={586}
                  style={{ position: "absolute" }}
                />
                <div
                  style={{
                    position: "absolute",
                  }}
                >
                   {positionPoint.map((item: any, index: number) =>
                    item.caseColor ? (
                      <div key={index}>
                        <div
                          style={{
                            left: CalculateAxisXPatient(item.x),
                            top: CalculateAxisYPatient(item.y),
                            position: "absolute",
                            zIndex: 0.5,
                          }}
                        >
                          <PatientPositionPoint
                            index={index}
                            bgColor={handleCaseColor(item.caseColor)}
                          />
                        </div>
                      </div>
                    ) : item.type === "HarmFactor" ? (
                      <div
                        key={index}
                        style={{
                          left: CalculateAxisXPatient(item.x),
                          top: CalculateAxisYPatient(item.y),
                          position: "absolute",
                          zIndex: 0.5,
                        }}
                      >
                    <img src={harmFactors.some((ele:any)=> ele.index === item.index) ? HarmFactorActive : HarmFactor} alt="" />
                      </div>
                    ) : (
                      <div style={{ position: "relative" }} key={index}>
                        <div
                          style={{
                            left: CalculateAxisXPatient(item.x),
                            top: CalculateAxisYPatient(item.y),
                            position: "absolute",
                          }}
                        >
                          <PatientPositionPoint index={index} />
                        </div>
                      </div>
                    )
                  )}
                </div>
                {!isEmpty(selectedScenario) && currentUser?.userId === selectedScenario?.createdUser && checkPagePermission(currentUser?.roles, "HANDLESCENARIO")
                && (
                    <ButtonContained
                      style={{
                        position: "absolute",
                        left: "calc(50% - 65px)",
                        bottom: 15,
                      }}
                      startIcon={<CreateIcon />}
                      colorText="blue"
                      onClick={() => changeState("UPDATE")}
                    >
                      Edit
                    </ButtonContained>
                  )}
              </div>
            </div>
          ) : (
            <div
              style={{
                height: 590,
                width: 900,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#B9B9B9",
              }}
            >
              Select a scenario to see detail.
            </div>
          )}
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "space-between", width: 320 }}
      >
        {!select ? (
          <Button
            variant="text"
            style={{ color: "#67BDE0", textTransform: "none" }}
            onClick={handleSelect}
          >
            Select
          </Button>
        ) : (
          <>
            {!isSelectAll ? (
              <Button
                variant="text"
                style={{ color: "#67BDE0", textTransform: "none" }}
                onClick={handleSelectAll}
              >
                Select all
              </Button>
            ) : (
              <Button
                variant="text"
                style={{ color: "#67BDE0", textTransform: "none" }}
                onClick={handleSelectAll}
              >
                Deselect all
              </Button>
            )}
          </>
        )}
        {select && (
          <Button
            variant="text"
            style={{ color: "#67BDE0", textTransform: "none" }}
            onClick={handleSelect}
          >
            Cancel
          </Button>
        )}
      </div>
    </>
  );
}
