import i18n from 'i18next'
import en from './en/translation.json'
import th from './th/translation.json'
import { initReactI18next } from 'react-i18next'

export const resources = {
	th: {
		translation: th
	},
	en: {
		translation: en
	}
}

i18n.use(initReactI18next).init({
	lng: 'th',
	resources
})
