import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import _ from "lodash";
import { TextFieldStyle, useStyles } from "../style";
import { SelectField, TextFieldCustom } from "../../../../commons/Inputs";
import BoyIcon from "../asset/boy.svg";
import Avatar from "@material-ui/core/Avatar";
import { ReactComponent as SelectIcon } from "../asset/editphoto.svg";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { IOrganization } from "..";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import dayjs from "dayjs";
import { Occupations } from './Preview';
interface IProps {
  formik: any;
  stepCreate: number;
  organizationAll: IOrganization[];
  generatePassword: () => void;
}
export const Permission: any = [
  {
    title: "User registration approval",
    subTitle: "This user can approve newly registered accounts.",
  },
  {
    title: "Modify patients",
    subTitle: "This user can edit patient details on the scenario page.",
  },
  {
    title: "Modify scenarios",
    subTitle:
      "This user can edit the details of the scenario on the scenario page.",
  },
];
const ManagementUser = ({
  formik,
  stepCreate,
  organizationAll,
  generatePassword,
}: IProps) => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [addPermission, setAddPermission] = useState<boolean[]>([
    false,
    false,
    false,
  ]);
  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }
  function handleClickPermission(indexSelected: number) {
    addPermission.forEach((item: any, index: number) => {
      if (indexSelected === index) {
        addPermission[index] = !addPermission[index];
      }
    });
    setAddPermission([...addPermission]);
  }
  const handleImage = (name: any) => (event: any) => {
    if (event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        formik.setFieldValue("img", event.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  
  return (
    <>
      <Grid container spacing={2}>
        {stepCreate === 0 && (
          <>
            {/* Pic */}
            <Grid item={true} xs={5}></Grid>
            <Grid item={true} xs={2} style={{display:'flex', justifyContent:'center'}}>
              <div style={{ position: "relative" }}>
                <Avatar
                  style={{ width: 130, height: 130 }}
                  src={formik.values.img ? formik.values.img : BoyIcon}
                />
                <div className={classes.rootInput}>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="icon-button-file"
                    type="file"
                    onChange={handleImage("file")}
                  />
                  <label htmlFor="icon-button-file">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      style={{ position: "absolute", bottom: 10, right: 0 }}
                    >
                      <SelectIcon fontSize="small" />
                    </IconButton>
                  </label>
                </div>
              </div>
            </Grid>
            <Grid item={true} xs={5}></Grid>
            {/* Role */}
            {matches && <Grid item={true} xs={2}></Grid>}
            <Grid item={true} xs={2} style={{display:'flex',alignItems:'center'}}>
              Role
            </Grid>
            <Grid item={true} lg={6} xl={6} md={6} sm={6} xs={9}>
              <SelectField
                name="role"
                displayEmpty
                value={formik.values.role}
                onClick={formik.handleChange}
                error={formik.touched.role && formik.errors.role ? true : false}
                textAlert={
                  formik.errors.role &&
                  formik.touched.role &&
                  formik.errors.role
                }
                renderValue={
                  formik.values.role !== ""
                    ? undefined
                    : () => <div className={classes.hintText}>Role</div>
                }      
              >
                <MenuItem value={"instructor"}>Instructor</MenuItem>
                <MenuItem value={"student"}>Student</MenuItem>
              </SelectField>
            </Grid>
            {matches && <Grid item={true} xs={2}></Grid>}
            {matches && <Grid item={true} xs={2}></Grid>}
            <Grid item={true} xs={2} style={{display:'flex',alignItems:'center'}}>
              Email
            </Grid>
            <Grid item={true} lg={6} xl={6} md={6} sm={6} xs={9}>
              <TextFieldCustom
                name="email"
                placeholder='Email'
                onChange={formik.handleChange}
                InputProps={{ classes: { input: classes.inputText } }}
                value={formik.values.email}
                variant="outlined"
                error={
                  formik.touched.email && formik.errors.email ? true : false
                }
                textAlert={
                  formik.errors.email &&
                  formik.touched.email &&
                  formik.errors.email
                }
              />
            </Grid>
            {matches && <Grid item={true} xs={2}></Grid>}
            {/* Password */}
            {matches && <Grid item={true} xs={2}></Grid>}
            <Grid item={true} xs={2} style={{display:'flex',alignItems:'center'}} >
              Password
            </Grid>
            <Grid item={true} lg={6} xl={6} md={6} sm={6} xs={9} style={{display:'flex',alignContent:'space-between'}}>
            <TextFieldCustom
                size="small"
                placeholder="Password"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                name="password"
                style={{width:matches ? 300 : 500}}
                onChange={formik.handleChange}
                value={formik.values.password}
                error={
                  formik.touched.password && formik.errors.password
                    ? true
                    : false
                }
                textAlert={
                  formik.errors.password &&
                  formik.touched.password &&
                  formik.errors.password
                }
                InputProps={{
                  classes: { input: classes.inputText },
                  endAdornment: (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      size="small"
                      edge="end"
                    >
                      {showPassword ? (
                        <Visibility fontSize="small" />
                      ) : (
                        <VisibilityOff fontSize="small" />
                      )}
                    </IconButton>
                  ),
                }}
              />
               <a
                id="myLink"
                href="#"
                onClick={() => generatePassword()}
                style={{
                  fontSize: 4,
                  textDecoration: "underline",
                  paddingLeft: 30,
                }}
              >
                Generate Password
              </a>
            </Grid>
            <Grid item={true} xs={2}>
            </Grid>
            {matches ? <Grid item={true} xs={4}></Grid>:<Grid item={true} xs={2}></Grid>}
            <Grid lg={6} xl={6} md={6} sm={6} xs={7}>
              <FormControl>
                <FormHelperText style={{ color: "#F15F5F", fontSize: 5,textAlign:'left' }}>
                  *Please remember your password, can't go back and look again.
                </FormHelperText>
              </FormControl></Grid>
            <Grid item={true} xs={2}></Grid>
          </>
        )}
        {stepCreate === 1 && (
          <>
            {/* Email */}
            {matches && <Grid item={true} xs={2}></Grid>}
            <Grid item={true} xs={2} style={{display:'flex',alignItems:'center'}}>
              Name
            </Grid>
            <Grid item={true} lg={3} xl={3} md={3} sm={3} xs={5}>
              <TextFieldCustom
                size="small"
                name="firstName"
                placeholder='Firstname'
                InputProps={{ classes: { input: classes.inputText } }}
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.firstName}
                error={
                  formik.touched.firstName && formik.errors.firstName
                    ? true
                    : false
                }
                textAlert={
                  formik.errors.firstName &&
                  formik.touched.firstName &&
                  formik.errors.firstName
                }
              />
            </Grid>
            <Grid item={true} lg={3} xl={3} md={3} sm={3} xs={5}>
              <TextFieldCustom
                size="small"
                name="lastName"
                placeholder='Lastname'
                InputProps={{ classes: { input: classes.inputText } }}
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.lastName}
                error={
                  formik.touched.lastName && formik.errors.lastName
                    ? true
                    : false
                }
                textAlert={
                  formik.errors.lastName &&
                  formik.touched.lastName &&
                  formik.errors.lastName
                }
              />
            </Grid>
            {matches && <Grid item={true} xs={2}></Grid>}
            {/* Gender */}
            {matches && <Grid item={true} xs={2}></Grid>}
            <Grid item={true} xs={2} style={{display:'flex',alignItems:'center'}}>
              Gender
            </Grid>
            <Grid item={true} lg={6} xl={6} md={6} sm={6} xs={10} >
              <SelectField
                name="gender"
                displayEmpty
                onChange={formik.handleChange}
                value={formik.values.gender}
                error={
                  formik.touched.gender && formik.errors.gender ? true : false
                }
                textAlert={
                  formik.errors.gender &&
                  formik.touched.gender &&
                  formik.errors.gender
                }
                renderValue={
                  formik.values.gender !== ""
                    ? undefined
                    : () => <div className={classes.hintText}>Gender</div>
                }
              >
                <MenuItem value={"M"}>Male</MenuItem>
                <MenuItem value={"F"}>Female</MenuItem>
              </SelectField>
            </Grid>
            {matches && <Grid item={true} xs={2}></Grid>}
            {/* Organization */}
            {matches && <Grid item={true} xs={2}></Grid>}
            <Grid item={true} xs={2} style={{display:'flex',alignItems:'center'}}>
              <div style={{fontSize: matches ? 16 : 12}}>Organization</div>
            </Grid>
            <Grid item={true} lg={6} xl={6} md={6} sm={6} xs={10}>
              <SelectField
                name="organization"
                displayEmpty
                onChange={formik.handleChange}
                value={formik.values.organization}  
                renderValue={
                  formik.values.organization !== ""
                    ? undefined
                    : () => <div className={classes.hintText}>Organization</div>
                }            
              >
                {organizationAll.map((item: any, index: number) => (
                  <MenuItem key={index} value={item._id}>
                    {item.orgName}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>
            {matches && <Grid item={true} xs={2}></Grid>}
            {/* Occupation */}
            {matches && <Grid item={true} xs={2}></Grid>}
            <Grid item={true} xs={2} style={{display:'flex',alignItems:'center'}}>
            <div style={{ fontSize: matches ? 16 : 12, }}>Occupation</div>
            </Grid>
            <Grid item={true} lg={6} xl={6} md={6} sm={6} xs={10}>
              <SelectField
                name="occupation"
                displayEmpty
                onChange={formik.handleChange}
                value={formik.values.occupation}
                error={
                  formik.touched.occupation && formik.errors.occupation
                    ? true
                    : false
                }
                textAlert={
                  formik.errors.occupation &&
                  formik.touched.occupation &&
                  formik.errors.occupation
                }
                renderValue={
                  formik.values.occupation !== ""
                    ? undefined
                    : () => <div className={classes.hintText}>Occupation</div>
                }      
              >
               <MenuItem value={"Medical Student"}>Medical Student</MenuItem>
              <MenuItem value={"Nursing Student"}>Nursing Student</MenuItem>
              <MenuItem value={"Paramedic Student"}>Paramedic Student</MenuItem>
              <MenuItem value={"Doctor"}>Doctor</MenuItem>
              <MenuItem value={"Nurse"}>Nurse</MenuItem>
              <MenuItem value={"First responder"}>First responder</MenuItem>
              <MenuItem value={"Other"}>Other</MenuItem>
              </SelectField>
            </Grid>
            {!Occupations.includes(formik.values.occupation) && (
                <>
                  <Grid item={true} xs={2}></Grid>
                  <Grid item={true} xs={2}></Grid>
                  <Grid item={true} xs={2}></Grid>
                  <Grid item={true} xs={6}>
                    <TextFieldCustom
                      name="occupationTmp"
                      size="small"
                      variant="outlined"
                      onChange={formik.handleChange}
                      value={formik.values.occupationTmp}
                      error={formik.touched.occupationTmp && formik.errors.occupationTmp ? true : false}
                      textAlert={
                        formik.errors.occupationTmp && formik.touched.occupationTmp && formik.errors.occupationTmp
                      }
                    />
                  </Grid>
                </>
              )}
            {matches && <Grid item={true} xs={2}></Grid>}
            {/* BirthDay */}
            {matches && <Grid item={true} xs={2}></Grid>}
            <Grid item={true} xs={2} style={{display:'flex',alignItems:'center'}}>
              BirthDay
            </Grid>
            <Grid item={true} lg={6} xl={6} md={6} sm={6} xs={8}>
              <TextFieldCustom
                size="small"
                variant="outlined"
                type="date"
                name="birthDate"
                onChange={formik.handleChange}
                value={formik.values.birthDate}
                error={
                  formik.touched.birthDate && formik.errors.birthDate
                    ? true
                    : false
                }
                textAlert={
                  formik.errors.birthDate &&
                  formik.touched.birthDate &&
                  formik.errors.birthDate
                }
              />
            </Grid>
            <Grid item={true} xs={2} style={{ display: "flex", alignItems: "center" }}>
              <Typography style={{ fontSize: 12 }}>
                Age &nbsp;
                {formik.values.birthDate
                  ? dayjs(new Date()).diff(
                      dayjs(formik.values.birthDate),
                      "year"
                    )
                  : "-"}
                &nbsp; Years old
              </Typography>
            </Grid>
            {/* Telephone */}
            {matches && <Grid item={true} xs={2}></Grid>}
            <Grid item={true} xs={2} style={{display:'flex',alignItems:'center'}}>
              Telephone
            </Grid>
            <Grid item={true} lg={6} xl={6} md={6} sm={6} xs={10}>
              <TextFieldCustom
                size="small"
                name="tel"
                variant="outlined"
                placeholder='Telephone'
                InputProps={{ classes: { input: classes.inputText } }}
                onChange={formik.handleChange}
                value={formik.values.tel}
                error={formik.touched.tel && formik.errors.tel ? true : false}
                textAlert={
                  formik.errors.tel && formik.touched.tel && formik.errors.tel
                }
              />
            </Grid>
            <Grid item={true} xs={2}></Grid>
          </>
        )}
        {stepCreate === 2 && formik.values.role && (
          <>
            {Permission.map((item: any, index: number) => {
              return (
                <Grid container style={{ marginTop: 20 }} spacing={2} key={index}>
                  <Grid item={true} xs={2}></Grid>
                  <Grid item={true} xs={8} key={index}>
                    <Button
                      fullWidth
                      className={classes.btnSelectPermission}
                      style={{
                        backgroundColor: addPermission[index]
                          ? "#E2FFF3"
                          : "#F5F5F7",
                        height: 73,
                      }}
                      onClick={() => handleClickPermission(index)}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <div style={{ fontSize: 16, fontWeight: 500 }}>
                            {item.title}
                          </div>
                          <div style={{ fontSize: 12, fontWeight: 300 }}>
                            {item.subTitle}
                          </div>
                        </div>
                        <CheckCircleIcon
                          style={{
                            fontSize: 45,
                            color: addPermission[index] ? "#60D0A1" : "#B9B9B9",
                          }}
                        />
                      </div>
                    </Button>
                  </Grid>
                  <Grid item={true} xs={2}></Grid>
                </Grid>
              );
            })}
          </>
        )}
      </Grid>
    </>
  );
};
export default ManagementUser;
