import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Grid,
  Typography,
  withStyles,
  Hidden,
  Modal,
  InputLabel,
  FormControl,
  FormLabel,
  FormHelperText,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../contexts/AuthContext";
import { useLocation } from "react-router-dom";
import { withRouter, RouteComponentProps, useHistory } from "react-router-dom";
import { ReactComponent as ArrowRight } from "../../../../images/pages/loginPage/arrowRight.svg";
import Alert from "@material-ui/lab/Alert";
import PersonIcon from "@material-ui/icons/Person";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import HttpsIcon from "@material-ui/icons/Https";
import logo_ever from "../../../../images/pages/loginPage/logo_ever.png";
import logo_rama from "../../../../images/pages/loginPage/logo_rama.png";
import seeYou from "../../../../images/pages/loginPage/seeYou.png";
import { ButtonContained } from "../../../commons/Buttons";
import { useStyles } from "./style";
import { TextFieldCustom } from "../../../commons/Inputs";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
interface ILoginForm extends RouteComponentProps {
  handleState: (text: string) => void;
}

const LoginForm = ({ handleState }: ILoginForm) => {
  const history = useHistory();
  const location: any = useLocation();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { login, logOut } = useAuth();
  const [modalStyle] = React.useState(getModalStyle);
  const [load, setLoad] = useState<boolean>(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const classes = useStyles();
  const validationSchema = Yup.object().shape({
    userName: Yup.string()
      .email("Must be a valid email")
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Must be a valid password")
      .required("Password is required"),
  });
  const formik = useFormik({
    initialValues: {
      showPassword: false,
      userName: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoad(true);
      let data = await login(values.userName, values.password);
      if (data?.status === 200) {
        enqueueSnackbar(t("authentication.signInSuccessFully"), {
          variant: "success",
        });
        setLoad(false);
      } 
      else if (data?.data.field === 'password'){
        enqueueSnackbar(t("password ไม่ถูกต้อง"), {
          variant: "error",
        });
        setLoad(false);
      }
      else if (data?.data.field === 'username'){
        enqueueSnackbar(t("ไม่มีข้อมูลในระบบ"), {
          variant: "error",
        });
        setLoad(false);
      }
      else{
        enqueueSnackbar(t("authentication.tryAgain"), {
          variant: "error",
        });
        setLoad(false);
      }
    },
  });
  function handleClickShowPassword() {
    formik.setFieldValue("showPassword", !formik.values.showPassword);
  }
  const body = (
    <div style={modalStyle} className={classes.modalPaper}>
      <h2
        style={{
          fontSize: 27,
          color: "#555555",
          textAlign: "center",
          marginBottom: 15,
        }}
      >
        {t("authentication.sorry")}
      </h2>
      <p
        style={{
          fontSize: 16,
          color: "#535353",
          textAlign: "center",
          marginBottom: 40,
        }}
      >
        {t("authentication.incorrectData")}
      </p>
      <div style={{ textAlign: "center" }}>
        <Button
          onClick={() => setShowModal(false)}
          className={classes.saveButton}
          variant="contained"
        >
          {t("authentication.tryAgain")}
        </Button>
      </div>
    </div>
  );

  return (
    <div className={classes.container}>
      {/* <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal> */}
      {!location?.state?.detail ? (
        <form
          // className={classes.rootForm}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          noValidate
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <div>
            <div style={{ marginBottom: 70 }}>
              <img src={logo_rama} alt="" style={{ marginRight: 5 }} />
              <img src={logo_ever} alt="" />
              <Typography
                style={{
                  fontSize: 24,
                  color: "#5A5A5A",
                  fontWeight: 700,
                }}
              >
                Welcome to
              </Typography>
              <Typography
                style={{
                  fontSize: 24,
                  color: "#51B2DA",
                  fontWeight: 700,
                }}
              >
                Ramathibodi Virtual Reality Triage
              </Typography>
            </div>

            <FormControl className={classes.formControl} fullWidth>
              <FormLabel htmlFor="input-with-icon-adornment">
                Sign in to Continue
              </FormLabel>
              <TextFieldCustom
                InputLabelProps={{
                  shrink: true,
                  // className: classes.formControl,
                }}
                error={
                  formik.touched.userName && formik.errors.userName
                    ? true
                    : false
                }
                textAlert={
                  formik.errors.userName &&
                  formik.touched.userName &&
                  formik.errors.userName
                }
                id="userName"
                name="userName"
                value={formik.values.userName}
                onChange={formik.handleChange}
                // style={{ width: "100%", maxWidth: 566 }}
                placeholder={t("Email")}
                margin="normal"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div style={{ padding: 12 }}>
                        <div className={classes.colorIcon}>
                          <PersonIcon />
                        </div>
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </div>
          <div>
            <FormControl className={classes.formControl} fullWidth>
              <TextFieldCustom
                onKeyDown={(event) => {
                  if (event.key === "Enter" && !load) {
                    formik.submitForm();
                  }
                }}
                error={
                  formik.errors.password && formik.touched.password
                    ? true
                    : false
                }
                textAlert={
                  formik.errors.password &&
                  formik.touched.password &&
                  formik.errors.password
                }
                id="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                style={{ width: "100%", maxWidth: 566 }}
                placeholder={t("Password")}
                margin="normal"
                variant="outlined"
                type={formik.values.showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        size="small"
                        edge="end"
                      >
                        <div className={classes.colorIcon}>
                          {formik.values.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </div>
                      </IconButton>
                    </InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment position="start">
                      <div style={{ padding: 12 }}>
                        <div className={classes.colorIcon}>
                          <HttpsIcon />
                        </div>
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
              {/* <FormHelperText style={{ color: "#F15F5F", fontSize: 12 }}>
                Forget Password?
              </FormHelperText> */}
            </FormControl>
          </div>
          {/* <Hidden smDown>
          {showErrorAlert && errorAlert(t("authentication.incorrectData2"))}
        </Hidden> */}
          <Grid container className={classes.containerBelow}>
            <Grid item xs={12}>
              <div className={classes.divSave}>
                <ButtonContained
                  colorText="blue"
                  variant="contained"
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                >
                  {/* {t("authentication.login")} */}
                  Sign In
                </ButtonContained>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 50,
                  alignItems: "baseline",
                }}
              >
                <div
                  // className={classes.divSave}
                  style={{
                    fontSize: 14,
                    // marginTop: 50,
                    color: "#9C9B9D",
                  }}
                >
                  Dont't have an account?
                </div>
                <a
                  id="myLink"
                  href="#"
                  onClick={() => handleState("createAccount")}
                  style={{
                    fontSize: 16,
                    color: "#51B2DA",
                    fontWeight: 600,
                    marginLeft: 4,
                  }}
                >
                  Create Account
                </a>
              </div>
            </Grid>
          </Grid>
          <Backdrop className={classes.backdrop} open={load}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </form>
      ) : (
        <div>
          <Typography
            style={{
              fontSize: 48,
              color: "#5A5A5B",
              fontWeight: 700,
            }}
          >
            See you again
          </Typography>
          <img style={{ margin: "70px 0px" }} src={seeYou} alt="" />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ color: "#9C9B9D", fontSize: 12 }}>
              Contact : Mail@admin.com
            </div>
            <div>
              <a
                style={{ fontSize: 14, color: "#51B2DA", marginTop: 48 }}
                href="#"
                onClick={() => history.replace({ state: { detail: "" } })}
              >
                Go back to login
              </a>
              <ArrowRight style={{ marginLeft: 5 }} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(LoginForm);
