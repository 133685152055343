import React from "react";
import ClassResultMock from "../../../../commons/asset/ClassResultMock.jpg";
import PDFResultTriage from "../PDFResultTriage";

interface IProps {
  formik: any;
  dataLogTriagePatients: any;
}
export default function ExportClassResult({
  formik,
  dataLogTriagePatients,
}: IProps) {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <PDFResultTriage formik={formik} dataLogTriagePatients={dataLogTriagePatients}/>
    </div>
  );
}
