import axiosInstance from "../index";

import { REACT_APP_API } from '../../config/config';

export interface IClassroom {
  active: boolean;
  code: string;
  desc: string;
  display: string;
  classDate: Date;
  status: string;
  instructorId: string;
  scenarios: Array<any>;
  students: Array<any>;
  createdUser: Array<any>;
  mode:string;
}

export async function fetchClassroomsApi(inputQuery: any) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .get(`${REACT_APP_API}/v1/classrooms`, {
      headers: headers,
      params: inputQuery
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export async function fetchClassroomsApiPagination(inputQuery: any) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .get(`${REACT_APP_API}/v1/classrooms/pagination`, {
      headers: headers,
      params: inputQuery
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export async function fetchUserAllApi() {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .get(`${REACT_APP_API}/v1/users`, {
      headers: headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export async function fetchClassroomMeApi(inputQuery?: any) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .get(`${REACT_APP_API}/v1/classrooms/me`, {
      headers: headers,
      params: inputQuery
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export async function fetchClassroomsMeApiPagination(inputQuery: any) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .get(`${REACT_APP_API}/v1/classrooms/me/pagination`, {
      headers: headers,
      params: inputQuery
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export async function fetchScenariosApi() {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .get(`${REACT_APP_API}/v1/scenarios`, {
      headers: headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export async function addClassroomsApi(dataClassroom: any[]) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .post(
      `${REACT_APP_API}/v1/classrooms/insert-many`,
      dataClassroom,
      {
        headers: headers,
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export async function updateClassroomsApi(dataClassroom: any) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .patch(
      `${REACT_APP_API}/v1/classrooms/update-data`,
      dataClassroom,
      {
        headers: headers,
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export async function deactiveClassroomsApi(dataClassroomId: any) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .patch(
      `${REACT_APP_API}/v1/classrooms/deactivate`,
      dataClassroomId,
      {
        headers: headers,
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export async function addClassroomStudentsApi(classroomId: string, studentIds: Array<string>) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .patch(`${REACT_APP_API}/v1/classrooms/add-students`,
      {
        classroomid: classroomId,
        students: studentIds
      },
      {
        headers: headers,
      })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export async function removeClassroomStudentsApi(classroomId: string, studentIds: Array<string>) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .patch(`${REACT_APP_API}/v1/classrooms/remove-students`,
      {
        classroomid: classroomId,
        students: studentIds
      },
      {
        headers: headers,
      })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export async function generatePDF(date: string, classId: string) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .post(`${REACT_APP_API}/v1/certificates/session-result-pdf`,
      {
        date: date,
        classId: classId
      },
      {
        headers: headers,
      })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export async function fetchLogTriagePatient(classroomId: string) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .get(`${REACT_APP_API}/v1/log-triage-patients/${classroomId}`,
      {
        headers: headers,
      })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export async function fetchCertificateById(params: string) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .get(`${REACT_APP_API}/v1/certificates/${params}`,
      {
        headers: headers,
      })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export async function fetctDisplayResultTriage(classroomId: string) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .get(`${REACT_APP_API}/v1/display-result-triage/${classroomId}`,
      {
        headers: headers,
      })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function createPDFResult(dataCreatePDF: any) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .patch(
      `${REACT_APP_API}/v1/classrooms/pdf-result`,
      dataCreatePDF,
      {
        headers: headers,
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}