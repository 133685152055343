import React, { useEffect } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import Certificate from "../../commons/asset/Certificate_Name.png";
import OurCertificates from "./ourElement/ourCertificates";

export default function Certificates({ certificates, userName }: any) {
  const { currentUser } = useAuth();
  return (
    <div
      style={{
        height: 365,
        backgroundColor: "rgba(165, 166, 246, 0.15)",
        borderRadius: 16,
      }}
    >
      <div style={{ padding: 10 }}>
        <div
          style={{
            fontSize: 22,
            fontWeight: 700,
            color: "#5A5A5B",
            margin: 10,
          }}
        >
          Certificate
        </div>
        <div>
          {certificates?.length > 0 && (
            <OurCertificates certificates={certificates} userName={userName} />
          )}
        </div>
      </div>
    </div>
  );
}
